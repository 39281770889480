import { useEffect, useMemo, useState } from "react";
import styles from "../../../../assets/styles/components/generals/forms/form";
import Input from "../Input";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";

function Form({ formSettings, loading, isSmallVersion, wrapperStyles }) {
  const {
    title,
    onSubmit,
    inputs,
    buttonLabel,
    buttonLabelDecline = '',
    onDecline = null,
    otherInput = null
  } = formSettings;

  const initialValuesSettings = () => {
    const values = {}
    inputs.forEach(input => {
      const { defaultValue, name } = input
      if (defaultValue) {
        values[name] = defaultValue
      }
    })
    return values
  }
  const initialValues = initialValuesSettings()
  const [formData, setFormData] = useState(initialValues);

  const realInputs = useMemo(() => {
    const alteredInputs = [ ...inputs ]
    if (
      typeof formData?.other !== 'undefined' &&
      formData?.other === true
    ) {
      const {
        name = "other-text-input",
        label = 'Other',
        type = 'text',
        required = true,
        disabled = false,
      } = otherInput
      alteredInputs.push({
        label,
        type,
        required,
        disabled,
        name
      })
    }
    return alteredInputs
  }, [formData?.other]) || []

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  const { disabled } = useMemo(() => {
    const notValidateThroughValues =
      realInputs.some(input => Boolean(input?.onChange))
    if (!notValidateThroughValues) {
      const values = Object.values(formData)
      const requiredFields = realInputs.filter(input => input.required);
      const requiredFieldValues = requiredFields.map(field => formData[field.name]);
      const valuesAreFilledOut = requiredFieldValues.every(value => {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else {
          return Boolean(value);
        }
      });

      const atLeastOneCheckboxes = realInputs.filter(input => input.atLeastOne);
      const atLeastOneCheckboxesValues = atLeastOneCheckboxes.map(field => formData[field.name]);
      const oneCheckboxIsFilledOut = atLeastOneCheckboxesValues.some(value => {
        return value === true
      });

      const everyValueExists =
        valuesAreFilledOut &&
        values.length >= requiredFields.length &&
        (atLeastOneCheckboxesValues.length === 0 || oneCheckboxIsFilledOut)
      return { disabled: !everyValueExists }

    }
    return {
      disabled: false
    }
  }, [formData])

  return (
    <div className={`${wrapperStyles ? `${wrapperStyles} ` : ''}${styles.wrapper(isSmallVersion)}`}>
      <div>
        {title ? <h1 className={styles.title}>{title}</h1> : null}
        <div>
          {realInputs.map((input, key) =>
            <Input
              key={key}
              input={input}
              values={formData}
              handleChange={handleChange}
              isSmallVersion={isSmallVersion}
            />
          )}
          <ApprovalButtons
            onApprove={(e) => onSubmit(e, formData)}
            titleAccept={buttonLabel}
            titleDecline={buttonLabelDecline}
            dissapearDecline={!Boolean(onDecline)}
            onDecline={(e) => onDecline(e, formData)}
            disabledAccept={disabled || loading}
            disabledDecline={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default Form;
