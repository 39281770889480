import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  IconButton,
  Table,
  TableContainer,
  TablePagination,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTableHead from "./CustomTableHead";
import CustomTableBody from "./CustomTableBody";
import handleSearch from "./utils/handleSearch";
import LoadingScreen from "../../../assets/LoadingScreen";
import handleSearchInsured from "./utils/handleSearchInsured";
import handleSearchPolicy from "./utils/handleSearchPolicy";
import axios from "axios";
import updatePolicyField from "../../../utils/updatePolicyField";
import { collectionGroup, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

function EndoSearch({
  userInfo,
  onlyForOneInsured,
  insuredID,
  onlyForOnePolicy,
  policyID,
  formData,
  setMtcEndorsements,
  mtcEndorsements,
  apdEndorsements,
  setApdEndorsements,
  loadingAPD,
  setLoadingAPD,
  loadingMTC,
  insuredInfo
}) {
  const [loading, setLoading] = useState(false);
  const [queryText, setQueryText] = useState("");
  const [page, setPage] = useState(0);
  const [filterText, setFilterText] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [results, setResults] = useState([]);
  const [driverNameFilter, setDriverNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(
    onlyForOneInsured || onlyForOnePolicy ? "All" : "Submitted"
  );
  const [searchFunction, setSearchFunction] = useState("status");
  const [outstandingEndorsements, setOutstandingEndorsements] = useState(0);
  const [selectedType, setSelectedType] = useState("");

  const userAgencyID = userInfo.agencyID;

  const [sortField, setSortField] = useState("endoNumber"); // Default sort field
  const [sortDirection, setSortDirection] = useState("asc"); // Default sort direction
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilter = (text) => {
    setPage(0);
    setFilterText(text);
    const filtered = results.filter((result) =>
      result.namedInsured.toLowerCase().includes(text.toLowerCase())
    );
    setFilteredResults(filtered);
  };

  const handleStatusFilter = (status) => {
    setPage(0);
    if (status === "") {
      setFilteredResults(results);
      return;
    }
    setStatusFilter(status);
    const filtered = results.filter((result) => result.status === status);
    setFilteredResults(filtered);
  };

  const handleDriverNameFilter = (text) => {
    setPage(0);
    setDriverNameFilter(text);
    const filtered = results.filter(
      (result) =>
        Array.isArray(result.searchData) &&
        result.searchData.some(
          (driverName) =>
            typeof driverName === "string" &&
            driverName.toLowerCase().includes(text.toLowerCase())
        )
    );
    setFilteredResults(filtered);
  };

  const handleTypeFilter = (type) => {
    if (type === "Filter By Type") {
      setFilteredResults(results);
      return;
    }
    setPage(0);
    setSelectedTypeFilter(type);
    const filtered = results.filter(
      (result) => result.type === type || result.type === type + "s"
    );
    setFilteredResults(filtered);
  };

  useEffect(() => {
    if (statusFilter === "") {
      setFilteredResults(results);
      return;
    }
    handleStatusFilter(statusFilter);
  }, [results, statusFilter]);

  useEffect(() => {
    handleFilter(filterText);
  }, [results, filterText]);

  const partialName = searchFunction === "partialName";
  const approvedStatus =
    searchFunction === "status" && selectedStatus === "Approved";
  const [endoNo, setEndoNo] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [selectedTypeFilter, setSelectedTypeFilter] =
    useState("Filter By Type");

  const handleSubmit = (initialSearch) => {
    if (onlyForOneInsured) {
      handleSearchInsured(
        initialSearch,
        queryText,
        searchFunction,
        insuredID,
        selectedStatus,
        setOutstandingEndorsements,
        setResults,
        setLoading,
        selectedType
      );
    } else if (onlyForOnePolicy) {
      handleSearchPolicy(
        initialSearch,
        queryText,
        searchFunction,
        policyID,
        selectedStatus,
        setOutstandingEndorsements,
        setResults,
        setLoading,
        selectedType
      );
    } else
      handleSearch(
        initialSearch,
        queryText,
        searchFunction,
        userAgencyID,
        selectedStatus,
        setOutstandingEndorsements,
        setResults,
        setLoading,
        selectedType
      );
  };

  // get default search when page loads
  useEffect(() => {
    ((searchFunction === "status" && selectedStatus === "Submitted") ||
      onlyForOnePolicy ||
      onlyForOneInsured) &&
      handleSubmit(true);
  }, []);

  const [loadedPolicies, setLoadedPolicies] = useState(false);
  const [policies, setPolicies] = useState({
    activeAPDPolicies: [],
    activeMTCPolicies: [],
  });
  const getAPDMTCEndos = async (token) => {
    if (!onlyForOnePolicy) return;

    console.log("Getting APD/MTC endos...");

    // TODO: Hide this URL from public view
    const dotNumber = onlyForOnePolicy ? formData.insuredID : null;
    const requestURL =
      "https://kpbrokersapi.com/api/policy/getpolicies?agentId=a1ptN3ZQcGhyTnM9";

    try {
      const { data: policies } = await axios.get(requestURL, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log("Policies fetched:", policies);

      // Filter policies based on DOT number
      const filteredResults = policies.filter(
        (obj) => parseInt(obj.dotNumber) === parseInt(dotNumber) || obj.insured?.toUpperCase().trim() === insuredInfo.company?.toUpperCase().trim()
      );

      const currentDate = formData.effectiveDate.toDate();

      // Function to filter active policies
      const filterActivePolicies = (policy, type) => {
        let expiryDate = new Date(policy.expiryDate);
        let effectiveDate = new Date(policy.inceptionDate);
        expiryDate.setHours(0, 1, 0, 0);
        effectiveDate.setHours(0, 1, 0, 0);

        return (
          effectiveDate.getTime() === currentDate.getTime() &&
          policy.coverageType === type
        );
      };

      const activeAPDPolicies = filteredResults.filter((p) =>
        filterActivePolicies(p, "Auto Physical Damage")
      );
      const activeMTCPolicies = filteredResults.filter((p) =>
        filterActivePolicies(p, "Motor Truck Cargo")
      );

      setPolicies({ activeAPDPolicies, activeMTCPolicies });

      // Function to fetch endorsements for a given policy
      const getEndosForKPPolicy = async (policy, policyType) => {
        try {
          const { data: endos } = await axios.get(
            `https://kpbrokersapi.com/api/endorsement/getendorsementsbypolicy?agentId=a1ptN3ZQcGhyTnM9&policyId=${policy.policyId}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          return endos.map((endo) => ({ ...endo, policyType }));
        } catch (error) {
          console.error(
            `Error fetching endorsements for ${policyType}:`,
            error
          );
          return [];
        }
      };

      // Fetch endorsements concurrently
      const apdEndos = (
        await Promise.all(
          activeAPDPolicies.map((p) => getEndosForKPPolicy(p, "APD"))
        )
      ).flat();
      const mtcEndos = (
        await Promise.all(
          activeMTCPolicies.map((p) => getEndosForKPPolicy(p, "MTC"))
        )
      ).flat();

      console.log("Active APD Endorsements:", apdEndos);
      console.log("Active MTC Endorsements:", mtcEndos);

      setApdEndorsements(apdEndos);
      setMtcEndorsements(mtcEndos);

      setLoadedPolicies(true);

      // Update coverage fields if necessary
      if (activeAPDPolicies.length > 0 && formData.hasAPDCoverage !== true) {
        updatePolicyField(policyID, "hasAPDCoverage", true);
      }
      if (activeMTCPolicies.length > 0 && formData.hasMTCCoverage !== true) {
        updatePolicyField(policyID, "hasMTCCoverage", true);
      }
    } catch (error) {
      console.error("Error fetching policies:", error);
    }
  };

  const getToken = async () => {
    // TODO: Hide this URL from public view
    const requestURL =
      "https://kpbrokersapi.com/api/access?username=southernstarmga&password=S0utH3rNP@assWord";
    axios.get(requestURL).then((results) => {
      getAPDMTCEndos(results?.data?.token);
    });
  };

  useEffect(() => {
    console.log('trying')
    console.log('only for one insured: ', onlyForOneInsured)
    console.log('insuredID: ', formData.insuredID)
    console.log('loadedPolicies: ', loadedPolicies)
    onlyForOnePolicy &&
      formData?.insuredID &&
      !loadedPolicies &&
      getToken()
  }, [loadedPolicies, formData.insuredID, ]);

  const [returnedEndorsements, setReturnedEndorsements] = useState([]);

  useEffect(() => {
    const returnedEndorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("agencyID", "==", userAgencyID),
      where("status", "==", "Returned")
    );
  
    const unsubscribe = onSnapshot(
      returnedEndorsementsQuery,
      (querySnapshot) => {
        const returnedEndorsements = querySnapshot.docs.map((doc) => {
          return {...doc.data(), id: doc.id};
        });
        setReturnedEndorsements(returnedEndorsements);
      },
      (error) => {
        console.error("Error fetching returned endorsements:", error);
      }
    );
  
    return () => unsubscribe();
  }, [userAgencyID]);
  

  return (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <LoadingScreen
          // TODO: TRANSLATE THIS
          text={
            "Searching, please wait" +
            (partialName || approvedStatus
              ? ". This may take several minutes..."
              : "...")
          }
        />
      ) : (
        <>
          {/* AL Endo Search */}
          <section>
            {returnedEndorsements.length > 0 && !onlyForOneInsured && !onlyForOnePolicy && (
              <section className="bg-red-100 p-4 rounded-lg mb-4 shadow-md">
                <Typography variant="h7">
                  You currently have returned Endorsements that need to be
                  corrected.
                </Typography>
                <div className="mt-4">
                <TableContainer>
                  <Table>
                    <CustomTableHead
                      sortDirection={sortDirection}
                      sortField={sortField}
                      setSortDirection={setSortDirection}
                      setSortField={setSortField}
                    />
                    <CustomTableBody
                      sortDirection={sortDirection}
                      sortField={sortField}
                      results={returnedEndorsements}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                  </Table>
                </TableContainer>
                </div>
              </section>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              {!onlyForOneInsured && !onlyForOnePolicy && (
                <Typography variant="h7">
                  Outstanding Endorsements: {outstandingEndorsements}
                </Typography>
              )}
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <TextField
                  variant="outlined"
                  size="small"
                  sx={{ width: 160 }} // Adjust width as needed for ~12 characters
                  placeholder="Jump To Endo No."
                  value={endoNo}
                  onChange={(e) => setEndoNo(e.target.value)}
                />
                <Button
                  disabled={endoNo.length > 0 ? false : true}
                  onClick={(e) => window.open("/endo/" + endoNo, "_blank")}
                  variant="contained"
                  size="small"
                  sx={{ textTransform: "none" }} // Keeps button text normal case
                >
                  Go
                </Button>
              </Box>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Select
                value={searchFunction}
                onChange={(e) => {
                  setSearchFunction(e.target.value);
                  setResults([]);
                  setSelectedStatus("Submitted");
                }}
                sx={{ mr: 1 }}
              >
                <MenuItem value="status">Search By Status</MenuItem>
                <MenuItem value="type">Search By Type</MenuItem>
                {!onlyForOneInsured && !onlyForOnePolicy && (
                  <MenuItem value="company">Search By Company Name</MenuItem>
                )}
                <MenuItem value="driver">Search By Driver Name</MenuItem>
                <MenuItem value="partialName">Search By Partial Name</MenuItem>
                <MenuItem value="license">
                  Search By Driver License Number
                </MenuItem>
                <MenuItem value="VIN">Search By VIN</MenuItem>
              </Select>
              {searchFunction === "status" ? (
                <Select
                  value={selectedStatus}
                  onChange={(e) => {
                    setSelectedStatus(e.target.value);
                  }}
                  sx={{ mr: 1, width: "100%" }}
                >
                  {(onlyForOneInsured || onlyForOnePolicy) && (
                    <MenuItem value="All">Show All</MenuItem>
                  )}
                  <MenuItem value="Submitted">Submitted</MenuItem>
                  <MenuItem value="Returned">Returned</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                  <MenuItem value="Declined">Declined</MenuItem>
                  <MenuItem value="Exception Request">
                    Exception Request
                  </MenuItem>
                </Select>
              ) : searchFunction === "type" ? (
                <Select
                  value={selectedType}
                  onChange={(e) => {
                    setSelectedType(e.target.value);
                  }}
                  sx={{ mr: 1, width: "100%" }}
                >
                  <MenuItem value="Add Power Units">Add Power Units</MenuItem>
                  <MenuItem value="Remove Power Units">
                    Remove Power Units
                  </MenuItem>
                  <MenuItem value="Add Drivers">Add Drivers</MenuItem>
                  <MenuItem value="Remove Drivers">Remove Drivers</MenuItem>
                </Select>
              ) : (
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  value={queryText}
                  onChange={(e) => setQueryText(e.target.value)}
                />
              )}
              <IconButton onClick={(e) => handleSubmit(false)} sx={{ ml: 1 }}>
                <SearchIcon /> <span className="text-sm"> Search</span>
              </IconButton>
            </Box>
            <Button onClick={() => setShowFilters(!showFilters)} sx={{ mb: 2 }}>
              {showFilters ? "Hide " : "Show "} Filters
            </Button>
            {showFilters &&
              results.length > 0 &&
              searchFunction !== "company" && (
                <>
                  <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <TextField
                      label="Filter By Company Name"
                      variant="outlined"
                      fullWidth
                      value={filterText}
                      onChange={(e) => handleFilter(e.target.value)}
                    />
                  </Box>
                  {searchFunction !== "type" && (
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                      <Select
                        value={selectedTypeFilter}
                        placeholder="Filter By Type"
                        onChange={(e) => {
                          handleTypeFilter(e.target.value);
                        }}
                        sx={{ mr: 1, width: "100%" }}
                      >
                        <MenuItem value="Filter By Type">
                          Filter By Type
                        </MenuItem>
                        <MenuItem value="Add Power Unit">
                          Add Power Unit
                        </MenuItem>
                        <MenuItem value="Remove Power Unit">
                          Remove Power Unit
                        </MenuItem>
                        <MenuItem value="Add Driver">Add Driver</MenuItem>
                        <MenuItem value="Remove Driver">Remove Driver</MenuItem>
                      </Select>
                    </Box>
                  )}
                </>
              )}
            {showFilters &&
              results.length > 0 &&
              searchFunction !== "driver" &&
              searchFunction !== "license" && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <TextField
                    label="Filter By Driver Name, License or VIN"
                    variant="outlined"
                    fullWidth
                    value={driverNameFilter}
                    onChange={(e) => handleDriverNameFilter(e.target.value)}
                  />
                </Box>
              )}
            {showFilters &&
              results.length > 0 &&
              searchFunction !== "status" && (
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <FormControl fullWidth sx={{ mr: 1 }}>
                    <InputLabel id="statusFilterLabel">
                      Filter Status
                    </InputLabel>
                    <Select
                      labelId="statusFilterLabel" // Associate label with Select
                      id="statusFilter"
                      value={statusFilter}
                      onChange={(e) => handleStatusFilter(e.target.value)}
                      label="Filter Status" // This will show as the floating label
                    >
                      <MenuItem value="Submitted">Show Submitted</MenuItem>
                      <MenuItem value="Approved">Show Approved</MenuItem>
                      <MenuItem value="Declined">Show Declined</MenuItem>
                      <MenuItem value="Exception Request">
                        Show Exception Request
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              )}
            <TableContainer>
              <Table>
                <CustomTableHead
                  sortDirection={sortDirection}
                  sortField={sortField}
                  setSortDirection={setSortDirection}
                  setSortField={setSortField}
                />
                <CustomTableBody
                  sortDirection={sortDirection}
                  sortField={sortField}
                  results={filteredResults}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              component="div"
              count={filteredResults.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </section>
        </>
      )}
    </Box>
  );
}

export default EndoSearch;
