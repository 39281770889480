import { addDoc, collection, doc, runTransaction } from "firebase/firestore";
import { auth, db } from "../../firebase";
import getCancelledPolicyReturnPremium from "../utils/getCancelledPolicyReturnPremium";
import updateSystemLog from "../utils/updateSystemLog";
import endoStatusChangeNotification from "../utils/notifications/endoStatusChangeNotification";

export const cancelPolicyFn = async (
  event,
  cancellationData,
  formData,
  insuredInfo,
  policyID,
  setLoading,
  goBack
) => {
  try {
    if (event && event?.preventDefault) event.preventDefault();
    const hasGLCoverage = formData.hasGLCoverage;
    const hasALCoverage = formData.hasALCoverage;
    const hasAPDCoverage = formData.hasAPDCoverage;
    const hasMTCCoverage = formData.hasMTCCoverage;
    console.log(formData)
    let otherPoliciesToCancel = {
      AL: null,
      GL: null,
      APD: null,
      MTC: null,
    };
    const shouldCancelGL = !hasGLCoverage ? null : window.confirm(
      "Would you also like to cancel the GL policy?"
    );
    const shouldCancelAPD = !hasAPDCoverage ? null : window.confirm(
      "Would you also like to cancel the APD policy?"
    );
    const shouldCancelMTC = !hasMTCCoverage ? null : window.confirm(
      "Would you also like to cancel the MTC policy?"
    );
    if (hasGLCoverage) {
      if (shouldCancelGL) {
        otherPoliciesToCancel.GL = formData.glPolicyID;
      }
    }
    if (hasAPDCoverage) {
      if (shouldCancelAPD) {
        otherPoliciesToCancel.APD = formData.apdPolicyID;
      }
    }
    if (hasMTCCoverage) {
      if (shouldCancelMTC) {
        otherPoliciesToCancel.MTC = formData.mtcPolicyID;
      }
    }

    setLoading(true);
    await runTransaction(db, async (transaction) => {
      const countersDocRef = doc(db, "unique-numbers", "endorsements");
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });
      const alPremiums = await getCancelledPolicyReturnPremium(
        formData.effectiveDate,
        cancellationData.cancellationDate,
        formData
      );
      const payload = {
        endorsementNumber: parseInt(newCounter),
        endoEffectiveDate: cancellationData.cancellationDate,
        policyID,
        type: "Cancellation",
        dateCreated: new Date(),
        status: "Submitted",
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        alPremiums,
        otherPoliciesToCancel,
        alPolicyNo: formData.alPolicyNo || "",
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        documentSource: "Endorsement",
        cancellationReasons: ["Cancellation requested by the insured"],
      };
      const addRef = await addDoc(
        collection(db, "policy-applications", policyID, "endorsements"),
        payload
      );
      updateSystemLog(
        auth,
        policyID,
        "Add Cancellation Endorsement Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        `Add Cancellation Endorsement`,
        cancellationData
      );
      endoStatusChangeNotification(
        "Cancellation",
        parseInt(newCounter),
        "Submitted",
        addRef.id,
        policyID,
        formData.agencyID
      );
      setLoading(false);
      goBack();
    });
  } catch (error) {
    setLoading(false);
    console.error("Error submitting cancellation request:", error);
  }
};
