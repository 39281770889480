import React, { useMemo, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  runTransaction,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Col, Container, Row } from "../../generals/GridSystem";
import { removeLastS } from "../../../utils/helpers";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import Popup from "../../generals/Popup";
import DriverDetails from "../../PolicyLifecycleManager/Drivers/DriverDetails";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";

export default function RemoveOne({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  subcollection,
  details,
  requestExceptionInputs,
  userInfo
}) {
  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const determineDeletionStatus = () => {
    return endorsement?.type?.includes("Exclude") ? "Excluded" : "Deleted";
  };

  const deletionStatus = determineDeletionStatus();

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);

  const approveAUEndorsement = async (e, requestExceptionData) => {
    const { agencyID, insuredID } = endorsement;
    e.preventDefault();
    const updateEndorsementStatusAndCreateDriver = async (
      endorsementId,
      status,
      payload
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );

      const {
        endoEffectiveDate,
        data: { id: subcollectionId, otherSideOfMirrorId },
        data,
      } = payload;

      const subCollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        subcollection,
        subcollectionId
      );

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = new Date()
          const payload = {
            documentType: "Endorsement",
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            payload["wasARequestException"] = true;
            payload["requestException"] = requestExceptionData;
          }
          await transaction.update(endorsementRef, payload);

          const payloadResult = setPolicyInfo(
            data,
            {
              policyEffectiveDate: data.effectiveDate,
              glPolicyNo: endorsement?.glPolicyNo,
              alPolicyNo: endorsement?.alPolicyNo,
              policyID: paramPolicyID,
              status: deletionStatus,
            },
            "endo-deletion"
          );

          if (typeof endorsement?.blacklistExpirationDate !== "undefined") {
            payloadResult["blacklistExpirationDate"] =
              endorsement.blacklistExpirationDate;
          }

          await setDoc(subCollectionRef, payloadResult, { merge: true });

          if (otherSideOfMirrorId) {
            const subCollectionRef = doc(
              db,
              "agencies",
              agencyID,
              "insureds",
              insuredID,
              subcollection,
              otherSideOfMirrorId
            );
            await setDoc(subCollectionRef, payloadResult, { merge: true });
          }

          if (deletionStatus === "Excluded") {
            await addDoc(collection(db, "drivers-blacklist"), {
              ...endorsement.data,
              blacklistedDate: new Date(),
            });
          }
        });
      } catch (error) {
        console.error(
          `Error updating endorsement status
          and creating in ${subcollection} collection`,
          error
        );
      }
    };

    const policyRef = doc(db, "policy-applications", paramPolicyID);
    const policyDoc = await getDoc(policyRef);
    const policyData = policyDoc.data();
    const policyStatus = policyData.status;
    if (policyStatus === "In-Cancellation") {
      setOpenInCancellationModal(true);
      return;
    }

    updateEndorsementStatusAndCreateDriver(paramRefID, "Approved", endorsement);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAUEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      documentType: "Endorsement",
      status: "Declined",
      wasARequestException: false,
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(endoRef, payload);

    const { agencyID, insuredID, data } = endorsement;
    const subCollectionRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      subcollection,
      data.id
    );
    const payloadResult = setPolicyInfo(
      data,
      {
        policyEffectiveDate: data.effectiveDate,
        glPolicyNo: endorsement?.glPolicyNo,
        alPolicyNo: endorsement?.alPolicyNo,
        policyID: paramPolicyID,
        status: "Active",
      },
      "endo-deletion"
    );
    await setDoc(subCollectionRef, payloadResult, { merge: true });

    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");

  const handleOpenDetailsModal = (id) => {
    setSelectedDriver(id);
    setOpenDetailsModal(!openDetailsModal);
  };

  return (
    <div>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <Popup
        isOpen={openDetailsModal}
        onRequestClose={() => setOpenDetailsModal(false)}
        maxWidth="inherit"
        showExpandButton={true}
      >
        <div>
          <DriverDetails
            formData={endorsement}
            userInfo={user}
            selectedDriver={selectedDriver}
            setShowDriverDetails={setOpenDetailsModal}
            policyID={paramPolicyID}
          />
        </div>
      </Popup>

      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />

      <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
        <Container className="!p-0">
          {details.map((detail, detailIndex) => (
            <Row
              onClick={() => {
                if (subcollection === "drivers") {
                  handleOpenDetailsModal(endorsement.data.id);
                }
              }}
              key={detailIndex}
              className="mb-1 cursor-pointer"
            >
              <Col sm={12} md={6}>
                <p className="font-semibold">{detail.label}:</p>
                <p>{detail.data}</p>
              </Col>
            </Row>
          ))}
        </Container>
      </div>

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      user.role === "Underwriter" ? (
        <>
          <article className="mt-3">
            <p
              className={
                isRequestException
                  ? "text-red-700 text-center !max-w-[345px] mx-auto"
                  : ""
              }
            >
              {isRequestException
                ? `This endorsement has already been declined. 
                You are accepting/decling an exception request`
                : `Approving this endorsement will remove the
                above ${removeLastS(subcollection)} to the
                policy and notify the insured of the change.`}
            </p>
          </article>
          {isRequestException ? (
            <RequestExceptions
              endorsement={endorsement}
              color="red"
              center={true}
            />
          ) : null}
          {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
            <ApprovalButtons
              onApprove={approveAUEndorsement}
              onDecline={() => setRejectionModal(true)}
            />
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </div>
  );
}
