import React, { useState } from "react";
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
} from "@mui/material";

export default function PolicyRatesReport({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Slice data based on the current page and rows per page
  const paginatedData = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  console.log("data: ", data);

  return (
    <Container className="mt-5">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Policy</TableCell>
              <TableCell>LOB</TableCell>
              <TableCell>Effective</TableCell>
              <TableCell>DOT</TableCell>
              <TableCell>Named Insured</TableCell>
              <TableCell>Zip Code</TableCell>
              <TableCell>County</TableCell>
              <TableCell>Product</TableCell>
              <TableCell>CSL</TableCell>
              <TableCell>Cargo Type</TableCell>
              <TableCell>State</TableCell>
              <TableCell>UIM</TableCell>
              <TableCell>91X</TableCell>
              <TableCell>NOA</TableCell>
              <TableCell>MCP65/Form-E</TableCell>
              <TableCell>Vehicle Count</TableCell>
              <TableCell>Radius 50</TableCell>
              <TableCell>Radius 100</TableCell>
              <TableCell>Radius 250</TableCell>
              <TableCell>Radius 500</TableCell>
              <TableCell>Radius 800</TableCell>
              <TableCell>Radius 1000</TableCell>
              <TableCell>Radius 1200</TableCell>
              <TableCell>Radius 1500</TableCell>
              <TableCell>GWP</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row, index) => (
              <TableRow key={"policy-rates-" + index}>
                <TableCell>{row?.alPolicyNo}</TableCell>
                <TableCell>{"Auto Liability"}</TableCell>
                <TableCell>
                  {row?.effectiveDate.toDate().toString().slice(4, 15)}
                </TableCell>
                <TableCell>{row?.insuredID}</TableCell>
                <TableCell>{row?.insuredInfo?.company}</TableCell>
                <TableCell>{row?.insuredInfo?.zip}</TableCell>
                <TableCell>{row?.insuredInfo?.county}</TableCell>
                <TableCell>{row?.product}</TableCell>
                <TableCell>{row?.coverageSingleLimit}</TableCell>
                <TableCell>{row?.cargoType}</TableCell>
                <TableCell>{row?.stateOfEntry}</TableCell>
                <TableCell>
                  <Checkbox disabled checked={true} />
                </TableCell>
                <TableCell>
                  <Checkbox disabled checked={row?.has91X} />
                </TableCell>
                <TableCell>
                  <Checkbox disabled checked={row?.hasNOA} />
                </TableCell>
                <TableCell> 
                  <Checkbox disabled checked={row?.hasFormE || row?.hasMCP65} />
                </TableCell>
                <TableCell>{row?.vehicleCount}</TableCell>
                <TableCell>{row?.radiusDollarAmounts?.['50']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['100']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['250']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['500']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['800']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['1000']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['1200']?.toFixed(2)}</TableCell>
                <TableCell>{row?.radiusDollarAmounts['1500']?.toFixed(2)}</TableCell>
                <TableCell>{(row?.alPremiums?.GWP || 0).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Container>
  );
}
