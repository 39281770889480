const findNextPolicyNumbers = async (
  formData,
  insuredInfo,
  uniqueIdentifierAL,
  uniqueIdentifierGL,
  setNextAL,
  setNextGL
) => {
  let nextAL = "";
  let nextGL = "";

  const calculateNextNumber = (policies) => {
    return policies.length === 0
      ? "00"
      : (policies.length).toString().padStart(2, "0");
  };

  if (formData.program === "NAFTA") {
    nextAL =
      "2-SSM-TX-19-S0" +
      (uniqueIdentifierAL === null
        ? insuredInfo?.uniqueIdentifierNAFTAAL
        : uniqueIdentifierAL) +
      "-" +
      calculateNextNumber(insuredInfo?.alPolicies || []);

    nextGL =
      "2-SSM-TX-17-S0" +
      (uniqueIdentifierGL === null
        ? insuredInfo?.uniqueIdentifierNAFTAGL
        : uniqueIdentifierGL) +
      "-" +
      calculateNextNumber(insuredInfo?.glPolicies || []);
  } else if (formData.program === "Domestic") {
    nextAL =
      "2-SSD-TX-19-S0" +
      (uniqueIdentifierAL === null
        ? insuredInfo?.uniqueIdentifierDomesticAL
        : uniqueIdentifierAL) +
      "-" +
      calculateNextNumber(insuredInfo?.alPolicies || []);

    nextGL =
      "2-SSD-TX-17-S0" +
      (uniqueIdentifierGL === null
        ? insuredInfo?.uniqueIdentifierDomesticGL
        : uniqueIdentifierGL) +
      "-" +
      calculateNextNumber(insuredInfo?.glPolicies || []);
  }

  setNextAL && setNextAL(nextAL);
  setNextGL && setNextGL(nextGL);

  return {
    nextAL,
    nextGL,
  };
};

export default findNextPolicyNumbers;
