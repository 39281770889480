import { doc, getDoc, runTransaction, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import miniValidation from "./premiumCalculation/miniValidation";
import getTruckList from "./premiumCalculation/getTruckList";
import updateSystemLog from "./updateSystemLog";

const requestQuote = async (
  e,
  formData,
  policyID,
  userAuth,
  userState,
  insuredInfo,
  formDataGL,
  formDataAPD,
  formDataMTC
) => {
  e.preventDefault();

  const hasAL = formData.hasALCoverage;
  const hasGL = formData.hasGLCoverage;
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;

  const truckList = await getTruckList(policyID, formData);

  const isValid = miniValidation(
    truckList,
    formData,
    insuredInfo,
    formDataGL,
    formDataAPD,
    formDataMTC
  );

  let totalInsuredValue = 0;
  truckList.forEach((truck) => {
    const thisPolicyObject = truck.policiesDetails.filter(
      (policy) => policy.id === policyID && policy.status === "Active"
    )[0];
    const thisPolicyACV = thisPolicyObject.truckACV;
    totalInsuredValue += thisPolicyACV || 0;
    updateDoc(doc(db, "policy-applications", policyID), {
      TIV: totalInsuredValue,
    });
  });

  const alPolicyRef = doc(db, "policy-applications", policyID);
  const glPolicyRef = hasGL ? doc(db, "gl-policies", formData.glPolicyID) : null;
  const apdPolicyRef = hasAPD ? doc(db, "apd-policies", formData.apdPolicyID) : null;
  const mtcPolicyRef = hasMTC ? doc(db, "mtc-policies", formData.mtcPolicyID) : null;
  
  const sourceDocSnapshot = await getDoc(alPolicyRef);
  const documentType = "Quote Request";
  const status = "Submitted";
  const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
  const agencyID = sourceDocSnapshot.data().agencyID;

  if (isValid) {
    try {
      await runTransaction(db, async (transaction) => {
        transaction.update(alPolicyRef, {
            status,
            documentType,
            returnToAgentData: null,
          });

        hasGL &&
          transaction.update(glPolicyRef, {
            status,
            documentType,
          });

        hasAPD &&
          transaction.update(apdPolicyRef, {
            status,
            documentType,
          });

        hasMTC &&
          transaction.update(mtcPolicyRef, {
            status,
            documentType,
          });
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  }

  isValid &&
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
  isValid &&
    updateSystemLog(
      auth,
      policyID,
      "Quote Request Submitted for " + insuredInfo.company + ".",
      "Quote Request Submitted",
      { agencyID, policyID }
    );
};

export default requestQuote;
