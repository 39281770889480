import { arrayUnion, doc, getDoc, runTransaction } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import getFleetCredit from "./getFleetCredit";
import updateSystemLog from "./updateSystemLog";
import assignPolicyNumber from "./assignPolicyNumber";
import { formatDateToYYYYMMDD } from "../Finances/Tables/utils";
import { blanketAdditionalInsuredEndorsementRequest } from "../../services/firebase-api/additionalInsureds";
import generatePolicyInvoice from "./generateInvoice";

const bindPolicy = async (
  formData,
  policyID,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  insuredInfo,
  nextAL,
  nextGL,
  insuredID,
  userAuth,
  userState,
  formDataGL,
  formDataAPD,
  formDataMTC,
  truckList
) => {
  const agencyID = formData.agencyID;
  const isReferral = formData.isReferral;
  const referralApproved = formData.referralApproved;
  const referralDeclined = formData.referralDeclined;
  const hasAL = formData.hasALCoverage;
  const hasGL = formData.hasGLCoverage;
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;

  if (!Boolean(userInfo?.hasBindAuthority)) {
    return alert(
      "You do not have permissions to bind policies. Contact your administrator."
    );
  }

  if (isReferral && !referralApproved) {
    return alert("This policy is a referral and has not been approved yet.");
  }

  if (isReferral && referralDeclined) {
    return alert("This policy is a referral and has been declined.");
  }

  const policyRef = doc(db, "policy-applications", formData.id);
  const insuredRef = doc(
    db,
    "agencies",
    userInfo.agencyID,
    "insureds",
    insuredInfo.id
  );

  // Check if a new identifier is needed for the policy and get the next policy numbers.
  const policyNumberResults = !hasAL
    ? null
    : await assignPolicyNumber(
        formData,
        formDataGL,
        insuredID,
        agencyID,
        insuredInfo
      );

  const useNewIdentifier = policyNumberResults[1];
  const newALPolicyNumber = policyNumberResults[0].nextAL;
  const newGLPolicyNumber = policyNumberResults[0].nextGL;
  const glPolicyNo = useNewIdentifier ? newGLPolicyNumber : nextGL;
  const alPolicyNo = useNewIdentifier ? newALPolicyNumber : nextAL;

  // Get the fleet credit for the policy.
  const fleetCredit = await getFleetCredit(powerUnitCount);
  // Get the adjustment data.
  const quoteRef = doc(
    db,
    "policy-applications",
    policyID,
    "quotes",
    formData?.selectedQuote
  );
  const quoteSnapshot = await getDoc(quoteRef);
  const quote = quoteSnapshot.data();
  const adjustmentFormData = quote?.adjustmentFormData;


  let radiusCounts = {
    50: 0,
    100: 0,
    250: 0,
    500: 0,
    800: 0,
    1000: 0,
    1200: 0,
    1500: 0,
  };
  let radiusDollarAmounts = {
    50: 0,
    100: 0,
    250: 0,
    500: 0,
    800: 0,
    1000: 0,
    1200: 0,
    1500: 0,
  };
  let GWP = 0;
  const powerUnits = [];
  truckList.forEach((unitDoc) => {
    const thisPolicy = unitDoc
      .data()
      .policiesDetails.find((policy) => policy.id === policyID);
      if (!thisPolicy) return;
      if (!thisPolicy.status !== "Active") return;
      if (!thisPolicy.documentSource !== "Policy") return; // ignore if it was added by endorsement since we're only getting the bound power units
    let radius = thisPolicy.truckRadius;
    radius = parseInt(radius.split("-")[1]);
    radiusCounts[radius] += 1;
    radiusDollarAmounts[radius] += thisPolicy.adjustedRate;
    GWP += thisPolicy.adjustedRate;
    powerUnits.push(unitDoc.data());
  });

  const bindData = {
    biLimit: quote.generatedData.q.biLiabilityPTL,
    pipLimit: quote.generatedData.q.personalInjuryProtectionLimit,
    biLiabilityDeductible: quote.generatedData.q.biLiabilityDeductible,
    alPolicyNo: hasAL ? alPolicyNo : null,
    glPolicyNo: hasGL ? glPolicyNo : null,
    powerUnitCountAtBind: quote.generatedData.q.numberOfUnits,
    bindDate: new Date(),
    boundBy: auth.currentUser.displayName,
    boundByID: auth.currentUser.uid,
    selectedQuote: formData.selectedQuote,
    stateOfEntryAtBind: formData.stateOfEntry,
    effectiveDate: formData.effectiveDate,
    effectiveDateGL: hasGL ? formDataGL.effectiveDate : null,
    powerUnitCount,
    driverCount,
    trailerCount,
    fleetCredit,
    boundPowerUnitsData: {
      radiusCounts,
      radiusDollarAmounts,
    },
    adjustments: adjustmentFormData,
    boundPremiums: {
      autoLiability: formData.alPremiums || null,
      generalLiability: formDataGL?.glPremiums || null,
      autoPhysicalDamage: formDataAPD?.apdPremiums || null,
      motorTruckCargo: formDataMTC?.mtcPremiums || null,
    },
    coverages: {
      hasALCoverage: formData.hasALCoverage || false,
      hasGLCoverage: hasGL || false,
      hasAPDCoverage: formData.hasAPDCoverage || false,
      hasMTCCoverage: formData.hasMTCCoverage || false,
      hasNonOwned: formData.hasNonOwned || false,
    },
    additionalInfo: {
      hasMCP65: formData.hasMCP65 || false,
      hasFormE: formData.hasFormE || false,
      has91X: formData.has91X || false,
    },
  };

  await runTransaction(db, async (transaction) => {
    const sourceDocRef = doc(db, "policy-applications", policyID);
    const sourceDocSnapshot = await getDoc(sourceDocRef);
    const documentType = "Policy";
    const effectiveDateHasAlreadyPassed =
      new Date() > formData.effectiveDate.toDate();
    const status = effectiveDateHasAlreadyPassed ? "In-Force" : "Bound";
    const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
    const agencyID = sourceDocSnapshot.data().agencyID;

    const policyGLRef = hasGL ? doc(db, "gl-policies", formDataGL.id) : null;
    const policyAPDRef = hasAPD
      ? doc(db, "apd-policies", formDataAPD.id)
      : null;
    const policyMTCRef = hasMTC
      ? doc(db, "mtc-policies", formDataMTC.id)
      : null;

    const formattedDate = formatDateToYYYYMMDD(new Date());

    const rootLevelData = {
      status,
      documentType,
      inRenewal: false,
      IssuedDate: formattedDate,
      isActive: effectiveDateHasAlreadyPassed ? true : false,
    };
    const glPolicyData = hasGL
      ? {
          effectiveDate: formDataGL.effectiveDate,
          policyID,
          policyNumber: glPolicyNo,
        }
      : {};
    const alPolicyData = hasAL
      ? {
          effectiveDate: formData.effectiveDate,
          program: formData.program,
          product: formData.product,
          coverageSingleLimit: formData.coverageSingleLimit,
          policyID,
          policyNumber: alPolicyNo,
        }
      : {};
    const apdPolicyData = hasAPD
      ? {
          effectiveDate: formDataAPD.effectiveDate,
          policyID,
          policyNumber: formDataAPD.apdPolicyNo,
          apdPremiums: formDataAPD.apdPremiums,
        }
      : {};
    const mtcPolicyData = hasMTC
      ? {
          effectiveDate: formDataMTC.effectiveDate,
          policyID,
          policyNumber: formDataMTC.mtcPolicyNo,
          mtcPremiums: formDataMTC.mtcPremiums,
        }
      : {};

    if (formDataGL.hasGLCoverage) {
      if (formDataGL.id) {
        transaction.update(policyGLRef, {
          ...glPolicyData,
          ...rootLevelData,
          glPolicyNo,
          alPolicyNo,
          glPremiums: formDataGL?.glPremiums || null,
        });
      }
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
        glPolicies: arrayUnion(glPolicyData),
      });
      transaction.update(policyRef, {
        ...rootLevelData,
        bindData,
        glPolicyNo,
        alPolicyNo,
        hasGLCoverage: formDataGL?.hasGLCoverage || false,
        glPremiums: formDataGL?.glPremiums || null,
      });
    } else {
      transaction.update(insuredRef, {
        alPolicies: arrayUnion(alPolicyData),
      });
      transaction.update(policyRef, { ...rootLevelData, alPolicyNo });
    }
    if (formData.hasAPDCoverage) {
      if (formDataAPD.id) {
        transaction.update(policyAPDRef, {
          ...apdPolicyData,
          ...rootLevelData,
        });
      }
      transaction.update(insuredRef, {
        apdPolicies: arrayUnion(apdPolicyData),
      });
    }
    if (formData.hasMTCCoverage) {
      if (formDataMTC.id) {
        transaction.update(policyMTCRef, {
          ...mtcPolicyData,
          ...rootLevelData,
        });
      }
      transaction.update(insuredRef, {
        mtcPolicies: arrayUnion(mtcPolicyData),
      });
    }
    blanketAdditionalInsuredEndorsementRequest(policyID, insuredInfo, formData);
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
    updateSystemLog(
      auth,
      policyID,
      "Policy Bound for " + insuredInfo.company + ".",
      "Policy Bound",
      { agencyID, policyID }
    );
  });
  await generatePolicyInvoice(
    insuredInfo.company,
    bindData.effectiveDate,
    bindData.effectiveDateGL,
    bindData.stateOfEntryAtBind,
    formData,
    null,
    formData.agencyID,
    "AL"
  );
  hasGL &&
    (await generatePolicyInvoice(
      insuredInfo.company,
      bindData.effectiveDate,
      bindData.effectiveDateGL,
      bindData.stateOfEntryAtBind,
      formData,
      null,
      formData.agencyID,
      "GL"
    ));
};

export default bindPolicy;
