import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import updateSystemLog from "./updateSystemLog";
import { handleDeleteFilesFromStorage } from "../../utils/helpers";

const returnToAgent = async (
  e,
  policyID,
  reasons,
  formData,
  userAuth,
  userState,
  insuredInfo,
  userInfo,
  returnToAgentAttachments,
  closeModal
) => {
  if (e) e?.preventDefault();
  const sourceDocRef = doc(db, "policy-applications", policyID);
  const sourceDocSnapshot = await getDoc(sourceDocRef);
  const documentType = "Application";
  const status = "Returned";
  const policyNumber = sourceDocSnapshot.data().alPolicyNo || "";
  const agencyID = sourceDocSnapshot.data().agencyID;

  policyStatusChangeNotification(
    documentType,
    policyNumber,
    status,
    policyID,
    agencyID,
    formData,
    userAuth,
    userState,
    insuredInfo,
    "returnToAgent",
    null,
    null,
    reasons,
    returnToAgentAttachments
  );
  const userRole = userInfo.role;
  //
  const payload = {
    status,
    documentType,
    bindRequestData: {},
    TIV: 0,
    selectedQuote: "#",
    alPremiums: {}, // TODO: remove premiums in other policies? maybe not important, not sure. -colt 2/8/2025
    returnToAgentReasons: reasons,
    returnToAgentDetails: {
      date: new Date(),
      userRole,
      user: auth.currentUser.displayName,
      userID: auth.currentUser.uid,
    },
  };

  if (returnToAgentAttachments && returnToAgentAttachments.length > 0) {
    payload["returnToAgentAttachments"] = returnToAgentAttachments;
  }

  await updateDoc(sourceDocRef, payload);
  closeModal();
  const quotesDocRef = query(
    collection(db, "policy-applications", policyID, "quotes")
  );
  const queryData = await getDocs(quotesDocRef);
  const queryResults = queryData.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
  for (const quote of queryResults) {
    await handleDeleteFilesFromStorage([quote.URL]);
    await deleteDoc(
      doc(db, "policy-applications", policyID, "quotes", quote.id)
    );
  }

  updateSystemLog(
    auth,
    policyID,
    "Application for " +
      insuredInfo.company +
      " returned by " +
      userRole +
      " .",
    "Application Returned",
    { agencyID, policyID }
  );
};

export default returnToAgent;
