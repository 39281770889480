import React, { useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { collection, doc, runTransaction, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import priceToHandle from "./utils/addPowerUnit/priceToHandle";
import PremiumsTable from "./AddPowerUnits/PremiumsTable";
import generatePolicyInvoice from "../../utils/generateInvoice";

export default function Cancellation(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const insuredInfo = props.insuredInfo;
  const requestExceptionInputs = props.requestExceptionInputs;
  const userInfo = props.userInfo;
  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const updateEndorsementStatusAndCreateCancellation = async (
      endorsementId,
      status
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );

      const policyRef = doc(db, "policy-applications", paramPolicyID);

      const otherPoliciesToCancel = props.endorsement.otherPoliciesToCancel;
      // other policies to cancel is an object with 4 keys: AL, GL, APD, MTC. if they are null, no policy to cancel, if they have an id, then there is a policy to cancel

      const cancelPolicy = async (collection, policyID, transaction) => {
        const thisPolicyRef = doc(collection, policyID);
        const policySnapshot = await transaction.get(thisPolicyRef);
        const newStatus = "Cancelled";
        await transaction.update(thisPolicyRef, {
          status: newStatus,
        });
      };

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = new Date()
          const payload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            payload["wasARequestException"] = true;
            payload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, payload);
          transaction.update(policyRef, {
            status: "Cancelled",
          });
          if (otherPoliciesToCancel.GL) {
            const glCollection = collection(db, "gl-policies");
            await cancelPolicy(glCollection, otherPoliciesToCancel.GL);
          }
          if (otherPoliciesToCancel.APD) {
            const apdCollection = collection(db, "apd-policies");
            await cancelPolicy(apdCollection, otherPoliciesToCancel.APD);
          }
          if (otherPoliciesToCancel.MTC) {
            const mtcCollection = collection(db, "mtc-policies");
            await cancelPolicy(mtcCollection, otherPoliciesToCancel.MTC);
          }
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating cancellation:",
          error
        );
      }
    };

    await updateEndorsementStatusAndCreateCancellation(
      paramRefID,
      "Approved",
      endorsement.data
    );
    await endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );

    // generate invoice
    const state = (policyNumber) => {
      return policyNumber.split("-")[2];
    };
    await generatePolicyInvoice(
      insuredInfo.company,
      endorsement.endoEffectiveDate,
      endorsement.endoEffectiveDate,
      state(endorsement.alPolicyNo),
      endorsement,
      endorsement,
      endorsement.agencyID,
      "AL",
      true,
      [],
      false
    );
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAUEndorsement = async (e, requestExceptionData, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const cancellationInsuredRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (requestExceptionData) {
      payload["wasARequestException"] = true;
      payload["requestException"] = requestExceptionData;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(cancellationInsuredRef, payload);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: approveAUEndorsement,
      onDecline: declineAUEndorsement,
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const requestExceptionModeSetting = async (e) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const alPremiums = endorsement?.alPremiums;

  return (
    <div>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <span className="font-semibold">Cancellation Reasons:</span>
      <ul className="mb-3">
        {endorsement?.cancellationReasons?.map((reason, index) => {
          return <li key={index}>{reason}</li>;
        })}
        {endorsement?.cancellationReasons?.length === 0 && (
          <li>No reason provided</li>
        )}
      </ul>
      {endorsement.status !== "Approved" && <PremiumsTable data={alPremiums} />}

      {isRequestException &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {endorsement.status === "Submitted" ? (
        <>
          <article className="mt-3">
            <p>
              Approving this endorsement will add the above cancellation to the
              policy and notify the insured of the change.
            </p>
          </article>
          {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
            <ApprovalButtons
              onApprove={approveAUEndorsement}
              onDecline={() => setRejectionModal(true)}
            />
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={requestExceptionModeSetting}
          />
        </>
      ) : null}
    </div>
  );
}
