import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase";
import { t } from "i18next";
import {
  Modal,
  Typography,
  Box,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
  SvgIcon,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function History({
  open,
  onClose,
  policyID,
  searchByInsured,
  insuredID,
}) {

  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const getHistory = () => {
      let q;
      if (searchByInsured) {
        q = query(
          collection(db, "system-activity"),
          where("insuredID", "==", insuredID)
        );
      } else {
        q = query(
          collection(db, "system-activity"),
          where("policyID", "==", policyID)
        );
      }

      // Real-time subscription
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const values = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });

        // Sort entries by date (newest to oldest)
        values.sort(
          (a, b) => b.activityDate.toDate() - a.activityDate.toDate()
        );
        setHistory(values);
      });

      return unsubscribe;
    };

    const unsubscribe = getHistory();

    return () => {
      unsubscribe();
    };
  }, [policyID, insuredID, searchByInsured]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onClose();
  };

  const historyContent = (
    <Box
      sx={{
        maxHeight: isMobileScreen ? "90vh" : "60vh",
        overflowY: "auto",
        padding: '0 0 50px 0'
      }}
    >
      <List>
        {history.map((entry, index) => (
          <React.Fragment key={entry.id}>
            <TimelineItem entry={entry} />
            {index !== history.length - 1 && <Divider variant="middle" />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  if (searchByInsured) {
    // Render inline content if `searchByInsured` is true
    return (
      <>
        <Typography className="mt-2" variant="h6" gutterBottom>
          Full History
        </Typography>
        {historyContent}
      </>
    );
  }

  // Render modal if `searchByInsured` is false
  return (
    <Modal open={open} onClose={null} disableBackdropClick={true}>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            width: isMobileScreen ? "100%" : "80%",
            height: isMobileScreen ? "100%" : "auto",
            borderRadius: isMobileScreen ? 0 : 4,
            maxHeight: isMobileScreen ? "100vh" : "80vh",
          }}
        >
          {/* Close button */}
          <IconButton
            sx={{
              position: "absolute",
              top: 8,
              right: 8,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          <Typography className="mt-2" variant="h6" gutterBottom>
            Full History
          </Typography>
          {historyContent}
        </Box>
      </form>
    </Modal>
  );
}

const formattedDate = (entry) =>
  entry.activityDate.toDate().toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

function TimelineItem({ entry }) {
  return (
    <ListItem alignItems="flex-start">
      <ListItemIcon>
        <SvgIcon component={IconComponent} />
      </ListItemIcon>
      <ListItemText
        primary={t(entry.description)}
        secondary={"By " + entry.authorName + " on " + formattedDate(entry)}
      />
    </ListItem>
  );
}

const IconComponent = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="10" fill="currentColor" />
    </svg>
  );
};
