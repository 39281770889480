import React, { useEffect, useMemo, useState } from "react";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Form from "../../generals/Forms/Form";
import {
  setPolicyDetailUpdate,
  setPolicyInfo,
} from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import handleGetPowerUnitsById from "./utils/addPowerUnit/handleGetPowerUnitsById";
import approveAddPowerUnitsEndorsement from "./utils/addPowerUnit/approveAddPowerUnitsEndorsement";
import PremiumsTable from "./AddPowerUnits/PremiumsTable";
import PowerUnitsMap from "./AddPowerUnits/PowerUnitsMap";
import requestExceptionModeSetting from "./utils/requestExceptionModeSetting";
import Modals from "./AddPowerUnits/Modals";
import ButtonInstructions from "./AddPowerUnits/ButtonInstructions";
import AddTruck from "../../PolicyLifecycleManager/Units/AddTruck";
import BlueButton from "../../assets/forms/BlueButton";

export default function AddPowerUnits({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  requestExceptionInputs,
  insuredInfo,
  userInfo,
  agencyID,
  formData,
  editMode
}) {
  const [unitsRated, setUnitsRated] = useState(false);
  const [endorsementData, setEndorsementData] = useState([]);
  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);
  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;
  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [taxSchedule, setTaxSchedule] = useState();
  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const [vinArray, setVinArray] = useState([]);

  useEffect(() => {
    const fetchPowerUnits = async () => {
      // Fetch the power units and update state
      const powerUnits = await handleGetPowerUnitsById(endorsement);
      if (powerUnits) {
        setEndorsementData(powerUnits);

        // Generate VIN array
        const values = powerUnits.map((unit) => unit.truckVIN);
        setVinArray(values);
      }
    };

    fetchPowerUnits();
  }, [endorsement, unitsRated]);

  const [addUI, setAddUI] = useState(false);
  const [editUI, setEditUI] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleAddition = (e) => {
    e.preventDefault();
    setAddUI(!addUI);
  }
  const handleEdition = (e, item) => {
    e.preventDefault();
    setEditUI(!editUI);
    setSelectedItem(item);
  }

  const necessaryValuesInsideEndorsement = {
    addUI: addUI,
    editUI: editUI,
    endorsement,
    selectedItem,
    completeList: endorsementData,
  }

  return (
    <>
      <Modals
        openInCancellationModal={openInCancellationModal}
        setOpenInCancellationModal={setOpenInCancellationModal}
        rejectionModal={rejectionModal}
        setRejectionModal={setRejectionModal}
        endorsement={endorsement}
        isRequestException={isRequestException}
        paramPolicyID={paramPolicyID}
        paramRefID={paramRefID}
        endorsementData={endorsementData}
        setPolicyInfo={setPolicyInfo}
        setPolicyDetailUpdate={setPolicyDetailUpdate}
        insuredInfo={insuredInfo}
        setSuccess={setSuccess}
      />

      {(endorsement.status === "Returned" &&
      !addUI &&
      !editUI
      ) && (
        <>
        {editMode && (
        <BlueButton
          text={"ADD NEW POWER UNIT"}
          onClickFunction={handleAddition}
          hasContentToTop={true}
          hasContentToBottom={true}
          className={`w-full mb-3`}
        />)}
        </>
      )}

      {(addUI || editUI) &&
      endorsement.status === "Returned" ?
        <AddTruck
          insuredInfo={insuredInfo}
          truckList={endorsementData}
          userID={userInfo.id}
          userInfo={userInfo}
          paramRefID={paramRefID}
          policyID={paramPolicyID}
          formData={formData}
          setShowAddTruck={() => {
            setAddUI(false);
            setEditUI(false);
          }}
          setSelectedItem={setSelectedItem}
          necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
          isEndoManager={false}
          rerateRequired={false}
          setRerateRequired={() => {}}
          getEndorsements={() => {}}
          getTruckList={() => {}}
          setTruckList={() => {}}
          setShowTable={() => {}}
          setFormData={() => {}}
        /> :
        <PowerUnitsMap
          endorsementData={endorsementData}
          paramPolicyID={paramPolicyID}
          handleEdition={handleEdition}
          endorsement={endorsement}
          editMode={editMode}
        />
      }

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(
        (endorsement.status === "Submitted" || isRequestException) &&
        (user.role === "Underwriter" || user.role === "Admin")
      ) && !Boolean(
        (addUI || editUI) &&
        (endorsement.status === "Submitted" ||
        endorsement.status === "Unsubmitted")
      ) ? (
        <>
          {!unitsRated ? (
            <RatePowerUnitEndorsement
              truckList={endorsementData}
              policyID={paramPolicyID}
              endoID={paramRefID}
              setSuccess={setUnitsRated}
              endoType={endorsement.type}
            />
          ) : (
            <>
              <ButtonInstructions isRequestException={isRequestException} />
              {isRequestException && (
                <RequestExceptions
                  endorsement={endorsement}
                  color="red"
                  center={true}
                />
              )}
              {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
                <ApprovalButtons
                  onApprove={(e) =>
                    approveAddPowerUnitsEndorsement(
                      e,
                      null,
                      endorsement,
                      paramPolicyID,
                      paramRefID,
                      setTaxSchedule,
                      insuredInfo,
                      setSuccess,
                      setOpenInCancellationModal,
                      endorsementData,
                      vinArray
                    )
                  }
                  onDecline={() => setRejectionModal(true)}
                />
              )}
            </>
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <ApprovalButtons
          dissapearAccept={true}
          titleDecline="REQUEST EXCEPTION"
          onDecline={() => setRequestingExceptionMode(true)}
        />
      ) : null}
    </>
  );
}
