export const formatDate = (date) => {
  if (!date || date === '-') return "-";
  const d = new Date(date);
  const month = ('0' + (d.getUTCMonth() + 1)).slice(-2);
  const day = ('0' + d.getUTCDate()).slice(-2);
  const year = d.getUTCFullYear();
  return `${month}/${day}/${year}`;
};

export const calculateLeapYear = (year) => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const humanDate = (date) => {
  if (date) {
    return date
  }
  return "-";
};

export const parseIssuedDateFromInspas = (dateString) => {
  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  return `${month}/${day}/${year}`;
}

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}${month}${day}`;
}

export const parseYYYYMMDDToDate = (dateString) => {
  try {
    const year = parseInt(dateString.slice(0, 4), 10);
    const month = parseInt(dateString.slice(4, 6), 10) - 1;
    const day = parseInt(dateString.slice(6, 8), 10);
    return new Date(year, month, day);
  } catch (error) {
    return null;
  }
}

export const filterPoliciesByIssuedDate = (entities, startDateObj, endDateObj) => {
  return entities.filter(entity => {
    if (entity?.coverageRequested) return true
    const entityIssuedDateObj = parseYYYYMMDDToDate(entity.IssuedDate);
    if (!entityIssuedDateObj) return false;
    const startCondition = !startDateObj || entityIssuedDateObj >= startDateObj;
    const endCondition = !endDateObj || entityIssuedDateObj <= endDateObj;
    return startCondition && endCondition;
  });
}

export const calculateDaysDifference = (policyEffectiveDate, endoEffectiveDate) => {
  if (!policyEffectiveDate || !endoEffectiveDate) {
    return "-";
  }
  const policyDate = policyEffectiveDate instanceof Date 
    ? policyEffectiveDate 
    : policyEffectiveDate.toDate();
  const endoDate = endoEffectiveDate instanceof Date 
    ? endoEffectiveDate 
    : endoEffectiveDate.toDate();
  const policyYear = policyDate.getFullYear();
  const totalDays = calculateLeapYear(policyYear) ? 366 : 365;
  if (policyDate.getTime() === endoDate.getTime()) {
    return totalDays.toString();
  }
  const differenceInMilliseconds = Math.abs(policyDate - endoDate);
  const daysElapsed = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  return (totalDays - daysElapsed).toString();
};

export const isLeapYear = (policyEffectiveDate) => {
  if (policyEffectiveDate instanceof Date) {
    const policyYear = policyEffectiveDate.getFullYear();
    return calculateLeapYear(policyYear);
  } else if (policyEffectiveDate && typeof policyEffectiveDate.toDate === 'function') {
    const policyYear = policyEffectiveDate.toDate().getFullYear();
    return calculateLeapYear(policyYear);
  }
  return false;
};

export const calculateExpirationDate = (effectiveDate, daysToAdd = 365) => {
  if (!effectiveDate) return "-";
  const seconds = effectiveDate.seconds || 0;
  const nanoseconds = effectiveDate.nanoseconds || 0;
  const milliseconds = seconds * 1000 + nanoseconds / 1000000;
  const expirationDate = new Date(milliseconds);
  expirationDate.setDate(expirationDate.getDate() + daysToAdd);
  return humanDate(expirationDate);
};

export const calculateExpirationDateTimestamp = (effectiveDate) => {
  if (!effectiveDate) return null;
  const expirationDate = new Date(effectiveDate);
  const originalDay = expirationDate.getDate();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  if (expirationDate.getDate() !== originalDay) {
    expirationDate.setDate(0);
  }
  return expirationDate;
};

export const formatPrice = (number, without$ = false) => {
  if (number === undefined || number === null || isNaN(number)) {
    return "";
  }
  const roundedNumber = parseFloat(number).toFixed(2);
  const priceWithCommas = roundedNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return without$ ? `${priceWithCommas}` : `$${priceWithCommas}`;
};

export const formatLiabilityLimit = (liabilityLimitID) => {
  if (!liabilityLimitID) return '';
  const match = liabilityLimitID.match(/^(\d+)([MK])$/);
  if (!match) {
    return '';
  }
  const [_, amount, unit] = match;
  let numericValue = parseInt(amount, 10);
  if (unit === 'M') {
    numericValue *= 1000000;
  } else if (unit === 'K') {
    numericValue *= 1000;
  }
  return `$${numericValue.toLocaleString('en-US', { minimumFractionDigits: 2 })}`;
}

export const itemVehicleCount = (item) => `${item?.bindData?.powerUnitCountAtBind ? item?.bindData?.powerUnitCountAtBind : item?.bindData?.powerUnitCount ? item?.bindData?.powerUnitCount : "-"}`
export const itemStatus = (item) => `${item?.status ? item?.status : "-"}`
export const itemProgram = (item) => `${item?.program ? item?.program?.toUpperCase() : "-"}`
export const itemInsuredName = (item) => `${item?.namedInsured || "-"}`
export const itemPolicyNo = (item) => `${item?.takeGL ? item?.glPolicyNo : item?.takeAL ? item?.alPolicyNo : "-"}`
export const itemType = (item) => `${item?.takeGL ? "GL" : item?.takeAL ? "AL" : "-"}`
export const itemCompany = (item) => `${item?.takeGL || item?.takeAL ? "ACCREDITED" : "-"}`
export const itemState = (item) => `${item?.stateOfEntry || "-"}`
export const itemEffectiveDate = (item) => `${item?.effectiveDate ? item?.effectiveDate?.toDate() : "-"}`
export const itemExpirationDate = (item) => `${item?.expirationDate ? item?.expirationDate?.toDate() : "-"}`
export const itemBindDate = (item) => {
  const issueDate =
    item?.bindData?.bindDate ? item?.bindData?.bindDate.toDate() :
    item?.IssuedDate ? parseIssuedDateFromInspas(item?.IssuedDate) : "-"
  return issueDate
}
export const itemPremium = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.GWP) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.GWP) : "$0.00"}`
export const itemProcessingFee = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.processingFeeAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.processingFeeAmount) : "$0.00"}`
export const itemOtherFees = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.otherFees) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.otherFees) : "$0.00"}`
export const itemSurplusLinesPlusTaxes = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : "$0.00"}`
export const itemStampFee = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.stampTaxAmount) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.stampTaxAmount) : "$0.00"}`
export const itemTotalPremium = (item) => `${item?.takeGL && item?.glPremiums ? formatPrice(item?.glPremiums?.totalPremium) : item?.takeAL && item?.alPremiums ? formatPrice(item?.alPremiums?.totalPremium) : "$0.00"}`
export const itemCoverageLimit = (item) => {
  if (item?.takeGL) return '$2,000,000'
  return `${item?.coverageSingleLimit || "$0.00"}`
}
export const itemZipCode = (item) => `${item?.insuredInfo?.zip || "-"}`
export const itemLiconaPer = () => "10%"
export const itemLicona$ = (item) => {
  const percentage = parseFloat(itemLiconaPer()?.replace('%', ''))  / 100
  const licona$ =
    item?.takeGL && item?.glPremiums ? item?.glPremiums?.GWP * percentage :
    item?.takeAL && item?.alPremiums ? item?.alPremiums?.GWP * percentage : 0
  return `${licona$ ? formatPrice(licona$) : "$0.00"}`
}
export const itemNetTotal = (item) => {
  const totalPremiumValue = itemTotalPremium(item)
  const licona$Value = itemLicona$(item)
  const totalPremium = totalPremiumValue.replace(/[\$,]/g, '');
  const licona$ = licona$Value.replace(/[\$,]/g, '');
  const netTotalValue = totalPremium - licona$
  const netTotal = (
    item?.takeGL && item?.glPremiums ? formatPrice(netTotalValue) :
    item?.takeAL && item?.alPremiums ? formatPrice(netTotalValue) : "$0.00"
  )

  return netTotal
}
export const itemInvoice = () => `-`

export const titleItemVehicleCount = "Vehicle Count"
export const titleItemStatus = "Status"
export const titleItemProgram = "Program"
export const titleItemInsuredName = "Insured Name"
export const titleItemPolicyNo = "Policy No"
export const titleItemType = "Type"
export const titleItemCompany = "Company"
export const titleItemState = "State"
export const titleItemEffectiveDate = "Effective Date" 
export const titleItemExpirationDate = "Expiration Date"
export const titleItemBindDate = "Issue Date"
export const titleItemPremium = "Premium"
export const titleItemProcessingFee = "Processing Fee"
export const titleItemOtherFees = "Other Fees"
export const titleItemSurplusLinesPlusTaxes = "Surplus Lines Taxes"
export const titleItemStampFee = "Stamp Fee"
export const titleItemTotalPremium = "Total Premium"
export const titleItemCoverageLimit = "Coverage Limit"
export const titleItemZipCode = "Zip Code"
export const titleItemLiconaPer = "Licona's %"
export const titleItemLicona$ = "Licona's $"
export const titleItemNetTotal = "Net Total"
export const titleItemInvoice = "Invoice #"

export const handleCopyToClipboardPolicy = (item, controlToast) => {
  const textToCopy = `
        ${titleItemVehicleCount}: ${itemVehicleCount(item)};
        ${titleItemStatus}: ${itemStatus(item)};
        ${titleItemProgram}: ${itemProgram(item)};
        ${titleItemInsuredName}: ${itemInsuredName(item)};
        ${titleItemPolicyNo}: ${itemPolicyNo(item)};
        ${titleItemType}: ${itemType(item)};
        ${titleItemCompany}: ${itemCompany(item)};
        ${titleItemState}: ${itemState(item)};
        ${titleItemEffectiveDate}: ${itemEffectiveDate(item)};
        ${titleItemExpirationDate}: ${itemExpirationDate(item)};
        ${titleItemBindDate}: ${itemBindDate(item)};
        ${titleItemPremium}: ${itemPremium(item)};
        ${titleItemProcessingFee}: ${itemProcessingFee(item)};
        ${titleItemOtherFees}: ${itemOtherFees(item)};
        ${titleItemSurplusLinesPlusTaxes}: ${itemSurplusLinesPlusTaxes(item)};
        ${titleItemStampFee}: ${itemStampFee(item)};
        ${titleItemTotalPremium}: ${itemTotalPremium(item)};
        ${titleItemCoverageLimit}: ${itemCoverageLimit(item)};
        ${titleItemZipCode}: ${itemZipCode(item)};
        ${titleItemLiconaPer}: ${itemLiconaPer(item)};
        ${titleItemLicona$}: ${itemLicona$(item)};
        ${titleItemNetTotal}: ${itemNetTotal(item)};
        ${titleItemInvoice}: ${itemInvoice(item)};
      `;
      const textToSearch = `
        ${itemVehicleCount(item)}
        ${itemStatus(item)}
        ${itemProgram(item)}
        ${itemInsuredName(item)}
        ${itemPolicyNo(item)}
        ${itemType(item)}
        ${itemCompany(item)}
        ${itemState(item)}
        ${itemEffectiveDate(item)}
        ${itemExpirationDate(item)}
        ${itemBindDate(item)}
        ${itemPremium(item)}
        ${itemProcessingFee(item)}
        ${itemOtherFees(item)}
        ${itemSurplusLinesPlusTaxes(item)}
        ${itemStampFee(item)}
        ${itemTotalPremium(item)}
        ${itemCoverageLimit(item)}
        ${itemZipCode(item)}
        ${itemLiconaPer(item)}
        ${itemLicona$(item)}
        ${itemNetTotal(item)}
        ${itemInvoice(item)}
      `;
  if (controlToast) {
    navigator.clipboard.writeText(textToCopy);
    controlToast(true, "The policy data was copied to clipboard", "success");
  }
  return textToSearch;
};

export const itemEndoNumber = (item) => `${item?.endorsementNumber || "-"}`
export const itemEndoStatus = (item) => `${item?.status || "-"}`
export const itemEndoProgram = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.program ? policyDetail?.program?.toUpperCase() : "-"}`
}
export const itemEndoEffectiveDate = (item) => `${item?.endoEffectiveDate ? humanDate(item?.endoEffectiveDate?.toDate()) : "-"}`
export const itemEndoIssuedDate = (item) => {
  const issueDate = item?.IssuedDate ? parseIssuedDateFromInspas(item?.IssuedDate) : "-"
  return issueDate
}
export const itemEndoTypeAddDelete = (item) => `${item?.type || "-"}`
export const itemEndoRadius = (item) => {
  const source = item?.data?.policiesDetails ? item?.data?.policiesDetails : item?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.truckRadius || item?.data?.trailerRadius || "-"}`
}
export const itemEndoYear = (item) => {
  return `${item?.data?.truckYear || item?.data?.trailerYear || "-"}`
}
export const itemEndoMake = (item) => {
  const source = item?.data?.policiesDetails ? item?.data?.policiesDetails : item?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const endoMake =
    policyDetail?.truckMake || item?.data?.truckMake ||
    item?.data?.trailerMake || "-"
  return endoMake
}
export const itemEndoVIN = (item) => {
  return `${item?.data?.truckVIN || item?.data?.trailerVIN || "-"}`
}
export const item355366Days = (item) => `${isLeapYear(item?.endoEffectiveDate) ? "366" : "365"}`
export const itemEndoState = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.stateOfEntry || "-"}`
}
export const itemEndoALNoPolicy = (item) => `${item?.alPolicyNo || "-"}`
export const itemEndoALExp = (item) => {
  const policyDetail = item?.policiesDetails?.find(detail => detail?.id === item?.policyID)
  const expirationDate = policyDetail?.expirationDate ? humanDate(policyDetail?.expirationDate?.toDate()) : "-"
  return expirationDate
}
export const itemEndoDaysAL = (item) => `${calculateDaysDifference(item?.policyEffectiveDate, item?.endoEffectiveDate)}`
export const itemEndoALPremium = (item) => {
  return `${item?.alPremiums?.GWP ? formatPrice(item?.alPremiums?.GWP) : "$0.00"}`
}
export const itemEndoGLPremium = (item) => {
  return `${item?.glPremiums?.GWP ? formatPrice(item?.glPremiums?.GWP) : "$0.00"}`
}
export const itemEndoProcessingFee = (item) => {
  return `${item?.alPremiums?.processingFeeAmount ? formatPrice(item?.alPremiums?.processingFeeAmount) : "$0.00"}`
}
export const itemEndoOtherFees = (item) => {
  return `${item?.alPremiums?.otherFee ? formatPrice(item?.alPremiums?.otherFees) : "$0.00"}`
}
export const itemEndoSurplusLinesPlusTaxes = (item) => {
  return `${item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : "$0.00"}`
}
export const itemEndoStampFee = (item) => {
  return `${item?.alPremiums?.stampTaxAmount ? formatPrice(item?.alPremiums?.stampTaxAmount) : "$0.00"}`
}
export const itemEndoCoverageLimit = (item) => {
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  return `${policyDetail?.coverageSingleLimit || "$0.00"}`
}

export const titleItemEndoNumber = "ID"
export const titleItemEndoStatus = "Status"
export const titleItemEndoEffectiveDate = "Effective Date"
export const titleItemEndoIssuedDate = "Issue Date"
export const titleItemEndoTypeAddDelete = "Endorsement Type (Add/Delete)"
export const titleItemEndoRadius = "Radius"
export const titleItemEndoYear = "Year"
export const titleItemEndoMake = "Make"
export const titleItemEndoVIN = "VIN"
export const titleItem355366Days = "365/366 Days"
export const titleItemEndoState = "State"
export const titleItemEndoALNoPolicy = "AL Policy"
export const titleItemEndoALExp = "AL Exp."
export const titleItemEndoDaysAL = "Days AL"
export const titleItemEndoALPremium = "AL Premium"
export const titleItemEndoProcessingFee = "Processing Fee"
export const titleItemEndoOtherFees = "Other Fees"
export const titleItemEndoSurplusLinesPlusTaxes = "Surplus Lines Taxes"
export const titleItemEndoStampFee = "Stamp Fee"
export const titleItemEndoCoverageLimit = "Coverage Limit"

export const handleCopyToClipboardEndo = (item, controlToast) => {
  const textToCopy = `
      ${titleItemEndoNumber}: ${itemEndoNumber(item)};
      ${titleItemEndoStatus}: ${itemEndoStatus(item)};
      ${titleItemProgram}: ${itemEndoProgram(item)};
      ${titleItemInsuredName}: ${itemInsuredName(item)};
      ${titleItemEndoEffectiveDate}: ${itemEndoEffectiveDate(item)};
      ${titleItemEndoIssuedDate}: ${itemEndoIssuedDate(item)};
      ${titleItemEndoTypeAddDelete}: ${itemEndoTypeAddDelete(item)};
      ${titleItemEndoYear}: ${itemEndoYear(item)};
      ${titleItemEndoRadius}: ${itemEndoRadius(item)};
      ${titleItemEndoMake}: ${itemEndoMake(item)};
      ${titleItemEndoVIN}: ${itemEndoVIN(item)};
      ${titleItem355366Days}: ${item355366Days(item)};
      ${titleItemEndoState}: ${itemEndoState(item)};
      ${titleItemEndoALNoPolicy}: ${itemEndoALNoPolicy(item)};
      ${titleItemEndoALExp}: ${itemEndoALExp(item)};
      ${titleItemEndoDaysAL}: ${itemEndoDaysAL(item)};
      ${titleItemEndoALPremium}: ${itemEndoALPremium(item)};
      ${titleItemEndoProcessingFee}: ${itemEndoProcessingFee(item)};
      ${titleItemEndoOtherFees}: ${itemEndoOtherFees(item)};
      ${titleItemEndoSurplusLinesPlusTaxes}: ${itemEndoSurplusLinesPlusTaxes(item)};
      ${titleItemEndoStampFee}: ${itemEndoStampFee(item)};
      ${titleItemEndoCoverageLimit}: ${itemEndoCoverageLimit(item)};
    `;
    const textToSearch = `
      ${itemEndoNumber(item)}
      ${itemEndoStatus(item)}
      ${itemEndoProgram(item)}
      ${itemInsuredName(item)}
      ${itemEndoEffectiveDate(item)}
      ${itemEndoIssuedDate(item)}
      ${itemEndoTypeAddDelete(item)}
      ${itemEndoYear(item)}
      ${itemEndoRadius(item)}
      ${itemEndoMake(item)}
      ${itemEndoVIN(item)}
      ${item355366Days(item)}
      ${itemEndoState(item)}
      ${itemEndoALNoPolicy(item)}
      ${itemEndoALExp(item)}
      ${itemEndoDaysAL(item)}
      ${itemEndoALPremium(item)}
      ${itemEndoProcessingFee(item)}
      ${itemEndoOtherFees(item)}
      ${itemEndoSurplusLinesPlusTaxes(item)}
      ${itemEndoStampFee(item)}
      ${itemEndoCoverageLimit(item)}
    `;
    if (controlToast) {
        navigator.clipboard.writeText(textToCopy);
        controlToast(true, "The endorsement data was copied to clipboard", "success");
    }
    return textToSearch;
};

const extractPrefix = (input) => {
  if (typeof input !== 'string') {
    return '-';
  }
  const match = input.match(/^[A-Z]+-[A-Z]+/);
  return match ? match[0] : '-';
}

// VIN BORDEREAUX

export const vinItemBordereauxId = (item) => {
  const isPolicy = !Boolean(item?.type)
  const id = isPolicy ? item?.inspasData?.PolicyMasterRecID : item?.endorsementNumber
  return id ? `SSM-${id}` : "-"
}
export const vinItemBordereauxPolicyNo = (item) => {
  const policyNo =
    typeof item?.takeAL !== 'undefined' ||
    typeof item?.takeGL !== 'undefined' ?
    itemPolicyNo(item) :
    item?.alPolicyNo ? item?.alPolicyNo :
    item?.glPolicyNo ? item?.glPolicyNo : '-'
  return policyNo || "-"
}
export const vinItemBordereauxProgram = (item) => {
  const isPolicy = !Boolean(item?.type)
  const program = isPolicy ? itemProgram(item) : itemEndoProgram(item)
  return program
}
export const vinItemBordereauxEndoEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEndoEffectiveDate(item)
  return effectiveDate
}
export const vinItemBordereauxPolicyEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEffectiveDate(item?.policyData)
  return effectiveDate
}
export const vinItemBordereauxTransactionDate = (item) => {
  return vinItemBordereauxEndoEffectiveDate(item)
}
export const vinItemBordereauxTermExpiryDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const expirationDate = isPolicy ? itemExpirationDate(item) : itemEndoALExp(item)
  return expirationDate
}
export const vinItemBordereauxCountry = (item) => {
  const isPolicy = !Boolean(item?.type)
  const country = isPolicy ? (
    item?.insuredInfo?.state ? 'USA' : 'MX'
  ) : (
    item?.stateOfEntry ? 'USA' : 'MX'
  )
  return country
}
export const vinItemBordereauxTransactionCode = (item) => {
  if (item?.documentType === 'Endorsement') {
    if (item?.type === 'Cancellation' || item?.type === 'Policy Cancels') {
      return 'C'
    }
    return 'AP'
  } else if (item?.documentType === 'Policy') {
    return 'N'
  } else {
    return '-'
  }
}
export const vinItemBordereauxTransactionType = (item) => { 
  if (item?.documentType === 'Endorsement') {
    return 'Endorsement'
  } else if (item?.documentType === 'Policy') {
    return 'New Business'
  } else {
    return '-'
  }
}
export const vinItemBordereauxPolicyType = (item) => {
  return 'New Business'
}
export const vinItemBordereauxCurrentPolicyStatus = (item) => {
  if (item?.type === 'Cancellation' || item?.type === 'Policy Cancels') {
    return 'Void'
  }
  return 'On-Risk'
}
export const vinItemBordereauxInsuredName = (item) => {
  return itemInsuredName(item)
}
export const vinItemBordereauxAddress1 = (item) => {
  return item?.insuredInfo?.address || '-'
}
export const vinItemBordereauxAddress2 = (item) => {
  return item?.insuredInfo?.address2 || '-'
}
export const vinItemBordereauxCity = (item) => {
  return item?.insuredInfo?.city || '-'
}
export const vinItemBordereauxState = (item) => {
  return item?.insuredInfo?.state || '-'
}
export const vinItemBordereauxZipCode = (item) => {
  return item?.insuredInfo?.zip || '-'
}
export const vinItemBordereauxCounty = (item) => {
  if (item?.insuredInfo?.counties && item?.insuredInfo?.counties?.length > 0) {
    return item?.insuredInfo?.counties[0]
  }
  return '-'
}
export const vinItemBordereauxMGA = (item) => {
  return 'SSM'
}
export const vinItemBordereauxLineOfBusiness = (item) => {
  const isPolicy = !Boolean(item?.type)
  return isPolicy ? (item?.takeAL ? 'AUTO LIABILITY' : item?.takeGL ? 'GENERAL LIABILITY' : '-') : 'AUTO LIABILITY'
}
export const vinItemBordereauxPrimaryClass = (item) => {
  const isPolicy = !Boolean(item?.type)
  return isPolicy ? (item?.takeAL ? '19' : item?.takeGL ? '17' : '-') : '19'
}
export const vinItemBordereauxPrimaryDescription = (item) => {
  const isPolicy = !Boolean(item?.type)
  return isPolicy ? (item?.takeAL ? 'AUTO LIABILITY' : item?.takeGL ? 'GENERAL LIABILITY' : '-') : 'AUTO LIABILITY'
}
export const vinItemBordereauxPolicyState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyState = isPolicy ? item?.insuredInfo?.state : item?.stateOfEntry
  return policyState || '-'
}
export const vinItemBordereauxPolicyCurrency = (item) => {
  return 'USD'
}
export const vinItemBordereauxCoverageLimit = (item) => {
  const isPolicy = !Boolean(item?.type)
  let inspasInfo = formatLiabilityLimit(item?.inspasData?.LiabilityLimitID)
  const coverageSingleLimitPolicy = itemCoverageLimit(item)
  const source = item?.policiesDetails ? item?.policiesDetails : item?.data?.policiesDetails
  const policyDetail = source?.find(detail => detail?.id === item?.policyID)
  const coverageSingleLimitEndo = policyDetail?.coverageSingleLimit
  const coverageSingleLimit =
    inspasInfo ? inspasInfo :
    isPolicy ? coverageSingleLimitPolicy : coverageSingleLimitEndo
  return coverageSingleLimit || '-'
}
export const vinItemBordereauxCoverageDeductible = (item) => {
  const isPolicy = !Boolean(item?.type)
  const biLiabilityDeductible = item?.bindData?.biLiabilityDeductible || 0
  const coverageBiLiabilityDeductible =
    isPolicy ? (
      formatPrice(biLiabilityDeductible)
    ) : '$1,000.00'
  return coverageBiLiabilityDeductible || '-'
}
export const vinItemBordereauxBordereauNotes = (item) => {
  if (item?.type?.includes('Swap Power Unit Removed')) {
    return 'Swap Power Unit (-)'
  } else if (item?.type?.includes('Swap Power Unit Added')) {
    return 'Swap Power Unit (+)'
  }
  return item?.inspasData?.EventComments || '-'
}
export const vinItemBordereauxGrossWrittenPremium = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy =
    isPolicy ? (
      (item?.takeAL ? (
        item?.alPremiums?.GWP ? formatPrice(item?.alPremiums?.GWP) : '$0.00'
      ) : item?.takeGL ? (
        item?.glPremiums?.GWP ? formatPrice(item?.glPremiums?.GWP) : '$0.00'
      ) : '$0.00')
    ) : item?.alPremiums?.GWP ? formatPrice(item?.alPremiums?.GWP) : '$0.00'
  return grossWrittenPremiumPolicy || '$0.00'
}
export const vinItemBordereauxMGACommissionAmount = (item) => {
  const commissionPercentage = vinItemBordereauxMGACommissionPercentage(item)
  const grossWrittenPremium = vinItemBordereauxGrossWrittenPremium(item)
  if (grossWrittenPremium !== '-' && commissionPercentage !== '-') {
    const purgedNumber = grossWrittenPremium.replace(/[\$,]/g, '');
    const floatNumber = parseFloat(purgedNumber);
    if (!isNaN(floatNumber)) {
      const commissionAmount = commissionPercentage * floatNumber / 100
      return formatPrice(commissionAmount)
    }
    return '-'
  }
  return '-'
}
export const vinItemBordereauxMGACommissionPercentage = (item) => {
  const policyEffectiveDate = vinItemBordereauxPolicyEffectiveDate(item)
  if (typeof policyEffectiveDate !== 'string') {
    return '-'
  }
  const year = new Date(policyEffectiveDate).getFullYear()
  const parsedYear = parseInt(year)
  if (typeof parsedYear === 'number') {
    if (parsedYear >= 2021 && parsedYear <= 2022) {
      return 12
    } else if (parsedYear >= 2023) {
      return 14.50
    }
  }
  return '-'
}
export const vinItemBordereauxFrontingFeeAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const grossWrittenPremiumPolicy =
    isPolicy ? (
      (item?.takeAL ? (
        item?.alPremiums?.processingFeeAmount ? formatPrice(item?.alPremiums?.processingFeeAmount) : '$0.00'
      ) : item?.takeGL ? (
        item?.glPremiums?.processingFeeAmount ? formatPrice(item?.glPremiums?.processingFeeAmount) : '$0.00'
      ) : '$0.00')
    ) : item?.alPremiums?.processingFeeAmount ? formatPrice(item?.alPremiums?.processingFeeAmount) : '$0.00'
  return grossWrittenPremiumPolicy || '$0.00'
}
export const vinItemBordereauxFetTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount =
    isPolicy ? (
      (item?.takeAL ? (
        item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
      ) : item?.takeGL ? (
        item?.glPremiums?.surplusLinesTaxAmount ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '$0.00'
      ) : '$0.00')
    ) : item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
  return surplusLinesTaxAmount || '$0.00'
}
export const vinItemBordereauxStatePremiumTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusLinesTaxAmount =
    isPolicy ? (
      (item?.takeAL ? (
        item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
      ) : item?.takeGL ? (
        item?.glPremiums?.surplusLinesTaxAmount ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '$0.00'
      ) : '$0.00')
    ) : item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
  return surplusLinesTaxAmount || '$0.00'
}
export const vinItemBordereauxMunicipalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const stampTaxAmount =
    isPolicy ? (
      (item?.takeAL ? (
        item?.alPremiums?.stampTaxAmount ? formatPrice(item?.alPremiums?.stampTaxAmount) : '$0.00'
      ) : item?.takeGL ? (
        item?.glPremiums?.stampTaxAmount ? formatPrice(item?.glPremiums?.stampTaxAmount) : '$0.00'
      ) : '$0.00')
    ) : item?.alPremiums?.stampTaxAmount ? formatPrice(item?.alPremiums?.stampTaxAmount) : '$0.00'
  return stampTaxAmount || '$0.00'
}
export const vinItemBordereauxTotalTaxAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const surplusAndStampFeeSum =
    isPolicy ? ((item?.takeAL ? (
      item?.alPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums ? (
        formatPrice(
          (item?.glPremiums?.surplusLinesTaxAmount || 0) +
          (item?.glPremiums?.stampTaxAmount || 0)
        )
      ) : '$0.00'
    ) : '$0.00')) : (
      item?.alPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '$0.00'
    )
  return surplusAndStampFeeSum || '$0.00'
}
export const vinItemBordereauxOtherFeesAmount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const otherFeesAmount = isPolicy ?
    (item?.takeAL ? (
      item?.alPremiums?.otherFees ? formatPrice(item?.alPremiums?.otherFees) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.otherFees ? formatPrice(item?.glPremiums?.otherFees) : '$0.00'
    ) : '$0.00') :
    item?.alPremiums?.otherFees ? formatPrice(item?.alPremiums?.otherFees) : '$0.00'
  return otherFeesAmount || '$0.00'
}
export const vinItemBordereauxNetAmountDueAmount = (item) => {
  const gwp = vinItemBordereauxGrossWrittenPremium(item)
  const commission = vinItemBordereauxMGACommissionAmount(item)
  const parseValue = (value) => {
    const cleanedValue = value.replace(/[^0-9.-]/g, '');
    return parseFloat(cleanedValue);
  }
  const netAmountDue$ = parseValue(gwp) - parseValue(commission);
  return netAmountDue$ ? formatPrice(netAmountDue$) : '$0.00'
}
export const vinItemBordereauxEndReportingPeriod = (item) => {
  const expiryData = vinItemBordereauxTermExpiryDate(item)
  return expiryData
}
export const vinItemBordereauxFrontingFeePercentage = (item) => {
  return '5%'
}
export const vinItemBordereauxRiskManagementFeeAmount = (item) => {
  return '5%'
}
export const vinItemBordereauxManagementFeeAmount = (item) => {
  const frontingFeeAmount = vinItemBordereauxFrontingFeeAmount(item)
  return frontingFeeAmount
}
export const vinItemBordereauxPowerUnitsCount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const vehicleCount = isPolicy ? itemVehicleCount(item) : itemVehicleCount(item?.policyData)
  return vehicleCount
}
export const vinItemBordereauxGarageAddress = (item) => {
  const address = item?.insuredInfo.address
  return address || '-'
}
export const vinItemBordereauxGarageCity = (item) => { 
  const city = item?.insuredInfo.city
  return city || '-'
}
export const vinItemBordereauxGarageState = (item) => {
  const state = item?.insuredInfo.state
  return state || '-'
}
export const vinItemBordereauxGarageZip = (item) => {
  const zip = item?.insuredInfo.zip
  return zip || '-'
}
export const vinItemBordereauxGarageVIN = (item) => {
  const truckVIN = item?.data?.truckVIN || item?.data?.trailerVIN
  return truckVIN || '-'
}
export const vinItemBordereauxGarageYear = (item) => {
  const truckYear = item?.data?.truckYear || item?.data?.trailerYear
  return truckYear || '-'
}
export const vinItemBordereauxGarageMake = (item) => {
  const truckMake = item?.data?.truckMake || item?.data?.trailerMake
  return truckMake || '-'
}
export const vinItemBordereauxGarageVehModel = (item) => {
  const truckModel = item?.data?.truckModel || item?.data?.trailerModel
  return truckModel || '-'
}
export const vinItemBordereauxGarageType = (item) => {
  const eventTypeID = item?.inspasData?.EventTypeID
  return eventTypeID || '-'
}
export const vinItemBordereauxGaragePuNet = (item) => {
  const isPolicy = !Boolean(item?.type)
  const data = item?.data?.policiesDetails
  const policyID = isPolicy ? item?.id : item?.policyID
  const policyDetail = data?.find(detail => detail?.id === policyID)
  const puNet = policyDetail?.adjustedRate ? formatPrice(policyDetail?.adjustedRate) : '$0.00'
  return puNet
}
export const vinItemBordereauxEventTask = (item) => {
  const eventTask = item?.inspasData?.EventTask
  return eventTask || '-'
}

// MAIN BORDEREAUX

export const mainItemBordereauxId = (item) => {
  const isPolicy = !Boolean(item?.type)
  const id = isPolicy ? item?.inspasData?.PolicyMasterRecID : item?.endorsementNumber
  return id ? `SSM-${id}` : "-"
}
export const mainItemBordereauxPolicyNo = (item) => {
  const policyNo =
    typeof item?.takeAL !== 'undefined' ||
    typeof item?.takeGL !== 'undefined' ?
    itemPolicyNo(item) :
    item?.alPolicyNo ? item?.alPolicyNo :
    item?.glPolicyNo ? item?.glPolicyNo : '-'
  return policyNo || "-"
}
export const mainItemBordereauxProgram = (item) => {
  // if (item?.endorsementNumber === 39095) {
  //   console.log('item', item)
  // }
  const isPolicy = !Boolean(item?.type)
  const program = isPolicy ? itemProgram(item) : itemProgram(item?.policyData)
  return program
}
export const mainItemBordereauxEndoEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEndoEffectiveDate(item)
  return effectiveDate
}
export const mainItemBordereauxPolicyEffectiveDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const effectiveDate = isPolicy ? itemEffectiveDate(item) : itemEffectiveDate(item?.policyData)
  return effectiveDate
}
export const mainItemBordereauxTransactionDate = (item) => {
  return mainItemBordereauxEndoEffectiveDate(item)
}
export const mainItemBordereauxTermExpiryDate = (item) => {
  const isPolicy = !Boolean(item?.type)
  const expirationDate = isPolicy ? itemExpirationDate(item) : itemExpirationDate(item?.policyData)
  return expirationDate
}
export const mainItemBordereauxCountry = (item) => {
  const isPolicy = !Boolean(item?.type)
  const country = isPolicy ? (
    item?.insuredInfo?.state ? 'USA' : 'MX'
  ) : (
    item?.policyData?.stateOfEntry ? 'USA' : 'MX'
  )
  return country
}
export const mainItemBordereauxTransactionCode = (item) => {
  if (item?.documentType === 'Endorsement') {
    if (item?.type === 'Cancellation' || item?.type === 'Policy Cancels') {
      return 'C'
    }
    return 'AP'
  } else if (item?.documentType === 'Policy') {
    return 'N'
  } else {
    return '-'
  }
}
export const mainItemBordereauxTransactionType = (item) => {
  if (item?.documentType === 'Endorsement') {
    return 'Endorsement'
  } else if (item?.documentType === 'Policy') {
    return 'New Business'
  } else {
    return '-'
  }
}
export const mainItemBordereauxPolicyType = (item) => {
  return 'New Business'
}
export const mainItemBordereauxCurrentPolicyStatus = (item) => {
  if (item?.type === 'Cancellation' || item?.type === 'Policy Cancels') {
    return 'Void'
  }
  return 'On-Risk'
}
export const mainItemBordereauxInsuredName = (item) => {
  return itemInsuredName(item)
}
export const mainItemBordereauxAddress1 = (item) => {
  return item?.insuredInfo?.address || '-'
}
export const mainItemBordereauxAddress2 = (item) => {
  return item?.insuredInfo?.address2 || '-'
}
export const mainItemBordereauxCity = (item) => {
  return item?.insuredInfo?.city || '-'
}
export const mainItemBordereauxState = (item) => {
  return item?.insuredInfo?.state || '-'
}
export const mainItemBordereauxZipCode = (item) => {
  return item?.insuredInfo?.zip || '-'
}
export const mainItemBordereauxCounty = (item) => {
  if (item?.insuredInfo?.counties && item?.insuredInfo?.counties?.length > 0) {
    return item?.insuredInfo?.counties[0]
  }
  return '-'
}
export const mainItemBordereauxMGA = (item) => {
  return 'SSM'
}
export const mainItemBordereauxLineOfBusiness = (item) => {
  return item?.takeAL ? 'AUTO LIABILITY' : item?.takeGL ? 'GENERAL LIABILITY' : '-'
}
export const mainItemBordereauxPrimaryClass = (item) => {
  return item?.takeAL ? '19' : item?.takeGL ? '17' : '-'
}
export const mainItemBordereauxPrimaryDescription = (item) => {
  return item?.takeAL ? 'AUTO LIABILITY' : item?.takeGL ? 'GENERAL LIABILITY' : '-'
}
export const mainItemBordereauxPolicyState = (item) => {
  const isPolicy = !Boolean(item?.type)
  const policyState = isPolicy ? item?.insuredInfo?.state : item?.policyData?.stateOfEntry
  return policyState || '-'
}
export const mainItemBordereauxPolicyCurrency = (item) => {
  return 'USD'
}
export const mainItemBordereauxCoverageLimit = (item) => {
  const isPolicy = !Boolean(item?.type)
  let inspasInfo = formatLiabilityLimit(item?.inspasData?.LiabilityLimitID)
  const coverageSingleLimitPolicy = itemCoverageLimit(item)
  const coverageSingleLimitEndo = itemCoverageLimit(item?.policyData)
  const coverageSingleLimit =
    inspasInfo ? inspasInfo :
    isPolicy ? coverageSingleLimitPolicy : coverageSingleLimitEndo
  return coverageSingleLimit || '-'
}
export const mainItemBordereauxCoverageDeductible = (item) => {
  const isPolicy = !Boolean(item?.type)
  const biLiabilityDeductible = item?.bindData?.biLiabilityDeductible || 0
  const coverageBiLiabilityDeductible =
    isPolicy ? (
      formatPrice(biLiabilityDeductible)
    ) : '$1,000.00'
  return coverageBiLiabilityDeductible || '-'
}
export const mainItemBordereauxBordereauNotes = (item) => {
  return item?.inspasData?.EventComments || '-'
}
export const mainItemBordereauxGrossWrittenPremium = (item) => {
  const grossWrittenPremiumPolicy =
    (item?.takeAL ? (
      item?.alPremiums?.GWP ? formatPrice(item?.alPremiums?.GWP) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.GWP ? formatPrice(item?.glPremiums?.GWP) : '$0.00'
    ) : '$0.00')
  return grossWrittenPremiumPolicy || '$0.00'
}
export const mainItemBordereauxMGACommissionAmount = (item) => {
  const commissionPercentage = mainItemBordereauxMGACommissionPercentage(item)
  const grossWrittenPremium = mainItemBordereauxGrossWrittenPremium(item)
  if (grossWrittenPremium !== '-' && commissionPercentage !== '-') {
    const purgedNumber = grossWrittenPremium.replace(/[\$,]/g, '');
    const floatNumber = parseFloat(purgedNumber);
    if (!isNaN(floatNumber)) {
      const commissionAmount = commissionPercentage * floatNumber / 100
      return formatPrice(commissionAmount)
    }
    return '-'
  }
  return '-'
}
export const mainItemBordereauxMGACommissionPercentage = (item) => {
  const policyEffectiveDate = mainItemBordereauxPolicyEffectiveDate(item)
  if (typeof policyEffectiveDate !== 'string') {
    return '-'
  }
  const year = new Date(policyEffectiveDate).getFullYear()
  const parsedYear = parseInt(year)
  if (typeof parsedYear === 'number') {
    if (parsedYear >= 2021 && parsedYear <= 2022) {
      return 12
    } else if (parsedYear >= 2023) {
      return 14.50
    }
  }
  return '-'
}
export const mainItemBordereauxFrontingFeeAmount = (item) => {
  const grossWrittenPremiumPolicy =
    (item?.takeAL ? (
      item?.alPremiums?.processingFeeAmount ? formatPrice(item?.alPremiums?.processingFeeAmount) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.processingFeeAmount ? formatPrice(item?.glPremiums?.processingFeeAmount) : '$0.00'
    ) : '$0.00')
  return grossWrittenPremiumPolicy || '$0.00'
}
export const mainItemBordereauxFetTaxAmount = (item) => {
  const surplusLinesTaxAmount =
    (item?.takeAL ? (
      item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.surplusLinesTaxAmount ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '$0.00'
    ) : '$0.00')
  return surplusLinesTaxAmount || '$0.00'
}
export const mainItemBordereauxStatePremiumTaxAmount = (item) => {
  const surplusLinesTaxAmount =
    (item?.takeAL ? (
      item?.alPremiums?.surplusLinesTaxAmount ? formatPrice(item?.alPremiums?.surplusLinesTaxAmount) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.surplusLinesTaxAmount ? formatPrice(item?.glPremiums?.surplusLinesTaxAmount) : '$0.00'
    ) : '$0.00')
  return surplusLinesTaxAmount || '$0.00'
}
export const mainItemBordereauxMunicipalTaxAmount = (item) => {
  const stampTaxAmount =
    (item?.takeAL ? (
      item?.alPremiums?.stampTaxAmount ? formatPrice(item?.alPremiums?.stampTaxAmount) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.stampTaxAmount ? formatPrice(item?.glPremiums?.stampTaxAmount) : '$0.00'
    ) : '$0.00')
  return stampTaxAmount || '$0.00'
}
export const mainItemBordereauxTotalTaxAmount = (item) => {
  const stampTaxAmount =
    (item?.takeAL ? (
      item?.alPremiums ? (
        formatPrice(
          (item?.alPremiums?.surplusLinesTaxAmount || 0) +
          (item?.alPremiums?.stampTaxAmount || 0)
        )
      ) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums ? (
        formatPrice(
          (item?.glPremiums?.surplusLinesTaxAmount || 0) +
          (item?.glPremiums?.stampTaxAmount || 0)
        )
      ) : '$0.00'
    ) : '$0.00')
  return stampTaxAmount || '$0.00'
}
export const mainItemBordereauxOtherFeesAmount = (item) => {
  const otherFeesAmount =
    (item?.takeAL ? (
      item?.alPremiums?.otherFees ? formatPrice(item?.alPremiums?.otherFees) : '$0.00'
    ) : item?.takeGL ? (
      item?.glPremiums?.otherFees ? formatPrice(item?.glPremiums?.otherFees) : '$0.00'
    ) : '$0.00')
  return otherFeesAmount || '$0.00'
}
export const mainItemBordereauxNetAmountDueAmount = (item) => {
  const gwp = mainItemBordereauxGrossWrittenPremium(item)
  const commission = mainItemBordereauxMGACommissionAmount(item)
  const parseValue = (value) => {
    const cleanedValue = value.replace(/[^0-9.-]/g, '');
    return parseFloat(cleanedValue);
  }
  const netAmountDue$ = parseValue(gwp) - parseValue(commission);
  return netAmountDue$ ? formatPrice(netAmountDue$) : '$0.00'
}
export const mainItemBordereauxEndReportingPeriod = (item) => {
  const expiryData = mainItemBordereauxTermExpiryDate(item)
  return expiryData
}
export const mainItemBordereauxFrontingFeePercentage = (item) => {
  return '5%'
}
export const mainItemBordereauxRiskManagementFeeAmount = (item) => {
  return '5%'
}
export const mainItemBordereauxManagementFeeAmount = (item) => {
  const managementFeeAmount = mainItemBordereauxFrontingFeeAmount(item)
  return managementFeeAmount
}
export const mainItemBordereauxPowerUnitsCount = (item) => {
  const isPolicy = !Boolean(item?.type)
  const vehicleCount = isPolicy ? itemVehicleCount(item) : itemVehicleCount(item?.policyData)
  return vehicleCount
}
export const mainItemBordereauxGarageAddress = (item) => {
  return item?.insuredInfo?.address || '-'
}
export const mainItemBordereauxGarageCity = (item) => {
  return item?.insuredInfo?.city || '-'
}
export const mainItemBordereauxGarageState = (item) => {
  return item?.insuredInfo?.state || '-'
}
export const mainItemBordereauxGarageZip = (item) => {
  return item?.insuredInfo?.zip || '-'
}
export const mainItemBordereauxGarageVIN = (item) => {
  return '-'
}
export const mainItemBordereauxGarageYear = (item) => {
  return '-'
}
export const mainItemBordereauxGarageMake = (item) => {
  return '-'
}
export const mainItemBordereauxGarageVehModel = (item) => {
  return '-'
}
export const mainItemBordereauxGarageType = (item) => {
  const eventTypeID = item?.inspasData?.EventTypeID
  return eventTypeID || '-'
}
export const mainItemBordereauxGaragePuNet = (item) => {
  const puNet = item?.takeAL ? item?.alPremiums?.GWP : item?.glPremiums?.GWP
  return puNet ? formatPrice(puNet) : '$0.00'
}
export const mainItemBordereauxEventTask = (item) => {
  const eventTask = item?.inspasData?.EventTask
  return eventTask || '-'
}

export const titleItemBordereauxUniqueReferenceNumber = 'Unique Reference Number'
export const titleItemBordereauxPolicyNumber = 'Policy Number'
export const titleItemBordereauxProgram = 'Program'
export const titleItemBordereauxBeginningReportingPeriod = 'Beginning Reporting Period'
export const titleItemBordereauxPolicyEffectiveDate = 'Policy Effective Date'
export const titleItemBordereauxTransactionEffectiveDate = 'Transaction Effective Date'
export const titleItemBordereauxTermExpiryDate = 'Term Expiry Date'
export const titleItemBordereauxCountry = 'Country'
export const titleItemBordereauxTransactionCode = 'Transaction Code'
export const titleItemBordereauxTransactionType = 'Transaction Type'
export const titleItemBordereauxPolicyType = 'Policy Type'
export const titleItemBordereauxCurrentPolicyStatus = 'Current Policy Status'
export const titleItemBordereauxInsuredName = 'Insured Name'
export const titleItemBordereauxAddress1 = 'Address 1'
export const titleItemBordereauxAddress2 = 'Address 2'
export const titleItemBordereauxCity = 'City'
export const titleItemBordereauxState = 'State'
export const titleItemBordereauxZipCode = 'Zip Code'
export const titleItemBordereauxCounty = 'County'
export const titleItemBordereauxMGA = 'MGA'
export const titleItemBordereauxLineOfBusiness = 'Line of Business'
export const titleItemBordereauxPrimaryClass = 'Primary Class'
export const titleItemBordereauxPrimaryDescription = 'Primary Description'
export const titleItemBordereauxPolicyState = 'Policy State'
export const titleItemBordereauxPolicyCurrency = 'Policy Currency'
export const titleItemBordereauxCoverageLimit = 'Coverage Limit'
export const titleItemBordereauxCoverageDeductible = 'Coverage Deductible'
export const titleItemBordereauxBordereauNotes = 'Bordereau Notes'
export const titleItemBordereauxGrossWrittenPremium = 'Gross Written Premium'
export const titleItemBordereauxMGACommission$ = 'MGA Commission $'
export const titleItemBordereauxMGACommissionPer = 'MGA Commission %'
export const titleItemBordereauxFrontingFee = 'Fronting Fee'
export const titleItemBordereauxFETTax$ = 'FET Tax $'
export const titleItemBordereauxStatePremiumTax$ = 'State Premium Tax $'
export const titleItemBordereauxMunicipalTax$ = 'Municipal Tax $'
export const titleItemBordereauxTotalTax$ = 'Total Tax $'
export const titleItemBordereauxOtherFees = 'Other Fees'
export const titleItemBordereauxNetAmountDue$ = 'Net Amount Due $'
export const titleItemBordereauxEndReportingPeriod = 'End Reporting Period'
export const titleItemBordereauxFrontingFeePer = 'Fronting Fee %'
export const titleItemBordereauxRiskManagementFee = 'Risk Management Fee'
export const titleItemBordereauxManagementFee = 'Management Fee'
export const titleItemBordereauxPowerUnitsCount = 'Power Units Count'
export const titleItemBordereauxGarageAddress = 'Garage Address'
export const titleItemBordereauxGarageCity = 'Garage City'
export const titleItemBordereauxGarageState = 'Garage State'
export const titleItemBordereauxGarageZip = 'Garage Zip'
export const titleItemBordereauxVIN = 'VIN'
export const titleItemBordereauxYear = 'Year'
export const titleItemBordereauxMake = 'Make'
export const titleItemBordereauxVehModel = 'Veh Model'
export const titleItemBordereauxType = 'Type'
export const titleItemBordereauxPUNET = 'PU NET'
export const titleItemBordereauxEventTask = 'EventTask'

export const handleCopyToClipboardBordereaux = (item, controlToast, bordereauxType) => {
  const isMain = bordereauxType === 'main'
  const textToCopy = `
      ${titleItemBordereauxUniqueReferenceNumber}: ${isMain ? mainItemBordereauxId(item) : vinItemBordereauxId(item)};
      ${titleItemBordereauxPolicyNumber}: ${isMain ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item)};
      ${titleItemBordereauxProgram}: ${isMain ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item)};
      ${titleItemBordereauxBeginningReportingPeriod}: ${isMain ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item)};
      ${titleItemBordereauxPolicyEffectiveDate}: ${isMain ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item)};
      ${titleItemBordereauxTransactionEffectiveDate}: ${isMain ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item)};
      ${titleItemBordereauxTermExpiryDate}: ${isMain ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item)};
      ${titleItemBordereauxCountry}: ${isMain ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item)};
      ${titleItemBordereauxTransactionCode}: ${isMain ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item)};
      ${titleItemBordereauxTransactionType}: ${isMain ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item)};
      ${titleItemBordereauxPolicyType}: ${isMain ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item)};
      ${titleItemBordereauxCurrentPolicyStatus}: ${isMain ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item)};
      ${titleItemBordereauxInsuredName}: ${isMain ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item)};
      ${titleItemBordereauxAddress1}: ${isMain ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item)};
      ${titleItemBordereauxAddress2}: ${isMain ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item)};
      ${titleItemBordereauxCity}: ${isMain ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item)};
      ${titleItemBordereauxState}: ${isMain ? mainItemBordereauxState(item) : vinItemBordereauxState(item)};
      ${titleItemBordereauxZipCode}: ${isMain ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item)};
      ${titleItemBordereauxCounty}: ${isMain ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item)};
      ${titleItemBordereauxMGA}: ${isMain ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item)};
      ${titleItemBordereauxLineOfBusiness}: ${isMain ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item)};
      ${titleItemBordereauxPrimaryClass}: ${isMain ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item)};
      ${titleItemBordereauxPrimaryDescription}: ${isMain ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item)};
      ${titleItemBordereauxPolicyState}: ${isMain ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item)};
      ${titleItemBordereauxPolicyCurrency}: ${isMain ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item)};
      ${titleItemBordereauxCoverageLimit}: ${isMain ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item)};
      ${titleItemBordereauxCoverageDeductible}: ${isMain ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item)};
      ${titleItemBordereauxBordereauNotes}: ${isMain ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item)};
      ${titleItemBordereauxGrossWrittenPremium}: ${isMain ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item)};
      ${titleItemBordereauxMGACommission$}: ${isMain ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item)};
      ${titleItemBordereauxMGACommissionPer}: ${isMain ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item)};
      ${titleItemBordereauxFrontingFee}: ${isMain ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item)};
      ${titleItemBordereauxFETTax$}: ${isMain ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item)};
      ${titleItemBordereauxStatePremiumTax$}: ${isMain ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item)};
      ${titleItemBordereauxMunicipalTax$}: ${isMain ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item)};
      ${titleItemBordereauxTotalTax$}: ${isMain ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item)};
      ${titleItemBordereauxOtherFees}: ${isMain ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item)};
      ${titleItemBordereauxNetAmountDue$}: ${isMain ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item)};
      ${titleItemBordereauxEndReportingPeriod}: ${isMain ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item)};
      ${titleItemBordereauxFrontingFeePer}: ${isMain ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item)};
      ${titleItemBordereauxRiskManagementFee}: ${isMain ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item)};
      ${titleItemBordereauxManagementFee}: ${isMain ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item)};
      ${titleItemBordereauxPowerUnitsCount}: ${isMain ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item)};
      ${titleItemBordereauxGarageAddress}: ${isMain ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item)};
      ${titleItemBordereauxGarageCity}: ${isMain ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item)};
      ${titleItemBordereauxGarageState}: ${isMain ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item)};
      ${titleItemBordereauxGarageZip}: ${isMain ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item)};
      ${titleItemBordereauxVIN}: ${isMain ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item)};
      ${titleItemBordereauxYear}: ${isMain ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item)};
      ${titleItemBordereauxMake}: ${isMain ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item)};
      ${titleItemBordereauxVehModel}: ${isMain ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item)};
      ${titleItemBordereauxType}: ${isMain ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item)};
      ${titleItemBordereauxPUNET}: ${isMain ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item)};
      ${titleItemBordereauxEventTask}: ${isMain ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item)};
    `;
    const textToSearch = `
      ${isMain ? mainItemBordereauxId(item) : vinItemBordereauxId(item)}
      ${isMain ? mainItemBordereauxPolicyNo(item) : vinItemBordereauxPolicyNo(item)}
      ${isMain ? mainItemBordereauxProgram(item) : vinItemBordereauxProgram(item)}
      ${isMain ? mainItemBordereauxEndoEffectiveDate(item) : vinItemBordereauxEndoEffectiveDate(item)}
      ${isMain ? mainItemBordereauxPolicyEffectiveDate(item) : vinItemBordereauxPolicyEffectiveDate(item)}
      ${isMain ? mainItemBordereauxTransactionDate(item) : vinItemBordereauxTransactionDate(item)}
      ${isMain ? mainItemBordereauxTermExpiryDate(item) : vinItemBordereauxTermExpiryDate(item)}
      ${isMain ? mainItemBordereauxCountry(item) : vinItemBordereauxCountry(item)}
      ${isMain ? mainItemBordereauxTransactionCode(item) : vinItemBordereauxTransactionCode(item)}
      ${isMain ? mainItemBordereauxTransactionType(item) : vinItemBordereauxTransactionType(item)}
      ${isMain ? mainItemBordereauxPolicyType(item) : vinItemBordereauxPolicyType(item)}
      ${isMain ? mainItemBordereauxCurrentPolicyStatus(item) : vinItemBordereauxCurrentPolicyStatus(item)}
      ${isMain ? mainItemBordereauxInsuredName(item) : vinItemBordereauxInsuredName(item)}
      ${isMain ? mainItemBordereauxAddress1(item) : vinItemBordereauxAddress1(item)}
      ${isMain ? mainItemBordereauxAddress2(item) : vinItemBordereauxAddress2(item)}
      ${isMain ? mainItemBordereauxCity(item) : vinItemBordereauxCity(item)}
      ${isMain ? mainItemBordereauxState(item) : vinItemBordereauxState(item)}
      ${isMain ? mainItemBordereauxZipCode(item) : vinItemBordereauxZipCode(item)}
      ${isMain ? mainItemBordereauxCounty(item) : vinItemBordereauxCounty(item)}
      ${isMain ? mainItemBordereauxMGA(item) : vinItemBordereauxMGA(item)}
      ${isMain ? mainItemBordereauxLineOfBusiness(item) : vinItemBordereauxLineOfBusiness(item)}
      ${isMain ? mainItemBordereauxPrimaryClass(item) : vinItemBordereauxPrimaryClass(item)}
      ${isMain ? mainItemBordereauxPrimaryDescription(item) : vinItemBordereauxPrimaryDescription(item)}
      ${isMain ? mainItemBordereauxPolicyState(item) : vinItemBordereauxPolicyState(item)}
      ${isMain ? mainItemBordereauxPolicyCurrency(item) : vinItemBordereauxPolicyCurrency(item)}
      ${isMain ? mainItemBordereauxCoverageLimit(item) : vinItemBordereauxCoverageLimit(item)}
      ${isMain ? mainItemBordereauxCoverageDeductible(item) : vinItemBordereauxCoverageDeductible(item)}
      ${isMain ? mainItemBordereauxBordereauNotes(item) : vinItemBordereauxBordereauNotes(item)}
      ${isMain ? mainItemBordereauxGrossWrittenPremium(item) : vinItemBordereauxGrossWrittenPremium(item)}
      ${isMain ? mainItemBordereauxMGACommissionAmount(item) : vinItemBordereauxMGACommissionAmount(item)}
      ${isMain ? mainItemBordereauxMGACommissionPercentage(item) : vinItemBordereauxMGACommissionPercentage(item)}
      ${isMain ? mainItemBordereauxFrontingFeeAmount(item) : vinItemBordereauxFrontingFeeAmount(item)}
      ${isMain ? mainItemBordereauxFetTaxAmount(item) : vinItemBordereauxFetTaxAmount(item)}
      ${isMain ? mainItemBordereauxStatePremiumTaxAmount(item) : vinItemBordereauxStatePremiumTaxAmount(item)}
      ${isMain ? mainItemBordereauxMunicipalTaxAmount(item) : vinItemBordereauxMunicipalTaxAmount(item)}
      ${isMain ? mainItemBordereauxTotalTaxAmount(item) : vinItemBordereauxTotalTaxAmount(item)}
      ${isMain ? mainItemBordereauxOtherFeesAmount(item) : vinItemBordereauxOtherFeesAmount(item)}
      ${isMain ? mainItemBordereauxNetAmountDueAmount(item) : vinItemBordereauxNetAmountDueAmount(item)}
      ${isMain ? mainItemBordereauxEndReportingPeriod(item) : vinItemBordereauxEndReportingPeriod(item)}
      ${isMain ? mainItemBordereauxFrontingFeePercentage(item) : vinItemBordereauxFrontingFeePercentage(item)}
      ${isMain ? mainItemBordereauxRiskManagementFeeAmount(item) : vinItemBordereauxRiskManagementFeeAmount(item)}
      ${isMain ? mainItemBordereauxManagementFeeAmount(item) : vinItemBordereauxManagementFeeAmount(item)}
      ${isMain ? mainItemBordereauxPowerUnitsCount(item) : vinItemBordereauxPowerUnitsCount(item)}
      ${isMain ? mainItemBordereauxGarageAddress(item) : vinItemBordereauxGarageAddress(item)}
      ${isMain ? mainItemBordereauxGarageCity(item) : vinItemBordereauxGarageCity(item)}
      ${isMain ? mainItemBordereauxGarageState(item) : vinItemBordereauxGarageState(item)}
      ${isMain ? mainItemBordereauxGarageZip(item) : vinItemBordereauxGarageZip(item)}
      ${isMain ? mainItemBordereauxGarageVIN(item) : vinItemBordereauxGarageVIN(item)}
      ${isMain ? mainItemBordereauxGarageYear(item) : vinItemBordereauxGarageYear(item)}
      ${isMain ? mainItemBordereauxGarageMake(item) : vinItemBordereauxGarageMake(item)}
      ${isMain ? mainItemBordereauxGarageVehModel(item) : vinItemBordereauxGarageVehModel(item)}
      ${isMain ? mainItemBordereauxGarageType(item) : vinItemBordereauxGarageType(item)}
      ${isMain ? mainItemBordereauxGaragePuNet(item) : vinItemBordereauxGaragePuNet(item)}
      ${isMain ? mainItemBordereauxEventTask(item) : vinItemBordereauxEventTask(item)}
    `;
    if (controlToast) {
        navigator.clipboard.writeText(textToCopy);
        controlToast(true, "The bordereaux data was copied to clipboard", "success");
    }
    return textToSearch;
};