import {
  TableHead,
  TableRow,
  TableSortLabel,
  TableCell,
  styled,
} from "@mui/material";
import React, { useState } from "react";

export default function CustomTableHead({
  sortField,
  setSortField,
  sortDirection,
  setSortDirection,
}) {
  const handleSort = (field) => {
    if (sortField === field) {
      // If the field is already the current sort field, toggle the direction
      setSortDirection((prevDirection) =>
        prevDirection === "asc" ? "desc" : "asc"
      );
    } else {
      // If clicking on a new field, set it as the sort field and default direction to ascending
      setSortField(field);
      setSortDirection("asc");
    }
  };

  const customStyle = {
    "& .MuiTableSortLabel-icon": {
      color: "white !important",
    },
    "&.Mui-active": {
      color: "white !important",
    },

    "&.MuiTableSortLabel-root:hover .MuiTableSortLabel-icon": {
      color: "white !important", // Change icon color to white on hover
    },
    "&.MuiTableSortLabel-root:hover": {
      color: "white", // Change text color to white on hover
    },
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            backgroundColor: "#072a48",
            color: "white",
            borderTopLeftRadius: "10px",
          }}
        >
          <TableSortLabel
            active={sortField === "status"}
            direction={sortDirection}
            onClick={() => handleSort("status")}
            sx={customStyle}
          >
            Status
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ backgroundColor: "#072a48", color: "white" }}>
          <TableSortLabel
            active={sortField === "endorsementNumber"}
            direction={sortDirection}
            onClick={() => handleSort("endorsementNumber")}
            sx={customStyle}
          >
            Endo No.
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ backgroundColor: "#072a48", color: "white" }}>
          <TableSortLabel
            active={sortField === "namedInsured"}
            direction={sortDirection}
            onClick={() => handleSort("namedInsured")}
            sx={customStyle}
          >
            Named Insured
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ backgroundColor: "#072a48", color: "white" }}>
          <TableSortLabel
            active={sortField === "type"}
            direction={sortDirection}
            onClick={() => handleSort("type")}
            sx={customStyle}
          >
            Endo Type
          </TableSortLabel>
        </TableCell>
        <TableCell
          sx={{
            backgroundColor: "#072a48",
            color: "white",
            borderTopRightRadius: "10px",
          }}
        >
          <TableSortLabel
            active={sortField === "endoEffectiveDate"}
            direction={sortDirection}
            onClick={() => handleSort("endoEffectiveDate")}
            sx={customStyle}
          >
            Effective Date
          </TableSortLabel>
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
