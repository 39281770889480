import { doc, getDoc, runTransaction, updateDoc } from "firebase/firestore";
import { auth, db } from "../../firebase";
import policyStatusChangeNotification from "./notifications/policyStatusChangeNotification";
import updateSystemLog from "./updateSystemLog";

const requestBind = async (
  e,
  policyID,
  agentComments,
  formData,
  userAuth,
  userState,
  insuredInfo
) => {
  e.preventDefault();

  const hasAL = formData.hasALCoverage;
  const hasGL = formData.hasGLCoverage;
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;

  const alPolicyRef = doc(db, "policy-applications", policyID);
  const glPolicyRef = hasGL ? doc(db, "gl-policies", formData.glPolicyID) : null;
  const apdPolicyRef = hasAPD ? doc(db, "apd-policies", formData.apdPolicyID) : null;
  const mtcPolicyRef = hasMTC ? doc(db, "mtc-policies", formData.mtcPolicyID) : null;

  const sourceDocSnapshot = await getDoc(alPolicyRef);
  const docData = sourceDocSnapshot.data();
  const documentType = "Bind Order";
  const status = "Submitted";
  const policyNumber = docData.alPolicyNo || "";
  const agencyID = docData.agencyID;

  const bindOrderData = {
    agentComments,
    requestDate: new Date(),
    bindingUser: auth.currentUser.displayName,
    bindingUserID: auth.currentUser.uid,
  };

  const policyData = {
    status,
    documentType,
    returnedToAgentData: null,
    bindOrderData,
  };

  // Make sure there is a quote selected and applied for all coverages
  const documentIsValid = () => {
    if (!docData.selectedQuote) return false;
    if (docData.selectedQuote === "") return false;
    if (docData.selectedQuote === "#") return false;
    return true;
  };

  const shouldProceed = documentIsValid();

  !shouldProceed &&
    alert("Please select a quote before submitting the bind request.");

  if (shouldProceed) {
    try {
      await runTransaction(db, async (transaction) => {
        hasAL &&
          transaction.update(alPolicyRef, {
            policyData,
          });

        hasGL &&
          transaction.update(glPolicyRef, {
            status,
            documentType,
          });

        hasAPD &&
          transaction.update(apdPolicyRef, {
            status,
            documentType,
          });

        hasMTC &&
          transaction.update(mtcPolicyRef, {
            status,
            documentType,
          });
      });
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  }

  shouldProceed &&
    policyStatusChangeNotification(
      documentType,
      policyNumber,
      status,
      policyID,
      agencyID,
      formData,
      userAuth,
      userState,
      insuredInfo
    );
  shouldProceed &&
    updateSystemLog(
      auth,
      policyID,
      "Bind Requested for " + insuredInfo.company + ".",
      "Bind Requested",
      { agencyID, policyID }
    );
};

export default requestBind;
