import React, { useEffect, useMemo, useState } from "react";
import TruckList from "./TruckList";
import AddTruck from "./AddTruck";
import ImportPowerUnits from "./ImportTrucks";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { isExpired, numberWithCommas } from "../../../utils/helpers";
import useTrucksList from "./useTrucksList";
import FormPopup from "../../generals/Popup/FormPopup";
import { useMediaQuery } from "@mui/material";
import { generateXlsxFile } from "../../../utils/excelCreator";
import { filterRightPoliciesDetails } from "../Drivers/setPolicyInfo";
import { handleDropdownPowerUnit } from "../../assets/lists/DynamicTable/utils";
import ImportAllLists from "../Drivers/ImportAllLists";
import useTranslations from "../../../Context/actions/useTranslations";
import ImportInspas from "../Drivers/ImportInspas";
import {
  Download as DownloadIcon,
  IosShare as IosShareIcon,
} from "@mui/icons-material";
import DropdownPowerUnitMultiUpload from "../../assets/forms/DynamicPropertiesInputs/DropdownPowerUnitMultiUpload";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";
import ApiService from "../../../services/southern-api/apiService";
import getTiv, { calculateTruckACVTotal } from "../utils/getTiv";

export default function PolicyTrucks(props) {
  const {
    policyID,
    formData,
    userInfo,
    truckList,
    isEndoManager,
    getTruckList,
    setFormData,
    rerateRequired,
    setRerateRequired,
    insuredInfo,
    setDidSuccess,
    emailValue,
    driverList,
    trailerList,
  } = props;

  const [display, setDisplay] = useState(true);
  const [showAddTruck, setShowAddTruck] = useState(false);
  const [showTable, setShowTable] = useState(true);
  const [showImport, setShowImport] = useState(false);
  const truckHook = useTrucksList(props);
  const { setDeletionMode, handleSubmit, deletionMode } = truckHook;

  const { t } = useTranslations();

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.units.title") },
  ];

  const count = useMemo(() => {
    const effectiveElementsList = truckList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  }, [truckList]);

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const activeList = truckList?.filter((elem) =>
    Boolean(
      filterRightPoliciesDetails(elem.policiesDetails, policyID)?.status ===
        "Active"
    )
  );

  const handleImportFn = () => {
    setShowImport(true);
    setShowTable(false);
  };

  const [showImportAll, setShowImportAll] = useState(false);
  const [showImportInspas, setShowImportInspas] = useState(false);

  const handleImportAll = () => {
    setShowImportAll(true);
    setShowTable(false);
  };

  const handleImportInspas = () => {
    setShowImportInspas(true);
    setShowTable(false);
  };

  const location = useLocation();
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient =
    location?.pathname?.includes("temporarily-client");

  const apiService = new ApiService();
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: formData,
        module: "power-units",
        emails: [emailValue],
      });
      setDidSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const [openShareModal, setOpenShareModal] = useState(false);

  const handleCount = (list) => {
    const effectiveElementsList = list.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const counter = effectiveElementsList?.length
      ? effectiveElementsList.length
      : 0;
    return counter;
  };

  const titleDocumentType = formData?.documentType
    ? t(`plm.status.${formData?.documentType}`)
    : "";
  const title = formData?.documentType ? t(`plm.status.Power Units`) : "";

  const counts = useMemo(() => {
    const driverCount = handleCount(driverList);
    const powerUnitCount = handleCount(truckList);
    const trailerCount = handleCount(trailerList);
    return {
      driverCount,
      powerUnitCount,
      trailerCount,
    };
  }, [driverList, truckList, trailerList]);

  const [tiv, setTiv] = useState();

  const handleGetTiv = async () => {
    try {
      const results = await calculateTruckACVTotal(policyID);
      setTiv(
        typeof results === "number"
          ? `$${numberWithCommas(results.toFixed(2))}`
          : 0
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetTiv();
  }, [policyID, truckList]);

  return (
    <>
      <ShareModal
        insuredInfo={insuredInfo}
        formData={formData}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={"power-units"}
      />
      {!showAddTruck && !showImport && (
        <>
          {!isEndoManager ? <Breadcrumbs items={breadcrumbs} /> : null}
          <h1
            className={`${
              isEndoManager
                ? "text-2xl mt-2.5 font-bold md:mb-0"
                : "text-3xl mt-2 font-semibold md:mb-0"
            }`}
          >
            {!isEndoManager
              ? `${
                  deletionMode
                    ? t("plm.units.description5")
                    : `${titleDocumentType} ${title}`
                } ${deletionMode ? "" : `(${count})`}`
              : `${
                  deletionMode
                    ? t("plm.units.description5")
                    : t("plm.units.description6")
                }`}
          </h1>
          <p className="my-3">
            {(formData.documentType === "Application" ||
              formData.documentType === "Policy") &&
              !deletionMode && <>{t("plm.units.description1")}</>}
            {deletionMode && <p>{t("plm.units.description3")}</p>}
            {formData.documentType !== "Application" &&
              formData.documentType !== "Policy" && (
                <>{t("plm.units.description4")}</>
              )}
          </p>
        </>
      )}
      {display && (
        <div className={`card-body ${isEndoManager ? "p-0" : ""}`}>
          {!showImport && !showImportAll && !showAddTruck && (
            <>
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") && (
                <>
                  <div className="mt-3 mb-2 flex items-center flex-wrap">
                    {userInfo?.policyPermissions && (
                      <>
                        <BlueButton
                          text={t("plm.units.add-unit")}
                          onClickFunction={(e) => {
                            setShowAddTruck(true);
                            setShowTable(false);
                          }}
                          disabled={deletionMode}
                          hasContentToBottom={true}
                          hasContentToRight={!isMobileScreen}
                          className={isMobileScreen ? "w-[100%]" : ""}
                        />
                        {isTemporalUrl &&
                        !Boolean(deletionMode) &&
                        count !== 0 ? (
                          <RedButton
                            text={t("plm.units.delete")}
                            onClickFunction={(e) => {
                              setDeletionMode(true);
                            }}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                      </>
                    )}
                    {!isTemporalUrl ? (
                      <>
                        {userInfo?.policyPermissions && (
                          <>
                            <DropdownPowerUnitMultiUpload
                              containerStyle={`bg-transparent border-none p-0
                        ${isMobileScreen ? "w-[100%]" : ""}
                        ${isMobileScreen ? "dropdown-multiupload" : ""}
                      `}
                              wrapperStyle={`
                        text-[#8B0000] hover:text-[#8B0000] d-inline
                        ${isMobileScreen ? "w-[100%]" : ""}
                      `}
                              handleDropdown={() =>
                                handleDropdownPowerUnit(
                                  null,
                                  "import",
                                  "power-units",
                                  handleImportFn,
                                  handleImportAll,
                                  handleImportInspas,
                                  t
                                )
                              }
                              formData={formData}
                              disabled={deletionMode}
                              item={null}
                              icon="import"
                              dropdownType="import"
                              list={truckList}
                            />
                            {formData?.documentType === "Application" &&
                            formData.status !== "In-Renewal" &&
                            Boolean(deletionMode) ? (
                              <RedButton
                                text={t("plm.units.delete-massive")}
                                onClickFunction={(e) => {
                                  handleSubmit(e, "multiple", "massive");
                                }}
                                hasContentToBottom={true}
                                hasContentToRight={!isMobileScreen}
                                className={isMobileScreen ? "w-[100%]" : ""}
                              />
                            ) : null}
                            {!Boolean(deletionMode) && count !== 0 ? (
                              <RedButton
                                text={t("plm.units.delete")}
                                onClickFunction={(e) => {
                                  setDeletionMode(true);
                                }}
                                hasContentToBottom={true}
                                hasContentToRight={!isMobileScreen}
                                className={isMobileScreen ? "w-[100%]" : ""}
                              />
                            ) : null}
                          </>
                        )}
                        {activeList?.length > 0 ? (
                          <BlueButton
                            text={t("plm.units.export")}
                            onClickFunction={(e) => {
                              e.preventDefault();
                              generateXlsxFile(
                                activeList,
                                "Active Power Units",
                                "Active Power Units.xlsx"
                              );
                            }}
                            disabled={deletionMode}
                            hasContentToBottom={true}
                            hasContentToRight={!isMobileScreen}
                            className={isMobileScreen ? "w-[100%]" : ""}
                            icon={<DownloadIcon />}
                          />
                        ) : null}
                        {userInfo?.role === "Agent" ||
                        userInfo?.role === "Admin" ||
                        userInfo?.role === "Underwriter" ? (
                          <BlueButton
                            text={t("plm.share-module.share")}
                            hasContentToBottom={true}
                            onClickFunction={() => setOpenShareModal(true)}
                            icon={<IosShareIcon />}
                            className={isMobileScreen ? "w-[100%]" : ""}
                          />
                        ) : null}
                      </>
                    ) : null}
                  </div>

                  {isTemporalUrl && !isTemporalUrlClient ? (
                    <div className="mb-3">
                      {/* TODO: Translate this line */}
                      <p className="mb-2">
                        Once you have made all necessary changes, please click
                        the 'Submit Changes' button below.
                      </p>
                      <BlueButton
                        text="Submit Changes"
                        onClickFunction={handleFinishOperation}
                        hasContentToBottom={true}
                        hasContentToRight={!isMobileScreen}
                        className={isMobileScreen ? "w-[100%]" : ""}
                        icon="no-icon"
                      />
                    </div>
                  ) : null}
                </>
              )}
            </>
          )}
          {showAddTruck && (
            <>
              <AddTruck
                isEndoManager={isEndoManager}
                getEndorsements={props.getEndorsements}
                insuredInfo={props.insuredInfo}
                userInfo={userInfo}
                rerateRequired={rerateRequired}
                setRerateRequired={setRerateRequired}
                getTruckList={getTruckList}
                setTruckList={props.setTruckList}
                truckList={truckList}
                setShowAddTruck={setShowAddTruck}
                userID={props.userID}
                paramRefID={props.paramRefID}
                formData={formData}
                setFormData={setFormData}
                policyID={props.policyID}
                policyNumber={"test"}
                setShowTable={setShowTable}
              />
            </>
          )}
          {showTable && (
            <>
              {formData.documentType === "Policy" && (
                <>
                  {deletionMode
                    ? t("plm.units.description7")
                    : t("plm.units.description2")}
                  <span className="d-block mt-2 mb-3">
                    {deletionMode && formData.documentType === "Policy" && (
                      <>
                        The power unit
                        <b> will not be removed from the policy</b> until the
                        endorsement is approved by an underwriter.
                      </>
                    )}
                  </span>
                </>
              )}

              <div className="flex items-center justify-between w-full">
                {tiv ? `${t("plm.units.total-insured-value")}: ${tiv}` : ""}
              </div>

              <TruckList
                insuredInfo={props.insuredInfo}
                getTruckList={getTruckList}
                policyID={props.policyID}
                setTruckList={props.setTruckList}
                userInfo={userInfo}
                formData={formData}
                truckList={truckList}
                {...truckHook}
              />
              <FormPopup
                isOpen={truckHook.openModal}
                onRequestClose={truckHook.handleCloseModal}
                title={`Select the expiration date of the truck
                ${truckHook.deletionMode ? " and the deletion reason" : ""}`}
                formSettings={truckHook.formSettings}
                loading={truckHook.modalSubmitting}
              />
            </>
          )}
          {showImport && (
            <ImportPowerUnits
              setTruckList={props.setTruckList}
              userInfo={userInfo}
              insuredInfo={props.insuredInfo}
              rerateRequired={rerateRequired}
              setRerateRequired={setRerateRequired}
              getTruckList={getTruckList}
              setShowAddTruck={setShowAddTruck}
              userID={props.userID}
              formData={formData}
              truckList={truckList}
              policyID={props.policyID}
              setShowTable={setShowTable}
              setFormData={props.setFormData}
              setShowImport={setShowImport}
              count={count}
            />
          )}
          {showImportAll && (
            <ImportAllLists
              insuredInfo={props.insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportAll}
              count={
                counts.driverCount + counts.powerUnitCount + counts.trailerCount
              }
            />
          )}
          {showImportInspas && (
            <ImportInspas
              insuredInfo={props.insuredInfo}
              formData={formData}
              userID={props.userID}
              userInfo={userInfo}
              policyID={policyID}
              setShowTable={setShowTable}
              setShowImport={setShowImportInspas}
              count={
                counts.driverCount + counts.powerUnitCount + counts.trailerCount
              }
            />
          )}
        </div>
      )}
    </>
  );
}
