import React from "react";
import UserListCheckbox from "./UserListCheckbox";

export default function UnderwriterPermissions({ selectedUser, userIsAdmin }) {
  return (
    <>
      {/* Bind Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"hasBindAuthority"}
          userData={selectedUser} // Use selectedUser data
          field={"hasBindAuthority"}
          userID={selectedUser.id}
        />
        <label htmlFor="hasBindAuthority" className="text-md font-medium ml-2">
          Bind Authority
        </label>
      </div>

      {/* Letters Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"canSendLetters"}
          userData={selectedUser} // Use selectedUser data
          field={"canSendLetters"}
          userID={selectedUser.id}
        />
        <label htmlFor="canSendLetters" className="text-md font-medium ml-2">
          Send Letters
        </label>
      </div>

      {/* Approve/Decline Endos Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"canApproveEndos"}
          userData={selectedUser} // Use selectedUser data
          field={"canApproveEndos"}
          userID={selectedUser.id}
        />
        <label htmlFor="canApproveEndos" className="text-md font-medium ml-2">
          Approve/Decline Endorsements
        </label>
      </div>
    </>
  );
}
