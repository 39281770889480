import React, { useMemo, useState } from "react";
import {
  Button,
  TextField,
  IconButton,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Popup from "../../generals/Popup";
import MultiUpload from "../../assets/forms/DynamicPropertiesInputs/MultiUpload";

const ReasonsModal = ({
  open,
  onClose,
  onSubmit,
  title,
  text,
  addButtonText,
  formData,
  withFiles = false,
}) => {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");
  const [fileAttachments, setFileAttachments] = useState([]);
  const [reasons, setReasons] = useState([""]);

  const handleAddReason = () => {
    setReasons([...reasons, ""]);
  };

  const handleChangeReason = (index, value) => {
    const updatedReasons = [...reasons];
    updatedReasons[index] = value;
    setReasons(updatedReasons);
  };

  const handleRemoveReason = (index) => {
    const updatedReasons = [...reasons];
    updatedReasons.splice(index, 1);
    setReasons(updatedReasons);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Reasons:", reasons);
    await onSubmit(e, reasons, fileAttachments);
    setReasons([""]);
  };

  const enabled = useMemo(() => {
    if (Boolean(reasons?.length === 0)) return false;
    const reasonsToVerify = reasons.every((reason) => Boolean(reason));
    return reasonsToVerify
  }, [reasons]);

  return (
    <Popup
      isOpen={open}
      onRequestClose={onClose}
      maxWidth={isMobileScreen ? "100%" : "400px"}
      zIndex={1000}
      contentLabel={title}
    >
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            maxHeight: "100vh",
            overflowY: "auto",
            width: "100%",
            borderRadius: isMobileScreen ? 0 : 4,
          }}
        >
          <Typography className="mt-2" variant="h6" gutterBottom>
            {title}
          </Typography>
          <p className="my-3">{text}</p>
          {reasons.map((reason, index) => (
            <Box key={index} display="flex" alignItems="center" mb={2}>
              <TextField
                InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                required
                label={`Reason ${index + 1}`}
                value={reason}
                onChange={(e) => handleChangeReason(index, e.target.value)}
                fullWidth
              />
              <IconButton onClick={() => handleRemoveReason(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          ))}
          <Button
            variant="outlined"
            onClick={handleAddReason}
            sx={{ mb: 2, color: "#072a48", borderColor: "#072a48" }}
          >
            {addButtonText}
          </Button>

          {withFiles ?
            <div className="mb-2">
              <MultiUpload
                label="Attach Files for Agent"
                property="returned-attachments"
                formData={fileAttachments}
                setFormData={setFileAttachments}
                storageFile={`files/policies/${formData?.id}`}
                multiple={true}
                changeNormal={true}
                fileInButton={true}
                width="100%"
                dissapearWhenImgExists={true}
                showInAPopup={true}
              />
            </div> : null
          }

          <ApprovalButtons
            onApprove={null}
            onDecline={onClose}
            titleAccept={"Submit"}
            titleDecline={"Cancel"}
            disabledAccept={!enabled}
            disabledDecline={false}
            handleAsSubmit={true}
          />
        </Box>
      </form>
    </Popup>
  );
};

export default ReasonsModal;
