import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import classNames from "classnames";
import {
  formatDate,
  handleCopyToClipboardPolicy,
  itemBindDate,
  itemCompany,
  itemCoverageLimit,
  itemEffectiveDate,
  itemExpirationDate,
  itemInsuredName,
  itemInvoice,
  itemLicona$,
  itemLiconaPer,
  itemNetTotal,
  itemOtherFees,
  itemPolicyNo,
  itemPremium,
  itemProcessingFee,
  itemProgram,
  itemStampFee,
  itemState,
  itemStatus,
  itemSurplusLinesPlusTaxes,
  itemTotalPremium,
  itemType,
  itemVehicleCount,
  itemZipCode,
  titleItemBindDate,
  titleItemCompany,
  titleItemCoverageLimit,
  titleItemEffectiveDate,
  titleItemExpirationDate,
  titleItemInsuredName,
  titleItemInvoice,
  titleItemLicona$,
  titleItemLiconaPer,
  titleItemNetTotal,
  titleItemOtherFees,
  titleItemPolicyNo,
  titleItemPremium,
  titleItemProcessingFee,
  titleItemProgram,
  titleItemStampFee,
  titleItemState,
  titleItemStatus,
  titleItemSurplusLinesPlusTaxes,
  titleItemTotalPremium,
  titleItemType,
  titleItemVehicleCount,
  titleItemZipCode
} from "./utils";
import useGeneralState from "../../../Context/actions/useGeneralState";

export const PoliciesTable = ({
  currentItems,
  handleSort,
  sortConfig,
  handleMouseEnter,
  handleMouseLeave,
  hoveredRow
}) => {

  const { controlToast } = useGeneralState()

  return (
    <Table>
      <Thead>
        <Tr>
          <Td
            className={classNames(
              "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "powerUnitCountAtBind",
                "bg-blue-500": sortConfig.key === "powerUnitCountAtBind",
              }
            )}
            onClick={() => handleSort("coverageSingleLimit")}
          >
            {titleItemCoverageLimit}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "zip",
              "bg-blue-500": sortConfig.key === "zip",
            })}
            onClick={() => handleSort("zip")}
          >
            {titleItemZipCode}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "coverageSingleLimit",
              "bg-blue-500": sortConfig.key === "coverageSingleLimit",
            })}
            onClick={() => handleSort("powerUnitCountAtBind")}
          >
            {titleItemVehicleCount}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "status",
              "bg-blue-500": sortConfig.key === "status",
            })}
            onClick={() => handleSort("status")}
          >
            {titleItemStatus}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "program",
              "bg-blue-500": sortConfig.key === "program",
            })}
            onClick={() => handleSort("program")}
          >
            {titleItemProgram}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "namedInsured",
              "bg-blue-500": sortConfig.key === "namedInsured",
            })}
            onClick={() => handleSort("namedInsured")}
          >
            {titleItemInsuredName}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "alPolicyNo",
              "bg-blue-500": sortConfig.key === "alPolicyNo",
            })}
            onClick={() => handleSort("alPolicyNo")}
          >
            {titleItemPolicyNo}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "coverageType",
              "bg-blue-500": sortConfig.key === "coverageType",
            })}
            onClick={() => handleSort("coverageType")}
          >
            {titleItemType}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "company",
              "bg-blue-500": sortConfig.key === "company",
            })}
            onClick={() => handleSort("company")}
          >
            {titleItemCompany}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "stateOfEntry",
              "bg-blue-500": sortConfig.key === "stateOfEntry",
            })}
            onClick={() => handleSort("stateOfEntry")}
          >
            {titleItemState}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "effectiveDate",
              "bg-blue-500": sortConfig.key === "effectiveDate",
            })}
            onClick={() => handleSort("effectiveDate")}
          >
            {titleItemEffectiveDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "expirationDate",
              "bg-blue-500": sortConfig.key === "expirationDate",
            })}
            onClick={() => handleSort("expirationDate")}
          >
            {titleItemExpirationDate}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "bindDate",
                "bg-blue-500": sortConfig.key === "bindDate",
              }
            )}
            onClick={() => handleSort("bindDate")}
          >
            {titleItemBindDate}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "GWP",
              "bg-blue-500": sortConfig.key === "GWP",
            })}
            onClick={() => handleSort("GWP")}
          >
            {titleItemPremium}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "processingFeeAmount",
              "bg-blue-500": sortConfig.key === "processingFeeAmount",
            })}
            onClick={() => handleSort("processingFeeAmount")}
          >
            {titleItemProcessingFee}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "otherFees",
              "bg-blue-500": sortConfig.key === "otherFees",
            })}
            onClick={() => handleSort("otherFees")}
          >
            {titleItemOtherFees}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "surplusLinesTaxAmount",
              "bg-blue-500": sortConfig.key === "surplusLinesTaxAmount",
            })}
            onClick={() => handleSort("surplusLinesTaxAmount")}
          >
            {titleItemSurplusLinesPlusTaxes}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "stampTaxAmount",
              "bg-blue-500": sortConfig.key === "stampTaxAmount",
            })}
            onClick={() => handleSort("stampTaxAmount")}
          >
            {titleItemStampFee}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "totalPremium",
              "bg-blue-500": sortConfig.key === "totalPremium",
            })}
            onClick={() => handleSort("totalPremium")}
          >
            {titleItemTotalPremium}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "liconaPer",
              "bg-blue-500": sortConfig.key === "liconaPer",
            })}
            onClick={() => handleSort("liconaPer")}
          >
            {titleItemLiconaPer}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "licona$",
              "bg-blue-500": sortConfig.key === "licona$",
            })}
            onClick={() => handleSort("licona$")}
          >
            {titleItemLicona$}
          </Td>
          <Td
            className={classNames("bg-[#072a48] text-white cursor-pointer", {
              "hover:bg-[#072a48]": sortConfig.key !== "netTotal",
              "bg-blue-500": sortConfig.key === "netTotal",
            })}
            onClick={() => handleSort("netTotal")}
          >
            {titleItemNetTotal}
          </Td>
          <Td
            className={classNames(
              "bg-[#072a48] text-white cursor-pointer rounded-tr-md",
              {
                "hover:bg-[#072a48]": sortConfig.key !== "liconaPer",
                "bg-blue-500": sortConfig.key === "liconaPer",
              }
            )}
            onClick={() => handleSort("invoice")}
          >
            {titleItemInvoice}
          </Td>
        </Tr>
      </Thead>

      <Tbody>
        {currentItems.map((policy, index) => {
          return (
            <Tr
              key={index}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleCopyToClipboardPolicy(policy, controlToast)}
              style={{
                cursor: "pointer",
                backgroundColor: hoveredRow === index ? "#e6f7ff" : "inherit",
              }}
            >
              <Td>{itemCoverageLimit(policy)}</Td>
              <Td>{itemZipCode(policy)}</Td>
              <Td>{itemVehicleCount(policy)}</Td>
              <Td>{itemStatus(policy)}</Td>
              <Td>{itemProgram(policy)}</Td>
              <Td>{itemInsuredName(policy)}</Td>
              <Td>{itemPolicyNo(policy)}</Td>
              <Td>{itemType(policy)}</Td>
              <Td>{itemCompany(policy)}</Td>
              <Td>{itemState(policy)}</Td>
              <Td>{formatDate(itemEffectiveDate(policy))}</Td>
              <Td>{formatDate(itemExpirationDate(policy))}</Td>
              <Td>{formatDate(itemBindDate(policy))}</Td>
              <Td>{itemPremium(policy)}</Td>
              <Td>{itemProcessingFee(policy)}</Td>
              <Td>{itemOtherFees(policy)}</Td>
              <Td>{itemSurplusLinesPlusTaxes(policy)}</Td>
              <Td>{itemStampFee(policy)}</Td>
              <Td>{itemTotalPremium(policy)}</Td>
              <Td>{itemLiconaPer(policy)}</Td>
              <Td>{itemLicona$(policy)}</Td>
              <Td>{itemNetTotal(policy)}</Td>
              <Td>{itemInvoice(policy)}</Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};