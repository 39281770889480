import {
  addDoc,
  collection,
  doc,
  runTransaction,
  setDoc,
} from "firebase/firestore";
import { db } from "../../../../firebase";
import { getAuth } from "firebase/auth";
import { TrendingUpRounded } from "@mui/icons-material";

export function cleanObject(obj, originalObj = null) {
  const cleaned = {};
  for (const [key, value] of Object.entries(obj)) {
    const originalValue = originalObj ? originalObj[key] : undefined;

    if (Array.isArray(value)) {
      const cleanedArray = value
        .map((item, index) => {
          if (typeof item === "object" && item !== null) {
            return cleanObject(
              item,
              originalValue ? originalValue[index] : null
            );
          }
          return item;
        })
        .filter((item) => item !== "" && item !== undefined);

      if (cleanedArray.length > 0 && !arraysAreEqual(value, cleanedArray)) {
        cleaned[key] = cleanedArray;
      }
    } else if (typeof value === "object" && value !== null) {
      const nestedCleaned = cleanObject(value, originalValue);
      if (Object.keys(nestedCleaned).length > 0) {
        cleaned[key] = nestedCleaned;
      }
    } else if (value !== undefined && value !== "") {
      cleaned[key] = value;
    }
  }
  return cleaned;
}

export function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function compareObjects(oldObj, newObj) {
  const changes = [];

  function deepCompare(oldValue, newValue, fieldName) {
    // Si el campo es commodityFields, no cambiar la estructura del array
    if (fieldName === "commodityFields") {
      if (JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
        changes.push({
          field: fieldName,
          oldValue: oldValue,
          newValue: newValue,
        });
      }
      return;
    }

    // Caso de que sean arrays en general
    if (Array.isArray(oldValue) && Array.isArray(newValue)) {
      if (oldValue.length !== newValue.length) {
        changes.push({ field: fieldName, oldValue, newValue });
      } else {
        oldValue.forEach((item, index) => {
          if (typeof item === "object" && typeof newValue[index] === "object") {
            const nestedChanges = compareObjects(item, newValue[index]);
            if (nestedChanges.length > 0) {
              changes.push({
                field: `${fieldName}[${index}]`,
                oldValue: item,
                newValue: newValue[index],
              });
            }
          } else if (item !== newValue[index]) {
            changes.push({
              field: `${fieldName}[${index}]`,
              oldValue: item,
              newValue: newValue[index],
            });
          }
        });
      }
    } else if (
      typeof oldValue === "object" &&
      typeof newValue === "object" &&
      oldValue !== null &&
      newValue !== null
    ) {
      // Si son objetos, comparar campo por campo
      const allKeys = new Set([
        ...Object.keys(oldValue),
        ...Object.keys(newValue),
      ]);
      allKeys.forEach((key) => {
        deepCompare(oldValue[key], newValue[key], `${fieldName}.${key}`);
      });
    } else if (oldValue !== newValue) {
      // Si los valores son diferentes, registrar el cambio
      changes.push({
        field: fieldName,
        oldValue: oldValue !== undefined ? oldValue : null,
        newValue: newValue !== undefined ? newValue : null,
      });
    }
  }

  const allKeys = new Set([...Object.keys(oldObj), ...Object.keys(newObj)]);
  allKeys.forEach((key) => {
    deepCompare(oldObj[key], newObj[key], key);
  });

  return changes;
}

export const compareObjectsCustom = (oldObj, newObj) => {
  let oldData = [];
  let newData = [];

  if (Array.isArray(oldObj) && Array.isArray(newObj)) {
    newObj.forEach((newItem) => {
      const matchingOldItem = oldObj.find(
        (oldItem) =>
          oldItem.commodityDescription === newItem.commodityDescription
      );

      if (!matchingOldItem) {
        oldData.push({
          commodityDescription: "",
          commodityLoad: "0",
          commodityDetails: "",
        });
        newData.push(newItem);
      } else {
        const commodityLoadChanged =
          matchingOldItem.commodityLoad !== newItem.commodityLoad;
        const commodityDetailsChanged =
          matchingOldItem.commodityDetails !== newItem.commodityDetails;

        if (commodityLoadChanged || commodityDetailsChanged) {
          oldData.push({ ...matchingOldItem });
          newData.push({ ...newItem });
        }
      }
    });
    oldObj.forEach((oldItem) => {
      const matchingNewItem = newObj.find(
        (newItem) =>
          newItem.commodityDescription === oldItem.commodityDescription
      );
      if (!matchingNewItem) {
        oldData.push({ ...oldItem });
        newData.push({
          commodityDescription: "",
          commodityLoad: "0",
          commodityDetails: "",
        });
      }
    });
  }

  return { oldData, newData };
};

export function transformLogsToFirestore(changes) {
  const dateOfChange = new Date();
  const auth = getAuth();
  return changes.map((change) => {
    return {
      "change-date": dateOfChange,
      commodity: change.field,
      "old-value": change.oldValue === null ? 0 : change.oldValue,
      "new-value": change.newValue,
      author: auth.currentUser.displayName,
      authorID: auth.currentUser.uid,
    };
  });
}

export const handleEndoCreationForCommodities = async ({
  formData,
  insuredInfo,
  cleanedInsuredInfo,
}) => {
  try {
    const endoRef = collection(
      db,
      "policy-applications",
      formData.id,
      "endorsements"
    );
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const auth = getAuth();
    await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      const newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });

      let payload = {
        data: {
          oldInsuredInfo: {
            commodityFields: insuredInfo.commodityFields || [],
            commodityTotal: insuredInfo.commodityTotal || 0,
            standardCommodityPercentages:
              insuredInfo.standardCommodityPercentages || {},
            standardCommodityValues: insuredInfo.standardCommodityValues || {},
          },
          newInsuredInfo: cleanedInsuredInfo,
        },
        alPremiums: {
          GWP: 0,
          otherFees: 0,
          processingFeeAmount: 0,
          stampTaxAmount: 0,
          surplusLinesTaxAmount: 0,
          totalPremium: 0,
        },
        endorsementNumber: parseInt(newCounter),
        type: "Modify Commodities",
        dateCreated: new Date(),
        issuedDate: new Date(),
        status: "Submitted",
        documentSource: "Endorsement",
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        endoEffectiveDate: formData.effectiveDate,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        policyID: formData.id,
      };
      if (Boolean(formData?.alPolicyNo)) {
        payload["alPolicyNo"] = formData.alPolicyNo;
      }
      if (Boolean(formData?.glPolicyNo)) {
        payload["glPolicyNo"] = formData.glPolicyNo;
      }
      await addDoc(endoRef, payload);
      return payload;
    });
  } catch (error) {
    throw error;
  }
};

export class CommodityLogger {
  constructor() {
    this.originalStandard = {};
    this.originalFields = [];
    this.changes = [];
  }

  captureOriginal(oldStandardCommodityPercentages, oldStandardCommodityFields) {
    this.originalStandard = { ...oldStandardCommodityPercentages };
    this.originalFields = JSON.parse(
      JSON.stringify(oldStandardCommodityFields)
    );
    this.changes = [];
  }

  compareAndLog(newStandardCommodityPercentages, newStandardCommodityFields) {
    this.logStandardChanges(newStandardCommodityPercentages);
    this.logFieldChanges(newStandardCommodityFields);
    return this.changes;
  }

  logStandardChanges(newStandard) {
    const oldStandard = this.originalStandard;
    for (const key in newStandard) {
      if (!(key in oldStandard)) {
        this.changes.push({
          type: "Added",
          key,
          oldValue: 0,
          newValue: newStandard[key],
        });
      } else if (newStandard[key] !== oldStandard[key]) {
        this.changes.push({
          type: "Modified",
          key,
          oldValue: oldStandard[key],
          newValue: newStandard[key],
        });
      }
    }

    for (const key in oldStandard) {
      if (!(key in newStandard)) {
        this.changes.push({
          type: "Removed",
          key,
          oldValue: oldStandard[key],
          newValue: 0,
        });
      }
    }
  }

  logFieldChanges(newFields) {
    const oldFields = this.originalFields;

    const oldMap = Object.fromEntries(
      oldFields.map((item) => [item.commodityDescription, item])
    );
    const newMap = Object.fromEntries(
      newFields.map((item) => [item.commodityDescription, item])
    );

    for (const key in newMap) {
      if (!(key in oldMap)) {
        this.changes.push({
          type: "Added",
          key,
          oldValue: 0,
          newValue: newMap[key].commodityLoad,
        });
      } else {
        const oldItem = oldMap[key];
        const newItem = newMap[key];
        if (newItem.commodityLoad !== oldItem.commodityLoad) {
          this.changes.push({
            type: "Modified",
            key,
            field: "commodityLoad",
            oldValue: oldItem.commodityLoad,
            newValue: newItem.commodityLoad,
          });
        }
      }
    }

    for (const key in oldMap) {
      if (!(key in newMap)) {
        this.changes.push({
          type: "Removed",
          key,
          oldValue: oldMap[key].commodityLoad,
          newValue: 0,
        });
      }
    }
  }
}

export const prepareLogsAndSubmission = ({ insuredInfo, newInsuredInfo }) => {
  const newFieldsInsuredInfo = {
    commodityFields: newInsuredInfo.commodityFields,
    commodityTotal: newInsuredInfo.commodityTotal,
    standardCommodityPercentages: newInsuredInfo.standardCommodityPercentages,
    standardCommodityValues: newInsuredInfo.standardCommodityValues,
  };
  const cleanedInsuredInfo = cleanObject(newFieldsInsuredInfo);

  const logger = new CommodityLogger();
  logger.captureOriginal(
    insuredInfo.standardCommodityPercentages || {},
    insuredInfo.commodityFields || []
  );

  const logValues = logger.compareAndLog(
    newInsuredInfo.standardCommodityPercentages || {},
    newInsuredInfo.commodityFields || []
  );

  return {
    logValues,
    cleanedInsuredInfo,
  };
};

export const commoditiesSubmission = async ({
  insuredInfo,
  newInsuredInfo,
  formData,
  documentType,
}) => {
  try {
    const { logValues, cleanedInsuredInfo } = prepareLogsAndSubmission({
      insuredInfo,
      newInsuredInfo,
    });

    if (documentType === "Application") {
      for (const logValue of logValues) {
        await addDoc(
          collection(
            db,
            "agencies",
            formData.agencyID,
            "insureds",
            formData.insuredID,
            "commodities-logs"
          ),
          {...logValue, dateModified: new Date() },
          { merge: true }
        );
      }

      const insuredRef = doc(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID
      );
      await setDoc(insuredRef, cleanedInsuredInfo, { merge: true });
    } else {
      await handleEndoCreationForCommodities({
        formData,
        insuredInfo,
        cleanedInsuredInfo,
      });
    }
    return {
      cleanedInsuredInfo,
    };
  } catch (error) {
    throw error;
  }
};
