import {
  addDoc,
  collection,
  doc,
  runTransaction,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import updateSystemLog from "../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import ApiService from "../../../services/southern-api/apiService";

const apiService = new ApiService();

export const convertDatesToTimestamps = (obj) => {
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;  
  const iterateAndConvert = (value) => {
    if (typeof value === 'string' && iso8601Regex.test(value)) {
      return Timestamp.fromDate(new Date(value));
    } else if (Array.isArray(value)) {
      return value.map(iterateAndConvert);
    } else if (typeof value === 'object' && value !== null) {
      const result = {};
      for (let key in value) {
        result[key] = iterateAndConvert(value[key]);
      }
      return result;
    }
    return value;
  };

  return iterateAndConvert(obj);
};

export const individualToEndorsement = async (
  policyID,
  endoEffectiveDate,
  type,
  data,
  insuredInfo,
  formData,
  userInfo,
  isEndoManager,
  getEndorsements,
  endorsements,
  blacklistExpirationDate
) => {

  const convertedData =  type?.includes('Driver') ? convertDatesToTimestamps(data) : data;

  try {
    const isTemporalUrl =
      typeof window !== "undefined"
        ? window?.location?.pathname?.includes("temporarily")
        : false;

    if (!isTemporalUrl) {
      const countersDocRef = doc(db, "unique-numbers", "endorsements");
      await runTransaction(db, async (transaction) => {
        const countersDoc = await transaction.get(countersDocRef);
        const currentCounter = countersDoc.data().lastCount;
        const newCounter = currentCounter + 1;
        transaction.update(countersDocRef, {
          lastCount: newCounter,
        });

        const endosRef = collection(
          db,
          "policy-applications",
          policyID,
          "endorsements"
        );

        const status = "Submitted";
        const payload = {
          endorsementNumber: parseInt(newCounter),
          endoEffectiveDate,
          policyID,
          type,
          data: convertedData,
          dateCreated: new Date(),
          status,
          author: auth.currentUser.displayName,
          authorID: auth.currentUser.uid,
          namedInsured: insuredInfo.company,
          policyEffectiveDate: formData.effectiveDate,
          alPolicyNo: formData.alPolicyNo || "",
          agencyID: formData.agencyID,
          insuredID: formData.insuredID,
          documentSource: 'Endorsement'
        };
        if (blacklistExpirationDate) {
          payload["blacklistExpirationDate"] = blacklistExpirationDate;
        }
        const endorsementMatchFound = endorsements?.find(
          (endorsement) => endorsement?.data?.id === data?.id
        );

        let docRef;
        if (
          Boolean(data?.id) &&
          endorsementMatchFound &&
          endorsementMatchFound?.status === status
        ) {
          const policyRefToSet = doc(db, "policy-applications", policyID);
          const endoRefToSet = doc(policyRefToSet, "endorsements", data.id);
          docRef = await setDoc(endoRefToSet, payload, { merge: true });
        } else {
          docRef = await addDoc(endosRef, payload);
        }

        endoStatusChangeNotification(
          type,
          String(newCounter),
          status,
          docRef?.id,
          policyID,
          userInfo.agencyID
        );

        const VIN = Object.keys(data).find((key) => key.includes("VIN")) || "";
        await updateSystemLog(
          auth,
          policyID,
          `${type} Endorsement (` +
            VIN +
            ") Submitted For " +
            insuredInfo.company +
            " by " +
            auth.currentUser.displayName,
          `${type} Endorsement`,
          data
        );
        if (isEndoManager) {
          await getEndorsements();
        }
      });
    } else {
      await apiService.renewalProcessEndorsementOperations({
        policyID,
        endoEffectiveDate,
        type,
        data,
        insuredInfo,
        formData,
        endorsements,
        blacklistExpirationDate
      });
    }
  } catch (error) {
    throw error;
  }
};
