import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import calculatePremiums from "../../../utils/calculatePremiums";
import { auth, db } from "../../../../firebase";
import requestData from "./requestData";
import miniValidation from "../../../utils/premiumCalculation/miniValidation";
import ApiService from "../../../../services/southern-api/apiService";
import findTotalAdjustments from "./findTotalAdjustments";
import calculateAPDMTCPremiums from "../../../utils/calculateAPDMTCPremiums";

const apiService = new ApiService("southern");

const createNewQuote = async (
  e,
  isReprint,
  setLoading,
  adjustmentFormData,
  setAdjustmentFormData,
  truckListData,
  setShowQuoteSuccess,
  formData,
  insuredInfo,
  unused,
  formDataGL,
  setIsNewQuote,
  isNewQuote,
  quoteInfo,
  formDataAPD,
  formDataMTC
) => {
  e && e.preventDefault();

  setLoading(true);
  // return to top of page
  !isReprint && window.scrollTo(0, 0);
  // Initial checks and logging
  try {
    if (!formData || !adjustmentFormData || !truckListData) {
      setLoading(false);
      throw new Error("Missing required form data.");
    }

    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    const policyID = formData.id;
    const totalAdjustment = findTotalAdjustments({ adjustmentFormData });
    const truckList = truckListData[1];
    const hasAL = formData.hasALCoverage;
    const hasGL = formData.hasGLCoverage;
    const hasAPD = formData.hasAPDCoverage;
    const hasMTC = formData.hasMTCCoverage;

const activePowerUnitsWithAPD = truckList.filter((doc) => {
  const policiesDetails = doc.policiesDetails;
  return policiesDetails.some(
    (policy) =>
      policy.id === policyID &&
      policy.status === "Active" &&
      policy.hasAPDCoverage
  );
});

const powerUnitCountWithAPD = activePowerUnitsWithAPD.length;

const activePowerUnitsWithMTC = truckList.filter((doc) => {
  const policiesDetails = doc.policiesDetails;
  return policiesDetails.some(
    (policy) =>
      policy.id === policyID && // Corrected this line
      policy.hasMTCCoverage
  );
});

const powerUnitCountWithMTC = activePowerUnitsWithMTC.length;


    if (
      !miniValidation(
        truckList,
        formData,
        insuredInfo,
        formDataGL,
        formDataAPD,
        formDataMTC
      )
    ) {
      setLoading(false);
      return;
    }

    let driverList = [];
    const driverSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "drivers")
    );
    driverSnapshot.forEach((doc) => {
      driverList.push({ ...doc.data(), id: doc.id });
    });
    const doNotRerate = isReprint ? true : false;
    const selectedQuote = "#";
    const isEndo = false;
    const endoID = null;

    // Calculate and if necessary Apply the AL/GL Premiums
    const autoAndGeneralPremiums = hasAL
      ? await calculatePremiums(
          policyID,
          formData,
          e,
          selectedQuote,
          totalAdjustment,
          doNotRerate,
          isEndo,
          endoID,
          formDataGL
        )
      : null;

    const alPremiums = autoAndGeneralPremiums ? autoAndGeneralPremiums[0] : {};
    const glPremiums = autoAndGeneralPremiums ? autoAndGeneralPremiums[1] : {};

    // Get the quote data if it isn't a new quote.
    let quoteData;
    if (!isNewQuote) {
      const docRef = doc(
        db,
        "policy-applications",
        policyID,
        "quotes",
        formData.selectedQuote
      );
      const quoteDoc = await getDoc(docRef);
      if (quoteDoc.exists()) {
        quoteData = quoteDoc.data();
      }
    }

    // Get the APD/MTC Premiums and if necessary Apply the APD/MTC Premiums
    let apdDetails = hasAPD
      ? isReprint
        ? quoteInfo.apdPremiums
        : !formData.hasAPDCoverage
        ? null
        : isNewQuote
        ? formData?.stagingArea?.apdDetails
        : quoteData?.generatedData?.apdDetails
      : null;

    let mtcDetails = hasMTC
      ? isReprint
        ? quoteInfo.mtcPremiums
        : !formData.hasMTCCoverage
        ? null
        : isNewQuote
        ? formData?.stagingArea?.mtcDetails
        : quoteData?.generatedData?.mtcDetails
      : null;

    if (isNewQuote) {
      const policyRef = doc(db, "policy-applications", policyID);
      await updateDoc(policyRef, {
        stagingArea: {
          ...(formData.stagingArea || {}),
          adjustmentFormData,
        },
        apdDetails,
        mtcDetails,
      });
    }

    const APDMTCPremiums = await calculateAPDMTCPremiums(
      policyID,
      formData,
      apdDetails,
      mtcDetails,
      !isReprint,
      powerUnitCountWithAPD,
      powerUnitCountWithMTC
    );

    apdDetails = APDMTCPremiums[0];
    mtcDetails = APDMTCPremiums[1];

    const dataForRequest = isReprint
      ? null
      : await requestData(
          formData,
          truckList,
          driverList,
          adjustmentFormData,
          insuredInfo,
          alPremiums,
          glPremiums,
          apdDetails,
          mtcDetails,
          isNewQuote
        );

    const powerUnitCountReference = !isReprint ? dataForRequest : quoteInfo;
    const powerUnitCount = powerUnitCountReference.q.powerUnitCount || 0;

    const response = await apiService.printQuote({
      id: policyID,
      userId: auth.currentUser.uid,
      payload: isReprint ? quoteInfo : dataForRequest,
    });

    if (!isReprint) {
      const docRef = await addDoc(
        collection(db, "policy-applications", policyID, "quotes"),
        {
          adjustmentFormData: {
            ...adjustmentFormData,
            totalAdjustment,
          },
          generatedData: {
            ...dataForRequest,
          },
          apdDetails,
          mtcDetails,
        }
      );
      await updateDoc(doc(db, "policy-applications", policyID), {
        selectedQuote: docRef.id,
      });

      const quoteRef = doc(
        db,
        "policy-applications",
        policyID,
        "quotes",
        docRef.id
      );

      await updateDoc(quoteRef, {
        URL: response.url,
      });

      const staging = formData?.stagingArea.adjustmentFormData;
      // Reset the adjustment form data. This can probably be deleted. -Colt 1/28/2025
      // setAdjustmentFormData({
      //   debitOrCredit: staging?.debitOrCredit || "Debit",
      //   description: "",
      //   lossRatioAdjustmentDebitOrCredit:
      //     staging?.lossRatioAdjustmentDebitOrCredit || "Debit",
      //   lossRatioAdjustmentPercentage:
      //     staging?.lossRatioAdjustmentPercentage || 0,
      //   percentage: staging?.percentage || 0,
      //   renewalCredit: staging?.renewalCredit || 0,
      //   safetyAdjustmentDebitOrCredit:
      //     staging?.safetyAdjustmentDebitOrCredit || "Debit",
      //   safetyAdjustmentPercentage: staging?.safetyAdjustmentPercentage || 0,
      // });
    }
    try {
      window.open(response.url, "_blank");
      setShowQuoteSuccess(true);
      setIsNewQuote(false);
      setLoading(false); // Ensure this is called
    } catch (error) {
      console.error("Error after window.open:", error);
      setLoading(false); // Reset loading even on errors
    }
  } catch (error) {
    console.log("Error: " + error.message);
    alert("Error: " + error.message);
    setLoading(false);
  }
};

export default createNewQuote;
