import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";
import { numberWithCommas } from "../../../../utils/helpers";

const PremiumsTable = ({ data }) => {
  const fieldsToDisplay = [
    "GWP",
    "processingFeeAmount",
    "stampTaxAmount",
    "surplusLinesTaxAmount",
    "totalPremium",
  ];
  // If the data is null, return null
  const shouldDisplayTable = fieldsToDisplay.some(
    (field) => data?.[field] !== null
  );
  return (
    <>
    {shouldDisplayTable && (
      <article className="my-4">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ backgroundColor: "#072a48", color: "white" }}
                  colSpan={2}
                >
                  Endorsement Totals
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fieldsToDisplay.map((field) => (
                <TableRow key={field}>
                  <TableCell>{camelCaseToTitleCase(field)}</TableCell>
                  <TableCell align="right">
                    $
                    {data?.[field] !== null
                      ? numberWithCommas(data?.[field]?.toFixed(2))
                      : "N/A"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </article>
      )}
    </>
  );
};

export default PremiumsTable;
