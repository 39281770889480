import React from "react";
import ALPremiums from "./ALPremiums";
import GLPremiums from "./GLPremiums";
import MTCPremiums from "../APDandMTC/MTC/MTCPremiums";
import APDPremiums from "../APDandMTC/APD/APDPremiums";
import { Container, Typography } from "@mui/material";
import BoundPowerUnitsTable from "../QuotesTable/BoundPowerUnitsTable";

export default function PremiumsTable({ formData, policyID, formDataGL, formDataAPD, formDataMTC }) {
  const hasALCoverage = formData.hasALCoverage;
  const hasGLCoverage = formData.hasGLCoverage;
  return (
    <Container>
    {formData.documentType === "Quote Request" && formData.selectedQuote !== '#' && (
      <>
      <hr />
        <Typography variant="h6" className="mb-2 mt-3">
          Proposed Premiums
        </Typography>
        <p className="mb-3">
          Below are the premiums for the policy according to the selected
          quote. To change the premiums, select a different quote.
        </p>
      </>
    )}
      {(formData.documentType === "Policy" ||
        (formData.documentType === "Quote Request" &&
          formData.selectedQuote !== "#")) && (
        <div className="card-body">
          {hasALCoverage && (
            <div className="my-4">
              <ALPremiums policyID={policyID} formData={formData} />
              <BoundPowerUnitsTable data={formData.bindData.boundPowerUnitsData} />
            </div>
          )}
          {hasGLCoverage && (
            <div className="my-4">
              <GLPremiums policyID={policyID} formData={formData} formDataGL={formDataGL} />
            </div>
          )}
          {formData.hasAPDCoverage && (
            <div className="my-4">
              <APDPremiums formData={formData} formDataAPD={formDataAPD} />
            </div>
          )}
          {formData.hasMTCCoverage && (
            <div className="my-4">
              <MTCPremiums formData={formData} formDataMTC={formDataMTC} />
            </div>
          )}
        </div>
      )}
    </Container>
  );
}
