import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@mui/material";
import numberWithCommas from "../../../utils/numberWithCommas";

const MuiTable = ({ data }) => {
   const { radiusCounts, radiusDollarAmounts } = data;
  // Transform the data into a structured format
  const transformedData = Object.keys(radiusCounts).flatMap((classKey) =>
    Object.keys(radiusCounts[classKey]).map((radius) => {
      const count = radiusCounts[classKey][radius];
      const total = radiusDollarAmounts[classKey][radius] || 0;
      const ppu = count > 0 ? (total / count).toFixed(2) : 0;

      return {
        class: classKey.replace("class", ""), // Extract numeric class value
        radius,
        count,
        ppu,
        total,
      };
    })
  ).filter(row => row?.count > 0 || row?.total > 0); // Filter out empty rows

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="bg-[#072a48] text-white rounded-tl-md">Class</TableCell>
            <TableCell className="bg-[#072a48] text-white">Radius</TableCell>
            <TableCell className="bg-[#072a48] text-white">Count</TableCell>
            <TableCell className="bg-[#072a48] text-white">PPU</TableCell>
            <TableCell className="bg-[#072a48] text-white rounded-tr-md">Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transformedData.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row?.class}</TableCell>
              <TableCell>{row?.radius}</TableCell>
              <TableCell>{row?.count}</TableCell>
              <TableCell>{"$" + numberWithCommas(row?.ppu)}</TableCell>
              <TableCell>{"$" + numberWithCommas(row?.total?.toFixed(2))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MuiTable;
