import {
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { db } from "../../../firebase";
import {
  convertISOToFirestoreFormat,
  isExpired,
  numberWithCommas,
} from "../../../utils/helpers";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useEndorsementsState from "../../../Context/actions/useEndorsementsState";
import { individualToEndorsement } from "../Drivers/individualToEndorsement.";
import useTranslations from "../../../Context/actions/useTranslations";
import { clearList } from "../Drivers/deleteMultiple";
import { buildShowingInfo, filterRightPoliciesDetails } from "../Drivers/setPolicyInfo";
import { selectIfDeleteOrExistingSubcollection } from "../../utils/validateIfBlacklisted";
import { handleSubcollectionStatus } from "../../../utils/constants";
import { confirmDeletePromptGlobal } from "../../../layout/ConfirmDeleteProvider";

const useTrailersLists = (props) => {
  const {
    formData,
    trailerList,
    policyID,
    setTrailerList,
    insuredInfo,
    userInfo,
    getEndorsements,
    isEndoManager
  } = props;

  const { t, language } = useTranslations();
  const [tableData, setTableData] = useState([]);
  const [tableDataInDeletionMode, setTableDataInDeletionMode] = useState([]);
  const [tableDataShowingDeletion, setTableDataShowingDeletion] = useState([]);
  const { controlToast } = useGeneralState();
  const { endorsements } = useEndorsementsState();
  const [modalValues, setModalValues] = useState(null);
  const [deletionMode, setDeletionMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalSubmitting, setModalSubmitting] = useState(false);

  const isAtLeastThreeSelectedInDeletedMode = useMemo(() => {
    const selectedData = tableDataInDeletionMode.filter((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    });
    return Boolean(selectedData.length >= 3 && deletionMode);
  }, [tableDataInDeletionMode]);

  const isAtLeastOneSelectedInDeletedMode = useMemo(() => {
    return tableDataInDeletionMode.some((data) => {
      return Boolean(data?.tableInfo?.actions?.actions?.[0]?.checkbox);
    }) && deletionMode;
  }, [tableDataInDeletionMode]);

  const clearTrailerList = async (
    e, values, button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    e.preventDefault();
    const effectiveDate = values["effective-date"];
    const deletionReason = values["deletion-reason"];
    setModalSubmitting(true)
    try {
      const verify = await clearList(
        formData,
        controlToast,
        policyIDToUse,
        trailerList,
        tableDataInDeletionMode,
        insuredInfo,
        userInfo,
        getEndorsements,
        endorsements,
        "Remove Trailers",
        "trailers",
        effectiveDate,
        deletionReason,
        null,
        button,
        documentTypeToUse,
        renewalProcess
      );
      if (!verify && button === 'massive') {
        setModalSubmitting(false)
        return 'verify is null'
      }
      setModalSubmitting(false)
      handleCloseModal()
      setTableDataInDeletionMode([])
      setTableDataShowingDeletion([])
    } catch (error) {
      setModalSubmitting(false)
      throw error;
    }
  };

  const detectingIndividualOrMultiple = async (
    e, data, button,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    try {
      if (modalValues === 'multiple' || data === 'multiple') {
        return await clearTrailerList(
          e, data, button,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      } else {
        return await deleteThisTrailer(
          e, data, null,
          policyIDToUse,
          documentTypeToUse,
          renewalProcess
        );
      }
    } catch (error) {
      throw error;
    }
  };

  const mirrorLogic = async (e, data, button) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" &&
      formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    try {
      if (notARenewalProcess) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          formData.documentType,
          false
        );
      }
      if (isRenewalApplication) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Application",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalPolicyID,
          "Policy",
          true
        );
      }
      if (isRenewalPolicy) {
        await detectingIndividualOrMultiple(
          e, data, button,
          policyID,
          "Policy",
          true
        );
        await detectingIndividualOrMultiple(
          e, data, button,
          formData.renewalApplicationID,
          "Application",
          true
        );
      }
      setDeletionMode(false);
    } catch (error) {
      throw error
    }
  }

  const inputs = useMemo(() => {
    let formInputs = [
      {
        label: "Effective Date of the endorsement",
        type: "date",
        name: "effective-date",
        minDate: new Date(),
        required: true,
      },
    ];
    if (isAtLeastThreeSelectedInDeletedMode) {
      formInputs.push({
        label: "Deletion Reason",
        type: "text",
        name: "deletion-reason",
        required: true,
      });
    }
    return formInputs;
  }, [isAtLeastThreeSelectedInDeletedMode]);

  const formSettings = {
    onSubmit: mirrorLogic,
    inputs,
    buttonLabel: "Create Deletion Endorsement",
  };

  const handleSubmit = async (e, values, button) => {
    try {
      if (e) e.preventDefault();
      if (
        formData.documentType === "Policy" ||
        formData.status === "In-Renewal"
      ) {
        setOpenModal(true);
        setModalValues(values);
      } else {
        setModalValues(values);
        const response = await mirrorLogic(e, values, button);
        if (response === 'verify is null') return
      }
    } catch (error) {
      throw error
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalValues(null);
  };

  const deleteThisTrailer = async (
    e, trailer, _,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    e.preventDefault();
    setModalSubmitting(true);
    try {
      let verify
      if (documentTypeToUse === "Application" && !renewalProcess) {
        verify = await confirmDeletePromptGlobal(
          "You are about to delete a Trailer! If you are sure, confirm the action"
        );
      }
      if (
        verify === "DELETE" ||
        renewalProcess ||
        documentTypeToUse === "Policy"
      ) {

        const payload = {
          ...modalValues,
          ...trailer,
        };
        delete payload["iconLeft"];
        delete payload["iconRight"];
        delete payload["tableInfo"];
        delete payload["dataIndex"];
        let effectiveDate
        if (trailer?.["effective-date"]) {
          effectiveDate = convertISOToFirestoreFormat(
            trailer["effective-date"]
          );
          delete payload["effective-date"];
        }
  
        const { agencyID, insuredID } = formData
        const payloadResult = await selectIfDeleteOrExistingSubcollection(
          payload,
          agencyID,
          insuredID,
          'power-units',
          policyIDToUse,
          renewalProcess,
          documentTypeToUse,
          formData
        )

        if (documentTypeToUse === "Policy" || renewalProcess) {
          if (trailer?.["effective-date"]) {
            await individualToEndorsement(
              policyIDToUse,
              formData.effectiveDate,
              "Remove Trailer",
              payloadResult,
              insuredInfo,
              formData,
              userInfo,
              isEndoManager,
              getEndorsements,
              endorsements
            );
          }
          controlToast(
            true,
            "The endorsement was succesfully created",
            "success"
          );
        }
        setModalSubmitting(false);
        setOpenModal(false);
        setModalValues(null);
      }
    } catch (error) {
      setModalSubmitting(false);
      controlToast(true, "There was an error in the service", "error");
      throw error;
    }
  };

  useEffect(() => {
    if (!formData.agencyID) return;
    const policyRef = doc(db, "policy-applications", policyID)
    const q = query(
      collection(
        db,
        "agencies",
        formData.agencyID,
        "insureds",
        formData.insuredID,
        "trailers"
      )
    );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      const filteredValues = buildShowingInfo(values, policyRef.id)
      setTrailerList(filteredValues);
    });
    return () => {
      unsubscribe();
    };
  }, [policyID, formData.agencyID]);

  const initialColumns = [
    {
      name: "trailerVIN",
      label: "VIN",
    },
    {
      name: "status",
      label: "Status",
    },
    {
      name: "trailerYear",
      label: t("plm.trailers.list.year"),
    },
    {
      name: "trailerMake",
      label: t("plm.trailers.list.make"),
    },
    {
      name: "trailerACV",
      label: t("plm.trailers.list.acv"),
    },
  ]

  const [columns, setColumns] = useState(initialColumns)
  const [columnsSet, setColumnsSet] = useState(false);

  useEffect(() => {
    if (trailerList.length > 0) {
      let newColumns = initialColumns;
      if (deletionMode) {
        newColumns.push({
          name: "actions",
          label: t("plm.trailers.list.actions"),
        })
      }
      setColumns(newColumns);
      setColumnsSet(true);
    }
  }, [trailerList, formData, deletionMode]);

  const formingTableData = (deletionFlag, tableBehavior) => {
    const effectiveElementsList = trailerList.filter((item) => {
      return !isExpired(item?.expirationDate);
    });
    const tableData = effectiveElementsList?.map((data, dataIndex) => {
      const {
        trailerVIN,
        trailerYear,
        trailerMake,
        trailerACV,
        policiesDetails
      } = data || {};

      const iconLeft = [];
      iconLeft.push({
        name: "copyToClipboard",
        toField: "trailerVIN",
        className: "text-sky-900 hover:text-[#072a48] mr-2",
        onClick: (_, item) => {
          navigator.clipboard.writeText(item.tableInfo?.trailerVIN.text)
          controlToast(
            true,
            "The VIN was copied to clipboard",
            "success"
          );
        }
      });
      const iconRight = [];
      // if (
      //   formData.documentType === "Application" ||
      //   formData.documentType === "Policy"
      // ) {
      //   iconRight.push({
      //     icon: "delete",
      //     action: true,
      //     toField: "action",
      //   });
      // }
      const tableObject = {
        ...data,
        tableInfo: {
          trailerVIN: {
            text: trailerVIN,
            isButton: true,
            className: 'break-all'
          },
          status: {
            text: handleSubcollectionStatus(
              filterRightPoliciesDetails(policiesDetails, policyID)?.status,
              language
            )
          },
          trailerYear: {
            text: trailerYear,
          },
          trailerMake: {
            text: trailerMake,
            className: 'break-all'
          },
          trailerACV: {
            text: `$${
              numberWithCommas(parseFloat(trailerACV)?.toFixed(2))
                ? numberWithCommas(parseFloat(trailerACV)?.toFixed(2))
                : "0.00"
            }`
          }
        },
        dataIndex,
        iconLeft,
        iconRight,
      }
      if (
        deletionMode
      ) {
        tableObject["tableInfo"]["actions"] = {
          actions: tableBehavior
              ? ["deletion-behavior"]
              : deletionFlag
              ? [
                  {
                    checkbox: false,
                  },
                ]
              : [],
        }
      }
      return tableObject
    });
    return tableData;
  };

  useEffect(() => {
    if (typeof trailerList.length !== "undefined") {
      if (deletionMode) {
        const tableDataDeletionMode = formingTableData(true);
        const pureActiveTableDataForDeletionMode = tableDataDeletionMode.filter(item => {
          let isActive = true
          const policyDetail = item?.policiesDetails?.find(detail => {
            return detail?.id === policyID
          })
          if (policyDetail && policyDetail?.status !== "Active") {
            isActive = false
          }
          return isActive
        })
        setTableDataInDeletionMode(pureActiveTableDataForDeletionMode);
      }
      const tableBehavior = deletionMode ? "deletion-behavior" : "";
      const tableData = formingTableData(false, tableBehavior);
      setTableData(tableData);
    }
  }, [trailerList, deletionMode, columnsSet]);

  const filterKeys = ["trailerVIN", "trailerYear", "trailerMake", "trailerACV", "status"];

  return {
    openModal,
    handleCloseModal,
    formSettings,
    modalSubmitting,
    columns,
    tableDataInDeletionMode,
    setTableDataInDeletionMode,
    handleSubmit,
    showItem: () => {},
    filterKeys,
    tableData,
    deletionMode,
    setDeletionMode,
    isAtLeastOneSelectedInDeletedMode,
    tableDataShowingDeletion,
    setTableDataShowingDeletion,
    formData,
    setDeletionMode
  };
};

export default useTrailersLists;
