import React, { useEffect, useState } from "react";
import {
  TextField,
  Box,
  IconButton,
  Table,
  TableContainer,
  TablePagination,
  MenuItem,
  Select,
  Typography,
  CircularProgress,
  InputLabel,
  FormControl,
  Button,
  TableCell,
  Paper,
  TableRow,
  TableHead,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CustomTableHead from "./CustomTableHead";
import CustomTableBody from "./CustomTableBody";
import handleSearch from "./utils/handleSearch";
import LoadingScreen from "../../../assets/LoadingScreen";
import handleSearchInsured from "./utils/handleSearchInsured";
import handleSearchPolicy from "./utils/handleSearchPolicy";
import axios from "axios";
import updatePolicyField from "../../../utils/updatePolicyField";
import EndoDocumentViewer from "./EndoDocumentViewer";

const DocumentViewer = ({ documentBinary, open, onClose }) => {
  if (!documentBinary) return null;

  // Check if documentBinary is a Base64 string and convert it to Uint8Array if needed
  let byteArray;
  if (typeof documentBinary === "string") {
    const binaryString = atob(documentBinary);
    byteArray = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      byteArray[i] = binaryString.charCodeAt(i);
    }
  } else {
    byteArray = new Uint8Array(documentBinary);
  }

  // Create Blob URL for the PDF
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const pdfUrl = URL.createObjectURL(blob);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Document Viewer</DialogTitle>
      <DialogContent>
        <iframe
          src={pdfUrl}
          width="100%"
          height="600px"
          style={{ border: "none" }}
        />
      </DialogContent>
    </Dialog>
  );
};

function APDEndoSearch({
  userInfo,
  onlyForOneInsured,
  insuredID,
  onlyForOnePolicy,
  policyID,
  formData,
  apdEndorsements,
  setAPDEndorsements,
  loading,
  setLoading
}) {

  const [selectedDocument, setSelectedDocument] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleViewDocument = (documentBinary) => {
    setSelectedDocument(documentBinary);
    setModalOpen(true);
  };

  return (
    <Box sx={{ p: 2 }}>
      {!loading && (
        <>
          <div>
            <TableContainer component={Paper} style={{ marginTop: 20 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Endorsement ID</TableCell>
                    <TableCell>Policy Number</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {apdEndorsements.length > 0 ? (
                    apdEndorsements.map((endorsement, index) => (
                      <TableRow key={index}>
                        <TableCell>{endorsement.documentId}</TableCell>
                        <TableCell>{endorsement.policyNumber}</TableCell>
                        <TableCell>{endorsement.createdDate}</TableCell>
                        <TableCell>{endorsement.documentType}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                              handleViewDocument(endorsement.documentBinary)
                            }
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={5} align="center">
                        No endorsements found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

      {/* Document Viewer Modal */}
      <DocumentViewer
        documentBinary={selectedDocument}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </Box>
  );
}

export default APDEndoSearch;
