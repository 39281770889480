import React, { useState } from "react";
import DriverPanel from "../../PolicyLifecycleManager/Drivers";
import PowerUnitPanel from "../../PolicyLifecycleManager/Units";
import TrailerPanel from "../../PolicyLifecycleManager/Trailers";
import { useNavigate } from "react-router-dom";
import { UNDERWRITING_ENDORSEMENT_ROUTE } from "../../../utils/routes";
import WaiverOfSubrogation from "../WaiverOfSubrogation";
import { Box, Paper, Tab, Tabs, useMediaQuery } from "@mui/material";
import GoBack from "../../generals/GoBack";
import EndoManagerTable from "./EndoManagerTable";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import Breadcrumbs from "../../assets/Breadcrumbs";
import CancelPolicyPanel from "../../CancelPolicyPanel";
import AddInsured from "../AddInsured";
import DriverExclusionsPanel from "../Drivers/DriverExclusionsPanel";
import useTranslations from "../../../Context/actions/useTranslations";
import EndoSearch from "../../underwriter/endorsements/EndoSearch";
import MTCEndoSearch from "../../underwriter/endorsements/EndoSearch/MTCEndoSearch";
import APDEndoSearch from "../../underwriter/endorsements/EndoSearch/APDEndoSearch";
import MTC from "../MTC";
import LoadingScreen from "../../assets/LoadingScreen";

export default function EndoManager({
  userInfo,
  setDisplayEndorsementManager,
  endorsements,
  tabProps,
  getEndorsements,
  policyID,
  skeletonEndoTableLoader,
  currentEndoFilters,
  setCurrentEndoFilters,
  insuredInfo,
  agencyInfo,
  formData,
}) {
  const navigate = useNavigate();
  const { t } = useTranslations();

  const newEndorsementsOptions = [
    {
      value: "endorsement-manager",
      label: t("endorsements.plm.endo-manager"),
    },
    {
      value: "waiver-of-subrogation",
      label: t("endorsements.plm.waiver-subrogation"),
    },
    {
      value: "endorse-drivers",
      label: t("endorsements.plm.endorse-drivers"),
    },
    {
      value: "endorse-power-units",
      label: t("endorsements.plm.endorse-power-units"),
    },
    {
      value: "endorse-trailers",
      label: t("endorsements.plm.endorse-trailers"),
    },
    {
      value: "cancel-policy",
      label: t("endorsements.plm.cancel-policy"),
    },
    // {
    //   value: "additional-insured",
    //   label: t("endorsements.plm.additional-insured"),
    // },
    {
      value: "endorse-driver-exclusion",
      label: t("endorsements.plm.endorse-drivers-exclusion"),
    },
    // {
    //   value: "add-coverage",
    //   label: t('endorsements.plm.add-coverage'),
    // },
  ];

  const [endorsementSelectedOption, setEndorsementSelectedOption] =
    useState("");

  const handleEndorsementsOption = (e) => {
    const optionSelected = newEndorsementsOptions.find(
      (option) => option.value === e.target.value
    );
    setEndorsementSelectedOption(optionSelected);
  };

  const goToEndorsement = (endorsementId) => {
    navigate(`${UNDERWRITING_ENDORSEMENT_ROUTE}/${endorsementId}/${policyID}`);
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbs = [
    { label: t("endorsements.policy") },
    { label: t("endorsements.endorsements") },
  ];
  const breadcrumbsSelected = [
    { label: t("endorsements.policy") },
    { label: t("endorsements.endorsements") },
    { label: endorsementSelectedOption?.label },
  ];

  const isAdminOrUnderwriter =
    userInfo?.role === "Admin" || userInfo?.role === "Underwriter";
  const isPolicy = formData?.documentType === "Policy";
  const canBeEndorsed =
    userInfo?.policyPermissions &&
    isAdminOrUnderwriter &&
    isPolicy &&
    (formData.status === "In-Force" ||
      formData.status === "In-Cancellation" ||
      formData.status === "In-Renewal");

  const [selectedTab, setSelectedTab] = useState("AL");

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const [mtcEndorsements, setMtcEndorsements] = useState([]);
  const [apdEndorsements, setApdEndorsements] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingAPD, setLoadingAPD] = useState(false);
  const [loadingMTC, setLoadingMTC] = useState(false);

  return (
    <>
      {loading ? (
        <LoadingScreen text="Loading Endorsements..." />
      ) : (
        <div
          className={`card ${
            !isMobileScreen ? "ml-3 mr-3 mb-3 mt-2" : "mt-3"
          } w-full`}
        >
          <div className="card-body">
            <GoBack handleFn={() => setDisplayEndorsementManager(false)} />
            <div className="mt-3">
              <Breadcrumbs
                items={
                  endorsementSelectedOption === ""
                    ? breadcrumbs
                    : breadcrumbsSelected
                }
              />
            </div>
            <span className="text-3xl font-semibold mb-4 md:mb-0">
              {endorsementSelectedOption === "" ||
              endorsementSelectedOption.value === "endorsement-manager"
                ? t("endorsements.title")
                : ""}
            </span>
            <div className="my-2">
              {endorsementSelectedOption === "" ||
              endorsementSelectedOption.value === "endorsement-manager" ? (
                <>
                  <p>{t("endorsements.plm.text-1")}</p>
                  {canBeEndorsed && (
                    <p className="mt-2 mb-4">{t("endorsements.plm.text-2")}</p>
                  )}
                </>
              ) : (
                <>
                  <p>{endorsementSelectedOption.descriptionLine1}</p>
                  <p className="mt-2 mb-4">
                    {endorsementSelectedOption.descriptionLine2}
                  </p>
                </>
              )}
            </div>
            {canBeEndorsed && (
              <div className="max-w-[750px]">
                <FormControl fullWidth>
                  <InputLabel id="select-label">
                    {t("endorsements.plm.make-selection")}
                  </InputLabel>
                  <Select
                    labelId="select-label"
                    value={endorsementSelectedOption?.value || ""}
                    className="my-2"
                    onChange={handleEndorsementsOption}
                  >
                    {newEndorsementsOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            )}
            {endorsementSelectedOption?.value === "waiver-of-subrogation" ? (
              <WaiverOfSubrogation
                endorsements={endorsements}
                getEndorsements={getEndorsements}
                {...tabProps.waiverOfSubrogationPanel}
              />
            ) : endorsementSelectedOption?.value ===
              "endorse-driver-exclusion" ? (
              <DriverExclusionsPanel
                isEndoManager
                showDriverExclusions
                getEndorsements={getEndorsements}
                {...tabProps.driverPanel}
              />
            ) : endorsementSelectedOption?.value === "endorse-drivers" ? (
              <DriverPanel
                isEndoManager
                getEndorsements={getEndorsements}
                {...tabProps.driverPanel}
              />
            ) : endorsementSelectedOption?.value === "endorse-power-units" ? (
              <PowerUnitPanel
                isEndoManager
                getEndorsements={getEndorsements}
                {...tabProps.powerUnitPanel}
              />
            ) : endorsementSelectedOption?.value === "endorse-trailers" ? (
              <TrailerPanel
                isEndoManager
                getEndorsements={getEndorsements}
                {...tabProps.trailerPanel}
              />
            ) : endorsementSelectedOption?.value === "cancel-policy" ? (
              <CancelPolicyPanel
                {...tabProps.cancelPolicyPanel}
                goBack={(e) =>
                  setEndorsementSelectedOption({
                    value: "endorsement-manager",
                    label: "Endorsement Manager",
                  })
                }
              />
            ) : endorsementSelectedOption?.value === "additional-insured" ? (
              <AddInsured
                {...tabProps.additionalInsuredsPanel}
                goBack={(e) =>
                  setEndorsementSelectedOption({
                    value: "endorsement-manager",
                    label: "Endorsement Manager",
                  })
                }
              />
            ) : (
              <Box sx={{ width: "100%", mt: 2 }}>
                <Paper elevation={3} sx={{ p: 2, borderRadius: 2 }}>
                  <Tabs
                    value={selectedTab}
                    onChange={handleChange}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    sx={{ mb: 2 }}
                  >
                    {formData.hasALCoverage && (
                      <Tab label="Auto Liability (AL)" value="AL" />
                    )}
                    {formData.hasMTCCoverage && (
                      <Tab label="Motor Truck Cargo (MTC)" value="MTC" />
                    )}
                    {formData.hasAPDCoverage && (
                      <Tab label="Auto Physical Damage (APD)" value="APD" />
                    )}
                  </Tabs>

                  <Box sx={{ mt: 2 }}>
                    {selectedTab === "AL" && (
                      <EndoSearch
                        loading={loading}
                        setLoading={setLoading}
                        userInfo={userInfo}
                        onlyForOnePolicy={true}
                        insuredInfo={insuredInfo}
                        policyID={policyID}
                        formData={formData}
                        mtcEndorsements={mtcEndorsements}
                        setMtcEndorsements={setMtcEndorsements}
                        apdEndorsements={apdEndorsements}
                        setApdEndorsements={setApdEndorsements}
                        loadingAPD={loadingAPD}
                        setLoadingAPD={setLoadingAPD}
                        loadingMTC={loadingMTC}
                        setLoadingMTC={setLoadingMTC}
                      />
                    )}
                    {selectedTab === "MTC" && (
                      <MTCEndoSearch
                        loading={loading}
                        setLoading={setLoading}
                        userInfo={userInfo}
                        onlyForOnePolicy={true}
                        policyID={policyID}
                        formData={formData}
                        mtcEndorsements={mtcEndorsements}
                        loadingMTC={loadingMTC}
                        setLoadingMTC={setLoadingMTC}
                      />
                    )}
                    {selectedTab === "APD" && (
                      <APDEndoSearch
                        loading={loading}
                        setLoading={setLoading}
                        userInfo={userInfo}
                        onlyForOnePolicy={true}
                        policyID={policyID}
                        formData={formData}
                        apdEndorsements={apdEndorsements}
                        loadingAPD={loadingAPD}
                        setLoadingAPD={setLoadingAPD}
                      />
                    )}
                  </Box>
                </Paper>
              </Box>
              // <EndoManagerTable
              //   endorsements={endorsements}
              //   goToEndorsement={goToEndorsement}
              //   skeletonEndoTableLoader={skeletonEndoTableLoader}
              //   currentEndoFilters={currentEndoFilters}
              //   setCurrentEndoFilters={setCurrentEndoFilters}
              //   insuredInfo={insuredInfo}
              //   agencyInfo={agencyInfo}
              // />
            )}
          </div>
        </div>
      )}
    </>
  );
}
