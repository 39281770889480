export const managePoliciesOptions = [
  {
    label: "In-Force",
    field: "inForce",
    isTrueByDefault: true,
    lang: {
      en: "In-Force",
      es: "En Vigor",
    },
  },
  {
    label: "Bound",
    field: "bound",
    isTrueByDefault: true,
    lang: {
      en: "Bound",
      es: "Atado",
    },
  },
  {
    label: "In-Renewal",
    field: "inRenewal",
    isTrueByDefault: true,
    lang: {
      en: "In-Renewal",
      es: "En Renovación",
    },
  },
  {
    label: "In-Cancellation",
    field: "inCancellation",
    isTrueByDefault: true,
    lang: {
      en: "In-Cancellation",
      es: "En Cancelación",
    },
  },
  {
    label: "Non-Renewal",
    field: "nonRenewal",
    isTrueByDefault: false,
    lang: {
      en: "Non-Renewal",
      es: "No Renovación",
    },
  },
  {
    label: "Expired",
    field: "expired",
    isTrueByDefault: false,
    lang: {
      en: "Expired",
      es: "Expirado",
    },
  },
  {
    label: "Cancelled",
    field: "cancelled",
    isTrueByDefault: false,
    lang: {
      en: "Cancelled",
      es: "Cancelado",
    },
  },
  // {
  //   label: "Has AL Coverage",
  //   field: "hasAL",
  //   isTrueByDefault: false,
  //   lang: {
  //     en: "Has AL Coverage",
  //     es: "Tiene Cobertura AL",
  //   }
  // },
  // {
  //   label: "Has GL Coverage",
  //   field: "hasGL",
  //   isTrueByDefault: false,
  //   lang: {
  //     en: "Has GL Coverage",
  //     es: "Tiene Cobertura GL",
  //   }
  // }
];

export const manageApplicationOptions = [
  {
    label: "Unsubmitted",
    field: "unsubmitted",
    isTrueByDefault: true,
    lang: {
      en: "Unsubmitted",
      es: "No Enviado",
    },
  },
  {
    label: "Submitted",
    field: "submitted",
    isTrueByDefault: true,
    lang: {
      en: "Submitted",
      es: "Enviado",
    },
  },
  {
    label: "Returned",
    field: "returned",
    isTrueByDefault: true,
    lang: {
      en: "Returned",
      es: "Devuelto",
    },
  },
  // {
  //   label: "Fulfilled",
  //   field: "fulfilled",
  //   isTrueByDefault: true,
  //   lang: {
  //     en: "Fulfilled",
  //     es: "Cumplido",
  //   },
  // },
  {
    label: "In-Renewal",
    field: "inRenewal",
    isTrueByDefault: true,
    lang: {
      en: "In-Renewal",
      es: "En Renovación",
    },
  },
  {
    label: "Declined",
    field: "declined",
    isTrueByDefault: false,
    lang: {
      en: "Declined",
      es: "Rechazado",
    },
  },
  {
    label: "Archived",
    field: "archived",
    isTrueByDefault: false,
    lang: {
      en: "Archived",
      es: "Archivado",
    },
  },
];

export const manageEndorsementOptions = [
  {
    label: "Submitted",
    field: "submitted",
    isTrueByDefault: true,
    lang: {
      en: "Submitted",
      es: "Enviado",
    },
  },
  {
    label: "Declined",
    field: "declined",
    isTrueByDefault: false,
    lang: {
      en: "Declined",
      es: "Rechazado",
    },
  },
  {
    label: "Approved",
    field: "approved",
    isTrueByDefault: false,
    lang: {
      en: "Approved",
      es: "Aprobado",
    },
  },
  {
    label: "Exception Request",
    field: "requestException",
    isTrueByDefault: false,
    documentTypeField: true,
    lang: {
      en: "Exception Request",
      es: "Solicitud de Excepción",
    },
  },
];

export const manageDriverOptions = [
  {
    label: "Accepted",
    field: "goodToGo",
    isTrueByDefault: false,
    lang: {
      en: "Accepted",
      es: "Aceptado",
    },
  },
  {
    label: "Not Accepted Yet",
    field: "notGoodToGo",
    isTrueByDefault: false,
    lang: {
      en: "Not Accepted Yet",
      es: "Aún no Aceptado",
    },
  },
];

export const activeFilters = (options, currentFilters) => {
  return options
    .filter((option) => currentFilters[option.field])
    .map((option) => {
      return option.label;
    });
};

export const handleFilterStatus = (
  status = "In-Force",
  options,
  language = "es"
) => {
  const foundOption = options.find((option) => {
    return option.label === status;
  });
  return foundOption?.lang[language] || status;
};
