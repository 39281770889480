import {
  addDoc,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  runTransaction,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { auth, db } from "../../../firebase";
import updateSystemLog from "../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import { setPolicyInfoForEndorsement } from "./setPolicyInfo";
import { convertISOToFirestoreFormat, generateSearchWords } from "../../../utils/helpers";
import ApiService from "../../../services/southern-api/apiService";
import { formatDOB } from "../../../utils/driversPayload";

const apiService = new ApiService("southern");

export const buildDocumentPath = (type, id, formData) => {
  let initialPath
  if (type.includes("Driver")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/drivers/${id}`;
  } else if (type.includes("Power Unit") || type.includes("Radius")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/power-units/${id}`;
  } else if (type.includes("Trailer")) {
    initialPath = `agencies/${formData.agencyID}/insureds/${formData.insuredID}/trailers/${id}`;
  }
  if (!initialPath) return null;
  return doc(db, initialPath)
};

export const getDocumentPaths = (dataArray, type, formData) => {
  return dataArray
    .map(data => {
      const id = data?.id;
      return buildDocumentPath(type, id, formData);
    })
    .filter(path => path !== null);
};

export const multipleToEndorsements = async (
  policyID,
  endoEffectiveDate,
  type,
  dataArray,
  insuredInfo,
  formData,
  userInfo,
  getEndorsements,
  endorsements,
  deletionReason,
  proofOfPowerDeletion,
  blacklistExpirationDate,
  emailToProvideInExclusion,
  necessaryValuesInsideEndorsement
) => {

  const isItAnEndorsementModification = Boolean(necessaryValuesInsideEndorsement)

  try {
    const countersDocRef = doc(db, "unique-numbers", "endorsements");
    const transactionResult = await runTransaction(db, async (transaction) => {
      const countersDoc = await transaction.get(countersDocRef);
      const currentCounter = countersDoc.data().lastCount;
      let newCounter = currentCounter + 1;
      transaction.update(countersDocRef, {
        lastCount: newCounter,
      });

      const endosRef = collection(
        db,
        "policy-applications",
        policyID,
        "endorsements"
      );
      
      const docPaths = getDocumentPaths(dataArray, type, formData);
      const effectiveDate = convertISOToFirestoreFormat(endoEffectiveDate)

      const dateCreated = Timestamp.fromDate(new Date())

      const status = "Submitted";
      const payload = {
        endorsementNumber: parseInt(newCounter),
        endoEffectiveDate: effectiveDate,
        policyID,
        type,
        dateCreated,
        status,
        author: auth.currentUser.displayName,
        authorID: auth.currentUser.uid,
        namedInsured: insuredInfo.company,
        policyEffectiveDate: formData.effectiveDate,
        alPolicyNo: formData.alPolicyNo,
        agencyID: formData.agencyID,
        insuredID: formData.insuredID,
        documentSource: 'Endorsement',
      };
      if (deletionReason) {
        payload['deletionReason'] = deletionReason
      }
      if (proofOfPowerDeletion) {
        payload['proofOfPowerDeletion'] = proofOfPowerDeletion
      }
      if (blacklistExpirationDate) {
        payload['blacklistExpirationDate'] = blacklistExpirationDate
      }

      // Flow driver exclusion, saving email to send the signature of the insured there
      // and a underwriterEmail to send the notification to the underwriter
      if (emailToProvideInExclusion) {
        payload['email'] = emailToProvideInExclusion
        payload['underwriterEmail'] = auth.currentUser.email
      }
      const endorsementMatchFound = endorsements?.some(endorsement => {
        return Boolean(endorsement?.type === type)
      });
      let docRef = null;
      if (
        endorsementMatchFound &&
        endorsementMatchFound?.status === status 
      ) {
        return `Approve or decline your old endorsement "${type}"`
      } else {
        const data = setPolicyInfoForEndorsement(payload, policyID)
        data['data'] = docPaths
        const thereIsValidSearchValue = dataArray.some(data => {
          const thereIsAValue = Boolean(
            data?.licenseNumber ||
            data?.driverFirst ||
            data?.driverLast ||
            data?.truckVIN ||
            data?.trailerVIN
          )
          return thereIsAValue
        })
        data['searchData'] = []
        if (dataArray?.length > 0  && thereIsValidSearchValue) {
          dataArray.forEach(item => {
            if (item?.driverFirst || item?.driverLast) {
              const searchName = String(`${item?.driverFirst ? item?.driverFirst : ""} ${item?.driverLast ? item?.driverLast : ""}`).trim()
              const words = generateSearchWords(searchName)
              const parsedFullName = [...words, searchName]
              data['searchData'].push(...parsedFullName)
            }
            if (item?.licenseNumber) {
              data['searchData'].push(String(item?.licenseNumber))
            }
            if (item?.truckVIN) {
              data['searchData'].push(String(item?.truckVIN))
            }
            if (item?.trailerVIN) {
              data['searchData'].push(String(item?.trailerVIN))
            }
          })
        }

        // MODIFY THE ENDORSEMENT BUT ALSO MODIFY A DRIVER/POWER UNIT/TRAILER ARRAY AND SEARCH DATA
        if (Boolean(necessaryValuesInsideEndorsement) && necessaryValuesInsideEndorsement?.editUI) {
          const updatedSearchData = [];
          const newCompleteList = necessaryValuesInsideEndorsement.completeList.map(item => {
            if (dataArray?.[0]?.id === item?.id) {
              return dataArray[0]
            }
            return item
          })
          newCompleteList.forEach(item => { 
            if (item?.driverFirst || item?.driverLast) {
              const searchName = `${item?.driverFirst || ""} ${item?.driverLast || ""}`.trim();
              const words = generateSearchWords(searchName);
              updatedSearchData.push(...words, searchName);
            }
            if (item?.licenseNumber) updatedSearchData.push(String(item?.licenseNumber));
            if (item?.truckVIN) updatedSearchData.push(String(item?.truckVIN));
            if (item?.trailerVIN) updatedSearchData.push(String(item?.trailerVIN));
          });
          const endoID = necessaryValuesInsideEndorsement?.endorsement?.id
          const endoRef = doc(db, `policy-applications/${policyID}/endorsements/${endoID}`)
          await transaction.set(endoRef, {
            searchData: data['searchData'],
            modifiedDate: new Date(),
            modifiedBy: auth.currentUser.displayName
          }, { merge: true });
          docRef = endoRef.id
          newCounter = necessaryValuesInsideEndorsement?.endorsement?.endorsementNumber
        } else if (Boolean(necessaryValuesInsideEndorsement) && necessaryValuesInsideEndorsement?.addUI) {
          // MODIFY THE ENDORSEMENT BUT ADD A NEW DRIVER/POWER UNIT/TRAILER IN THE DATA ARRAY AND SEARCH DATA
          const endoID = necessaryValuesInsideEndorsement?.endorsement?.id
          const endoRef = doc(db, `policy-applications/${policyID}/endorsements/${endoID}`)
          await transaction.set(endoRef, {
            data: arrayUnion(...docPaths),
            searchData: arrayUnion(...data['searchData']),
            modifiedDate: new Date(),
            modifiedBy: auth.currentUser.displayName
          }, { merge: true });
          docRef = endoRef.id
          newCounter = necessaryValuesInsideEndorsement?.endorsement?.endorsementNumber
        } else {
          // CREATE A NORMAL ENDORSEMENT AS ALWAYS HAS BEEN
          docRef = await addDoc(endosRef, data);
        }
        if (emailToProvideInExclusion) {
          const address =
            insuredInfo?.mailingAddress ? insuredInfo?.mailingAddress :
            insuredInfo?.physicalAddress ? insuredInfo?.physicalAddress :
            insuredInfo?.address ? insuredInfo?.address : ''
          const drivers = dataArray.map(data => {
            return {
              driverName: data?.driverFirst ? `${data?.driverFirst} ${data?.driverLast}` : '',
              dob: formatDOB(data?.dob),
              cdl: data?.licenseNumber ? data?.licenseNumber : '',
            }
          })
          const date = formatDOB(dateCreated)
          const printDriverExclusionPayload = {
            policyID,
            payload: {
              date,
              policyNo: formData.alPolicyNo,
              namedInsured: insuredInfo.company,
              address,
              drivers
            }
          }
          const results = await apiService.printDriverExclusion(printDriverExclusionPayload)
          const urlToEnvelope = results?.url
          const envelopePayload = {
            signerEmail: emailToProvideInExclusion,
            signerName: 'Test',
            url: urlToEnvelope,
            policyID,
            endoID: docRef.id,
            authorID: auth.currentUser.uid,
          };
          await apiService.sendEnvelopeDriverExclusion(envelopePayload)
        }
      }
      endoStatusChangeNotification(
        type,
        String(newCounter),
        status,
        docRef.id,
        policyID,
        userInfo.agencyID
      );
      const VINs = dataArray.map(
        (data) => Object.keys(data).find((key) => key.includes("VIN")) || ""
      );

      const text = isItAnEndorsementModification ? "Modified" : "Submitted"

      await updateSystemLog(
        auth,
        policyID,
        `${type} Endorsements (${VINs.join(", ")}) ${text} For ${
          insuredInfo.company
        } by ${auth.currentUser.displayName}`,
        `${type} Endorsement`,
        dataArray
      );
      if (Boolean(getEndorsements)) {
        await getEndorsements();
      }
      return ''
    });
    return transactionResult
  } catch (error) {
    throw error;
  }
};

export const deleteItemFromEndorsement = async (
  e,
  item, // driver/power-unit/trailer to be removed
  completeItems, // lists of driver/units accesible
  subcollection, // drivers, power-units, trailers
  policyID,
  endorsement // necessary values to know which endorsement is being modified
) => {

  e.preventDefault();
  const confirmDelete = window.confirm("Are you sure you want to delete this item from this endorsement?");
  if (!confirmDelete) return;

  const endoRef = doc(db, `policy-applications/${policyID}/endorsements/${endorsement.id}`);

  try {
    const updatedData = endorsement.data?.filter(ref => ref.id !== item.id) || [];
    const updatedSearchData = [];
    completeItems.filter(elem => elem?.id !== item.id).forEach(item => { 
      if (item?.driverFirst || item?.driverLast) {
        const searchName = `${item?.driverFirst || ""} ${item?.driverLast || ""}`.trim();
        const words = generateSearchWords(searchName);
        updatedSearchData.push(...words, searchName);
      }
      if (item?.licenseNumber) updatedSearchData.push(String(item?.licenseNumber));
      if (item?.truckVIN) updatedSearchData.push(String(item?.truckVIN));
      if (item?.trailerVIN) updatedSearchData.push(String(item?.trailerVIN));
    });

    const payload = {
      data: updatedData,
      searchData: updatedSearchData,
      modifiedDate: new Date(),
      modifiedBy: auth.currentUser.displayName
    }

    // If the endorsement is a "Addition" endorsement, we need to verify if the driver is in more than one policy
    if (endorsement?.type.includes("Add")) {
      const { policiesDetails } = item;
      // If the driver is in more than one policy, we need to remove in the policiesDetails array the corresponding policy
      if (policiesDetails.length > 1) {
        const policyIndex = policiesDetails.findIndex(policy => policy.id === policyID);
        if (policyIndex !== -1) {
          policiesDetails.splice(policyIndex, 1);
          const elemRef = doc(db, `agencies/${endorsement.agencyID}/insureds/${endorsement.insuredID}/${subcollection}/${item.id}`);
          await updateDoc(elemRef, { policiesDetails });
          console.log(`Policy Detail with ID ${policyID} removed from driver.`);
        } else {
          console.log("Policy ID not found in policiesDetails.");
        }
      // If there is only one policy in policiesDetails, we can remove the driver from the endorsement
      } else if (policiesDetails.length === 1) {
        const policy = policiesDetails[0];
        if (policy.id === policyID) {
          const elemRef = doc(db, `agencies/${endorsement.agencyID}/insureds/${endorsement.insuredID}/${subcollection}/${item.id}`);
          await deleteDoc(elemRef);
          console.log("Driver/Power Unit really removed.");
        } else {
          console.log("Policy ID does not match the only policy in policiesDetails.");
        }
      } else {
        console.log("No policies found in policiesDetails.");
      }
    }

    // If the endorsement is a "Remove" endorsement, we need to set the status of the driver to "Active" always
    if (endorsement?.type.includes("Remove")) {
      const { policiesDetails } = item;
      const newPoliciesDetails = policiesDetails.map(policyDetail => {
        if (policyDetail.id === policyID) {
          return {
            ...policyDetail,
            status: "Active",
          }
        }
        return policyDetail
      })
      const elemRef = doc(db, `agencies/${endorsement.agencyID}/insureds/${endorsement.insuredID}/${subcollection}/${item.id}`);
      await updateDoc(elemRef, { policiesDetails: newPoliciesDetails });
    }

    await updateDoc(endoRef, payload);
    
  } catch (error) {
    console.error("Error removing item:", error);
  }
};