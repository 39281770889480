import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { collection, doc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import useTranslations from "../../../../Context/actions/useTranslations";

export default function Step2({
  driverData,
  setDriverData,
  licenseExpirationDate,
  setLicenseExpirationDate,
  progresspercent,
  goBack,
  setShowStep1,
  setShowStep2,
  setShowStep3,
  driverList,
  necessaryValuesInsideEndorsement
}) {
  const { t } = useTranslations();

  const [isLicenseValid, setIsLicenseValid] = useState(true);

  const submitAddDriverStep2 = async (e) => {
    e.preventDefault(e);
    const lists = driverList.map((driver) => {
      return driver.licenseNumber;
    });
    if (
      lists.includes(driverData.licenseNumber) &&
      !necessaryValuesInsideEndorsement?.editUI
    ) {
      goBack(e);
      return alert(
        `Driver with license number ${driverData.licenseNumber} already exists in the policy.`
      );
    } else {
      setShowStep2(false);
      setShowStep3(true);
    }
  };
  
  return (
    <div>
      {driverData.licenseCountry === "USA" && (
        <div>
          <form
            onSubmit={(e) => {
              submitAddDriverStep2(e);
            }}
          >
            <p className="font-bold text-lg">
              {" "}
              {driverData.driverFirst} {driverData.driverLast} (
              {driverData.licenseCountry}){" "}
            </p>
            <hr className="my-2" />
            <label htmlFor="licenseState" className="mt-1">
              {t("plm.drivers.driver-license-state:")}
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseState: e.target.value,
                })
              }
              value={driverData.licenseState}
              type="text"
              id="licenseState"
              className="w-full p-2 border"
            >
              <option></option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <label htmlFor="licenseState" className="mt-1">
              {t("plm.drivers.details.license-class")}:
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseClass: e.target.value,
                })
              }
              value={driverData.licenseClass}
              type="text"
              id="licenseClass"
              className="w-full p-2 border"
            >
              <option></option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
            </select>
            <label className="mt-1">
              {t("plm.drivers.driver-license-number:")}:
            </label>
            <input
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseNumber: e.target.value,
                })
              }
              value={driverData.licenseNumber}
              type="text"
              id="licenseNumber"
              className="w-full p-2 border"
            />
            <>
              <label className="">
                {t("plm.drivers.license-expiration-date")}:
              </label>
              <DatePicker
                required
                wrapperClassName="datePicker"
                selected={licenseExpirationDate}
                onChange={(date) => {
                  setDriverData({
                    ...driverData,
                    licenseExpiration: date,
                  });
                  setLicenseExpirationDate(date);
                }}
                className="w-full p-2 border"
              />
            </>
            <ApprovalButtons
              onApprove={null}
              handleAsSubmit={true}
              onDecline={(e) => {
                setShowStep2(false);
                setShowStep1(true);
              }}
              titleAccept="Continue"
              titleDecline="Go Back"
            />
          </form>
        </div>
      )}
      {driverData.licenseCountry === "MX" && (
        <div>
          <form
            onSubmit={(e) => {
              submitAddDriverStep2(e);
            }}
          >
            <label className="mt-1">
              {t("plm.drivers.driver's-license-number")}
            </label>
            <input
              required
              onChange={(e) => {
                const value = e.target.value;
                const isValid = /^(?=.*[a-zA-Z])[a-zA-Z0-9]*$/.test(value);
                setDriverData({
                  ...driverData,
                  licenseNumber: value,
                });
                if (!isValid) {
                  setIsLicenseValid(false);
                } else {
                  setIsLicenseValid(true);
                }
              }}
              value={driverData.licenseNumber}
              type="text"
              id="licenseNumber"
              className="w-full p-2 border"
            />
            {!isLicenseValid && (
              <p className="text-red-500 text-sm mt-1">
                License number should contain letters and numbers
              </p>
            )}

            <label htmlFor="licenseState" className="mt-1">
              {t("plm.drivers.details.license-class")}
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseClass: e.target.value,
                })
              }
              value={driverData.licenseClass}
              type="text"
              id="licenseClass"
              className="w-full p-2 border"
            >
              <option></option>
              <option value="A">A</option>
              <option value="B">B</option>
              <option value="C">C</option>
              <option value="D">D</option>
              <option value="E">E</option>
            </select>
            <label htmlFor="mxNMP" className="mt-1">
              Número Médico Preventiva
            </label>
            <input
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  mxNMP: e.target.value,
                })
              }
              value={driverData.mxNMP}
              type="text"
              id="mxNMP"
              className="w-full p-2 border"
            />
            {/* {driverData?.curp ? (
              <>
                <label htmlFor="curp" className="mt-1">
                  CURP
                </label>
                <input
                  required
                  onChange={(e) =>
                    setDriverData({
                      ...driverData,
                      curp: e.target.value,
                    })
                  }
                  value={driverData.curp}
                  type="text"
                  id="curp"
                  className="w-full p-2 border"
                />
              </>
            ) : null}
            {driverData?.doa ? (
              <>
                <label className="">Antiquity Date:</label>
                <DatePicker
                  required
                  wrapperClassName="datePicker"
                  selected={doa}
                  onChange={(date) => {
                    setDriverData({
                      ...driverData,
                      doa: date,
                    });
                    setDoa(date);
                  }}
                  className="w-full p-2 border"
                />
              </>
            ) : null}*/}
            <>
              <label className="">License Expiration Date:</label>
              <DatePicker
                required
                wrapperClassName="datePicker"
                selected={licenseExpirationDate}
                onChange={(date) => {
                  setDriverData({
                    ...driverData,
                    licenseExpiration: date,
                  });
                  setLicenseExpirationDate(date);
                }}
                className="w-full p-2 border"
              />
            </>
            <ApprovalButtons
              onApprove={null}
              handleAsSubmit={true}
              onDecline={(e) => {
                setShowStep2(false);
                setShowStep1(true);
              }}
              disabledAccept={
                !licenseExpirationDate ||
                progresspercent !== 0 ||
                !isLicenseValid
              }
              titleAccept="Continue"
              titleDecline="Go Back"
            />
          </form>
        </div>
      )}
      {driverData.licenseCountry === "CA" && (
        <div>
          <form
            onSubmit={(e) => {
              submitAddDriverStep2(e);
            }}
          >
            <label className="mt-1">
              {t("plm.drivers.driver's-license-number")}
            </label>
            <input
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseNumber: e.target.value,
                })
              }
              value={driverData.licenseNumber}
              type="text"
              id="licenseNumber"
              className="w-full p-2 border"
            />
            <label htmlFor="licenseState" className="mt-1">
              License Class
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  licenseClass: e.target.value,
                })
              }
              value={driverData.licenseClass}
              type="text"
              id="licenseClass"
              className="w-full p-2 border"
            >
              <option></option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
            </select>
            <>
              <label className="">License Expiration Date:</label>
              <DatePicker
                required
                wrapperClassName="datePicker"
                selected={licenseExpirationDate}
                onChange={(date) => {
                  setDriverData({
                    ...driverData,
                    licenseExpiration: date,
                  });
                  setLicenseExpirationDate(date);
                }}
                className="w-full p-2 border"
              />
            </>
            <ApprovalButtons
              onApprove={null}
              handleAsSubmit={true}
              onDecline={(e) => {
                setShowStep2(false);
                setShowStep1(true);
              }}
              titleAccept="Continue"
              titleDecline="Go Back"
            />
          </form>
        </div>
      )}
    </div>
  );
}
