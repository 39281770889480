const miniValidation = (
  truckList,
  formData,
  insuredInfo,
  formDataGL,
  formDataAPD,
  formDataMTC
) => {
  // log all values
  const errors = [];

  const validations = [
    {
      condition: !insuredInfo.commodityTotal || insuredInfo.commodityTotal !== 100,
      message: "The commodity total must equal 100%."
    },
    {
      condition: !insuredInfo.businessStructure,
      message: "Please modify the insured's details and add a business structure (LLC, Corporation, etc.).",  
    },
    {
      condition: !truckList || truckList.length === 0,
      message: "Please add trucks.",
    },
    {
      condition: formData.hasALCoverage && !formData.product,
      message: "Please select a product.",
    },
    {
      condition: !formData.stateOfEntry,
      message: "Please select a state of entry.",
    },
    {
      condition: formData.hasALCoverage && !formData.coverageSingleLimit,
      message: "Please select a coverage single limit.",
    },
    {
      condition: formData.hasALCoverage && !formData.program,
      message: "Please select a program.",
    },
    {
      condition: formData.hasALCoverage && !formData.effectiveDate,
      message: "Please select an AL effective date.",
    },
    {
      condition: formData.hasGLCoverage && !formDataGL.effectiveDate,
      message: "Please select a GL effective date.",
    },
    {
      condition: formData.hasAPDCoverage && !formDataAPD.effectiveDate,
      message: "Please select an APD effective date.",
    },
    {
      condition: formData.hasMTCCoverage && !formDataMTC.effectiveDate,
      message: "Please select an MTC effective date.",
    },
  ];

  validations.forEach(({ condition, message }) => {
    if (condition) {
      errors.push(message);
    }
  });

  if (errors.length > 0) {
    alert(errors.join("\n"));
    return false;
  }

  return true;
};

export default miniValidation;
