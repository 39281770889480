import React, { useEffect, useState } from "react";
import {
  getDocs,
  collection,
  query,
  addDoc,
  orderBy,
  where,
} from "firebase/firestore";
import { db, auth } from "../../firebase";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import FormGroup from "react-bootstrap/esm/FormGroup";
import useTranslations from "../../Context/actions/useTranslations";
import config from "../../config/env";
import useUsersState from "../../Context/actions/useUsersState";

function SouthernStarCompanySelection(props) {
  const setSelected = props.setSelected;

  const { t } = useTranslations();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState({});

  const { user: userInfo } = useUsersState();

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [dotNumberForAdd, setDotNumberForAdd] = useState("");

  const handleChangeDot = (event) => {
    const { value } = event.target;
    setDotNumberForAdd(value);
  };

  const insuredsRef = collection(db, "ss-insureds");

  const [customerAdded, setCustomerAdded] = useState(false);
  const AddCustomer = async (e) => {
    e.preventDefault();

    await addDoc(insuredsRef, {
      ...formData,
      author: { id: auth.currentUser.uid },
      dateCreated: Date.now(),
    });
    setShow(false);
    setFormData({});
    setCustomerAdded(true);
    setSelected("");
    setGotCompanies(false);
    getCompanies();
  };

  let navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const companiesCollectionRef = collection(db, "ss-insureds");

  const [gotCompanies, setGotCompanies] = useState(false);
  const getCompanies = async () => {
    if (gotCompanies) return;
    const data = await getDocs(
      query(companiesCollectionRef, orderBy("company"))
    );
    setCompanyList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    setGotCompanies(true);
  };

  const [companyInfoUpdated, setCompanyInfoUpdated] = useState(false);
  const [loading, setLoading] = useState(false);
  const fetchCompanyInfo = async (e, dot) => {
    e.preventDefault();
    setLoading(true);

    try {
      const q = query(
        collection(db, "ss-insureds"),
        where("dot", "==", dotNumberForAdd)
      );
      const querySnapshot = await getDocs(q);
      const values = [];

      querySnapshot.forEach((doc) => {
        values.push(doc.data);
        alert("An insured already exists with that DOT number.");
        setDotNumberForAdd("");
        setLoading(false);
        return;
      });

      const myInfo = { dotNumber: dot };

      const { REACT_APP_SOUTHERN_BACKEND } = config;

      if (!values.length > 0) {
        const response = await fetch(`${REACT_APP_SOUTHERN_BACKEND}/safer/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(myInfo),
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch data. Status: ${response.status}`);
        }

        const data = await response.json();

        setFormData({
          ...formData,
          company: data.legalName,
          dba: data.dbaName,
          dot: data.dotNumber,
          mailingAddress: data.mailingAddress,
          physicalAddress: data.physicalAddress,
          mc: data.mcNumber,
          phone: data.phone,
        });

        setCompanyInfoUpdated(true);
        handleShow();
        setLoading(false);
        setDotNumberForAdd("");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error gracefully, e.g., set default values or display an error message.
      setLoading(false);
      setFormData({
        ...formData,
        dot: dotNumberForAdd,
      });
      setDotNumberForAdd("");
      handleShow();
    }
  };

  const resetPage = (e) => {
    setDotNumberForAdd("");
    setCustomerAdded(false);
    setSelected("");
  };

  useEffect(() => {
    !gotCompanies && getCompanies();
  }, [db, customerAdded, companyInfoUpdated, loading]);

  return (
    <div>
      <section>
        <p className="mb-3">{t("account.proposals.get-started")}</p>
        <div className="mt-2">
          <h1 className="text-[#072a48] text-lg">
            {t("account.proposals.select-insured")}
          </h1>
          <select
            className="form-control rounded-md font-medium border-2 p-2 mt-2 sm:text-lg md:text-lg"
            onChange={(e) => {
              const agencyID = e.target.value;
              setSelected(agencyID);
              props.setShowCompanySelection(false);
            }}
          >
            <option value="">{t("account.proposals.choose-one")}</option>

            {companyList.map((company) => {
              if (company.company) {
                return (
                  <option key={"id" + company.id} value={company.id}>
                    {company.company.substring(0, 25)}
                  </option>
                );
              }
            })}
          </select>

          <>
            {customerAdded && (
              <div>
                <p className="my-2 text-lg text-green-600 font-[600]">
                  {t("account.proposals.customer-successfully-added")}
                </p>
                <button
                  onClick={(e) => resetPage(e)}
                  className="text-[#072a48]"
                >
                  {t("account.proposals.add-another-customer")}
                </button>
              </div>
            )}
            {!customerAdded && (
              <form onSubmit={(e) => fetchCompanyInfo(e, dotNumberForAdd)}>
                <h1 className="text-[#072a48] text-lg mt-3">
                  {t("account.proposals.or-add-an-insured")}
                </h1>
                <input
                  required
                  className="form-control rounded-md border-2 p-2 mt-2 sm:text-lg md:text-lg"
                  placeholder="Enter DOT Number"
                  type="text"
                  value={dotNumberForAdd}
                  onChange={handleChangeDot}
                />
                {!loading ? (
                  <button
                    className="text-lg text-white bg-[#072a48] hover:bg-red-700 py-2 px-4 mt-3 mb-10 rounded-md"
                    type="submit"
                  >
                    {t("account.proposals.add-a-customer")}
                  </button>
                ) : (
                  <button
                    disabled
                    className="text-lg text-white bg-green-900 hover:bg-green-800 py-2 px-4 mt-3 mb-10 rounded-md"
                    onClick={() => fetchCompanyInfo(dotNumberForAdd)}
                  >
                    {t("account.proposals.loading")}
                  </button>
                )}
              </form>
            )}

            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("account.proposals.add-customer")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {(userInfo.insuredPermissions || userInfo.role === "Admin") && (
                  <div className="createPostContainer">
                    <Form>
                      <div className="inputGroup">
                        <FormGroup>
                          <label>{t("account.proposals.company-name")}</label>
                          <Form.Control
                            required
                            type="text"
                            name="company"
                            placeholder={t("account.proposals.company-name")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.company}
                          />
                          <label className="mt-1">
                            {t("account.proposals.safer-physical-address")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="paddress"
                            placeholder={t(
                              "account.proposals.full-physical-address"
                            )}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.physicalAddress}
                          />
                          <label className="mt-1">
                            {t("account.proposals.safer-mailing-address")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="maddress"
                            placeholder={t(
                              "account.proposals.full-mailing-address"
                            )}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.mailingAddress}
                          />
                          <label className="mt-1">
                            {t("account.proposals.address-for-notifications")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="address"
                            placeholder={t(
                              "account.proposals.address-for-notifications"
                            )}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.address}
                          />
                          <label className="mt-1">
                            {t("account.proposals.city")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="city"
                            placeholder={t("account.proposals.city")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.city}
                          />
                          <label className="mt-1">
                            {t("account.proposals.state")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="state"
                            placeholder={t("account.proposals.state")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.state}
                          />
                          <label className="mt-1">
                            {t("account.proposals.zip")}:
                          </label>
                          <Form.Control
                            required
                            type="text"
                            name="zip"
                            placeholder={t("account.proposals.zip")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.zip}
                          />
                          <label className="mt-1">
                            {t("account.proposals.phone")}:
                          </label>
                          <Form.Control
                            required
                            type="phone"
                            name="phone"
                            placeholder={t("account.proposals.main-phone")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.phone}
                          />
                          <label className="mt-1">
                            {t("account.proposals.dot-number")}:
                          </label>
                          <Form.Control
                            required
                            disabled
                            type="text"
                            name="dot"
                            placeholder={t("account.proposals.dot-number")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.dot}
                          />
                          <label className="mt-1">
                            {t("account.proposals.mc-number")}:
                          </label>
                          <Form.Control
                            required
                            disabled
                            type="text"
                            name="mc"
                            placeholder={t("account.proposals.mc-number")}
                            className="form-input mb-2"
                            onChange={handleChange}
                            value={formData.mc}
                          />
                        </FormGroup>
                      </div>

                      <button
                        className="block ml-2 text-lg text-white bg-[#072a48] hover:bg-red-700 pt-1 pb-2 px-3 mt-3"
                        onClick={AddCustomer}
                      >
                        {t("account.proposals.address-customer-rule")}
                      </button>
                    </Form>
                  </div>
                )}
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="block text-lg text-white bg-gray-900 hover:bg-gray-700 pt-1 pb-2 px-3 mt-3"
                  onClick={handleClose}
                >
                  {t("account.proposals.cancel")}
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      </section>
    </div>
  );
}

export default SouthernStarCompanySelection;
