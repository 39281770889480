import numberWithCommas from "../../../utils/numberWithCommas";
import calculateTaxesAPD from "../../../utils/premiumCalculation/calculateTaxesAPD";
import calculateTaxesMTC from "../../../utils/premiumCalculation/calculateTaxesMTC";

function camelCaseToTitleCase(str) {
  if (!str) return "";
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/\b\w/g, (word) => word.toUpperCase());
}

function mapCommoditiesToArray(formData) {
  const resultArray = [];

  if (formData && formData?.standardCommodityPercentages) {
    for (const fieldName in formData?.standardCommodityPercentages) {
      const fieldValue = formData?.standardCommodityPercentages[fieldName];
      if (fieldValue && fieldValue > 0) {
        const titleCaseFieldName = camelCaseToTitleCase(fieldName);
        resultArray.push(titleCaseFieldName);
      }
    }
  }

  if (formData?.commodityFields) {
    formData?.commodityFields.forEach((commodity) => {
      const commodityDescription = commodity?.commodityDescription;
      if (commodityDescription) {
        resultArray.push(commodityDescription);
      }
    });
  }

  return resultArray.join(", ");
}

async function calculateTaxesAndFees(
  policyID,
  formData,
  alPremiums,
  glPremiums,
  mtcPremiums,
  apdPremiums,
  truckList
) {
  // powerUnitCount is the total number of power units in the truckList with a status of "Active"
  const powerUnitCount = truckList.filter((truck) => {
    const policiesDetails = truck.policiesDetails;
    const matchingPolicies =
      policiesDetails.filter(
        (policiesDetails) =>
          policiesDetails.id === policyID && policiesDetails.status === "Active"
      ).length > 0;
    return matchingPolicies;
  }).length;

  // Get AL Taxes & Fees
  const {
    otherFees = 0,
    processingFeeAmount = 0,
    stampTaxAmount = 0,
    surplusLinesTaxAmount = 0,
  } = alPremiums;

  // Get GL Taxes & Fees
  const {
    processingFeeAmount: glProcessingFeeAmount = 0,
    stampTaxAmount: glStampTaxAmount = 0,
    surplusLinesTaxAmount: glSurplusLinesTaxAmount = 0,
  } = glPremiums || {};

  // Get APD Taxes
  const {
    stampTaxAmount: apdStampTaxAmount = 0,
    surplusLinesTaxAmount: apdSurplusLinesTaxAmount = 0,
    policyFeeAmount: apdPolicyFeeAmount = 0,
  } = (await calculateTaxesAPD(
    policyID,
    formData,
    apdPremiums,
    powerUnitCount
  )) || {};

  // Get MTC Taxes
  const {
    stampTaxAmount: mtcStampTaxAmount = 0,
    surplusLinesTaxAmount: mtcSurplusLinesTaxAmount = 0,
    policyFeeAmount: mtcPolicyFeeAmount = 0,
  } = (await calculateTaxesMTC(
    policyID,
    formData,
    mtcPremiums,
    powerUnitCount,
    false,
    formData.hasXSCoverage
  )) || {};

  // Calculate the total
  const total =
    otherFees +
    processingFeeAmount +
    stampTaxAmount +
    surplusLinesTaxAmount +
    glProcessingFeeAmount +
    glStampTaxAmount +
    glSurplusLinesTaxAmount +
    apdStampTaxAmount +
    apdSurplusLinesTaxAmount +
    apdPolicyFeeAmount +
    mtcStampTaxAmount +
    mtcSurplusLinesTaxAmount +
    mtcPolicyFeeAmount;

  // Ensure total is a number before calling toFixed
  if (typeof total === "number" && !isNaN(total)) {
    return parseFloat(total.toFixed(2));
  } else {
    throw new Error("Total is not a valid number.");
  }
}

function findGrandTotal(
  formData,
  taxesAndFees,
  alPremiums,
  glPremiums,
  mtcPremium,
  apdPremium
) {
  const hasAL = formData?.hasALCoverage;
  const hasGL = formData?.hasGLCoverage;
  const hasAPD = formData?.hasAPDCoverage;
  const hasMTC = formData?.hasMTCCoverage;

  const total =
    parseFloat(taxesAndFees) +
    parseFloat(hasAL ? alPremiums?.GWP : 0) +
    parseFloat(hasGL ? glPremiums?.GWP : 0) +
    parseFloat(hasMTC ? mtcPremium : 0) +
    parseFloat(hasAPD ? apdPremium : 0);

  const totalWithCommas = numberWithCommas(total?.toFixed(2));
  return totalWithCommas;
}

const requestData = async (
  formData,
  truckList,
  driverList,
  adjustmentFormData,
  insuredInfo,
  alPremiums,
  glPremiums,
  apdDetails,
  mtcDetails
) => {
  const hasAL = formData?.hasALCoverage;
  const hasGL = formData?.hasGLCoverage;
  const hasAPD = formData?.hasAPDCoverage;
  const hasMTC = formData?.hasMTCCoverage;

  const totalInsuredValue = formData?.TIV || 0;
  const policyID = formData?.id;

  const mtcPremium = formData.hasMTCCoverage
    ? mtcDetails?.totalPremiumBeforeTaxes || 0
    : null;
  const apdPremium = formData.hasAPDCoverage
    ? apdDetails?.totalPremiumBeforeTaxes || 0
    : null;

  const powerUnitCount = truckList.filter((truck) => {
    const policiesDetails = truck.policiesDetails;
    const matchingPolicies =
      policiesDetails.filter(
        (policiesDetails) =>
          policiesDetails.id === policyID && policiesDetails.status === "Active"
      ).length > 0;
    return matchingPolicies;
  }).length;

  const taxesAndFees = await calculateTaxesAndFees(
    policyID,
    formData,
    alPremiums,
    glPremiums,
    mtcPremium,
    apdPremium,
    truckList
  );
  console.log("taxesAndFees", taxesAndFees);

  const grandTotal = findGrandTotal(
    formData,
    taxesAndFees,
    alPremiums,
    glPremiums,
    mtcPremium,
    apdPremium
  );
  console.log("grandTotal", grandTotal);

  const apdCoverage = hasAPD
    ? {
        apdCarrier: formData?.stagingArea?.apdCarrier,
        collisionDeductible:
          "$" + numberWithCommas(apdDetails?.collisionDeductible),
        collisionLimit: "$" + numberWithCommas(totalInsuredValue),
        comprehensiveDeductible:
          "$" + numberWithCommas(apdDetails?.comprehensiveDeductible),
        comprehensiveLimit: "$" + numberWithCommas(totalInsuredValue),
        apdTrailerInterchangeLimit:
          "$" + numberWithCommas(apdDetails?.trailerInterchangeLimit),
        apdPremium:
          "$" +
          numberWithCommas(apdDetails?.totalPremiumBeforeTaxes?.toFixed(2)),
      }
    : {};

  const mtcCoverage = hasMTC
    ? {
        mtcCarrier: formData?.stagingArea?.mtcCarrier,
        mtcGeneralAggregateLimit:
          "$" + numberWithCommas(mtcDetails?.generalAggregateLimit),
        cargoDeductible:
          "$" + numberWithCommas(mtcDetails?.generalAggregateDeductible),
        cargoAgLimit: "$" + numberWithCommas(mtcDetails?.generalAggregateLimit),
        reeferDeductible:
          mtcDetails?.reeferDeductible === "Included"
            ? ""
            : "$" + numberWithCommas(mtcDetails?.reeferDeductible),
        reeferLimit: "Included",
        mtcEndorsedCommodities: mapCommoditiesToArray(insuredInfo),
        mtcPremium:
          "$" +
          numberWithCommas(mtcDetails?.totalPremiumBeforeTaxes?.toFixed(2)),
        theftOfAutoPartsDeductible: !formData.stagingArea?.mtcDetails
          ?.theftOfAutoPartsDeductible
          ? null
          : numberWithCommas(
              formData.stagingArea?.mtcDetails?.theftOfAutoPartsDeductible || 0
            ),
      }
    : {};

  const drivermap = driverList.map((driver) => {
    const policiesDetails = driver.policiesDetails;
    const matchingPolicies = policiesDetails.filter(
      (policiesDetails) =>
        policiesDetails.id === formData?.id &&
        policiesDetails.status === "Active"
    );

    const hasMatchingPolicies = matchingPolicies.length > 0;

    // change dob (which is currently a timestamp) to a string
    let dob = "";
    if (driver.dob) dob = driver.dob.toDate().toLocaleDateString("en-US");
    if (!hasMatchingPolicies) return null;
    return {
      ...driver,
      dob,
    };
  });

  const preparedTruckList = truckList.map((truck) => {
    const policiesDetails = truck.policiesDetails;
    const matchingPolicies = policiesDetails.filter(
      (policiesDetails) =>
        policiesDetails.id === formData?.id &&
        policiesDetails.status === "Active"
    );
    const matchingPolicy = matchingPolicies[0];
    const hasMatchingPolicies = matchingPolicies.length > 0;
    if (!hasMatchingPolicies) return null;
    return {
      ...truck,
      ...matchingPolicy,
      truckACV: "$" + numberWithCommas(matchingPolicy.truckACV),
    };
  });

  // remove null values from prepared truck list
  const filteredTruckList = preparedTruckList.filter((truck) => truck !== null);
  const filteredDriverList = drivermap.filter((driver) => driver !== null);

  const hasCustomDeductibles =
    formData?.stagingArea?.selectedCommodities?.length > 0;

  // function to add commas to a number
  const customDeductibles = !hasCustomDeductibles
    ? null
    : formData?.stagingArea?.selectedCommodities.map((commodity) => {
        return {
          deductible: "$" + numberWithCommas(commodity.deductible),
          limit: "$" + numberWithCommas(commodity.limit),
          commodityDescription: commodity.commodityDescription,
        };
      });
  const result = {
    adjustmentFormData,
    drivers: filteredDriverList || [],
    trucks: filteredTruckList || [],
    alPremiums: hasAL ? alPremiums : {},
    glPremiums: hasGL ? glPremiums : {},
    apdPremiums: hasAPD ? apdDetails : {},
    mtcPremiums: hasMTC ? mtcDetails : {},
    stagingArea: formData?.stagingArea,
    hasCustomDeductibles,
    customDeductibles,
    q: {
      // TODO: Add these values to be edited in the admin panel in ManageQuoteFigures.jsx
      galPTL: "$2,000,000",
      productsPTL: "N/A",
      injuryPTL: "N/A",
      occurencePTL: "$1,000,000",
      rentedPTL: "$100,000",
      medExpensePTL: "$5,000",
      createdOn: new Date().toLocaleDateString("en-US"),
      r: "",
      number: "",
      apdCarrier: hasAPD
        ? camelCaseToTitleCase(formData?.stagingArea?.apdCarrier)
        : null,
      mtcCarrier: hasMTC
        ? camelCaseToTitleCase(formData?.stagingArea?.mtcCarrier)
        : null,
      newOrRenewal: formData?.newOrRenewal || null,
      biLiabilityDeductible: !hasAL ? null :
        "$" +
        numberWithCommas(
          formData?.stagingArea?.alDetails?.biLiabilityDeductible || 0
        ),
      biLiabilityPTL: !hasAL ? null : formData?.coverageSingleLimit || 0,
      umLiabilityPTL: !hasAL ? null :
        "$" +
        numberWithCommas(
          formData?.stagingArea?.alDetails?.uninsuredMotoristLimit || 0
        ),
      hasTI:
        formData?.apdDetails?.trailerInterchangeTarget ||
        formData?.mtcDetails?.trailerInterchangeTarget ||
        false,
      personalInjuryProtectionLimit: !hasAL ? null :
        "$" +
        numberWithCommas(
          formData?.stagingArea?.alDetails?.personalInjuryProtectionLimit || 0
        ),

      towingAndStorageLimit:
        "$" +
          numberWithCommas(formData?.stagingArea?.apdDetails?.towingAndStorageLimit || 0) +
          (apdDetails?.towingAndStorageIsPerUnit ===
          "Yes" ? "/unit" : ""), // not working...
      hasTowingAndStorage:
        hasAPD && formData?.apdDetails?.towingStorageAndCleanUp ? true : false,
      payInFullAmount: "$" + grandTotal,
      taxesAndFees: "$" + numberWithCommas((taxesAndFees || 0).toFixed(2)),
      totalInsuredValue,
      hasGLCoverage: hasGL ?? false,
      hasALCoverage: hasAL ?? false,
      hasAPDCoverage: hasAPD ?? false,
      hasMTCCoverage: hasMTC ?? false,
      hasNonOwned: formData?.hasNonOwned ?? false,
      hasReefer: mtcDetails?.reeferDeductible ? true : false,
      namedInsured: insuredInfo?.company,
      address: insuredInfo?.address,
      address2: insuredInfo?.address2 || null,
      city: insuredInfo?.city,
      state: insuredInfo?.state,
      zip: insuredInfo?.zip,
      effectiveDate:  !hasAL ? null : formData?.effectiveDate
        ?.toDate()
        ?.toLocaleDateString("en-US"),
      expirationDate:  !hasAL ? null : new Date(
        new Date(formData?.effectiveDate?.toDate())?.setFullYear(
          new Date(formData?.effectiveDate?.toDate())?.getFullYear() + 1
        )
      ).toLocaleDateString("en-US"),
      numberOfUnits: (truckList || []).filter(
        (truck) =>
          // Filter out trucks that do not have a matching policy in the policyDetailsArray or have a status other than "Active"
          truck.policiesDetails.filter(
            (policiesDetails) =>
              policiesDetails.id === formData?.id &&
              policiesDetails.status === "Active"
          ).length > 0
      ).length,
      alPremium:  !hasAL ? null :
        "$" + numberWithCommas((alPremiums?.GWP || 0).toFixed(2)) || "ERROR",
      glPremium: hasGL
        ? "$" + numberWithCommas((glPremiums?.GWP || 0).toFixed(2)) || "N/A"
        : "N/A",
      totalPremium: "$" + grandTotal,
      ...apdCoverage,
      ...mtcCoverage,
      downPaymentAmount:
        "$" +
        numberWithCommas(
          formData?.stagingArea?.financeDetails?.downPaymentAmount || 0
        ),
      installmentAmount:
        "$" +
        numberWithCommas(
          formData?.stagingArea?.financeDetails?.installmentPlanAmount || 0
        ),
      powerUnitCount,
      strikingOfLoad:
        hasMTC && mtcDetails?.strikingOfLoadDeductible
          ? mtcDetails?.strikingOfLoadDeductible !== "N/A"
          : false,
      strikingOfLoadDeductible:
        hasMTC && mtcDetails?.strikingOfLoadDeductible
          ? "$" + numberWithCommas(mtcDetails?.strikingOfLoadDeductible)
          : null,
      theftOfWineAndAlcohol:
        hasMTC &&
        mtcDetails?.theftOfWineAndAlcoholDeductible &&
        mtcDetails?.theftOfWineAndAlcoholDeductible !== "N/A"
          ? true
          : false,
      theftOfWineAndAlcoholDeductible:
        hasMTC && mtcDetails?.theftOfWineAndAlcoholDeductible
          ? "$" + numberWithCommas(mtcDetails?.theftOfWineAndAlcoholDeductible)
          : null,
      theftOfWineAndAlcoholLimit:
        hasMTC && mtcDetails?.theftOfWineAndAlcoholLimit
          ? "$" + numberWithCommas(mtcDetails?.theftOfWineAndAlcoholLimit)
          : null,
      theftOfAutoParts:
        hasMTC &&
        mtcDetails?.theftOfAutoPartsDeductible &&
        mtcDetails?.theftOfAutoPartsDeductible !== "N/A"
          ? true
          : false,
      trailerInterchangeLimit: hasAPD
        ? "$" + numberWithCommas(apdDetails?.trailerInterchangeLimit)
        : hasMTC
        ? "$" + numberWithCommas(mtcDetails?.trailerInterchangeLimit)
        : null,
      agentName:
        formData?.agentName && formData.agentName !== ""
          ? formData.agentName
          : "David Licona",
    },
  };
  console.log('request data: ', result);
  return result;
};

export default requestData;
