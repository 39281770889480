import React, { useEffect, useState } from "react";
import CreateUserPanel from "../../Components/agent/createUsers/CreateUserPanel";
import UserList from "../../Components/agent/createUsers/UserList";
import Breadcrumbs from "../../Components/assets/Breadcrumbs";
import Commodities from "../../Components/PolicyLifecycleManager/Commodities";
import { ACCOUNT_ROUTE } from "../../utils/routes";
import useTranslations from "../../Context/actions/useTranslations";
import Popup from "../../Components/generals/Popup";
import RedButton from "../../Components/assets/forms/RedButton";
import useGeneralState from "../../Context/actions/useGeneralState";
import ClientList from "../../Components/agent/createClients/ClientList";
import AddInsured from "../../Components/PolicyLifecycleManager/CreateNewApp/SelectInsured";
import {
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Box,
} from "@mui/material";
import { auth, db } from "../../firebase";
import BigAlert from "../../Components/generals/Toast/BigAlert";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import InsuredInfo from "../PolicyLifecycleManager/CoveragesSidePanel/InsuredInfo";
import InsuredsPoliciesList from "./InsuredsPoliciesList";
import PortalCard from "../assets/cards/PortalCard";
import PortalPanel from "../assets/cards/PortalPanel";
import History from "../PolicyLifecycleManager/History";
import EndoSearch from "../underwriter/endorsements/EndoSearch";
import { useParams } from "react-router-dom";
import LoadingScreen from "../assets/LoadingScreen";

export default function InsuredProfile({
  userInfo,
  loading,
  setLoading,
}) {
  const { t } = useTranslations();

  let { paramInsuredID: insuredID } = useParams();

  const hasPermissionToView =
    userInfo.insuredPermissions || userInfo.companyOwner || userInfo.admin;

  const [didSuccess, setDidSuccess] = useState(false);
  const [displayCompanyDetails, setDisplayCompanyDetails] = useState(false);
  const [insuredInfo, setInsuredInfo] = useState({});
  const [formData, setFormData] = useState({});
  const agencyID = userInfo.agencyID;

  const getEmailFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("email");
  };

  const emailValue = getEmailFromCurrentUrl();

  useEffect(() => {
    if (!insuredID) return;
    const fetchActivePolicy = async () => {
      try {
        const activePolicies = await getDocs(
          query(
            collection(db, "policy-applications"),
            where("insuredID", "==", insuredID),
            where("documentType", "==", "Policy"),
            where("status", "in", ['In-Force', 'Bound', 'In-Renewal', 'In-Cancellation'])
          )
        );
        const policies = activePolicies.docs.map((doc) => ({
          id: doc?.id,
          ...doc.data(),
        }))
        let policy = policies?.[0] || null
        if (!policies?.[0]) {
          policy = {
            agencyID,
            insuredID,
            documentType: "Application",
            status: "Unsubmitted",
          }
        }
        setFormData(policy);
      } catch (error) {
        console.error("Error fetching temp data:", error);
      }
    };
    fetchActivePolicy();
  }, [insuredID, agencyID]);

  const [policies, setPolicies] = useState([]);

  const [activePolicies, setActivePolicies] = useState({
    al: false,
    gl: false,
    apd: false,
    mtc: false,
    xsmtc: false,
  })

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onSnapshot(
      query(
        collectionGroup(db, "insureds"),
        where("dot", "==", insuredID)
      ),
      (querySnapshot) => {
        const values = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });
        const insuredResult = values?.[0];
        if (insuredResult) {
          setInsuredInfo(insuredResult);
        } else {
          setInsuredInfo(null);
          setLoading(false);
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const policiesRef = collection(db, "policy-applications");
    // realtime query policies collection where insuredID is equal to the current insuredID
    const q = query(policiesRef, where("insuredID", "==", insuredID));
    const unsubscribePolicies = onSnapshot(q, (querySnapshot) => {
      let values = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const coverage =
          (data.hasALCoverage ? "AL " : "") +
          (data.hasGLCoverage ? "GL " : "") +
          (data.hasAPDCoverage ? "APD" : "") +
          (data.hasMTCCoverage ? "MTC" : "");
        values.push({
          ...doc.data(),
          id: doc.id,
          coverage,
          policyNumber: doc.data().alPolicyNo,
        });
      });
      // sort by effective date
      values.sort((a, b) => b.effectiveDate - a.effectiveDate);
      setPolicies(values);
    });
    return () => unsubscribePolicies();
  }, [insuredID]);



  return (
    <PortalPanel>
      {!insuredInfo && loading ? (
        <LoadingScreen text={"Loading insured details..."} />
      ) :
      !insuredInfo && !loading ?
        <h1 className="text-center text-lg font-semibold text-red-500">
          Insured not found
        </h1> :
        <>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
            <span className="text-4xl font-semibold mb-1 md:mb-0">
              Insured Profile
            </span>
          </div>
          <p className="mb-4">{t("agent.clients-policies")}</p>
          {!hasPermissionToView ? (
            <p>
              You do not have permission to manage clients. Please contact an
              administrator.
            </p>
          ) : (
            <>
              <div className="mt-4">
                <InsuredInfo
                  insuredInfo={insuredInfo}
                  setDisplayCompanyDetails={setDisplayCompanyDetails}
                />
              </div>
              <PortalCard title={"Policies & Applications"}>
                <InsuredsPoliciesList policiesDetails={policies} />
              </PortalCard>
              <PortalCard title={"Commodities"}>
                <Commodities
                  formData={formData}
                  policyID={formData?.id}
                  userInfo={userInfo}
                  emailValue={emailValue}
                  insuredInfo={insuredInfo}
                  setDidSuccess={setDidSuccess}
                  setInsuredInfo={setInsuredInfo}
                />
              </PortalCard>
              <PortalCard title={"Endorsements"}>
                <EndoSearch
                  userInfo={userInfo}
                  onlyForOneInsured={true}
                  insuredID={insuredID}
                  insuredInfo={insuredInfo}
                />
              </PortalCard>
              {/* TODO: Uncomment this once we have fixed system log to include insuredID */}
              {/* <PortalCard title={"History"}> 
                <History insuredID={insuredID} searchByInsured={true} />
              </PortalCard> */}
            </>
          )}
        </>
      }
    </PortalPanel>
  );
}
