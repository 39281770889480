import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import calculateAPDMTCPremiums from "../../../utils/calculateAPDMTCPremiums";
import calculatePremiums from "../../../utils/calculatePremiums";
import updatePolicyField from "../../../utils/updatePolicyField";
import findTotalAdjustments from "./findTotalAdjustments";

const applyAdjustments = async (
  e,
  quoteData,
  policyID,
  formData,
  taxSchedule,
  formDataGL
) => {
  e.preventDefault();
  const totalAdjustment = findTotalAdjustments(quoteData);

  const getPowerUnitCount = async () => {
    const insuredID = formData.insuredID;
    const agencyID = formData.agencyID;
    const truckCollectionRef = collection(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      "power-units"
    );
    const q = query(
      truckCollectionRef,
      where("policies", "array-contains", policyID)
    );
    const truckSnapshot = await getDocs(q);
    const truckList = [];
    let powerUnitCountAPD;
    let powerUnitCountMTC;
    truckSnapshot.forEach((doc) => {
      truckList.push({ ...doc.data(), id: doc.id });
    });
    const powerUnitCount = truckList.filter((truck) => {
      const policiesDetails = truck.policiesDetails;
      const matchingPolicies =
        policiesDetails.filter(
          (policiesDetails) =>
            policiesDetails.id === policyID &&
            policiesDetails.status === "Active"
        ).length > 0;
        if (matchingPolicies) {
          if (truck.hasAPDCoverage) {
            powerUnitCountAPD++;
          } else if (truck.hasMTCCoverage) {
            powerUnitCountMTC++;
          }
        }
      return matchingPolicies;
    }).length;
    return [powerUnitCount, powerUnitCountAPD, powerUnitCountMTC];
  };

  const powerUnitArray = await getPowerUnitCount();
  const powerUnitCount = powerUnitArray[0];
  const powerUnitCountWithAPD = powerUnitArray[1];
  const powerUnitCountWithMTC = powerUnitArray[2];

  await calculatePremiums(
    policyID,
    formData,
    e,
    quoteData.id,
    totalAdjustment,
    false,
    false,
    null,
    formDataGL
  );

  await calculateAPDMTCPremiums(
    policyID,
    formData,
    quoteData.apdDetails,
    quoteData.mtcDetails,
    true,
    powerUnitCountWithAPD,
    powerUnitCountWithMTC
  );

  document.getElementById("premiums").scrollIntoView({
    behavior: "smooth",
  });

  quoteData.adjustmentFormData.deductible &&
    (await updatePolicyField(
      policyID,
      "biLiabilityDeductible",
      quoteData.adjustmentFormData.deductible
    ));
};

export default applyAdjustments;
