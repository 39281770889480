import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { getPolicyGLData } from "../premiumCalculation/getPolicyData";
import rateAllUnits from "./rateAllUnits";
import updateTrucks from "./updateTrucks";
import { buildDocumentPath } from "../../PolicyLifecycleManager/Drivers/multipleToEndorsements";
import { individualToEndorsement } from "../../PolicyLifecycleManager/Drivers/individualToEndorsement.";
import cleanTruckListFunction from "./cleanTruckListFunction";
import getTotals from "./getTotals";

const ratePowerUnitGeneral = async (
  e,
  truckList,
  policyID,
  singleUnit,
  endoID,
  setSuccess,
  endoType,
  insuredInfo,
  userInfo
) => {
  e.preventDefault();
  const policyRef = doc(db, "policy-applications", policyID);
  const policySnap = await getDoc(policyRef);
  const formDataGL = await getPolicyGLData({
    ...policySnap.data(),
    id: policySnap.id,
  });
  const formData = {
    ...policySnap.data(),
    id: policySnap.id,
    hasGLCoverage: formDataGL?.hasGLCoverage || false,
    glPolicyNo: formDataGL?.glPolicyNo || "",
    glPremiums: formDataGL?.glPremiums || null,
  };
  const trucks = cleanTruckListFunction(truckList);
  const isApplication = formData?.documentType === "Application";
  const isEndorsement = Boolean(
    typeof formData === "undefined" || formData?.documentType === "Policy"
  );
  let isAddPowerUnitsEndorsement = false;
  let isRemovePowerUnitsEndorsement = false;
  if (isEndorsement) {
    isAddPowerUnitsEndorsement = endoType === "Add Power Units" || endoType === "Adjust Radius";
    isRemovePowerUnitsEndorsement = endoType === "Remove Power Units";
  }
  let updatedTruckList = [];
  try {
    if (isApplication) {
      await updateTrucks(trucks, formData);
    } else if (isEndorsement) {
      updatedTruckList = await rateAllUnits(
        e,
        policyID,
        formData,
        trucks,
        formData?.alPremiums?.adjustment || 0,
        isEndorsement,
        isRemovePowerUnitsEndorsement, // Set doNotRerate if it is a remove because we do want to rate added units
        endoID,
        isEndorsement,
        insuredInfo
      );
    }
    if (
      typeof formData === "undefined" ||
      formData?.documentType === "Policy"
    ) {
      if (endoID) {
        const finalTruckList = cleanTruckListFunction(updatedTruckList[1]);
        if (!singleUnit) {
          const totals = getTotals(updatedTruckList[1], policyID);
          // The payload varies depending on the endorsement type.
          // A payload for a remove power units will not update the trucks data, only the total premiums.
          let payload = {
            alPremiums: {
              GWP: totals.GWP,
              adjustment: formData?.alPremiums?.adjustment || 0,
              debitOrCredit: formData?.alPremiums?.debitOrCredit,
              otherFees: totals.otherFees,
              processingFeeAmount: totals.processingFeeAmount,
              stampTaxAmount: totals.stampTaxAmount,
              surplusLinesTaxAmount: totals.surplusLinesTaxAmount,
              totalPremium: totals.totalPremium,
            },
          };
          if (isAddPowerUnitsEndorsement) {
            // Add the trucks since it is an add power units endorsement
            payload = {
              ...payload,
              data: finalTruckList.map((truck) => {
                const paths = buildDocumentPath(
                  "Adjust Radius", // TODO: I find this very confusing since it seems to be used for all power unit endorsements but uses the adjust radius tag specifically
                  truck.id,
                  formData
                );
                return paths;
              }),
            };
          }
          updateDoc(
            doc(db, "policy-applications", policyID, "endorsements", endoID),
            payload
          );
        }
      } else {
        await individualToEndorsement(
          policyID,
          trucks?.[0]?.effectiveDate,
          "Adjust Radius",
          trucks[0],
          insuredInfo,
          formData,
          userInfo,
          null,
          null,
          null
        );
      }
    }
    if (setSuccess) setSuccess(true);
  } catch (error) {
    throw error;
  }
};

export default ratePowerUnitGeneral;
