import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../../../firebase";
import updateSystemLog from "../../../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../../../utils/notifications/endoStatusChangeNotification";

const declineEndorsement = async (
  e,
  _,
  reasons,
  endorsement,
  isRequestException,
  paramPolicyID,
  paramRefID,
  endorsementData,
  setPolicyInfo,
  setPolicyDetailUpdate,
  insuredInfo,
  setSuccess,
  subcollection
) => {
  if (e) {
    e.preventDefault();
  }
  const powerUnitRef = doc(
    db,
    "policy-applications",
    paramPolicyID,
    "endorsements",
    paramRefID
  );

  const payload = {
    status: "Declined",
    wasARequestException: false,
    declinedDate: new Date(),
  };
  if (isRequestException) {
    payload["wasARequestException"] = true;
  }
  if (reasons) {
    payload["rejectionReasons"] = reasons;
  }

  await updateDoc(powerUnitRef, payload);

  const policyRef = doc(db, "policy-applications", paramPolicyID);
  const policyDoc = await getDoc(policyRef);
  const policyData = {
    ...policyDoc.data(),
    id: policyDoc.id,
  }

  const promises = endorsementData?.map(async (subcollectionItem) => {
    const { agencyID, insuredID } = endorsement;
    const subcollectionRef = doc(
      db,
      "agencies",
      agencyID,
      "insureds",
      insuredID,
      subcollection,
      subcollectionItem?.id
    );

    const subCollectionPayload = setPolicyInfo(
      {
        ...subcollectionItem,
        dateCreated: new Date(),
      },
      {
        policyID: paramPolicyID,
        status: "Active",
      },
      "endo-addition"
    );

    await setDoc(subcollectionRef, subCollectionPayload, { merge: true });

    updateSystemLog(
      auth,
      paramPolicyID,
      "Remove Endorsement (" +
        subCollectionPayload.truckVIN +
        ") Rejected For " +
        insuredInfo.company +
        " by " +
        auth.currentUser.displayName,
      "Remove Endo Rejected",
      subCollectionPayload,
      true
    );

    const renewalApplicationID = policyData?.renewalApplicationID;

    if (renewalApplicationID) {
      const mirroredSubcollectionRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        subcollection,
        subcollectionItem?.id
      );

      const mirroredPayload = setPolicyInfo(
        {
          ...subCollectionPayload,
          dateCreated: new Date(),
        },
        {
          policyID: renewalApplicationID,
          status: "Active",
        },
        "endo-addition"
      );      

      await setDoc(mirroredSubcollectionRef, mirroredPayload, {
        merge: true,
      });

      updateSystemLog(
        auth,
        renewalApplicationID,
        "Remove Endorsement (" +
          mirroredPayload.truckVIN +
          ") Rejected For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Remove Endo Rejected",
        mirroredPayload,
        true
      );
    }
  });
  await Promise.all(promises);

  endoStatusChangeNotification(
    endorsement.type,
    endorsement.endorsementNumber,
    "Declined",
    paramRefID,
    paramPolicyID,
    endorsement.agencyID
  );
  setSuccess(true);
};

export default declineEndorsement;
