import {
  CREATE_COMPANY,
  GET_LOSS_RUNS,
  GET_USER,
  GET_USERS,
  RESET_STATE,
  SET_COMPANY,
  SET_LANGUAGE,
  SET_PERSISTENCE,
  SET_THEME,
  SET_TOAST,
  SET_USER,
  SET_ENDORSEMENTS,
  SET_WAIVERS_OF_SUBROGATION,
  SET_POLICIES_PDF,
  SET_CALENDAR_TOKEN,
  SET_CALENDAR_SELECTED_DATA,
  SET_FORM_DATA,
  SET_FORM_DATA_GL,
  SET_PAGINATIONS,
  SET_MANAGING_ALLOW_APPROVAL_RED_FLAG,
  SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG,
  SET_CLIENT_ROUTE,
  SET_FORM_DATA_MTC,
  SET_FORM_DATA_XS,
  SET_FORM_DATA_APD,
  SET_QUOTE_SELECTED,
  SET_FORM_DATA_CMTC
} from "./types";

const handlePersistence = (stateToStringify) => {
  localStorage.setItem(
    "state",
    JSON.stringify(stateToStringify)
  );
};

const AppReducer = (state, action) => {
  switch (action.type) {
    case RESET_STATE:
      return action.payload;
    case SET_PERSISTENCE:
      return action.payload;
    case SET_THEME:
      const themePayload = {
        ...state,
        theme: action.payload,
      };
      handlePersistence(themePayload);
      return themePayload;
    case SET_LANGUAGE:
      const langPayload = {
        ...state,
        language: action.payload,
      };
      handlePersistence(langPayload);
      return langPayload;
    case GET_USERS:
      const getUsersPayload = {
        ...state,
        users: action.payload,
      };
      handlePersistence(getUsersPayload);
      return getUsersPayload;
    case GET_USER:
      const getUserPayload = {
        ...state,
        user: action.payload,
      };
      handlePersistence(getUserPayload);
      return getUserPayload;
    case SET_USER:
      const setUserPayload = {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        }
      };
      handlePersistence(setUserPayload);
      return setUserPayload;
    case CREATE_COMPANY:
      const createCompanyPayload = {
        ...state,
        company: action.payload
      };
      handlePersistence(createCompanyPayload);
      return createCompanyPayload;
    case SET_COMPANY:
      const setCompanyPayload = {
        ...state,
        company: {
          ...state.user,
          ...action.payload,
        }
      };
      handlePersistence(setCompanyPayload);
      return setCompanyPayload;
    case GET_LOSS_RUNS:
      const getLossRunsPayload = {
        ...state,
        lossRuns: action.payload
      };
      handlePersistence(getLossRunsPayload);
      return getLossRunsPayload;
    case SET_TOAST:
      const toastPayload = {
        ...state,
        toast: action.payload
      };
      handlePersistence(toastPayload);
      return toastPayload;
    case SET_ENDORSEMENTS:
      const endoPayload = {
        ...state,
        endorsements: action.payload
      };
      handlePersistence(endoPayload);
      return endoPayload;
    case SET_WAIVERS_OF_SUBROGATION:
      const waiversPayload = {
        ...state,
        waiversOfSubrogation: action.payload
      };
      handlePersistence(waiversPayload);
      return waiversPayload;
    case SET_POLICIES_PDF:
      const policiesPdf = {
        ...state,
        policiesPdf: {
          ...state.policiesPdf,
          ...action.payload
        }
      };
      handlePersistence(policiesPdf);
      return policiesPdf;
    case SET_CALENDAR_TOKEN:
      const calendarToken = {
        ...state,
        calendar: {
          ...state.calendar,
          token: action.payload
        }
      };
      handlePersistence(calendarToken);
      return calendarToken;
    case SET_CALENDAR_SELECTED_DATA:
      const calendarData = {
        ...state,
        calendar: {
          ...state.calendar,
          selectedData: action.payload
        }
      };
      handlePersistence(calendarData);
      return calendarData;
    case SET_FORM_DATA:
      const formDataPayload = {
        ...state,
        formData: action.payload
      };
      handlePersistence(formDataPayload);
      return formDataPayload;
    case SET_FORM_DATA_GL:
      const formDataGLPayload = {
        ...state,
        formDataGL: action.payload
      };
      handlePersistence(formDataGLPayload);
      return formDataGLPayload;
    case SET_FORM_DATA_MTC:
      const formDataMTCPayload = {
        ...state,
        formDataMTC: action.payload
      };
      handlePersistence(formDataMTCPayload);
      return formDataMTCPayload;
    case SET_FORM_DATA_XS:
      const formDataXSPayload = {
        ...state,
        formDataXS: action.payload
      };
      handlePersistence(formDataXSPayload);
      return formDataXSPayload;
    case SET_FORM_DATA_APD:
      const formDataAPDPayload = {
        ...state,
        formDataAPD: action.payload
      };
      handlePersistence(formDataAPDPayload);
      return formDataAPDPayload;
    case SET_FORM_DATA_CMTC:
      const formDataCMTCPayload = {
        ...state,
        formDataCMTC: action.payload
      };
      handlePersistence(formDataCMTCPayload);
      return formDataCMTCPayload;
    case SET_QUOTE_SELECTED:
      const formDataQuotePayload = {
        ...state,
        quoteSelected: action.payload
      };
      handlePersistence(formDataQuotePayload);
      return formDataQuotePayload;
    case SET_PAGINATIONS:
      const paginationsPayload = {
        ...state,
        paginations: action.payload
      };
      handlePersistence(paginationsPayload);
      return paginationsPayload;
    case SET_MANAGING_ALLOW_APPROVAL_RED_FLAG:
      const managingAllowApprovalRedFlags = {
        ...state,
        managing: {
          ...state?.managing,
          redFlag: {
            ...state?.managing?.redFlag,
            allowApprovalRedFlags: Boolean(action?.payload)
          }
        }
      };
      handlePersistence(managingAllowApprovalRedFlags);
      return managingAllowApprovalRedFlags;
    case SET_CHANGE_MANAGING_ALLOW_APPROVAL_RED_FLAG:
      const managingChangeAllowApprovalRedFlags = {
        ...state,
        managing: {
          ...state?.managing,
          redFlag: {
            ...state?.managing?.redFlag,
            allowApprovalRedFlags: !Boolean(
              state?.managing?.redFlag?.allowApprovalRedFlags
            )
          }
        }
      };
      handlePersistence(managingChangeAllowApprovalRedFlags);
      return managingChangeAllowApprovalRedFlags;
    case SET_CLIENT_ROUTE:
      const clientRoutePayload = {
        ...state,
        client: {
          ...state?.client,
          clientRoute: action.payload
        }
      };
      handlePersistence(clientRoutePayload);
      return clientRoutePayload;
    default:
      return state;
  }
};

export default AppReducer;
