import React from "react";
import phoneNumber from "../../utils/phoneNumber";
import useGeneralState from "../../../Context/actions/useGeneralState";
import EditIcon from "@mui/icons-material/Edit";
import useTranslations from "../../../Context/actions/useTranslations";
import useUsersState from "../../../Context/actions/useUsersState";

export default function InsuredInfo({
  insuredInfo,
  setDisplayCompanyDetails,
  showJustEdit = false,
}) {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations();
  const { user: userInfo } = useUsersState();
  const handleCopyToClipboard = (e) => {
    e?.preventDefault();
    navigator.clipboard.writeText(insuredInfo.dot);
    controlToast(true, "The DOT was copied to clipboard", "success");
  };

  if (showJustEdit) {
    return (
      <div className="input-container mb-2">
        <button
          onClick={(e) => {
            setDisplayCompanyDetails(true);
            // TODO: Fix this behavior
            // scroll 'insuredProfile' element into view
            // document
            //   .getElementById("insuredProfile")
            //   .scrollIntoView({ behavior: "smooth" });
          }}
          className="text-[#0c4a6e] hover:text-[#072a48] d-block text-left flex items-center"
        >
          <EditIcon sx={{ marginRight: "8px" }} />
          {t("plm.coverage-side-panel.edit-insured-details")}
        </button>
      </div>
    );
  }

  return (
    <div className="input-container mb-2">
      {/* Insured Name */}
      <p className="text-2xl font-bold" title={insuredInfo.id}>
        {insuredInfo.company}
      </p>

      <p className="">
        {insuredInfo.address}, {insuredInfo.city}, {insuredInfo.state}{" "}
        {insuredInfo.zip}
      </p>
      <a
        href={`tel:${insuredInfo.phone}`}
        className="text-[#0c4a6e] hover:text-[#072a48] d-block"
      >
        {phoneNumber(insuredInfo.phone)}
      </a>
      <div style={{ display: "flex", alignItems: "center" }}>
        <label className="text-capitalize mr-1">DOT: {insuredInfo.dot}</label>
        <button
          type="button"
          onClick={handleCopyToClipboard}
          className="cursor-pointer mt-[-4px] text-[#0c4a6e] hover:text-[#072a48]"
          disabled={false}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-copy d-inline"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z"
            />
          </svg>
        </button>
      </div>

      <>
        {!insuredInfo.primaryContact ? (
          <div>{t("plm.coverage-side-panel.add-primary-contact")}.</div>
        ) : (
          <a href="#" className="d-block text-[#0c4a6e]">
            {insuredInfo.primaryContact}
          </a>
        )}
        {!insuredInfo.email ? (
          <div>{t("plm.coverage-side-panel.add-primary-email")}.</div>
        ) : (
          <a href="#" className="d-block text-[#0c4a6e]">
            {insuredInfo.email}
          </a>
        )}
        {(userInfo.insuredPermissions || userInfo.role === "Admin") && (
          <button
            onClick={(e) => {
              setDisplayCompanyDetails(true);
              // TODO: Fix this behavior
              // scroll 'insuredProfile' element into view
              // document
              //   .getElementById("insuredProfile")
              //   .scrollIntoView({ behavior: "smooth" });
            }}
            className="text-[#0c4a6e] hover:text-[#072a48] d-block text-left flex items-center"
          >
            <EditIcon sx={{ marginRight: "8px" }} />
            {/* {t("Edit Insured's Details")} */}
            {t("plm.coverage-side-panel.edit-insured-details")}
          </button>
        )}
      </>
    </div>
  );
}
