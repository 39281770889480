import React from "react";
import { Tbody, Td, Thead, Tr, Table } from "react-super-responsive-table";
import { Col, Row } from "react-bootstrap";
import { TextField, useMediaQuery } from "@mui/material";
import { Box, Typography, Divider } from "@mui/material";
import numberWithCommas from "../../../../utils/numberWithCommas";
import useTranslations from "../../../../../Context/actions/useTranslations";

export default function APDPremiums({ formData, formDataAPD }) {
  const data = formDataAPD;
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  // total premium but with parseFloat values
  const totalPremium =
    parseFloat(data?.apdPremiums?.totalPremiumBeforeTaxes || 0) +
    parseFloat(data?.apdPremiums?.otherFees || 0) +
    parseFloat(data?.apdPremiums?.stampTaxAmount || 0) +
    parseFloat(data?.apdPremiums?.surplusLinesTaxAmount || 0) +
    parseFloat(data?.apdPremiums?.policyFeeAmount || 0);

  const { t } = useTranslations();

  return (
    <Row className="mb-3">
      {/* Premiums */}
      <Col sm={12}>
        <section>
          <h1 className="header-text" id="premiums">
            {t("plm.quotes.quotes-table.apd-premiums")}
          </h1>
          <article className="mt-2">
            {data.rerateRequired && data.documentType !== "Policy" ? (
              <p>
                {t("Please rerate the policy to view premium information.")}
              </p>
            ) : (
              <section>
                {!isMobileScreen && (
                  <Table>
                    <Thead>
                      <Tr>
                        <Td className="bg-[#072a48] text-white rounded-tl-md">
                          {t("plm.quotes.quotes-table.item")}
                        </Td>
                        <Td className="bg-[#072a48] text-white rounded-tr-md text-right mr-3">
                          {t("plm.quotes.quotes-table.amount")}:
                        </Td>
                      </Tr>
                    </Thead>
                    {data?.apdPremiums?.totalPremiumBeforeTaxes &&
                    data?.apdPremiums?.totalPremiumBeforeTaxes !== 0 ? (
                      <Tbody>
                        <Tr>
                          <Td>{t("plm.quotes.quotes-table.gwp")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (
                                data?.apdPremiums?.totalPremiumBeforeTaxes || 0
                              ).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>
                            {t("plm.quotes.quotes-table.surplus-lines-tax")}
                          </Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (
                                data?.apdPremiums?.surplusLinesTaxAmount || 0
                              ).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("plm.quotes.quotes-table.stamp-fee")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (data?.apdPremiums?.stampTaxAmount || 0).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("plm.quotes.quotes-table.policy-fee")}</Td>
                          <Td className="text-right mr-3">
                            $
                            {numberWithCommas(
                              (data?.apdPremiums?.policyFeeAmount || 0).toFixed(2)
                            )}
                          </Td>
                        </Tr>
                        <Tr>
                          <Td>{t("plm.quotes.quotes-table.total-premium")}</Td>
                          <Td className="text-right mr-3">
                            ${numberWithCommas((totalPremium || 0).toFixed(2))}
                          </Td>
                        </Tr>
                      </Tbody>
                    ) : (
                      <Tbody>
                        <Tr>
                          <Td colSpan={2}>
                            {t("plm.quotes.quotes-table.apd-underwriter")}
                          </Td>
                        </Tr>
                      </Tbody>
                    )}
                  </Table>
                )}
                {isMobileScreen && (
                  <Box>
                    {data?.apdPremiums?.totalPremiumBeforeTaxes &&
                    data?.apdPremiums?.totalPremiumBeforeTaxes !== 0 ? (
                      <>
                        <Divider
                          style={{
                            backgroundColor: "#000",
                            marginBottom: "15px",
                            marginTop: "10px",
                          }}
                        />
                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={t("plm.quotes.quotes-table.gwp")}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (
                                data?.apdPremiums?.totalPremiumBeforeTaxes || 0
                              ).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={"plm.quotes.quotes-table.stamp-fee"}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (data?.apdPremiums?.stampTaxAmount || 0).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box mb={2}>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={"plm.quotes.quotes-table.surplus-lines-tax"}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (
                                data?.apdPremiums?.surplusLinesTaxAmount || 0
                              ).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>

                        <Box>
                          <TextField
                            InputLabelProps={{ style: { fontSize: "1.2rem" } }}
                            label={"plm.quotes.quotes-table.total-premium"}
                            variant="outlined"
                            fullWidth
                            value={`$${numberWithCommas(
                              (totalPremium || 0).toFixed(2)
                            )}`}
                            InputProps={{ readOnly: true }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Typography variant="body1" align="center">
                        {t("plm.quotes.quotes-table.apd-underwriter")}
                      </Typography>
                    )}
                  </Box>
                )}
              </section>
            )}
          </article>
        </section>
      </Col>
    </Row>
  );
}
