import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { calculateAge2 } from "../Components/utils/calculateAge";
import { db } from "../firebase";
import searchByLicense from "../Components/LossControl/utils/getData/searchByLicense";
import searchByName from "../Components/LossControl/utils/getData/searchByName";
import searchByPolicy from "../Components/LossControl/utils/getData/searchByPolicy";
import performJoins from "../Components/LossControl/utils/getData/performJoins";
import mapEventualities from "../Components/LossControl/utils/getData/mapEventualities";
import mapClassifications from "../Components/LossControl/utils/getData/mapClassifications";
import aggregateDriverInfo from "../Components/LossControl/utils/listeners/aggregateDriverInfo";
import getViolationsQuery from "../Components/LossControl/utils/getData/getViolationsQuery";
import ApiService from "../services/southern-api/apiService";
import qs from "qs";
import assignFrequency from "../Components/LossControl/utils/assignFrequency";
import assignSeverity from "../Components/LossControl/utils/assignSeverity";
import assignCombined from "../Components/LossControl/utils/assignCombined";

const apiService = new ApiService("southern");

export const formatMexicanDate = (dateString, timezone) => {
  if (!dateString) return "";
  
  const [day, month, year] = dateString.split("/");
  const formattedDate = new Date(`${year}-${month}-${day}`);
  
  return formattedDate.toLocaleDateString(timezone, {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
}

export const formatAmericanDate = (dateString, timezone) => {
  if (!dateString) return "";
  
  const [month, day, year] = dateString.split("/");
  const formattedDate = new Date(`${year}-${month}-${day}`);
  
  return formattedDate.toLocaleDateString(timezone, {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
}

export const translateExamType = (examType, language) => {
  if (examType === 'EXAMEN PSICOFISICO INTEGRAL') {
    return language === 'en' ?
    'COMPREHENSIVE PSYCHOPHYSICAL EXAMINATION' :
    'EXAMEN PSICOFISICO INTEGRAL'
  }
  return ''
}

export const translateFit = (fit, language) => {
  if (fit === 'SI') {
    return language === 'en' ? 'YES' : 'SI'
  } else if (fit === 'NO') {
    return language === 'en' ? 'NO' : 'NO'
  }
  return ''
}

export const translateDetermination = (determination, language) => {
  if (determination === 'El operador cumple con los requisitos para poder conducir') {
    return language === 'en' ? 'The operator meets the requirements to drive' : 'El operador cumple con los requisitos para poder conducir'
  }
}

export const translateObservations = (observations, language) => {
  if (observations === 'ESTA LICENCIA ES VÁLIDA EN EUA Y CANADA') {
    return language === 'en' ? 'THIS LICENSE IS VALID IN USA AND CANADA' : 'ESTA LICENCIA ES VÁLIDA EN EUA Y CANADA'
  }
  return ''
}

export const translateStatus = (status, language) => {
  if (status === 'Complete') {
    return language === 'en' ? 'Complete' : 'Completo'
  } else if (status === 'Incomplete') {
    return language === 'en' ? 'Incomplete' : 'Incompleto'
  }
  return ''
}

export const formatDOB = (dob) => {
  if (!dob) return "-";
  let birthDate;
  if (dob.seconds !== undefined && dob.nanoseconds !== undefined) {
    birthDate = new Date(dob.seconds * 1000);
  } 
  else if (dob instanceof Date) {
    birthDate = dob;
  } 
  else {
    birthDate = new Date(dob);
  }
  if (isNaN(birthDate)) return "-";
  return birthDate.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

// TAMP409513

export const driversPayload = async (driver, formData) => {
  const {
    mxNMP,
    licenseNumber
  } = driver
  // to test basic information, score, risk factor, sct
  // const licenseNumber = "9396361"
  // to test crashes, training, policy, inspections
  // const licenseNumber = "CHIH115224"
  // to test trainings
  // const licenseNumber = "COAH101343"
  // to test inspections
  // const licenseNumber = "COAH110592"
  // const mxNMP = 96656
  if (!licenseNumber && !mxNMP) return;
  try {
    const driversMap = await searchByLicense(licenseNumber);
    const crashesQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "crashes"),
        where("drvLicNbr", "==", licenseNumber)
      )
    );
    const crashesArray = crashesQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const inspectionsQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "inspections"),
        where("drvLicNbr", "==", licenseNumber),
      )
    );

    const inspectionsToCountViolationsByCode = inspectionsQuerySnapshot.docs.map((doc) => {
      return doc?.data()?.code
    }) || []
    const violationCount = inspectionsToCountViolationsByCode.filter((code) => {
      return Boolean(code) && code !== "Crash" && code !== "Clean"
    })?.length
    const crashCount = inspectionsToCountViolationsByCode.filter((code) => { 
      return code === "Crash"
    })?.length

    const crashes = {
      crashes: crashCount,
      fatalities: crashesArray?.[0]?.fatalities || 0,
      injuries: crashesArray?.[0]?.injuries || 0,
    }

    const inspectionsArray = inspectionsQuerySnapshot.docs.map((doc) => {
      const code = doc.data()?.code
      const thereIsCodeToLinkAViolation = Boolean(code) && code !== "Crash" && code !== "Clean"
      return {
        id: doc.id,
        ...doc.data(),
        reportNo: doc.data()?.number,
        eventuality: !thereIsCodeToLinkAViolation ? 'Revision Clean/Clean' : doc.data()?.bASIC,
        expeditionDate: doc.data()?.inspectionDate?.toDate().toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
          year: "numeric",
        }),
        eventualityGroup: !thereIsCodeToLinkAViolation ? 'N/A' : doc.data()?.violationGroupDescription,
        status: doc.data()?.outOfService === "No" ? "Active" : "Inactive",
        description: !thereIsCodeToLinkAViolation ? 'Revision Without Eventualities' : doc.data()?.description
      }
    });

    const violationsQuerySnapshot = await getDocs(
      query(
        collection(db, "loss-control", "driverData", "violations"),
        where("LicenseNumber", "==", licenseNumber)
      )
    );
    const violationsArray = violationsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    const eventualitiesMap = await mapEventualities();
    const classificationsMap = await mapClassifications();

    const extraViolationsQuerySnapshot = await getViolationsQuery(
      "license",
      licenseNumber
    );

    const fullyJoinedViolations = await performJoins(
      extraViolationsQuerySnapshot,
      eventualitiesMap,
      classificationsMap,
      false,
      violationsArray
    );

    const aggregatedData = await aggregateDriverInfo(
      fullyJoinedViolations || [],
      driversMap,
      crashesArray,
      () => {},
      inspectionsArray
    );

    const aggregatedDataWithCrashes = aggregatedData.map((driverData) => ({
      ...driverData,
      crashes: crashesArray.filter(
        (crash) => crash.drvLicNbr === driverData.LicenseNumber
      ),
    }));

    let responseData = null
    if (mxNMP) {
      try {
        const results = await apiService.updateSCT({
          driverLicenseNumber: licenseNumber,
          driverSCTNumber: mxNMP,
        });
        responseData = qs.parse(results);
      } catch (error) {
        console.error("Error fetching SCT data:", error);
      }
    }

    function getCourseDuration(course) {
      if (!course.assignedOnDate || !course.completionDate) return '-'
      const assignedDate = new Date(course.assignedOnDate.seconds * 1000);
      const completionDate = new Date(course.completionDate.seconds * 1000);
      const durationInMilliseconds = completionDate - assignedDate;
      const durationInDays = durationInMilliseconds / (1000 * 60 * 60 * 24);
      return Math.ceil(durationInDays);
    }
    let parsedTrainings = [];
    const trainings = [];
    try {
      const trainingsCollectionRef = collection(db, "driver-trainings");
      const q = query(
        trainingsCollectionRef,
        where("driversLicenseNumber", "==", licenseNumber)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        trainings.push({ ...doc.data(), id: doc.id });
      });
      parsedTrainings = trainings.map((training) => {
        return {
          training: training?.courseTitle,
          institution: training?.trainingProvider,
          completionDate: training?.completionDate?.toDate ? training?.completionDate?.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          }) : '',
          trainingDuration: getCourseDuration(training) ,
          description: training?.notes || '-',
        }
      });
    } catch (error) {
      console.error("Error fetching trainings:", error);
    }
    
    const driverGroup = await getDocs(
      query(
        collectionGroup(db, "drivers"),
        where("licenseNumber", "==", licenseNumber),
      )
    );
    const drivers = driverGroup.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    let policiesIDs = [];
    for (const driver of drivers) {
      for (const policyDetail of driver.policiesDetails) {
        if (policyDetail.id) {
          policiesIDs.push(policyDetail.id);
        }
      }
    }
    policiesIDs = [...new Set(policiesIDs)];

    const policiesItems = []

    for (const policyID of policiesIDs) {
      const policyDoc = await getDoc(doc(db, "policy-applications", policyID));
      policiesItems.push({
        id: policyDoc.id,
        ...policyDoc.data(),
      });
    }

    let policies = [];
    for (const policy of policiesItems || []) {
      try {
        if (policy?.alPolicyNo) {
          const insuredRef = doc(
            db,
            "agencies",
            policy.agencyID,
            "insureds",
            policy.insuredID
          );
          const insuredSnapshot = await getDoc(insuredRef);
          let insuredInfo = null;
          if (insuredSnapshot.exists()) {
            insuredInfo = {
              id: insuredSnapshot.id,
              ...insuredSnapshot.data(),
            };
          }
          policies.push({
            policyNbr: policy?.alPolicyNo,
            dot: insuredInfo?.dot,
            insuredName: insuredInfo?.company,
            id: policy.id,
          });
        }
      } catch (error) {
        console.error("Error fetching policies:", error);
      }
    }

    const fetchMatrixData = async (matrixType) => {
      try {
        const matrixRef = collection(db, "loss-control", "tables", matrixType);
        const snapshot = await getDocs(matrixRef);
        const matrix = [];
        snapshot.forEach((doc) => {
          matrix.push(doc.data());
        });
        return matrix;
      } catch (error) {
        console.error(`Error getting ${matrixType} matrix:`, error);
      }
    };

    let frequency = '-'
    let severity = '-'
    let combined = '-'
    try {
      const frequencyMatrix = await fetchMatrixData("frequencyMatrix");
      const severityMatrix = await fetchMatrixData("severityMatrix");
      const combinedMatrix = await fetchMatrixData("combinedMatrix");
      const parsedDriver = aggregatedDataWithCrashes?.[0]
      if (parsedDriver) {
        frequency = frequencyMatrix && frequencyMatrix?.length !== 0 ? parseFloat(assignFrequency(parsedDriver, true, frequencyMatrix)).toFixed(2) : '-'
        severity = severityMatrix && severityMatrix?.length !== 0 ? parseFloat(assignSeverity(parsedDriver, true, severityMatrix)).toFixed(2) : '-'
        combined = combinedMatrix && combinedMatrix?.length !== 0 ? parseFloat(assignCombined(parsedDriver, true, combinedMatrix)).toFixed(2) : '-'
      }
    } catch (error) {
      console.error("Error fetching matrix data:", error);
    }

    const licenseExpiration =
      driver?.licenseExpiration && driver?.licenseExpiration?.toDate ? driver?.licenseExpiration :
      driver?.licenseExpirationDate && driver?.licenseExpirationDate?.toDate ? driver?.licenseExpirationDate : null;

    const data = {
      payload: {
        profileDefinition: {
          url: driver?.driverLicenseFront ? driver?.driverLicenseFront : '',
          name: `${driver?.driverFirst} ${driver?.driverLast}`,
          dob: `${formatDOB(driver?.dob)} (Age: ${calculateAge2(driver?.dob)})`,
          licenseMX: licenseNumber,
          expiresOn: licenseExpiration
            ?.toDate()
            .toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            }),
        },
        score: {
          scorePoints: aggregatedDataWithCrashes?.[0]?.scoreTotal?.toFixed(4) || 0,
          violations: violationCount,
            // parseInt(
            //   aggregatedDataWithCrashes?.[0]?.majorViolationsCount +
            //   aggregatedDataWithCrashes?.[0]?.minorViolationsCount
            // ) || 0,
          claims: aggregatedDataWithCrashes?.[0]?.claimsCount || 0,
          // crashes: aggregatedDataWithCrashes?.[0]?.crashesCount || 0,
          crashes: crashCount,
          training: parsedTrainings.length,
        },
        riskFactor: {
          frequency,
          severity,
          combined
        },
        basicInformation: {
          licenseClass: driver?.licenseClass
            ? driver?.licenseClass
            : driver?.inspasData?.LicClass
            ? driver?.inspasData?.LicClass
            : "-",
          experience: driver?.yoe,
          curp: driver?.inspasData?.DrvCURP ? driver?.inspasData?.DrvCURP : "-",
          scoreStatus: aggregatedDataWithCrashes?.[0]?.result || "-",
          noMedicinaPreventiva:
            driver?.mxNMP && driver?.licenseCountry === "MX"
              ? driver?.mxNMP
              : "Not Applicable",
        },
        sct: {
          effectiveFrom: responseData?.issued || '-',
          effectiveUntil: responseData?.expires || '-',
          fit: responseData?.driverFit || '-',
          examType: responseData?.examType || '-'
        },
        crashes,
        training: parsedTrainings,
        policy: policies,
        inspections: inspectionsArray || [],
      },
      driverId: driver.id,
    };

    return {
      data,
      crashesArray
    };
  } catch (error) {
    console.error("Error processing driver payload:", error);
    throw error;
  }
};