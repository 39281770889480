import React, { useState, useEffect } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import Breadcrumbs from "../../assets/Breadcrumbs";
import { db } from "../../../firebase";
import NamedShippers from "./NamedShippers";
import KPBrokersInfo from "../KPBrokersInfo";
import EditDropdownModal from "./EditDropdownModal";
import Deductible from "./Deductible";
import Limits from "./Limits";
import AdditionalCoverage from "./AdditionalCoverage";
import NonStandardClauses from "./NonStandardClauses";
import useTranslations from "../../../Context/actions/useTranslations";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import camelCaseToTitleCase from "../../utils/camelCaseToTitleCase";

export default function MTC({
  policyID,
  formData,
  setFormData,
  isAdminOrUnderwriter,
  loadedPolicies,
  policies,
  formSaved,
  setFormSaved,
  userInfo,
  formDataMTC,
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalField, setModalField] = useState("");
  const [dropdownType, setDropdownType] = useState("");
  const [dropdownOptions, setDropdownOptions] = useState({});

  const { t } = useTranslations();

  // realtime listener to get dropdown options
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "dropdownData", "mtcDropdowns"),
      (doc) => {
        if (doc.exists()) {
          setDropdownOptions(doc.data());
          console.log(doc.data());
        } else {
          console.log("No dropdown options found.");
        }
      }
    );

    return () => unsubscribe();
  }, []);

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.mtc.title") },
  ];

  const disabledRule = formData.documentType !== "Application";

  const handleOpenModal = (field, type) => {
    setModalField(field);
    setDropdownType(type);
    setModalOpen(true);
  };

  const formatValue = (value) => {
    if (typeof value === "object") {
      if (value?.__time__) {
        return new Date(value.__time__).toLocaleString();
      } else if (value?.seconds) {
        return new Date(value.seconds * 1000).toLocaleString();
      }
    }
    if (typeof value === "boolean") {
      return value ? "True" : "False";
    }
    return value ?? "N/A";
  };

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex flex-col md:flex-row md:items-center md:justify-between mt-1">
        <span className="text-3xl font-semibold mb-4 md:mb-0">
          {t("plm.mtc.title")}
        </span>
      </div>

      <KPBrokersInfo
        activePolicies={policies.activeMTCPolicies}
        userInfo={userInfo}
      />

      {formData.documentType !== "Policy" && (
        <>
          {!formData?.hasMTCCoverage ? (
            <p className="mb-4">
              {t("plm.mtc.description1")}{" "}
              {t(`plm.status.${formData.documentType}`)}{" "}
              {t("plm.mtc.description2")}
            </p>
          ) : (
            <fieldset disabled={disabledRule}>
              <Deductible
                formData={formData}
                setFormData={setFormData}
                dropdownOptions={dropdownOptions}
                policyID={policyID}
                disabledRule={disabledRule}
                isAdminOrUnderwriter={isAdminOrUnderwriter}
                handleOpenModal={handleOpenModal}
              />

              <Limits
                formData={formData}
                setFormData={setFormData}
                dropdownOptions={dropdownOptions}
                policyID={policyID}
                disabledRule={disabledRule}
                isAdminOrUnderwriter={isAdminOrUnderwriter}
                handleOpenModal={handleOpenModal}
              />

              <AdditionalCoverage
                formData={formData}
                setFormData={setFormData}
                dropdownOptions={dropdownOptions}
                policyID={policyID}
                disabledRule={disabledRule}
                isAdminOrUnderwriter={isAdminOrUnderwriter}
                handleOpenModal={handleOpenModal}
              />

              {!formData.hasAPDCoverage && (
                <NonStandardClauses
                  formData={formData}
                  setFormData={setFormData}
                  dropdownOptions={dropdownOptions}
                  policyID={policyID}
                  disabledRule={disabledRule}
                />
              )}
              <div className="mt-3">
                <NamedShippers
                  formData={formData}
                  setFormData={setFormData}
                  policyID={policyID}
                  formSaved={formSaved}
                  setFormSaved={setFormSaved}
                />
              </div>
            </fieldset>
          )}
        </>
      )}

      {loadedPolicies &&
        formData.mtcPolicyID &&
        !(policies.activeMTCPolicies.length > 0) && (
          <>
            <Typography variant="h5" component="h2" className="mt-4">
              {/* TODO: Translate this */}
              Broker Details Not Available
            </Typography>
            <Typography className="mt-2 mb-3">
              This policy has an MTC Policy ID but the broker details could not
              be retrieved. Displaying available details:
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {Object.entries(formDataMTC).map(([key, value]) => (
                    <React.Fragment key={key}>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          {camelCaseToTitleCase(key)}
                        </TableCell>
                        <TableCell>{formatValue(value)}</TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

      <EditDropdownModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        field={modalField}
        dropdownType={dropdownType}
      />
    </div>
  );
}
