import React from "react";
import ActionButton from "./ActionButton";
import usePoliciesPdfState from "../../../Context/actions/usePoliciesPdfState";
import renewPolicy from "../../utils/renewPolicy";
import excludeEmptyStrings from "../../utils/excludeEmptyStrings";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import GavelIcon from "@mui/icons-material/Gavel";
import ReplyIcon from "@mui/icons-material/Reply";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PrintIcon from "@mui/icons-material/Print";
import bindPolicy from "../../utils/bindPolicy";
import requestQuote from "../../utils/requestQuote";
import { UserAuth } from "../../../Context/AuthContent";
import useUsersState from "../../../Context/actions/useUsersState";
import useFormDataState from "../../../Context/actions/useFormData";
import useTranslations from "../../../Context/actions/useTranslations";

const BottomNavbar = ({
  isAdminOrUnderwriter,
  isQuoteRequest,
  isApplication,
  isPolicy,
  isSubmitted,
  isInCancellation,
  isMissingAtLeastOneCoverage,
  formData,
  insuredInfo,
  driverList,
  truckList,
  trailerList,
  returnToAgent,
  setDisplayEndorsementManager,
  isBindOrder,
  printPolicy,
  printPolicyProgress,
  policyID,
  setDisplayUnderwritingPanel,
  openBindPolicyModal,
  handleOpenBindPolicyModal,
  handleCloseBindPolicyModal,
  openRequestBindModal,
  handleCloseRequestBindModal,
  handleOpenRequestSignaturesModal,
  handleOpenRequestBindModal,
  handleOpenPrintOptionsModal,
  handleOpenSendQuoteModal,
  powerUnitCount,
  driverCount,
  trailerCount,
  userInfo,
  nextAL,
  nextGL,
  insuredID,
  eligibleForRenewal,
  isSubmittedOrFulfilled,
  isAgentOrSubUser,
}) => {
  const { percentage } = printPolicyProgress;
  const { policiesPdf } = usePoliciesPdfState();
  const { loading, url } = policiesPdf || {
    loading: false,
    url: "",
    error: false,
    cancelRequest: false,
  };
  const { user: userAuth } = UserAuth();
  const { user: userState } = useUsersState();

  const hasProposalOutForSigning = formData?.signedProposalData?.envelopeId;

  const { formDataGL, formDataAPD, formDataMTC } = useFormDataState();

  const isNotRenewal = formData.status !== "In-Renewal";
  const notValid =
    formData.status === "Cancelled" || formData.status === "Expired";

  const { t } = useTranslations();

  const signatureStatus = formData?.signedProposalData?.status || "";

  const hasSelectedQuote =
    formData.selectedQuote && formData?.selectedQuote !== "#";

  const signatureIsIncomplete = signatureStatus !== "complete";

  const buttonIcons = [
    isApplication ? <RequestQuoteIcon /> : "",
    isPolicy && !notValid ? <PlaylistAddCheckIcon /> : "",
    eligibleForRenewal && isPolicy && isNotRenewal ? <AutorenewIcon /> : "",
    isAdminOrUnderwriter && isSubmitted && hasSelectedQuote ? (
      <AssignmentIcon />
    ) : (
      ""
    ),
    isSubmittedOrFulfilled && signatureIsIncomplete ? <AttachEmailIcon /> : "",
    (isAgentOrSubUser || isAdminOrUnderwriter) &&
    isSubmittedOrFulfilled &&
    isQuoteRequest ? (
      <GavelIcon />
    ) : (
      ""
    ),
    isSubmittedOrFulfilled && !hasProposalOutForSigning ? <ReplyIcon /> : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter ? (
      <LibraryAddCheckIcon />
    ) : (
      ""
    ),
    isAdminOrUnderwriter && isPolicy && userInfo.canSendLetters ? <AssignmentTurnedInIcon /> : "",
    isPolicy && formData.hasALCoverage ? <PrintIcon /> : "",
  ];

  const buttonLabels = [
    isApplication ? t("plm.navbar.mobile.request-quote") : "",
    isPolicy && !notValid ? t("plm.navbar.mobile.endorsements") : "",
    eligibleForRenewal && isPolicy && isNotRenewal
      ? t("plm.navbar.mobile.renewal-options")
      : "",
    isSubmitted && hasSelectedQuote
      ? signatureStatus === "sent"
        ? t("plm.navbar.desktop.signature-requested")
        : signatureStatus === "complete"
        ? t("plm.navbar.desktop.signature-complete")
        : t("plm.navbar.desktop.request-signatures")
      : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled && signatureIsIncomplete
      ? t("plm.navbar.mobile.send-quotes")
      : "",
    (isAgentOrSubUser || isAdminOrUnderwriter) &&
    isSubmittedOrFulfilled &&
    isQuoteRequest
      ? t("plm.navbar.mobile.bind-policy")
      : "",
    isSubmittedOrFulfilled && !hasProposalOutForSigning
      ? t("plm.navbar.mobile.return-to-agent")
      : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter
      ? t("plm.navbar.mobile.bind-uw")
      : "",
    isAdminOrUnderwriter && isPolicy && userInfo.canSendLetters ? t("plm.navbar.mobile.send-letters") : "",
    isPolicy && formData.hasALCoverage
      ? t("plm.navbar.mobile.print-policy")
      : "",
  ];

  const onClickFunctions = [
    isApplication
      ? (e) =>
          requestQuote(
            e,
            formData,
            policyID,
            userAuth,
            userState,
            insuredInfo,
            formDataGL,
            formDataAPD,
            formDataMTC
          )
      : "",
    isPolicy && !notValid ? () => setDisplayEndorsementManager(true) : "",
    eligibleForRenewal && isPolicy && isNotRenewal
      ? (e) =>
          renewPolicy(
            e,
            policyID,
            null,
            formData,
            userAuth,
            userState,
            insuredInfo,
            driverList,
            truckList,
            trailerList
          )
      : "",
    isSubmitted && hasSelectedQuote
      ? () => handleOpenRequestSignaturesModal()
      : "",
    isAdminOrUnderwriter && isSubmittedOrFulfilled && signatureIsIncomplete
      ? () => handleOpenSendQuoteModal()
      : "",
    (isAgentOrSubUser || isAdminOrUnderwriter) &&
    isSubmittedOrFulfilled &&
    isQuoteRequest
      ? () => handleOpenRequestBindModal()
      : "",
    isSubmittedOrFulfilled && !hasProposalOutForSigning
      ? () => returnToAgent()
      : "",
    isSubmitted && isBindOrder && isAdminOrUnderwriter
      ? () =>
          bindPolicy(
            formData,
            policyID,
            powerUnitCount,
            driverCount,
            trailerCount,
            userInfo,
            insuredInfo,
            nextAL,
            nextGL,
            insuredID,
            userAuth,
            userState,
            formDataGL,
            formDataAPD,
            formDataMTC
          )
      : "",
    isAdminOrUnderwriter && isPolicy && userInfo.canSendLetters
      ? () => setDisplayUnderwritingPanel(true)
      : "",
    isPolicy && formData.hasALCoverage
      ? (e) => handleOpenPrintOptionsModal()
      : "",
  ];

  const cleanedUpOnClickFunctions = excludeEmptyStrings(onClickFunctions);
  const cleanedUpLabels = excludeEmptyStrings(buttonLabels);
  const cleanedUpIcons = excludeEmptyStrings(buttonIcons);

  return (
    <div className="bottom-navbar">
      <div className="bottom-div">
        {cleanedUpIcons.map((icon, index) => (
          <ActionButton
            key={index}
            onClickFunction={cleanedUpOnClickFunctions[index]}
            label={cleanedUpLabels[index]}
            icon={icon}
            disabled={loading || (url && index === cleanedUpIcons.length - 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default BottomNavbar;
