import { doc, increment, runTransaction } from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import {
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../../Drivers/setPolicyInfo";
import {
  selectIfNewOrExistingSubcollection,
  validateIfExists,
} from "../../../utils/validateIfBlacklisted";
import { multipleToEndorsements } from "../../Drivers/multipleToEndorsements";

const submitAddTrailerEndorsement = async (
  e,
  policyID,
  trailerDataToVerify,
  insuredInfo,
  userInfo,
  endorsementEffectiveDate,
  formData,
  goBack,
  isEndoManager,
  getEndorsements,
  documentTypeToUse,
  renewalProcess,
  trailerList,
  necessaryValuesInsideEndorsement
) => {
  e?.preventDefault();

  const { agencyID, insuredID } = formData;

  const data = await validateIfExists(
    [trailerDataToVerify],
    agencyID,
    insuredID,
    "trailers"
  );

  const trailerData = data?.[0];

  let endoEffectiveDate;
  if (documentTypeToUse === "Policy" || renewalProcess) {
    endoEffectiveDate = endorsementEffectiveDate;
  } else {
    endoEffectiveDate = "";
  }
  if (trailerData.trailerVIN.length !== 17)
    return alert("VIN must be 17 characters.");
  if (trailerData.trailerYear.length !== 4)
    return alert("Year must be 4 characters.");
  if (trailerData.trailerACV === "0")
    return alert("Actual Cash Value must be greater than $0.00");

  const foundTrailer = trailerList.find(
    (trailer) => trailer.trailerVIN === trailerData.trailerVIN
  );

  if (
    foundTrailer?.id &&
    !renewalProcess && documentTypeToUse === "Policy" &&
    !necessaryValuesInsideEndorsement?.editUI
  ) {
    goBack(e);
    return alert(
      `Trailer with VIN ${trailerData.trailerVIN} already exists in the policy.`
    );
  } else {
    const { agencyID, insuredID } = formData;

    // ADD A TRAILER IN A REMOVE ENDORSEMENT
    let payloadResult
    if (
      necessaryValuesInsideEndorsement?.addUI &&
      necessaryValuesInsideEndorsement?.endorsement?.type.includes("Remove")
    ) {
      const policiesDetails = trailerData.policiesDetails.map((detail) => {
        if (detail.id === policyID) {
          return {
            ...detail,
            status: 'Pending Deletion'
          };
        }
        return detail;
      })
      trailerData["policiesDetails"] = policiesDetails;
      payloadResult = trailerData
    // EDIT A TRAILER IN ANY TYPE OF ENDORSEMENT
    } else if (
      necessaryValuesInsideEndorsement?.editUI &&
      necessaryValuesInsideEndorsement?.selectedItem
    ) {
      payloadResult = trailerData
    // ADD A TRAILER IN A ADD ENDORSEMENT AS NORMAL
    } else {
      payloadResult = setPolicyInfo(
        {
          ...trailerData,
          dateCreated: new Date(),
        },
        {
          ...formData,
          policyEffectiveDate: formData?.effectiveDate,
          glPolicyNo: formData?.glPolicyNo,
          alPolicyNo: formData?.alPolicyNo,
          policyID,
          status:
            !renewalProcess && documentTypeToUse === "Application"
              ? "Active"
              : "Pending",
          documentType: documentTypeToUse,
          documentSource:
            documentTypeToUse === "Policy" ? "Endorsement" : "Policy",
          policyStatus: formData?.status,
          agencyID,
          insuredID,
        },
        !renewalProcess && documentTypeToUse === "Application"
          ? "direct-addition"
          : "endo-addition",
        false,
        endoEffectiveDate
      );
    }

    if (payloadResult["bindData"]) delete payloadResult["bindData"];
    if (payloadResult["iconLeft"]) delete payloadResult["iconLeft"];
    if (payloadResult["iconRight"]) delete payloadResult["iconRight"];
    if (payloadResult["tableInfo"]) delete payloadResult["tableInfo"];
    if (payloadResult["inspasData"]) delete payloadResult["inspasData"];

    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "trailers"
    );

    if (documentTypeToUse === "Policy") {
      const type = "Add Trailers";
      const payload = {
        ...payloadResult,
        id: response.id,
      };

      const payloadForEndo = setPolicyInfoForEndorsement(payload, policyID);
      await multipleToEndorsements(
        policyID,
        endoEffectiveDate,
        type,
        [payloadForEndo],
        insuredInfo,
        formData,
        userInfo,
        getEndorsements,
        [],
        null,
        null,
        null,
        null,
        necessaryValuesInsideEndorsement
      );
      if (isEndoManager && getEndorsements) {
        await getEndorsements();
      }

      updateSystemLog(
        auth,
        policyID,
        "Add Trailer Endorsement (" +
          trailerData.trailerVIN +
          ") Submitted For " +
          insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Add Trailer Endorsement",
        trailerData
      );
    }

    updateSystemLog(
      auth,
      policyID,
      "Trailer (" +
        trailerData.trailerVIN +
        ") Added To Application For " +
        insuredInfo.company +
        " by " +
        auth.currentUser.displayName,
      "Trailer Added",
      trailerData
    );
    return;
  }
};

export default submitAddTrailerEndorsement;
