import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase"; // Adjust the path based on your Firebase setup
import useTranslations from "../../../../Context/actions/useTranslations";
import commodityData from "../../Commodities/utils/commodityData";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";
import { Delete as DeleteIcon } from "@mui/icons-material";

export default function CustomMTCLineItems({
  formData,
  userInfo,
  insuredInfo,
}) {
  const [selectedCommodities, setSelectedCommodities] = useState([]);
  const [isEditMode, setIsEditMode] = useState(true);
  const [customIsDisabled, setCustomIsDisabled] = useState(false);
  const policyID = formData.id;
  const { t } = useTranslations();

  const myCommodityData = commodityData(t);
  const standardCommoditiesWithoutValues = !insuredInfo?.standardCommodityPercentages ? [] :   Object.keys(
    insuredInfo?.standardCommodityPercentages
  ).map((key) => ({
    commodityDescription: camelCaseToTitleCase(key),
    commodityLoad: Number(insuredInfo.standardCommodityPercentages[key]),
  }));

  const nonStandardCommoditiesWithoutValues = !insuredInfo.commodityFields
    ? []
    : insuredInfo.commodityFields.map((field) => ({
        commodityDescription: field.commodityDescription,
        commodityLoad: Number(field.commodityLoad),
      }));

  const commoditiesWithoutValues = [
    ...standardCommoditiesWithoutValues,
    ...nonStandardCommoditiesWithoutValues,
  ];

  const commodities = commoditiesWithoutValues.map((commodity) => {
    const commodityData = myCommodityData.find(
      (data) => data.commodityDescription === commodity.commodityDescription
    );
    return {
      ...commodity,
      commodityMaxValue: commodityData?.value || 0,
      commodityAvgValue: "",
    };
  });

  const handleCheckboxChange = (commodity) => {
    setSelectedCommodities((prev) => {
      const exists = prev.find(
        (item) => item.commodityDescription === commodity.commodityDescription
      );

      if (exists) {
        return prev.filter(
          (item) => item.commodityDescription !== commodity.commodityDescription
        );
      }

      return [...prev, { ...commodity, deductible: "", premium: "" }];
    });
  };

  const handleFieldChange = (commodityDescription, field, value) => {
    setSelectedCommodities((prev) =>
      prev.map((item) =>
        item.commodityDescription === commodityDescription
          ? { ...item, [field]: value }
          : item
      )
    );
  };

  const saveData = async () => {
    setIsEditMode(false);
    const policyDocRef = doc(db, "policy-applications", policyID);

    try {
      await updateDoc(policyDocRef, {
        stagingArea: {
          ...formData.stagingArea,
          selectedCommodities,
        },
      });
    } catch (error) {
      console.error("Error updating policy stagingArea:", error);
    }
  };

  const enableEditMode = () => {
    setIsEditMode(true);
  };

  useEffect(() => {
    if (formData?.stagingArea?.selectedCommodities) {
      setSelectedCommodities(formData.stagingArea.selectedCommodities);
    }
  }, [formData]);

  commodities.sort((a, b) =>
    a.commodityDescription?.localeCompare(b.commodityDescription)
  );
  selectedCommodities.sort((a, b) =>
    a.commodityDescription?.localeCompare(b.commodityDescription)
  );

  const handleDelete = (commodityDescription) => {
    setSelectedCommodities((prev) =>
      prev.filter((item) => item.commodityDescription !== commodityDescription)
    );
  };

  return (
    <>
      {formData.hasMTCCoverage && (
        <div style={{ padding: "1rem" }}>
          <Typography variant="h6">Custom Commodity Deductibles</Typography>
          <Typography variant="body2">
            Select commodities and set custom deductibles for their theft or
            damage under the cargo policy.
          </Typography>
          <fieldset disabled={customIsDisabled}>
            {isEditMode && (
              <>
                {/* Checkbox List */}
                {commodities.map((commodity) => (
                  <FormControlLabel
                    key={commodity.commodityDescription}
                    control={
                      <Checkbox
                        checked={selectedCommodities.some(
                          (item) =>
                            item.commodityDescription ===
                            commodity.commodityDescription
                        )}
                        onChange={() => handleCheckboxChange(commodity)}
                      />
                    }
                    label={commodity.commodityDescription}
                  />
                ))}
              </>
            )}
            {/* Display Table if at least one commodity is selected */}

            <>
              <TableContainer component={Paper} style={{ marginTop: "1rem" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Commodity Name</TableCell>
                      <TableCell>Deductible</TableCell>
                      <TableCell>Limit</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  {!(selectedCommodities.length > 0) && (
                    <TableBody>
                      <TableRow>
                        <TableCell colSpan={3}>
                          No commodities selected, edit custom line items to add
                          some.
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                  {selectedCommodities.length > 0 && (
                    <TableBody>
                      {selectedCommodities.map((commodity) => (
                        <TableRow key={commodity.commodityDescription}>
                          <TableCell>
                            {commodity.commodityDescription}
                          </TableCell>
                          <TableCell>
                            {isEditMode ? (
                              <TextField
                                value={commodity.deductible || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    commodity.commodityDescription,
                                    "deductible",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              commodity.deductible || "N/A"
                            )}
                          </TableCell>
                          <TableCell>
                            {isEditMode ? (
                              <TextField
                                value={commodity.limit || ""}
                                onChange={(e) =>
                                  handleFieldChange(
                                    commodity.commodityDescription,
                                    "limit",
                                    e.target.value
                                  )
                                }
                              />
                            ) : (
                              commodity.limit || "N/A"
                            )}
                          </TableCell>
                          <TableCell>
                            <DeleteIcon
                              sx={{
                                color: "#8B0000",
                                cursor: "pointer",
                                marginLeft: "10px",
                              }}
                              onClick={() =>
                                handleDelete(commodity.commodityDescription)
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </>
          </fieldset>
          {/* Save/Edit Button */}
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: "1rem" }}
            onClick={(e) => {
              if (isEditMode) {
                saveData();
                setCustomIsDisabled(true);
              } else {
                setCustomIsDisabled(false);
                enableEditMode();
              }
            }}
          >
            {isEditMode ? "Save" : "Edit"} Custom Line Items
          </Button>
        </div>
      )}
    </>
  );
}
