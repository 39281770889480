import React from "react";
import readXlsxFile from "read-excel-file";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import hasDuplicates from "../../../utils/hasDuplicates";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import { isExpired } from "../../../../utils/helpers";
import endoStatusChangeNotification from "../../../utils/notifications/endoStatusChangeNotification";
import {
  filterRightPoliciesDetails,
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../../Drivers/setPolicyInfo";
import {
  selectIfNewOrExistingSubcollection,
  validateIfExists,
} from "../../../utils/validateIfBlacklisted";
import "react-datepicker/dist/react-datepicker.css";
import { getDocumentPaths } from "../../Drivers/multipleToEndorsements";

const useImportTrailers = (props) => {
  const policyID = props.policyID;
  const { controlToast } = useGeneralState();
  const { agencyID, insuredID } = props.formData;
  const formData = props.formData;
  const setSuccess = props.setSuccess
  const endoEffectiveDate = props.endoEffectiveDate
  const goBack = props.goBack
  const allFlag = props?.allFlag

  const handleUploadFile = async (
    file,
    setLoading,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess,
    excelRowData
  ) => {
    if (setLoading) setLoading(true);
    if (!file) return console.log("no file");
    const startInfoRow = 8
    let rows = excelRowData ?  excelRowData : await readXlsxFile(file, { sheet: 3 })
    const prevValues = [...rows];
    const values = prevValues.splice(startInfoRow);
    let prevDupeArray = [...rows];
    const dupeArray = prevDupeArray.splice(startInfoRow);

    try {
      const existingTrailersSnapshot = await getDocs(
        collection(db, `agencies/${agencyID}/insureds/${insuredID}/trailers`)
      );
      const trailers = existingTrailersSnapshot.docs.map((doc) => doc.data());

      let isTheSamePolicyYouAreAboutToDuplicate = false;
      trailers.forEach((trailer) => {
        trailer.policiesDetails.forEach((policyDetail) => {
          if (
            policyDetail.policyID === policyIDToUse ||
            policyDetail.id === policyIDToUse
          ) {
            isTheSamePolicyYouAreAboutToDuplicate = true;
          }
        });
      });

      const existingTrailers = existingTrailersSnapshot.docs.map(
        (doc) => doc.data().trailerVIN
      );

      function hasIntersection(existingVINs, trailersArray) {
        const vinSet = new Set(existingVINs);
        for (const trailer of trailersArray) {
          const trailerVIN = trailer[0];
          if (vinSet.has(trailerVIN)) {
            return true;
          }
        }
        return false;
      }

      const valuesToImport = values.map((value) => {
        let resultObj = {};
        value.forEach((val, valIndex) => {
          if (valIndex === 0) resultObj["trailerVIN"] = val;
        });
        return resultObj;
      });

      if (valuesToImport.length === 0) {
        const finalMessage = "Your trailers sheet is empty. Please fill in the required fields and resubmit.";
        alert(finalMessage);
        throw new Error(finalMessage);
      }

      const VINSToImport = valuesToImport.map(
        (valueToImport) => valueToImport.trailerVIN
      );
      const valuesToValidate = trailers.filter((trailer) => {
        return VINSToImport.includes(trailer.trailerVIN);
      });

      const atLeastOneIsStillActive = valuesToValidate.some((trailer) => {
        const policiesDetails = filterRightPoliciesDetails(
          trailer.policiesDetails,
          policyIDToUse
        );
        const rightDetailStatus = policiesDetails?.status;
        const rightDetailRemovedDate = policiesDetails?.removedDate;

        return (
          !isExpired(rightDetailRemovedDate) &&
          (rightDetailStatus === "Active" ||
            rightDetailStatus === "Pending" ||
            rightDetailStatus === "Pending Deletion" ||
            rightDetailStatus === "Pending Exclusion")
        );
      });

      if (
        (hasIntersection(existingTrailers, dupeArray) ||
          hasDuplicates(dupeArray, 1)) &&
        atLeastOneIsStillActive &&
        (
          (renewalProcess &&
          documentTypeToUse === "Policy") ||
          !renewalProcess
        ) &&
        isTheSamePolicyYouAreAboutToDuplicate
      ) {
        goBack();
        alert(
          "Your sheet contains VINS already listed on this policy or duplicate VIN numbers. Please fix these errors and resubmit. No trailers have been added."
        );
        throw new Error();
      } else {
        const handleBatchDriverUpload = async (myValues, isPolicy) => {
          try {
            const subcollectionIds = [];
            for (const trailer of myValues) {
              const docData = {
                trailerLookupStatus: "Incomplete",
                trailerVIN: trailer[0] || 0,
                trailerMake: trailer[1] || 0,
                trailerYear: trailer[2] || 0,
                trailerACV: trailer[3] || 0,
                dateCreated: new Date(),
                status: !renewalProcess && !isPolicy ? "Active" : "Pending",
              };

              const verifiedInExistingData = await validateIfExists(
                [docData],
                agencyID,
                insuredID,
                "trailers"
              );

              const payload = setPolicyInfo(
                verifiedInExistingData[0],
                {
                  ...props?.formData,
                  policyEffectiveDate: props.formData?.effectiveDate,
                  glPolicyNo: props.formData?.glPolicyNo,
                  alPolicyNo: props.formData?.alPolicyNo,
                  policyID: policyIDToUse,
                  status: !renewalProcess && !isPolicy ? "Active" : "Pending",
                  documentType: documentTypeToUse,
                  documentSource:
                    documentTypeToUse === "Policy" ?
                    "Endorsement" : "Policy",
                  policyStatus: props?.formData?.status,
                  agencyID,
                  insuredID,
                },
                "direct-addition",
                false,
                endoEffectiveDate
              );

              const response = await selectIfNewOrExistingSubcollection(
                payload,
                agencyID,
                insuredID,
                "trailers"
              );

              if (response.id) {
                subcollectionIds.push(response.id);
              }
            }
            console.log("Batch upload successful!");
            if (setLoading) setLoading(false);
            return {
              ids: subcollectionIds,
            };
          } catch (error) {
            console.error("Error during batch upload:", error);
            if (setLoading) setLoading(false);
          }
        };

        const handleBatchDriverEndorsementUpload = async (
          myValues,
          subcollectionIds
        ) => {
          let endorsementEffectiveDate;
          if (
            props.formData.documentType === "Policy" ||
            props.formData.status === "In-Renewal"
          ) {
            endorsementEffectiveDate = endoEffectiveDate;
          } else {
            endorsementEffectiveDate = "";
          }
          let trailerData = myValues.map((trailer, trailerIndex) => ({
            trailerVIN: trailer[0] || 0,
            trailerMake: trailer[1] || 0,
            trailerYear: trailer[2] || 0,
            trailerACV: trailer[3] || 0,
            endoEffectiveDate: endorsementEffectiveDate,
            id: subcollectionIds[trailerIndex],
          }));

          trailerData = trailerData.map((trailer) => {
            return setPolicyInfo(
              trailer,
              {
                ...props?.formData,
                policyEffectiveDate: props.formData?.effectiveDate,
                glPolicyNo: props.formData?.glPolicyNo,
                alPolicyNo: props.formData?.alPolicyNo,
                policyID: policyIDToUse,
                status: "Pending",
                documentType: documentTypeToUse,
                documentSource:
                  documentTypeToUse === "Policy" ?
                  "Endorsement" : "Policy",
                policyStatus: props?.formData?.status,
                agencyID,
                insuredID,
              },
              "endo-addition",
              false,
              endoEffectiveDate
            );
          });

          const countersDocRef = doc(db, "unique-numbers", "endorsements");
          await runTransaction(db, async (transaction) => {
            const countersDoc = await transaction.get(countersDocRef);
            const currentCounter = countersDoc.data().lastCount;
            const newCounter = currentCounter + 1;
            transaction.update(countersDocRef, {
              lastCount: newCounter,
            });
            const endosRef = collection(
              db,
              "policy-applications",
              policyIDToUse,
              "endorsements"
            );

            const type = "Add Trailers";
            const status = "Submitted";

            const payloadForEndo = setPolicyInfoForEndorsement(trailerData, policyIDToUse)
            const docPaths = getDocumentPaths(payloadForEndo, type, props?.formData);

            const payloadEndo = {
              endorsementNumber: parseInt(newCounter),
              type,
              data: docPaths,
              dateCreated: new Date(),
              policyID: policyIDToUse,
              status,
              namedInsured: props.insuredInfo.company,
              alPolicyNo: props.formData.alPolicyNo,
              policyEffectiveDate: props.formData.effectiveDate,
              endoEffectiveDate,
              author: auth.currentUser.displayName,
              authorID: auth.currentUser.uid,
              agencyID: props.formData.agencyID,
              insuredID: props.formData.insuredID,
              documentSource: "Endorsement",
            }

            const thereIsValidSearchValue = payloadForEndo?.some(data => {
              const thereIsAValue = Boolean(
                data?.licenseNumber ||
                data?.driverFirst ||
                data?.driverLast ||
                data?.truckVIN ||
                data?.trailerVIN
              )
              return thereIsAValue
            })

            if (payloadForEndo?.length > 0  && thereIsValidSearchValue) {
              payloadEndo['searchData'] = []
              payloadForEndo.forEach(data => {
                if (data?.driverFirst || data?.driverLast) {
                  const searchName = String(`${data?.driverFirst ? data?.driverFirst : ""} ${data?.driverLast ? data?.driverLast : ""}`).trim()
                  payloadEndo['searchData'].push(searchName)
                }
                if (data?.licenseNumber) {
                  payloadEndo['searchData'].push(String(data?.licenseNumber))
                }
                if (data?.truckVIN) {
                  payloadEndo['searchData'].push(String(data?.truckVIN))
                }
                if (data?.trailerVIN) {
                  payloadEndo['searchData'].push(String(data?.trailerVIN))
                }
              })
            }

            const docRef = await addDoc(endosRef, payloadEndo);

            endoStatusChangeNotification(
              type,
              String(newCounter),
              status,
              docRef.id,
              policyIDToUse,
              props.userInfo.agencyID
            );
            if (setSuccess) setSuccess(true);
          });
        };

        const uploadedTrailers = await handleBatchDriverUpload(
          values,
          documentTypeToUse === "Policy"
        );
        if (documentTypeToUse === "Policy") {
          handleBatchDriverEndorsementUpload(values, uploadedTrailers.ids);
        }
        updateSystemLog(
          auth,
          policyIDToUse,
          "Trailers List Imported To Application For " +
            props.insuredInfo.company,
          "Trailers List Uploaded",
          { ...values, policyID: policyIDToUse }
        );
        if (!allFlag) goBack();
        if (renewalProcess && !renewalProcess && documentTypeToUse === "Policy") {
          return uploadedTrailers.ids;
        }
      }
    } catch (error) {
      console.error("Error fetching existing trailers:", error);
      throw new Error();
    }
  };

  const mirrorLogic = async (file, setLoading, excelRowData) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    try {
      if (notARenewalProcess) {
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          formData.documentType,
          false,
          excelRowData
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Trailers endorsement successfully!"
          : "You created a list of Trailers successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Application",
          true,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          formData.renewalPolicyID,
          "Policy",
          true,
          excelRowData
        );
        const message = "You created an Add Trailers endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await handleUploadFile(
          file,
          setLoading,
          formData.renewalApplicationID,
          "Application",
          true,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Policy",
          true,
          excelRowData
        );
        const message = "You created an Add Trailers endorsement successfully!";
        if (!allFlag) {
          controlToast(true, message, "success");
        }
      }
    } catch (error) {
      if (!allFlag) {
        controlToast(true, "No trailer was created", "error");
      }
      console.error(error);
      if (!allFlag) goBack()
      throw error
    }
  };

  return { mirrorLogic };
};

export default useImportTrailers;
