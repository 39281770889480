import React from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Tooltip,
  Stack,
  Box,
} from "@mui/material";
import CopyIcon from "@mui/icons-material/ContentCopy";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import DriverDetailFlags from "../../../PolicyLifecycleManager/RedFlags/DriverDetailFlags";
import useGeneralState from "../../../../Context/actions/useGeneralState";

const DriverCard = ({ driver, itemIndex, showDriver, formData }) => {

  const { controlToast } = useGeneralState()
  const handleCopy = () => {
    navigator.clipboard.writeText(`${driver.driverFirst} ${driver.driverLast}`);
    controlToast(true, "Driver name copied", "success");
  };

  const status = driver?.policiesDetails.find(policyDetail => policyDetail.id === formData.id)?.status

  return (
    <Card
      sx={{
        mb: 2,
        borderRadius: 3,
        backgroundColor: "#f9f9f9",
      }}
    >
      <CardContent>
        <Box>
          <Typography onClick={(e) => {
            showDriver(e, driver?.id, itemIndex)
          }} variant="h6">
            {driver.driverFirst} {driver.driverLast}
          </Typography>
        </Box>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Tooltip title="Copy Driver Name">
            <IconButton onClick={handleCopy} color="primary">
              <CopyIcon />
            </IconButton>
          </Tooltip>
          {!driver.licensePhoto && (
            <Tooltip title="No Driver's License">
              <IconButton
                color="error"
                onClick={(e) => showDriver(e, driver.id, itemIndex)}
              >
                <PersonOffIcon />
              </IconButton>
            </Tooltip>
          )}
          <DriverDetailFlags singleIcon={true} driver={driver} />
        </Stack>
        <Stack mt={2}>
          <Typography
            variant="body2"
            color={
              driver.driverLookupStatus !== "Incomplete"
                ? "green"
                : "textSecondary"
            }
          >
            Country: {driver.licenseCountry}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            License State: {driver.licenseState || "N/A"}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            License Number: {driver.licenseNumber}
          </Typography>
          {driver?.mxNMP ?
            <Typography variant="body2" color="textSecondary">
              MXNMP: {driver.mxNMP}
            </Typography> : null
          }
          <Typography variant="body2" color="textSecondary">
            Years of Experience: {driver.yoe}
          </Typography>
          {status ?
            <Typography variant="body2" color="textSecondary">
              Status: {status}
            </Typography> : null
          }
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DriverCard;
