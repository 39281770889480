import React from "react";
import calculateAge from "../../../utils/calculateAge";
import DocumentsTable from "../../../DocumentsTable";
import updateSystemLog from "../../../utils/updateSystemLog";
import { auth } from "../../../../firebase";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import {
  selectIfNewOrExistingSubcollection,
  validateIfBlacklisted,
  validateIfExists,
} from "../../../utils/validateIfBlacklisted";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";
import { setPolicyInfoForEndorsement } from "../setPolicyInfo";
import { multipleToEndorsements } from "../multipleToEndorsements";
import { generateSearchWords } from "../../../../utils/helpers";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import MyCheckbox from "../../../assets/forms/Checkbox";
// import ApiService from "../../../../services/southern-api/apiService";

export default function Step3({
  parentProps,
  driverData,
  setDriverData,
  fileObjectsMedicalForm,
  fileObjectsLicenseBack,
  fileObjectsLicenseFront,
  fileObjectsMvr,
  fileObjectsCaDriverAbstract,
  fileObjectsAdditionalForm,
  fileObjectsOtherDocuments,
  setFileObjectsMedicalForm,
  setFileObjectsLicenseBack,
  setFileObjectsLicenseFront,
  setFileObjectsMvr,
  setFileObjectsCaDriverAbstract,
  setFileObjectsAdditionalForm,
  setFileObjectsOtherDocuments,
  endoEffectiveDate,
  setSuccess,
  setPolicyInfo,
  goBack,
  setShowStep0,
  setShowStep1,
  setShowStep2,
  setShowStep3,
  appearATutorInput,
  formData,
  setEndoEffectiveDate,
  setLicenseExpirationDate,
  setDobDate,
  setProgresspercent,
  necessaryValuesInsideEndorsement
}) {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations();

  const documentUploadInfo = [
    {
      name: "Drivers License (Front)",
      key: "driverLicenseFront",
      files: fileObjectsLicenseFront,
      setFiles: setFileObjectsLicenseFront,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    {
      name: "Drivers License (Back)",
      key: "driverLicenseBack",
      files: fileObjectsLicenseBack,
      setFiles: setFileObjectsLicenseBack,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    driverData.licenseCountry === "USA" && {
      name: "MVR",
      key: "MVR",
      files: fileObjectsMvr,
      setFiles: setFileObjectsMvr,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    driverData.licenseCountry === "CA" && {
      name: "CA Driver Abstract",
      key: "caDriverAbstract",
      files: fileObjectsCaDriverAbstract,
      setFiles: setFileObjectsCaDriverAbstract,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    calculateAge(driverData?.dob) > 64 && {
      name: "Medical Long Form",
      key: "medicalLongForm",
      files: fileObjectsMedicalForm,
      setFiles: setFileObjectsMedicalForm,
      storageFile: `files/drivers/${driverData.licenseNumber}`,
    },
    appearATutorInput && {
      name: "Driver Additional Documents",
      key: "additionalDocument",
      files: fileObjectsAdditionalForm,
      setFiles: setFileObjectsAdditionalForm,
      storageFile: `files/drivers/${driverData.licenseNumber}/additional-docs`,
      multiple: true,
    },
    {
      name: "Other Documents",
      key: "otherDocuments",
      files: fileObjectsOtherDocuments,
      setFiles: setFileObjectsOtherDocuments,
      storageFile: `files/drivers/${driverData.licenseNumber}/other-docs`,
      multiple: true,
    },
  ];

  const submitAddDriverStep3 = async (
    e,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess
  ) => {
    e.preventDefault();
    
    const { agencyID, insuredID } = parentProps.formData;
    const verifiedInBlacklistDrivers = await validateIfBlacklisted(
      [driverData],
      true,
      auth?.currentUser
    );
    console.log('verifiedInBlacklistDrivers', verifiedInBlacklistDrivers)
    const someDriverIsBlacklisted = verifiedInBlacklistDrivers.some(
      (possibleBlacklistedDriver) =>
        Boolean(possibleBlacklistedDriver.blacklist)
    );
    console.log('someDriverIsBlacklisted', someDriverIsBlacklisted)
    if (someDriverIsBlacklisted) {
      console.log('someDriverIsBlacklisted inside', someDriverIsBlacklisted)
      throw new Error("This user is blacklisted");
    }
    const verifiedInExistingData = await validateIfExists(
      verifiedInBlacklistDrivers,
      agencyID,
      insuredID,
      "drivers"
    );

    const newPayload = verifiedInExistingData[0];

    if (fileObjectsLicenseFront[0])
      newPayload["driverLicenseFront"] = fileObjectsLicenseFront[0];
    if (fileObjectsLicenseBack[0])
      newPayload["driverLicenseBack"] = fileObjectsLicenseBack[0];
    if (fileObjectsMvr[0]) newPayload["MVR"] = fileObjectsMvr[0];
    if (fileObjectsCaDriverAbstract[0])
      newPayload["caDriverAbstract"] = fileObjectsCaDriverAbstract[0];
    if (fileObjectsMedicalForm[0])
      newPayload["medicalLongForm"] = fileObjectsMedicalForm[0];
    if (fileObjectsAdditionalForm[0])
      newPayload["additionalDocument"] = fileObjectsAdditionalForm;
    if (fileObjectsOtherDocuments[0])
      newPayload["otherDocuments"] = fileObjectsOtherDocuments;

    // ADD A DRIVER IN A REMOVE ENDORSEMENT
    let payloadResult
    if (
      necessaryValuesInsideEndorsement?.addUI &&
      necessaryValuesInsideEndorsement?.endorsement?.type.includes("Remove")
    ) {
      const policiesDetails = newPayload.policiesDetails.map((detail) => {
        if (detail.id === policyIDToUse) {
          return {
            ...detail,
            status: 'Pending Deletion'
          };
        }
        return detail;
      })
      newPayload["policiesDetails"] = policiesDetails;
      payloadResult = newPayload
    // EDIT A DRIVER IN ANY TYPE OF ENDORSEMENT
    } else if (
      necessaryValuesInsideEndorsement?.editUI &&
      necessaryValuesInsideEndorsement?.selectedItem
    ) {
      payloadResult = newPayload
    // ADD A DRIVER IN A ADD ENDORSEMENT AS NORMAL
    } else {
      payloadResult = setPolicyInfo(
        {
          ...newPayload,
          dateCreated: new Date(),
        },
        {
          ...parentProps.formData,
          policyEffectiveDate: endoEffectiveDate,
          glPolicyNo: parentProps?.formData?.glPolicyNo,
          alPolicyNo: parentProps?.formData?.alPolicyNo,
          policyID: policyIDToUse,
          documentType: documentTypeToUse,
          documentSource:
            documentTypeToUse === "Policy" ?
            "Endorsement" : "Policy",
          policyStatus: parentProps?.formData?.status,
          status:
            !renewalProcess && documentTypeToUse === "Application"
              ? "Active"
              : "Pending",
          agencyID,
          insuredID,
        },
        !renewalProcess && documentTypeToUse === "Application"
          ? "direct-addition"
          : "endo-addition",
        false,
        endoEffectiveDate
      );
    }

    payloadResult["driverFirst"] = newPayload.driverFirst.toUpperCase();
    payloadResult["driverLast"] = newPayload.driverLast.toUpperCase();

    if (payloadResult["bindData"]) delete payloadResult["bindData"];
    if (payloadResult["iconLeft"]) delete payloadResult["iconLeft"];
    if (payloadResult["iconRight"]) delete payloadResult["iconRight"];
    if (payloadResult["tableInfo"]) delete payloadResult["tableInfo"];
    if (payloadResult["inspasData"]) delete payloadResult["inspasData"];

    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "drivers"
    );

    if (documentTypeToUse === "Policy") {
      const payload = {
        ...payloadResult,
        id: response.id,
      };

      const payloadForEndo = setPolicyInfoForEndorsement(
        payload,
        policyIDToUse
      );

      const endoPolicyID =
        !renewalProcess ?
        parentProps.formData?.id :
          (
            parentProps.formData.documentType === "Policy" ?
            parentProps.formData?.id :
            parentProps.formData.renewalPolicyID
          )

      await multipleToEndorsements(
        endoPolicyID,
        endoEffectiveDate,
        "Add Drivers",
        [payloadForEndo],
        parentProps.insuredInfo,
        parentProps.formData,
        parentProps.userInfo,
        parentProps.getEndorsements,
        [],
        null,
        null,
        null,
        null,
        necessaryValuesInsideEndorsement
      );
    }

    const isTemporalUrl =
      typeof window !== "undefined"
        ? window?.location?.pathname?.includes("temporarily")
        : false;

    if (!isTemporalUrl) {
      updateSystemLog(
        auth,
        policyIDToUse,
        "Driver (" +
          newPayload.driverFirst +
          " " +
          newPayload.driverLast +
          ") Added To Application For " +
          parentProps.insuredInfo.company +
          " by " +
          auth.currentUser.displayName,
        "Driver Added",
        newPayload
      );
    }
  };

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      parentProps.formData.status === "In-Renewal" &&
      parentProps.formData.documentType === "Application";
    const isRenewalPolicy =
      parentProps.formData.status === "In-Renewal" &&
      parentProps.formData.documentType === "Policy";
    const notARenewalProcess = parentProps.formData.status !== "In-Renewal";

    try {
      if (notARenewalProcess) {
        await submitAddDriverStep3(
          e,
          parentProps.policyID,
          parentProps.formData.documentType,
          false
        );
        const isPolicy = parentProps.formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Driver endorsement successfully!"
          : "You created a Driver successfully!";
        console.log("message", message);
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await submitAddDriverStep3(
          e,
          parentProps.policyID,
          "Application",
          true
        );
        await submitAddDriverStep3(
          e,
          parentProps.formData.renewalPolicyID,
          "Policy",
          true
        );
        const message = "You created an Add Driver endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await submitAddDriverStep3(
          e,
          parentProps.formData.renewalApplicationID,
          "Application",
          true
        );
        await submitAddDriverStep3(e, parentProps.policyID, "Policy", true);
        const message = "You created an Add Driver endorsement successfully!";
        controlToast(true, message, "success");
      }

      let userResponse
      if (necessaryValuesInsideEndorsement) {
        userResponse = false
      } else {
        // eslint-disable-next-line no-restricted-globals
        userResponse = confirm("Would you like to add another driver?")
      }
      if (userResponse) {
        setShowStep0(true);
        setShowStep1(false);
        setShowStep2(false);
        setShowStep3(false);
        setDriverData({});
        setEndoEffectiveDate(new Date());
        setLicenseExpirationDate(new Date());
        setDobDate(new Date());
        setProgresspercent(0);
      } else {
        setDriverData({});
        setSuccess(true);
        goBack(e);
      }
    } catch (error) {
      if (error.message === "This user is blacklisted") {
        const licenseNumber = driverData?.licenseNumber
        controlToast(true, `This ${licenseNumber} is blacklisted`, "error");
        setDriverData({});
        setSuccess(true);
        goBack(e);
      }
      console.log("error", error);
    }
  };

  return (
    <section>
      <Typography variant="h6" component="h2" gutterBottom>
        Special Provisions
      </Typography>
      <div className="ml-4 mb-4">
        <FormControlLabel
          control={
            <Checkbox
              id={"isNonCDLDriver"}
              checked={driverData.isNonCDLDriver}
              onChange={(e) => {
                setDriverData({
                  ...driverData,
                  isNonCDLDriver: e.target.checked,
                });
              }}
              style={{
                fontSize: "20px",
                color: driverData.isNonCDLDriver ? "#116582" : "#072a48",
              }}
              className="larger-checkbox"
              size="large"
            />
          }
          label={"Driver does not have a commercial license."}
          className="ml-2"
        />
      </div>
      <Typography variant="h6" component="h2" gutterBottom>
        Driver Documents
      </Typography>
      <DocumentsTable
        documentUploadInfo={documentUploadInfo}
        formData={formData}
      />
      <ApprovalButtons
        onApprove={mirrorLogic}
        handleAsSubmit={false}
        onDecline={(e) => {
          setShowStep3(false);
          setShowStep2(true);
        }}
        titleAccept="Submit"
        titleDecline="Go Back"
      />
    </section>
  );
}
