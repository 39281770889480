import { auth } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import {
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../../Drivers/setPolicyInfo";
import { selectIfNewOrExistingSubcollection, validateIfExists } from "../../../utils/validateIfBlacklisted";
import { multipleToEndorsements } from "../../Drivers/multipleToEndorsements";

const submitAddTruckEndorsement = async (
  e,
  policyID,
  truckDataToVerify,
  insuredInfo,
  userInfo,
  endorsementEffectiveDate,
  formData,
  goBack,
  getEndorsements,
  documentTypeToUse,
  renewalProcess,
  truckList,
  necessaryValuesInsideEndorsement
) => {
  e?.preventDefault();

  const { agencyID, insuredID } = formData;

  const data = await validateIfExists(
    [truckDataToVerify],
    agencyID,
    insuredID,
    "power-units"
  );

  const truckData = data?.[0];

  let endoEffectiveDate;
  if (documentTypeToUse === "Policy" || renewalProcess) {
    endoEffectiveDate = endorsementEffectiveDate;
  } else {
    endoEffectiveDate = "";
  }

  if (truckData?.truckVIN?.length !== 17)
    return alert("VIN must be 17 characters.");
  if (truckData?.truckYear?.length !== 4)
    return alert("Year must be 4 characters.");
  if (truckData?.truckACV === "0")
    return alert("Actual Cash Value must be greater than $0.00");

  const truckAlreadyExists = truckList.find(
    (truck) => truck.truckVIN === truckData.truckVIN
  );
  if (
    truckAlreadyExists && !renewalProcess &&
    documentTypeToUse === "Policy" && !necessaryValuesInsideEndorsement?.editUI
  ) {
    goBack(e);
    return alert(
      `A truck with the VIN ${truckData.truckVIN} is already active in the policy.`
    );
  } else {
    const { agencyID, insuredID } = formData;

    // ADD A POWER UNIT IN A REMOVE ENDORSEMENT
    let payloadResult
    if (
      necessaryValuesInsideEndorsement?.addUI &&
      necessaryValuesInsideEndorsement?.endorsement?.type.includes("Remove")
    ) {
      const policiesDetails = truckData.policiesDetails.map((detail) => {
        if (detail.id === policyID) {
          return {
            ...detail,
            status: 'Pending Deletion'
          };
        }
        return detail;
      })
      truckData["policiesDetails"] = policiesDetails;
      payloadResult = truckData
    // EDIT A POWER UNIT IN ANY TYPE OF ENDORSEMENT
    } else if (
      necessaryValuesInsideEndorsement?.editUI &&
      necessaryValuesInsideEndorsement?.selectedItem
    ) {
      payloadResult = truckData
    // ADD A POWER UNIT IN A ADD ENDORSEMENT AS NORMAL
    } else {
      payloadResult = setPolicyInfo(
        {
          ...truckData,
          dateCreated: new Date(),
        },
        {
          ...formData,
          policyEffectiveDate: formData?.effectiveDate,
          glPolicyNo: formData?.glPolicyNo,
          alPolicyNo: formData?.alPolicyNo,
          policyID,
          status:
            !renewalProcess && documentTypeToUse === "Application"
              ? "Active"
              : "Pending",
          documentType: documentTypeToUse,
          documentSource:
            documentTypeToUse === "Policy" ?
            "Endorsement" : "Policy",
          policyStatus: formData?.status,
          agencyID,
          insuredID,
        },
        !renewalProcess && documentTypeToUse === "Application"
          ? "direct-addition"
          : "endo-addition",
        false,
        endoEffectiveDate
      );
    }

    if (payloadResult["bindData"]) delete payloadResult["bindData"];
    if (payloadResult["iconLeft"]) delete payloadResult["iconLeft"];
    if (payloadResult["iconRight"]) delete payloadResult["iconRight"];
    if (payloadResult["tableInfo"]) delete payloadResult["tableInfo"];
    if (payloadResult["inspasData"]) delete payloadResult["inspasData"];

    const response = await selectIfNewOrExistingSubcollection(
      payloadResult,
      agencyID,
      insuredID,
      "power-units"
    );

    if (documentTypeToUse === "Policy") {
      const payload = {
        ...payloadResult,
        id: response.id,
      };
      const payloadForEndo = setPolicyInfoForEndorsement(payload, policyID);
      const endoPolicyID =
        !renewalProcess ?
        formData?.id :
          (
            formData.documentType === "Policy" ?
            formData?.id :
            formData.renewalPolicyID
          )

      await multipleToEndorsements(
        endoPolicyID,
        endoEffectiveDate,
        "Add Power Units",
        [payloadForEndo],
        insuredInfo,
        formData,
        userInfo,
        getEndorsements,
        [],
        null,
        null,
        null,
        null,
        necessaryValuesInsideEndorsement
      );
    }
    updateSystemLog(
      auth,
      policyID,
      "Power Unit (" +
        truckData.truckVIN +
        ") Added To Application For " +
        insuredInfo.company +
        " by " +
        auth?.currentUser?.displayName,
      "Power Unit Added",
      truckData
    );
  }
};

export default submitAddTruckEndorsement;