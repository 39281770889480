import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import getTaxSchedule from "../getTaxSchedule";
import getStampFee from "./getStampFee";
import getSurplusLinesRate from "./getSurplusLinesRate";
import numberWithCommas from "../numberWithCommas";

const calculateTaxesMTC = async (
  policyID,
  formData,
  mtcPremium,
  powerUnitCount,
  updatePolicy,
  hasXS
) => {
  if (!powerUnitCount)
    return alert(
      "Power unit count is required to calculate taxes and fees for MTC coverage"
    );
  if (!formData.hasMTCCoverage) return 0;

  const fourUnitsOrLess = powerUnitCount <= 4;

  const taxSchedule = await getTaxSchedule();
  const taxesAndFees = { ...taxSchedule };

  const stampFee = getStampFee(formData.stateOfEntry, taxesAndFees);
  const surplusLinesTax = getSurplusLinesRate(
    formData.stateOfEntry,
    taxesAndFees
  );

  let policyFeeAmount = fourUnitsOrLess ? 325 : 625;
  if (hasXS) policyFeeAmount = policyFeeAmount + (fourUnitsOrLess ? 250 : 500);

  const taxAmounts = {
    stampTaxAmount: ((mtcPremium + policyFeeAmount) * stampFee * 100) / 100,
    surplusLinesTaxAmount:
      ((mtcPremium + policyFeeAmount) * surplusLinesTax * 100) / 100,
    policyFeeAmount,
  };

  updatePolicy &&
    updateDoc(doc(db, "policy-applications", policyID), {
      mtcDetails: { ...formData?.mtcDetails, ...taxAmounts },
    });

  return taxAmounts;
};

export default calculateTaxesMTC;
