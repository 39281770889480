import React from "react";
import ReasonsModal from "../../../../PolicyLifecycleManager/ReturnToAgentModal";
import declineEndorsement from "../../utils/addPowerUnit/declineAddPowerUnitsEndorsement";
import Popup from "../../../../generals/Popup";
import DriverDetails from "../../../../PolicyLifecycleManager/Drivers/DriverDetails";

export default function Modals({
    openInCancellationModal,
    setOpenInCancellationModal,
    rejectionModal,
    setRejectionModal,
    endorsement,
    isRequestException,
    paramPolicyID,
    paramRefID,
    endorsementData,
    setPolicyInfo,
    setPolicyDetailUpdate,
    insuredInfo,
    setSuccess,
    selectedDriver,
    openDetailsModal,
    setOpenDetailsModal,
    user,
    subcollection
}) {

  return (
    <>
      <Popup
        isOpen={openInCancellationModal}
        onRequestClose={() => setOpenInCancellationModal(false)}
        maxWidth="50%"
      >
        <p className="text-2xl font-semibold mb-2 text-center text-red-700">
          This endorsement cannot be approved because the policy is In
          Cancellation
        </p>
      </Popup>
      <Popup
        isOpen={openDetailsModal}
        onRequestClose={() => setOpenDetailsModal(false)}
        showExpandButton={true}
        maxWidth="inherit"
      >
        <div>
          <DriverDetails
            formData={endorsement}
            userInfo={user}
            selectedDriver={selectedDriver}
            setShowDriverDetails={setOpenDetailsModal}
            policyID={paramPolicyID}
          />
        </div>
      </Popup>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineEndorsement(
            e,
            null,
            reasons,
            endorsement,
            isRequestException,
            paramPolicyID,
            paramRefID,
            endorsementData,
            setPolicyInfo,
            setPolicyDetailUpdate,
            insuredInfo,
            setSuccess,
            subcollection
          )
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
    </>
  );
}
