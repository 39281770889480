import React, { useEffect, useState } from "react";
import TextInput from "../../../assets/forms/TextInput";
import NumberInput from "../../../assets/forms/NumberInput";
import ApprovalButtons from "../../../assets/forms/ApprovalButtons";
import DatePicker from "react-datepicker";
import calculateAge from "../../../utils/calculateAge";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import useTranslations from "../../../../Context/actions/useTranslations";
import "react-datepicker/dist/react-datepicker.css";
import { setTimeToNoonOne } from "../../../../utils/helpers";

export default function Step1({
  parentProps,
  driverData,
  setDriverData,
  setEndoEffectiveDate,
  endoEffectiveDate,
  dobDate,
  setDobDate,
  setOpenModalAddDriverException,
  goToSecondStep,
  appearATutorInput,
  activeDriversWithAtLeas5YearsOfExperience,
  setShowStep0,
  setShowStep1,
  necessaryValuesInsideEndorsement
}) {
  const { controlToast } = useGeneralState();
  const { t } = useTranslations()

  const submitAddDriverStep1 = async (e) => {
    e.preventDefault();
    const age = calculateAge(dobDate);
    if (age > 22 && age < 71) {
      if (driverData.yoe < 2) {
        setOpenModalAddDriverException(true);
      } else {
        goToSecondStep();
      }
    } else {
      controlToast(
        true,
        t('plm.drivers.add-driver.alert-3'),
        "error"
      );
    }
  };

  function isOver65YearsOld(dateOfBirth) {
    if (!dateOfBirth) return false;
    const currentDate = new Date();
    const birthDate = new Date(dateOfBirth);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    const hasHadBirthdayThisYear =
      currentDate.getMonth() > birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() >= birthDate.getDate());
    return hasHadBirthdayThisYear ? age > 65 : age - 1 > 65;
  }
  
  return (
    <form onSubmit={(e) => submitAddDriverStep1(e)}>
      <div>
        {
        !necessaryValuesInsideEndorsement?.addUI &&
        (parentProps.formData.documentType === "Policy" ||
        parentProps.formData.status === "In-Renewal") ? (
          <>
            <label className="">{t('plm.drivers.add-driver.effective-date')}:</label>
            <DatePicker
              wrapperClassName="datePicker"
              required
              selected={endoEffectiveDate}
              onChange={(date) => {
                const newDate = setTimeToNoonOne(date);
                setEndoEffectiveDate(newDate);
              }}
              className="w-full p-2 border"
            />
          </>
        ) : null}
        <TextInput
          required={true}
          label={`${t('plm.drivers.add-driver.driver-first-name')}:`}
          allowPaste={true}
          property={"driverFirst"}
          setFormData={(value) => {
            value['driverFirst'] = value['driverFirst'].toUpperCase();
            setDriverData(value);
          }}
          formData={driverData}
          saveForm={() => {}}
          setFormSaved={() => {}}
          formSaved={""}
          policyID={parentProps.policyID}
          withoutSpaces={true}
        />
        <TextInput
          required={true}
          label={`${t('plm.drivers.add-driver.driver-last-name')}:`}
          property={"driverLast"}
          setFormData={(value) => {
            value['driverLast'] = value['driverLast'].toUpperCase();
            setDriverData(value);
          }}
          formData={driverData}
          saveForm={parentProps.saveForm}
          setFormSaved={parentProps.setFormSaved}
          formSaved={parentProps.formSaved}
          policyID={parentProps.policyID}
        />
        <NumberInput
          required={true}
          label={`${t('plm.drivers.add-driver.yoe')}:`}
          property={"yoe"}
          setFormData={setDriverData}
          formData={driverData}
          saveForm={parentProps.saveForm}
          setFormSaved={parentProps.setFormSaved}
          formSaved={parentProps.formSaved}
          policyID={parentProps.policyID}
        />
        {/* <>
          <label className="">{t('plm.drivers.add-driver.doa')}:</label>
          <DatePicker
            required
            wrapperClassName="datePicker"
            selected={doaDate}
            onChange={(date) => {
              setDriverData({
                ...driverData,
                doa: date,
              });
              setDoaDate(date);
            }}
            className="w-full p-2 border"
          />
        </> */}
        {appearATutorInput ?
          <div className="mt-1 mb-2">
            <label htmlFor="tutor" className="d-block">
              {t('plm.drivers.add-driver.tutor')}
            </label>
            <select
              required
              onChange={(e) =>
                setDriverData({
                  ...driverData,
                  tutor: e.target.value,
                })
              }
              value={driverData.tutor}
              type="text"
              id="tutor"
              className="w-full p-2 border"
            >
              <option>{t('plm.drivers.add-driver.select-tutor')}</option>
              {activeDriversWithAtLeas5YearsOfExperience.map((driver) => {
                return (
                  <option key={driver.id} value={driver.id}>
                    {driver?.driverFirst} {driver?.driverLast ? driver.driverLast : ''}
                  </option>
                );
              })}
            </select>
          </div> : null
        }
        <>
          <label className="">{t('plm.drivers.add-driver.dob')}:</label>
          <DatePicker
            wrapperClassName="datePicker"
            required
            selected={dobDate}
            onChange={(date) => {
              setDriverData({
                ...driverData,
                dob: date,
                medicalLongFormExpiration: isOver65YearsOld(date) ? new Date() : null
              });
              setDobDate(date);
            }}
            className="w-full p-2 border"
          />
        </>

        {isOver65YearsOld(driverData.dob) ? (
          <>
            <label className="">{t('plm.drivers.add-driver.medicalLongFormExpiration')}:</label>
            <DatePicker
              required
              wrapperClassName="datePicker"
              selected={driverData.medicalLongFormExpiration}
              onChange={(date) => {
                setDriverData({
                  ...driverData,
                  medicalLongFormExpiration: date
                });
              }}
              className="w-full p-2 border"
              minDate={new Date()}
            />
          </>
        ) : null}
        <label htmlFor="licenseCountry" className="mt-1 d-block">
          {t('plm.drivers.add-driver.driver-country')}:
        </label>
        <select
          required
          onChange={(e) =>
            setDriverData({
              ...driverData,
              licenseCountry: e.target.value,
            })
          }
          value={driverData.licenseCountry}
          type="text"
          id="licenseCountry"
          className="w-full p-2 border"
        >
          <option></option>
          <option value="USA">{t('plm.drivers.add-driver.united-states')}</option>
          <option value="MX">{t('plm.drivers.add-driver.mexico')}</option>
          <option value="CA">{t('plm.drivers.add-driver.canada')}</option>
        </select>
      </div>
      <ApprovalButtons
        onApprove={null}
        handleAsSubmit={true}
        onDecline={(e) => {
          e.preventDefault();
          setShowStep0(true);
          setShowStep1(false);
        }}
        titleAccept={t('plm.drivers.add-driver.continue')}
        titleDecline={t('plm.drivers.add-driver.go-back')}
      />
    </form>
  );
}
