import { db } from "../../../../../firebase"; // Updated import path
import {
  collectionGroup,
  query,
  where,
  getDocs,
  orderBy,
  or,
} from "firebase/firestore";

const handleSearchPolicy = async (
  initialSearch,
  queryText,
  searchFunction,
  policyID,
  selectedStatus,
  setOutstandingEndorsements,
  setResults,
  setLoading,
  selectedType
) => {
  let endorsementsQuery;
  setLoading(true);
  setResults([]);
  const searchValue = queryText.toUpperCase().trim(); // Convert to uppercase & remove extra spaces

  const partialName = searchFunction === "partialName";

  // Split searchValue into individual words for array-contains-any
  // const searchWords = partialName ? searchValue.split(/\s+/) : [];
  function generateSearchWords(fullName) {
    const parts = fullName.split(" ");
    const result = new Set();

    // Generate all contiguous combinations
    for (let i = 0; i < parts.length; i++) {
      let phrase = "";
      for (let j = i; j < parts.length; j++) {
        phrase += (phrase ? " " : "") + parts[j];
        result.add(phrase);
      }
    }

    return Array.from(result);
  }
  const searchWords = generateSearchWords(searchValue);

  if (searchFunction === "company") {
    // Search for company name in namedInsured field
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("policyID", "==", policyID),
      where("namedInsured", ">=", searchValue),
      where("namedInsured", "<", searchValue + "\uf8ff"),
      orderBy("namedInsured", "desc")
    );
  } else if (
    searchFunction === "driver" ||
    searchFunction === "license" ||
    searchFunction === "VIN"
  ) {
    // Search for driver name, license number, or VIN in searchData array
    if (searchFunction === "license") {
      let possibleSearchValues = [searchValue];
      possibleSearchValues.push("0" + searchValue);
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("policyID", "==", policyID),
        where("searchData", "array-contains-any", possibleSearchValues)
      );
    } else {
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("policyID", "==", policyID),
        where("searchData", "array-contains", searchValue)
      );
    }
  } else if (searchFunction === "status") {
    // Filter by endorsement status
    if (selectedStatus === "All") {
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("policyID", "==", policyID)
      );
    } else {
      endorsementsQuery = query(
        collectionGroup(db, "endorsements"),
        where("policyID", "==", policyID),
        where("status", "==", selectedStatus)
      );
    }
  } else if (searchFunction === "type") {
    // Filter by endorsement type
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("policyID", "==", policyID),
      where("type", "==", selectedType)
    );
  } else if (partialName) {
    // Search for Partial Name using searchWords
    endorsementsQuery = query(
      collectionGroup(db, "endorsements"),
      where("policyID", "==", policyID),
      where("searchData", "array-contains-any", searchWords)
    );
  }

  if (endorsementsQuery) {
    try {
      const querySnapshot = await getDocs(endorsementsQuery);
      let searchResults = querySnapshot.docs.map((document) => {
        const data = document.data();

        // Compute match score based on how many search words exist in searchWords that are present in the document
        const matchedWords = !partialName
          ? []
          : searchWords.filter((word) => data.searchData.includes(word));
        const matchScore = !partialName ? 0 : matchedWords.length;

        // Check if all search words are present in the searchWords array
        const areAllWordsPresent = !partialName
          ? true
          : searchWords.every((word) => data.searchData.includes(word));

        // Check if the search words appear in the same order
        const isOrderedMatch = () => {
          if (!partialName) return true;
          let startIndex = 0;
          for (let i = 0; i < searchWords.length; i++) {
            startIndex = data.searchData.indexOf(searchWords[i], startIndex);
            if (startIndex === -1) {
              return false;
            }
            startIndex += 1;
          }
          return true;
        };

        return {
          id: document.id,
          ...document.data(),
          endorsementNumber: Number(document.data().endorsementNumber),
          matchScore,
          isOrderedMatch: isOrderedMatch(),
          areAllWordsPresent,
        };
      });

      // Only keep results that is an ordered match
      if (partialName)
        searchResults = searchResults.filter((result) => result.isOrderedMatch);

      // only keep results where all words are present
      if (partialName)
        searchResults = searchResults.filter(
          (result) => result.areAllWordsPresent
        );

      // Sort by endorsementNumber in descending order
      searchResults = searchResults.sort(
        (a, b) => b.endorsementNumber - a.endorsementNumber
      );

      initialSearch && setOutstandingEndorsements(searchResults.length);

      setResults(searchResults);
    } catch (error) {
      console.error("Error fetching endorsements:", error);
    }
  } else {
    console.log("no query");
    setResults([]);
  }
  setLoading(false);
};

export default handleSearchPolicy;
