import React, {
    createContext,
    useReducer,
    useState,
    useEffect
} from 'react';
import AppReducer from './AppReducer';
import { SET_PERSISTENCE } from './types';

export const initialState = {
    language: 'en',
    theme: 'light',
    users: [],
    user: null,
    company: null,
    lossRuns: [],
    endorsements: [],
    paginations: { 
        endorsementsPagination: {
            currentPage: 0,
            itemsPerPage: 10,
        }
    },
    waiversOfSubrogation: [],
    policiesPdf: {
        loading: false,
        loadingCancel: false,
        url: '',
        error: false,
        cancelRequest: false,
        dateWasCancel: null
    },
    toast: {
        open: false,
        message: '',
        type: ''
    },
    lossControl: {
        tables: {
            age: [],
            claimsDollarAmount: [],
            classifications: [],
            combinedMatrix: [],
            driverProfileScaling: [],
            eventualities: [],
            experience: [],
            finalWeight: [],
            frequencyMatrix: [],
            major: [],
            minor: [],
            notDrivingRisk: [],
            notIncluded: [],
            numberOfClaims: [],
            severityMatrix: []
        },
        driverData: {
            fmcsaViolations: [],
            fmcsaDriverFitness: [],
            driverProfile: []
        }
    },
    calendar: {
        selectedData: null,
        token: null
    },
    formData: {},
    formDataGL: {},
    formDataMTC: {},
    formDataAPD: {},
    formDataXS: {},
    formDataCMTC: {},
    quoteSelected: null,
    managing: {
        redFlag: {
            allowApprovalRedFlags: false
        }
    },
    client: {
        clientRoute: null
    }
};

const AppContext = createContext();

const AppProvider = ({ children }) => {

    const [isLoading, setIsLoading] = useState(true);

    const [state, dispatch] = useReducer(AppReducer, initialState);

    useEffect(() => {
        const state = localStorage.getItem('state')
        if (state) {
            const parsedState = JSON.parse(state)
            dispatch({ type: SET_PERSISTENCE, payload: {
                ...parsedState,
                paginations: initialState.paginations
            } })
        }
        setIsLoading(false);
    }, []);

    if (isLoading) {
        return <div></div>
    }

    return (
        <AppContext.Provider value={{
            state,
            dispatch,
            contextLoading: isLoading
        }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppContext, AppProvider }