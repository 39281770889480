import React, { useState } from "react";
import {
  Container,
  TextField,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import useUsersState from "../../Context/actions/useUsersState";
import {
  collection,
  query,
  where,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebase";
import DatePicker from "react-datepicker";
import PolicyRatesReport from "./PolicyRatesReport";
import LoadingScreen from "../assets/LoadingScreen";
import PolicyReport from "./PolicyReport";

export default function Reports() {
  const { user: userInfo } = useUsersState();
  const firstDayOfCurrentMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    1
    );
    const lastDayOfCurrentMonth = new Date(
    new Date().getFullYear(),
    new Date().getMonth() + 1,
    0
    );
  const [startDate, setStartDate] = useState(firstDayOfCurrentMonth);
  const [endDate, setEndDate] = useState(lastDayOfCurrentMonth);
  const [reportType, setReportType] = useState("Policies");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const validateDates = (start, end) => {
    // const oneYear = 366 * 24 * 60 * 60 * 1000; // One year in milliseconds
    // return end - start <= oneYear;
    return true;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    if (!validateDates(date, endDate)) {
      setError("The date range must be within one year.");
    } else {
      setError("");
    }
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    if (!validateDates(startDate, date)) {
      setError("The date range must be within one year.");
    } else {
      setError("");
    }
  };

  const getPoliciesReport = async () => {
    const policiesRef = collection(db, "policy-applications");
    // get policies with an effectiveDate between startDate and endDate
    const q = query(
      policiesRef,
      where("documentType", "==", "Policy"),
      where("hasALCoverage", "==", true),
      where("effectiveDate", ">=", startDate),
      where("effectiveDate", "<=", endDate)
    );
    const querySnapshot = await getDocs(q);
    const results = [];

    // Using map to handle asynchronous operations
    const policyPromises = querySnapshot.docs.map(async (document) => {
      const insuredID = document.data().insuredID;
      const agencyID = document.data().agencyID;
      const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);
      const insuredDoc = await getDoc(insuredRef);
      const insuredInfo = insuredDoc.data();
      const agencyRef = doc(db, "agencies", agencyID);
      const agencyDoc = await getDoc(agencyRef);
      const producer = agencyDoc.data().name;
      results.push({ ...document.data(), insuredInfo, producer });
    });

    // Wait for all policyPromises to resolve
    await Promise.all(policyPromises);
    console.log("results: ", results);
    setResults(results);
  };

  const getApplicationsReport = async () => {
    const applicationsRef = collection(db, "policy-applications");
    // get applications with an effectiveDate between startDate and endDate
    const documentTypesArray = ["Application", "Quote Request", "Bind Order"];
    const q = query(
      applicationsRef,
      where("documentType", "in", documentTypesArray),
      where("hasALCoverage", "==", true),
      where("effectiveDate", ">=", startDate),
      where("effectiveDate", "<=", endDate)
    );
    const querySnapshot = await getDocs(q);
    const results = [];

    // Using map to handle asynchronous operations
    const policyPromises = querySnapshot.docs.map(async (document) => {
      const insuredID = document.data().insuredID;
      const agencyID = document.data().agencyID;
      const insuredRef = doc(db, "agencies", agencyID, "insureds", insuredID);
      const insuredDoc = await getDoc(insuredRef);
      const insuredInfo = insuredDoc.data();
      const agencyRef = doc(db, "agencies", agencyID);
      const agencyDoc = await getDoc(agencyRef);
      const producer = agencyDoc.data().name;
      results.push({ ...document.data(), insuredInfo, producer });
    });

    // Wait for all policyPromises to resolve
    await Promise.all(policyPromises);
    console.log("results: ", results);
    setResults(results);
  };

  const getPolicyRates = async () => {
    setLoading(true);
    const policiesRef = collection(db, "policy-applications");
    // get policies with an effectiveDate between startDate and endDate
    const q = query(
      policiesRef,
      where("documentType", "==", "Policy"),
      where("hasALCoverage", "==", true),
      where("effectiveDate", ">=", startDate),
      where("effectiveDate", "<=", endDate)
    );
    const querySnapshot = await getDocs(q);
    const preliminaryResults = [];

    // Using map to handle asynchronous operations
    const policyPromises = querySnapshot.docs.map(async (policyDoc) => {
      const policyID = policyDoc.id;
      const policy = policyDoc.data();
      const insuredID = policy.insuredID;
      const agencyID = policy.agencyID;
      const powerUnitsRef = collection(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        "power-units"
      );

      // get power units where the policyID is found in the policies array of the power unit
      const powerUnitQuery = query(
        powerUnitsRef,
        where("policies", "array-contains", policyID)
      );
      const powerUnitSnapshot = await getDocs(powerUnitQuery);
      const radiusCounts = {
        50: 0,
        100: 0,
        250: 0,
        500: 0,
        800: 0,
        1000: 0,
        1200: 0,
        1500: 0,
      };
      const radiusDollarAmounts = {
        50: 0,
        100: 0,
        250: 0,
        500: 0,
        800: 0,
        1000: 0,
        1200: 0,
        1500: 0,
      };
      let vehicleCount = 0;
      let GWP = 0;
      const powerUnits = [];
      powerUnitSnapshot.forEach((unitDoc) => {
        vehicleCount++;
        const thisPolicy = unitDoc
          .data()
          .policiesDetails.find((policy) => policy.id === policyID);
        let radius = thisPolicy.truckRadius;
        radius = parseInt(radius.split("-")[1]);
        radiusCounts[radius] += 1;
        radiusDollarAmounts[radius] += thisPolicy.adjustedRate;
        GWP += thisPolicy.adjustedRate;
        powerUnits.push(unitDoc.data());
      });
      const insuredInfoRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID
      );
      const insuredInfoDoc = await getDoc(insuredInfoRef);
      const insuredInfo = insuredInfoDoc.data();
      preliminaryResults.push({
        ...policyDoc.data(),
        radiusCounts,
        radiusDollarAmounts,
        powerUnits,
        vehicleCount,
        insuredInfo,
      });
    });

    // Wait for all policyPromises to resolve
    await Promise.all(policyPromises);
    console.log("results: ", preliminaryResults);
    setResults(preliminaryResults);
    setLoading(false);
    // Find the power units, then get the breakdown of power units by radius
  };

  const fetchReport = () => {
    switch (reportType) {
      case "Policies":
        getPoliciesReport();
        break;
      case "Applications":
        getApplicationsReport();
        break;
      case "Policy Rates":
        getPolicyRates();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading ? (
        <LoadingScreen text="Loading Report..." />
      ) : (
        <Container>
          <Typography variant="h5">Generate Reports</Typography>
          <Typography variant="body2" className="mt-4">
            Start Date:
          </Typography>
          <DatePicker
            className="appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
            label="Start Date"
            wrapperClassName="datePicker"
            selected={startDate}
            onChange={handleStartDateChange}
          />

          <Typography variant="body2" className="mt-2">
            End Date
          </Typography>

          <DatePicker
            className="mb-4 appearance-none block w-full bg-white border border-gray-300 rounded-md py-1 px-1 leading-tight focus:outline-none focus:shadow-outline-blue focus:border-blue-300"
            label="End Date"
            wrapperClassName="datePicker"
            selected={endDate}
            onChange={handleEndDateChange}
          />
          <TextField
            select
            label="Report Type"
            value={reportType}
            onChange={(e) => {
              setResults([]);
              setReportType(e.target.value);
            }}
            fullWidth
            margin="normal"
            className="mb-4"
          >
            <MenuItem value="Policies">Policies</MenuItem>
            {/* <MenuItem value="Applications">Applications</MenuItem> */}
            {/* <MenuItem value="Policy Rates">Policy Rates</MenuItem> */}
          </TextField>
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={fetchReport}
            fullWidth
            disabled={!!error}
          >
            Generate Report
          </Button>
          {results.length > 0 && (
            <>
              {reportType === "Policy Rates" && (
                <PolicyRatesReport data={results} />
              )}
              {reportType === "Policies" && <PolicyReport data={results} />}
            </>
          )}
        </Container>
      )}
    </>
  );
}
