import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import submitAddTrailerEndorsement from "./utils/submitAddTrailerEndorsement";
import Breadcrumbs from "../../assets/Breadcrumbs";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import useGeneralState from "../../../Context/actions/useGeneralState";
import useUsersState from "../../../Context/actions/useUsersState";
import config from "../../../config/env";
import BlueButton from "../../assets/forms/BlueButton";
import { useMediaQuery } from "@mui/material";
import FormPopup from "../../generals/Popup/FormPopup";
import { collection, doc, onSnapshot, query, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useLocation } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import ApiService from "../../../services/southern-api/apiService";
import { setTimeToNoonOne } from "../../../utils/helpers";
import useTranslations from "../../../Context/actions/useTranslations";

export default function AddTrailer(props) {
  const { REACT_APP_LICONA_INSURANCE_GROUP_ID } = config;
  const formData = props.formData;
  const isEndoManager = props.isEndoManager;
  const policyID = props.policyID;
  const trailerList = props.trailerList;
  const necessaryValuesInsideEndorsement = props.necessaryValuesInsideEndorsement;
  const { user } = useUsersState();
  const location = useLocation();
  const { t } = useTranslations()
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient =
    location?.pathname?.includes("temporarily-client");

  const [trailerData, setTrailerData] = useState({});
  const { controlToast } = useGeneralState();

  const goBack = () => {
    props.setShowAddTrailer(false);
    props.setShowTable(true);
  };

  const breadcrumbs = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t("plm.trailers.add.breadcrumb.item1") },
    { label: t("plm.trailers.add.breadcrumb.item2") },
  ];

  const [success, setSuccess] = useState(false);
  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());

  useEffect(() => {
    if (
      necessaryValuesInsideEndorsement?.editUI &&
      necessaryValuesInsideEndorsement?.selectedItem
    ) {
      setTrailerData(necessaryValuesInsideEndorsement?.selectedItem);
    }
  }, [
    necessaryValuesInsideEndorsement?.editUI,
    necessaryValuesInsideEndorsement?.selectedItem,
  ]);

  console.log('necessaryValuesInsideEndorsement', necessaryValuesInsideEndorsement)

  const mirrorLogic = async (e) => {
    e.preventDefault();
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";

    try {
      if (notARenewalProcess) {
        await submitAddTrailerEndorsement(
          e,
          policyID,
          trailerData,
          props.insuredInfo,
          props.userInfo,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          formData.documentType,
          false,
          trailerList,
          necessaryValuesInsideEndorsement
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? t("plm.trailers.add.alert-1")
          : t("plm.trailers.add.alert-2")
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await submitAddTrailerEndorsement(
          e,
          policyID,
          trailerData,
          props.insuredInfo,
          props.userInfo,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          trailerList,
          necessaryValuesInsideEndorsement
        );
        await submitAddTrailerEndorsement(
          e,
          formData.renewalPolicyID,
          trailerData,
          props.insuredInfo,
          props.userInfo,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          trailerList,
          necessaryValuesInsideEndorsement
        );
        const message = t("plm.trailers.add.alert-1")
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await submitAddTrailerEndorsement(
          e,
          formData.renewalApplicationID,
          trailerData,
          props.insuredInfo,
          props.userInfo,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Application",
          true,
          trailerList,
          necessaryValuesInsideEndorsement
        );
        await submitAddTrailerEndorsement(
          e,
          policyID,
          trailerData,
          props.insuredInfo,
          props.userInfo,
          endoEffectiveDate,
          formData,
          goBack,
          props.isEndoManager,
          props.getEndorsements,
          "Policy",
          true,
          trailerList,
          necessaryValuesInsideEndorsement
        );
        const message = t("plm.trailers.add.alert-1")
        controlToast(true, message, "success");
      }

      let userResponse
      if (necessaryValuesInsideEndorsement) {
        userResponse = false
      } else {
        // eslint-disable-next-line no-restricted-globals
        userResponse = confirm("Would you like to add another trailer?")
      }
      if (userResponse) {
        setTrailerData({
          trailerVIN: "",
          trailerYear: "",
          trailerMake: "",
          trailerACV: "",
        });
        setEndoEffectiveDate(new Date())
      } else {
        setTrailerData({});
        setSuccess(true);
        goBack(e);
      }
    } catch (error) {
      throw error;
    }
  };

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const [openTrailerMakeModal, setOpenTrailerMakeModal] = useState(false);

  const handleOpenTrailerMakeModal = () => {
    setOpenTrailerMakeModal(true);
  };

  const handleCloseTrailerMakeModal = () => {
    setOpenTrailerMakeModal(false);
  };

  const addTrailerMakeModal = [
    {
      label: t("plm.trailers.add.trailer-make"),
      type: "text",
      name: "trailerMake",
      required: true,
    },
  ];

  const handleSaveTraining = async (e, value) => {
    e.preventDefault();
    const trailerMakeRef = doc(db, "trailer-makes", value.trailerMake);
    await setDoc(trailerMakeRef, {}, { merge: true });
    handleCloseTrailerMakeModal();
  };

  const formSettingsTrailerMake = {
    onSubmit: handleSaveTraining,
    onDecline: handleCloseTrailerMakeModal,
    inputs: addTrailerMakeModal,
    buttonLabel: t("plm.trailers.add.submit"),
    buttonLabelDecline: t("plm.trailers.add.cancel"),
  };

  const [loading, setLoading] = useState(false);
  const [trailersMake, setTrailersMake] = useState([]);

  useEffect(() => {
    const fetchTrailerMakes = () => {
      setLoading(true);
      const trailerMakesRef = collection(db, "trailer-makes");
      const q = query(trailerMakesRef);
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const makes = [];
        querySnapshot.forEach((doc) => {
          makes.push({ ...doc.data(), id: doc.id });
        });
        const ids = makes.map((make) => make.id);
        setTrailersMake(ids);
        setLoading(false);
      });
      return unsubscribe;
    };

    const fetchTrailerMakesTemporal = async () => {
      try {
        setLoading(true);
        const apiService = new ApiService("southern");
        const makes = await apiService.getTrailersMake();
        const ids = makes.map((make) => make.id);
        setTrailersMake(ids);
        setLoading(false);
        return () => {};
      } catch (error) {
        setTrailersMake([]);
        setLoading(false);
        return () => {};
      }
    };

    const isTemporal = isTemporalUrl && !isTemporalUrlClient;
    const unsubscribe = isTemporal
      ? fetchTrailerMakesTemporal()
      : fetchTrailerMakes();

    return () => unsubscribe();
  }, []);

  return (
    <>
      <FormPopup
        isOpen={openTrailerMakeModal}
        formSettings={formSettingsTrailerMake}
        onRequestClose={handleCloseTrailerMakeModal}
        title={t("plm.trailers.add.add-trailer-make")}
      />
      <main>
        <header>
          {!isEndoManager && !necessaryValuesInsideEndorsement ? <Breadcrumbs items={breadcrumbs} /> : null}
          {necessaryValuesInsideEndorsement?.editUI ?
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              Edit Trailer
            </h1> :
            <h1
              className={`${
                isEndoManager
                  ? "text-2xl mt-2.5 font-bold md:mb-0"
                  : "text-3xl mt-2 font-semibold md:mb-0"
              }`}
            >
              {necessaryValuesInsideEndorsement?.editUI ?
                "Edit Trailer" :
                t("plm.trailers.add.title")
              }
            </h1>
          }
          <p className="mt-2 mb-3">
            {(formData.documentType === "Application" ||
              formData.documentType === "Policy") &&
              t("plm.trailers.add.description")}
            {formData.documentType !== "Application" &&
              formData.documentType !== "Policy" && (
                <>{t("plm.trailers.add.description2")}</>
              )}
          </p>
        </header>
        {/* Form */}
        <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
          <div className="card-body">
            <form onSubmit={mirrorLogic}>
              {!necessaryValuesInsideEndorsement?.addUI &&
              (formData.documentType === "Policy" ||
              formData.status === "In-Renewal") ? (
                <>
                  <label className="">{t("plm.trailers.add.effective-date")}:</label>
                  <DatePicker
                    wrapperClassName="datePicker"
                    required
                    selected={endoEffectiveDate}
                    onChange={(date) => {
                      const newDate = setTimeToNoonOne(date);
                      setEndoEffectiveDate(newDate);
                    }}
                    className="standard-form-input"
                  />
                </>
              ) : null}
              <label className="d-block mt-1">{t("plm.trailers.add.trailer-vin")}:</label>
              {trailerData?.trailerVIN &&
                trailerData?.trailerVIN.length !== 17 && (
                  <p className="text-[#8B0000]">{t("plm.trailers.add.alert-3")}</p>
                )}
              <input
                required
                onChange={(e) =>
                  setTrailerData({
                    ...trailerData,
                    trailerVIN: e.target.value,
                  })
                }
                value={trailerData.trailerVIN}
                type="text"
                id="trailerVIN"
                className="standard-form-input"
              />
              <label className="mt-1">{t("plm.trailers.add.trailer-year")}:</label>
              {trailerData?.trailerYear &&
                trailerData.trailerYear.length !== 4 && (
                  <p>{t("plm.trailers.add.alert-4")}</p>
                )}
              <input
                required
                onChange={(e) =>
                  setTrailerData({
                    ...trailerData,
                    trailerYear: e.target.value,
                  })
                }
                value={trailerData.trailerYear}
                type="text"
                id="trailerYear"
                className="standard-form-input"
              />
              {!loading ? (
                <>
                  <label htmlFor="trailerMake" className="mt-1">
                    {t("plm.trailers.add.trailer-make")}:
                  </label>
                  <select
                    required
                    onChange={(e) =>
                      setTrailerData({
                        ...trailerData,
                        trailerMake: e.target.value,
                      })
                    }
                    value={trailerData.trailerMake}
                    id="trailerMake"
                    className="standard-form-input"
                  >
                    <option value=""></option>
                    {trailersMake.map((make) => (
                      <option key={make} value={make}>
                        {make}
                      </option>
                    ))}
                  </select>
                </>
              ) : null}
              <div>
                {REACT_APP_LICONA_INSURANCE_GROUP_ID === user?.agencyID ? (
                  <BlueButton
                    text={t("plm.trailers.add.custom-button")}
                    onClickFunction={handleOpenTrailerMakeModal}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                  />
                ) : null}
              </div>
              <label htmlFor="trailerACV" className="mt-1">
                {t("plm.trailers.add.actual-cash-value")}:
              </label>
              <input
                required
                onChange={(e) =>
                  setTrailerData({
                    ...trailerData,
                    trailerACV: e.target.value,
                  })
                }
                value={trailerData.trailerACV}
                type="number"
                id="trailerACV"
                className="standard-form-input"
              />
              <ApprovalButtons
                onApprove={null}
                handleAsSubmit={true}
                onDecline={(e) => {
                  goBack();
                }}
                titleAccept={t("plm.trailers.add.submit")}
                titleDecline={t("plm.trailers.add.go-back")}
              />
            </form>
          </div>
        </section>
      </main>
    </>
  );
}
