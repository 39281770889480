import React, { useEffect, useMemo, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  addDoc,
  collection,
  doc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";

export default function AdditionalInsured(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;
  const userInfo = props.userInfo;

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [endorsementData, setEndorsementData] = useState({});

  useEffect(() => {
    function convertArrayToObject(array) {
      return array.reduce((acc, item) => {
        return { ...acc, ...item };
      }, {});
    }
    let data;
    if (Array.isArray(endorsement?.data)) {
      data = convertArrayToObject(endorsement?.data);
    } else {
      data = endorsement?.data;
    }
    setEndorsementData(data);
  }, []);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const updateEndorsementStatusAndCreateAdditionalInsured = async (
      endorsementId,
      status
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = new Date()
          const payload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            payload["wasARequestException"] = true;
            payload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, payload);
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating additionalInsured:",
          error
        );
      }
    };

    updateEndorsementStatusAndCreateAdditionalInsured(
      paramRefID,
      "Approved",
      endorsement.data
    );
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAUEndorsement = async (e, requestExceptionData, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const additionalInsuredRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (requestExceptionData) {
      payload["wasARequestException"] = true;
      payload["requestException"] = requestExceptionData;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(additionalInsuredRef, payload);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: approveAUEndorsement,
      onDecline: declineAUEndorsement,
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const requestExceptionModeSetting = async (e) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  return (
    <div>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
        <Container className="p-0">
          <Row>
            <Col sm={12}>
              <span className="font-semibold">Name:</span>{" "}
              {endorsementData?.name
                ? endorsementData?.name
                : endorsement?.["Add-Ins Name"]
                ? endorsement?.["Add-Ins Name"]
                : "-"}
            </Col>
            <Col sm={12}>
              <span className="font-semibold">Address Line 1:</span>{" "}
              {endorsementData?.addressLine1
                ? endorsementData?.addressLine1
                : endorsementData?.Address
                ? endorsementData?.Address
                : endorsementData?.address
                ? endorsementData?.address
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <span className="font-semibold">Address Line 2:</span>{" "}
              {endorsementData?.addressLine2
                ? endorsementData?.addressLine2
                : endorsementData?.AddressLineTwo
                ? endorsementData?.AddressLineTwo
                : endorsementData?.addressLineTwo
                ? endorsementData?.addressLineTwo
                : "-"}
            </Col>
            <Col sm={12}>
              <span className="font-semibold">City:</span>{" "}
              {endorsementData?.city
                ? endorsementData?.city
                : endorsementData?.City
                ? endorsementData?.City
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <span className="font-semibold">State:</span>{" "}
              {endorsementData?.state
                ? endorsementData?.state
                : endorsementData?.State
                ? endorsementData?.State
                : "-"}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <span className="font-semibold">Zip:</span>{" "}
              {endorsementData?.zip
                ? endorsementData?.zip
                : endorsementData?.Zip
                ? endorsementData?.Zip
                : "-"}
            </Col>
          </Row>
        </Container>
      </div>

      {isRequestException &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {endorsement.status === "Submitted" ? (
        <>
          <article className="mt-3">
            <p>
              Approving this endorsement will add the above additionalInsured to
              the policy and notify the insured of the change.
            </p>
          </article>
          {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
            <ApprovalButtons
              onApprove={approveAUEndorsement}
              onDecline={() => setRejectionModal(true)}
            />
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={requestExceptionModeSetting}
          />
        </>
      ) : null}
    </div>
  );
}
