import React from "react";
import readXlsxFile from "read-excel-file";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  runTransaction,
} from "firebase/firestore";
import { auth, db } from "../../../../firebase";
import updateSystemLog from "../../../utils/updateSystemLog";
import hasDuplicates from "../../../utils/hasDuplicates";
import useGeneralState from "../../../../Context/actions/useGeneralState";
import "react-datepicker/dist/react-datepicker.css";
import { generateSearchWords, isExpired } from "../../../../utils/helpers";
import {
  filterRightPoliciesDetails,
  setPolicyInfo,
  setPolicyInfoForEndorsement,
} from "../setPolicyInfo";
import {
  selectIfNewOrExistingSubcollection,
  validateIfBlacklisted,
  validateIfExists,
} from "../../../utils/validateIfBlacklisted";
import { convertDatesToTimestamps } from "../individualToEndorsement.";
import { getDocumentPaths } from "../multipleToEndorsements";

const useImportDrivers = (props) => {
  const policyID = props.policyID;
  const { controlToast } = useGeneralState();
  const { agencyID, insuredID } = props.formData;
  const formData = props.formData;
  const setSuccess = props.setSuccess;
  const endoEffectiveDate = props.endoEffectiveDate;
  const goBack = props.goBack;
  const allFlag = props?.allFlag;

  const splitFirstAndRest = (input) => {
    if (!input) {
      return {
        first: "",
        rest: "",
      };
    }
    const [first, ...rest] = input.split(" ");
    return {
      first: first.toUpperCase().trim(),
      rest: rest.join(" ").toUpperCase().trim(),
    };
  };

  const handleDate = (dateString) => {
    if (typeof dateString === "string") {
      const shortFormatRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
      if (shortFormatRegex.test(dateString)) {
        const [month, day, year] = dateString.split("/");
        let date = new Date(`${year}-${month}-${day}`);
        if (date === "Invalid Date") {
          date = new Date(`${year}-${day}-${month}`);
        }
        if (date === "Invalid Date") {
          date = null;
        }
        return date;
      } else {
        return new Date(dateString);
      }
    }
    return dateString;
  };

  const handleUploadFile = async (
    file,
    setLoading,
    policyIDToUse,
    documentTypeToUse,
    renewalProcess,
    excelRowData
  ) => {
    if (setLoading) setLoading(true);
    if (!file && !excelRowData) return console.log("no file");
    const startInfoRow = 8;
    let rows = excelRowData
      ? excelRowData
      : await readXlsxFile(file, { sheet: 2 });
    const prevValues = [...rows];
    const values = prevValues.splice(startInfoRow);
    let prevDupeArray = [...rows];
    const dupeArray = prevDupeArray.splice(startInfoRow);
    try {
      const existingDriversSnapshot = await getDocs(
        collection(db, `agencies/${agencyID}/insureds/${insuredID}/drivers`)
      );
      const drivers = existingDriversSnapshot.docs.map((doc) => doc.data());

      let isTheSamePolicyYouAreAboutToDuplicate = false;
      drivers.forEach((driver) => {
        driver.policiesDetails.forEach((policyDetail) => {
          if (
            policyDetail.policyID === policyIDToUse ||
            policyDetail.id === policyIDToUse
          ) {
            isTheSamePolicyYouAreAboutToDuplicate = true;
          }
        });
      });

      const existingDrivers = existingDriversSnapshot.docs.map(
        (doc) => doc.data().licenseNumber
      );

      function hasIntersection(existinglicenseNumbers, driversArray) {
        const licenseNumberSet = new Set(existinglicenseNumbers);
        for (const driver of driversArray) {
          const licenseNumber = driver[4];
          if (licenseNumberSet.has(licenseNumber)) {
            return true;
          }
        }
        return false;
      }

      const valuesToImport = values.map((value) => {
        let resultObj = {};
        value.forEach((val, valIndex) => {
          if (valIndex === 0) {
            const { first, rest } = splitFirstAndRest(val);
            resultObj["driverFirst"] = first;
            resultObj["driverLast"] = rest;
          }
          if (valIndex === 1) resultObj["dob"] = val;
          if (valIndex === 2) resultObj["licenseCountry"] = val;
          if (valIndex === 3) resultObj["licenseState"] = val;
          if (valIndex === 4) resultObj["licenseNumber"] = val;
          if (valIndex === 5) resultObj["mxNMP"] = val;
          if (valIndex === 6)
            resultObj["licenseExpiration"] = handleDate(val);
          if (valIndex === 7) resultObj["yoe"] = val;
          // if (valIndex === 8) resultObj["doa"] = val;
        });
        return resultObj;
      });

      if (valuesToImport.length === 0) {
        const finalMessage = "Your drivers sheet is empty. Please fill in the required fields and resubmit.";
        alert(finalMessage);
        throw new Error(finalMessage);
      }

      const missingFieldsMessages = [];

      valuesToImport.forEach((valueToImport, index) => {
        const missingFields = [];
        const extraFields = [];

        if (!valueToImport.driverFirst || !valueToImport.driverLast) missingFields.push("Full Name (a First and a Last Name included)");
        if (!valueToImport.dob) missingFields.push("DOB");
        if (!valueToImport.licenseCountry) missingFields.push("Country");
        if (!valueToImport.licenseNumber) missingFields.push("License No.");
        if (!valueToImport.licenseExpiration)
          missingFields.push("License Expiration Date");
        if (!valueToImport.yoe) missingFields.push("YOE");
        // if (!valueToImport.doa) missingFields.push("Date of Antiquity");
        if (valueToImport.licenseCountry === "MX") {
          if (!valueToImport.mxNMP) missingFields.push("NMP");
          if (valueToImport.licenseState) extraFields.push("License State");
        } else if (valueToImport.licenseCountry === "USA") {
          if (!valueToImport.licenseState) missingFields.push("License State");
          if (valueToImport.mxNMP) extraFields.push("NMP");
        } else if (valueToImport.licenseCountry === "CA") {
          if (valueToImport.licenseState) extraFields.push("License State");
          if (valueToImport.mxNMP) extraFields.push("NMP");
        }

        const thereIsFullName = Boolean(valueToImport.driverFirst && valueToImport.driverLast);
        const fullName = ` ${valueToImport.driverFirst} ${valueToImport.driverLast},`;
        if (missingFields.length > 0) {
          missingFieldsMessages.push(
            `In row ${index + 1},${thereIsFullName ? fullName : ''} you need to type: ${missingFields.join(", ")}`
          );
        }
        if (extraFields.length > 0) {
          missingFieldsMessages.push(
            `In row ${index + 1},${thereIsFullName ? fullName : ''} remove: ${extraFields.join(", ")}`
          );
        }
      });
      const finalMessage = missingFieldsMessages.join("\n");
      if (missingFieldsMessages.length > 0) {
        goBack();
        alert(finalMessage);
        throw new Error(finalMessage);
      }

      const licenseNumbersToImport = valuesToImport.map(
        (valueToImport) => valueToImport.licenseNumber
      );
      const countryCodes = valuesToImport.map(
        (valueToImport) => valueToImport.licenseCountry
      );
      const valuesToValidate = drivers.filter((driver) => {
        return licenseNumbersToImport.includes(driver.licenseNumber);
      });
      const atLeastOneIsStillActive = valuesToValidate.some((driver) => {
        const policiesDetails = filterRightPoliciesDetails(
          driver.policiesDetails,
          policyIDToUse
        );
        const rightDetailStatus = policiesDetails?.status;
        const rightDetailRemovedDate = policiesDetails?.removedDate;

        return (
          !isExpired(rightDetailRemovedDate) &&
          (rightDetailStatus === "Active" ||
            rightDetailStatus === "Pending" ||
            rightDetailStatus === "Pending Deletion" ||
            rightDetailStatus === "Pending Exclusion")
        );
      });

      const isNotValidCountryCode = countryCodes.some((code) => {
        return Boolean(code !== "USA" && code !== "CA" && code !== "MX");
      });

      if (isNotValidCountryCode) {
        goBack();
        alert(
          "Your sheet contains invalid country codes. The valid codes are MX, USA and CA. Please fix these errors and resubmit. No drivers have been added."
        );
        throw new Error("Invalid country codes in the sheet");
      }

      if (
        (hasIntersection(existingDrivers, dupeArray) ||
          hasDuplicates(dupeArray, 6)) &&
        atLeastOneIsStillActive &&
        ((renewalProcess && documentTypeToUse === "Policy") ||
          !renewalProcess) &&
        isTheSamePolicyYouAreAboutToDuplicate
      ) {
        goBack();
        alert(
          "Your sheet contains license numbers already listed on this policy or duplicate license number numbers with active effective dates. Please fix these errors and resubmit. No drivers have been added."
        );
        throw new Error();
      } else {
        const handleBatchDriverUpload = async (myValues, isPolicy) => {
          try {
            const subcollectionIds = [];
            for (const driver of myValues) {
              const { first, rest } = splitFirstAndRest(driver[0]);
              const docData = {
                driverFirst: first || 0,
                driverLast: rest || 0,
                driverDOB: driver[1] || 0,
                dob: driver[1] || 0,
                licenseCountry: driver[2] || 0,
                licenseState: driver[3] || 0,
                licenseNumber: driver[4] || 0,
                mxNMP: driver[5] || 0,
                licenseExpiration:
                  handleDate(driver[6]) &&
                  handleDate(driver[6]) !== "Invalid Date"
                    ? handleDate(driver[6])
                    : new Date(),
                yoe: driver[7] || 0,
                // doa: driver[8] || 0,
                driverLookupStatus: "Incomplete",
                dateCreated: new Date(),
              };

              const verifiedInExistingData = await validateIfExists(
                [docData],
                agencyID,
                insuredID,
                "drivers"
              );

              const payload = setPolicyInfo(
                verifiedInExistingData[0],
                {
                  ...props?.formData,
                  policyEffectiveDate: props.formData?.effectiveDate,
                  glPolicyNo: props.formData?.glPolicyNo,
                  alPolicyNo: props.formData?.alPolicyNo,
                  policyID: policyIDToUse,
                  status: !renewalProcess && !isPolicy ? "Active" : "Pending",
                  documentType: documentTypeToUse,
                  documentSource:
                    documentTypeToUse === "Policy" ? "Endorsement" : "Policy",
                  policyStatus: props?.formData?.status,
                  agencyID,
                  insuredID,
                },
                "direct-addition",
                false,
                endoEffectiveDate
              );

              const response = await selectIfNewOrExistingSubcollection(
                payload,
                agencyID,
                insuredID,
                "drivers"
              );

              if (response.id) {
                subcollectionIds.push(response.id);
              }
            }
            console.log("Batch upload successful!");
            if (setLoading) setLoading(false);
            return {
              ids: subcollectionIds,
            };
          } catch (error) {
            console.error("Error during batch upload:", error);
            if (setLoading) setLoading(false);
          }
        };

        const handleBatchDriverEndorsementUpload = async (
          myValues,
          subcollectionIds
        ) => {
          let endorsementEffectiveDate;
          if (
            props.formData.documentType === "Policy" ||
            props.formData.status === "In-Renewal"
          ) {
            endorsementEffectiveDate = endoEffectiveDate;
          } else {
            endorsementEffectiveDate = "";
          }
          let driverData = myValues.map((driver, driverIndex) => {
            const { first, rest } = splitFirstAndRest(driver[0]);
            return {
              driverFirst: first || 0,
              driverLast: rest || 0,
              driverDOB: driver[1] || 0,
              dob: driver[1] || 0,
              licenseCountry: driver[2] || 0,
              licenseState: driver[3] || 0,
              licenseNumber: driver[4] || 0,
              mxNMP: driver[5] || 0,
              licenseExpiration:
                handleDate(driver[6]) &&
                handleDate(driver[6]) !== "Invalid Date"
                  ? handleDate(driver[6])
                  : new Date(),
              yoe: driver[7] || 0,
              // doa: driver[8] || 0,
              driverLookupStatus: "Incomplete",
              dateCreated: new Date(),
              status: "Pending",
              endoEffectiveDate: endorsementEffectiveDate,
              id: subcollectionIds[driverIndex],
            };
          });
          driverData = driverData.map((driver) => {
            return setPolicyInfo(
              driver,
              {
                ...props?.formData,
                policyEffectiveDate: props.formData?.effectiveDate,
                glPolicyNo: props.formData?.glPolicyNo,
                alPolicyNo: props.formData?.alPolicyNo,
                policyID: policyIDToUse,
                status: "Pending",
                documentType: documentTypeToUse,
                documentSource:
                  documentTypeToUse === "Policy" ? "Endorsement" : "Policy",
                policyStatus: props?.formData?.status,
                agencyID,
                insuredID,
              },
              "endo-addition",
              false,
              endoEffectiveDate
            );
          });

          const countersDocRef = doc(db, "unique-numbers", "endorsements");
          await runTransaction(db, async (transaction) => {
            const countersDoc = await transaction.get(countersDocRef);
            const currentCounter = countersDoc.data().lastCount;
            const newCounter = currentCounter + 1;
            transaction.update(countersDocRef, {
              lastCount: newCounter,
            });
            const endosRef = collection(
              db,
              "policy-applications",
              policyIDToUse,
              "endorsements"
            );

            const type = "Add Drivers";
            const payloadForEndo = setPolicyInfoForEndorsement(
              driverData,
              policyIDToUse
            );
            const convertedData = convertDatesToTimestamps(payloadForEndo);
            const docPaths = getDocumentPaths(
              convertedData,
              type,
              props?.formData
            );

            const endoPolicyID = !renewalProcess
              ? props?.formData?.id
              : props?.formData?.documentType === "Policy"
              ? props?.formData?.id
              : props?.formData?.renewalPolicyID;

            const payloadEndo = {
              endorsementNumber: parseInt(newCounter),
              type,
              data: docPaths,
              dateCreated: new Date(),
              policyID: endoPolicyID,
              status: "Submitted",
              namedInsured: props.insuredInfo.company,
              alPolicyNo: props.formData.alPolicyNo,
              policyEffectiveDate: props.formData.effectiveDate,
              endoEffectiveDate,
              documentSource: "Endorsement",
              author: auth.currentUser.displayName,
              authorID: auth.currentUser.uid,
              agencyID: props.formData.agencyID,
              insuredID: props.formData.insuredID,
            };

            const thereIsValidSearchValue = convertedData?.some((data) => {
              const thereIsAValue = Boolean(
                data?.licenseNumber ||
                  data?.driverFirst ||
                  data?.driverLast ||
                  data?.truckVIN ||
                  data?.trailerVIN
              );
              return thereIsAValue;
            });

            if (convertedData?.length > 0 && thereIsValidSearchValue) {
              payloadEndo['searchData'] = []
              convertedData.forEach(data => {
                if (data?.driverFirst || data?.driverLast) {
                  const searchName = String(`${data?.driverFirst ? data?.driverFirst : ""} ${data?.driverLast ? data?.driverLast : ""}`).trim()
                  const words = generateSearchWords(searchName)
                  const parsedFullName = [...words, searchName]
                  payloadEndo['searchData'].push(...parsedFullName)
                }
                if (data?.licenseNumber) {
                  payloadEndo['searchData'].push(String(data?.licenseNumber))
                }
                if (data?.truckVIN) {
                  payloadEndo['searchData'].push(String(data?.truckVIN))
                }
                if (data?.trailerVIN) {
                  payloadEndo['searchData'].push(String(data?.trailerVIN))
                }
              })
            }

            await addDoc(endosRef, payloadEndo);
            if (setSuccess) setSuccess(true);
          });
        };

        const docInfo = values.map((driver) => {
          const { first, rest } = splitFirstAndRest(driver[0]);
          return {
            driverFirst: first.toUpperCase().trim() || 0,
            driverLast: rest.toUpperCase().trim() || 0,
            driverDOB: driver[1] || 0,
            dob: driver[1] || 0,
            licenseCountry: driver[2] || 0,
            licenseState: driver[3] || 0,
            licenseNumber: driver[4] || 0,
            mxNMP: driver[5] || 0,
            licenseExpiration:
              handleDate(driver[6]) && handleDate(driver[6]) !== "Invalid Date"
                ? handleDate(driver[6])
                : new Date(),
            yoe: driver[7] || 0,
            // doa: driver[8] || 0,
            driverLookupStatus: "Incomplete",
            dateCreated: new Date(),
          };
        });

        const verifiedInBlacklistDrivers = await validateIfBlacklisted(docInfo);
        const someDriverIsBlacklisted = verifiedInBlacklistDrivers.some(
          (possibleBlacklistedDriver) =>
            Boolean(possibleBlacklistedDriver.blacklist)
        );

        if (someDriverIsBlacklisted) {
          controlToast(true, "Some of the users is blacklisted", "error");
          if (setLoading) setLoading(false);
          throw new Error("Some of the users are blacklisted");
        }

        const uploadedDrivers = await handleBatchDriverUpload(
          values,
          documentTypeToUse === "Policy"
        );
        if (documentTypeToUse === "Policy") {
          await handleBatchDriverEndorsementUpload(values, uploadedDrivers.ids);
        }
        updateSystemLog(
          auth,
          policyIDToUse,
          "Drivers List Imported To Application For " +
            props.insuredInfo.company,
          "Drivers List Uploaded",
          { ...values, policyID: policyIDToUse }
        );
        if (!allFlag) goBack();
        if (
          renewalProcess &&
          !renewalProcess &&
          documentTypeToUse === "Policy"
        ) {
          return uploadedDrivers.ids;
        }
      }
    } catch (error) {
      console.error("Error fetching existing drivers:", error);
      throw new Error(error);
    }
  };

  const mirrorLogic = async (file, setLoading, excelRowData) => {
    const isRenewalApplication =
      formData.status === "In-Renewal" &&
      formData.documentType === "Application";
    const isRenewalPolicy =
      formData.status === "In-Renewal" && formData.documentType === "Policy";
    const notARenewalProcess = formData.status !== "In-Renewal";
    try {
      if (notARenewalProcess) {
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          formData.documentType,
          false,
          excelRowData
        );
        const isPolicy = formData.documentType === "Policy";
        const message = isPolicy
          ? "You created an Add Drivers endorsement successfully!"
          : "You created a list of Drivers successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalApplication) {
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Application",
          true,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          formData.renewalPolicyID,
          "Policy",
          true,
          excelRowData
        );
        const message = "You created an Add Drivers endorsement successfully!";
        controlToast(true, message, "success");
      }
      if (isRenewalPolicy) {
        await handleUploadFile(
          file,
          setLoading,
          formData.renewalApplicationID,
          "Application",
          true,
          null,
          excelRowData
        );
        await handleUploadFile(
          file,
          setLoading,
          policyID,
          "Policy",
          true,
          excelRowData
        );
        const message = "You created an Add Drivers endorsement successfully!";
        if (!allFlag) {
          controlToast(true, message, "success");
        }
      }
    } catch (error) {
      const isBlacklisted = Boolean(
        error?.message === "Error: Some of the users are blacklisted"
      );
      const isInvalidCountryCode = Boolean(
        error?.message === "Error: Invalid country codes in the sheet"
      );
      if (!allFlag) {
        controlToast(
          true,
          isBlacklisted
            ? "Some of the users is blacklisted"
            : isInvalidCountryCode
            ? "Invalid country codes in the sheet, Just MX, USA and CA are allowed"
            : "No driver was created",
          "error"
        );
      }
      console.error(error);
      if (!allFlag) goBack();
      throw error;
    }
  };

  return { mirrorLogic };
};

export default useImportDrivers;
