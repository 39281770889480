import React from "react";
import UserListCheckbox from "./UserListCheckbox";

export default function StandardPermissions({ selectedUser, userIsAdmin }) {
  return (
    <>
      {/* User/Company Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"userPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"userPermissions"}
          userID={selectedUser.id}
        />
        <label htmlFor="userPermissions" className="text-md font-medium ml-2">
          Modify Users & Company Info
        </label>
      </div>

      {/* Insured Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"insuredPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"insuredPermissions"}
          userID={selectedUser.id}
        />
        <label
          htmlFor="insuredPermissions"
          className="text-md font-medium ml-2"
        >
          Edit Insureds
        </label>
      </div>

      {/* Policy Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"policyPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"policyPermissions"}
          userID={selectedUser.id}
        />
        <label htmlFor="policyPermissions" className="text-md font-medium ml-2">
          Modify Policies/Applications
        </label>
      </div>

      {/* Notification Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"notificationPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"notificationPermissions"}
          userID={selectedUser.id}
        />
        <label
          htmlFor="notificationPermissions"
          className="text-md font-medium ml-2"
        >
          Manage Notifications
        </label>
      </div>

      {/* Client Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"clientPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"clientPermissions"}
          userID={selectedUser.id}
        />
        <label htmlFor="clientPermissions" className="text-md font-medium ml-2">
          Create Clients
        </label>
      </div>

      {/* Loss Control Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"lossControlPermissions"}
          userData={selectedUser} // Use selectedUser data
          field={"lossControlPermissions"}
          userID={selectedUser.id}
        />
        <label
          htmlFor="lossControlPermissions"
          className="text-md font-medium ml-2"
        >
          View/Edit Loss Control
        </label>
      </div>
      {/* Loss Control Permissions */}
      <div className="flex items-center">
        <UserListCheckbox
          disabled={selectedUser.companyOwner && !userIsAdmin}
          name={"hasFinanceAuthority"}
          userData={selectedUser} // Use selectedUser data
          field={"hasFinanceAuthority"}
          userID={selectedUser.id}
        />
        <label
          htmlFor="hasFinanceAuthority"
          className="text-md font-medium ml-2"
        >
          View/Edit Finance
        </label>
      </div>
    </>
  );
}
