import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import commodityData from "../../Commodities/utils/commodityData";
import camelCaseToTitleCase from "../../../utils/camelCaseToTitleCase";
import findHighestActualCashValue from "../../utils/findHighestActualCashValue";
import axios from "axios";
import config from "../../../../config/env";
import calculateGrandTotalOfActualCashValues from "../../utils/calculateGrandTotalOfActualCashValues";
import numberWithCommas from "../../../utils/numberWithCommas";
import ApiService from "../../../../services/southern-api/apiService";

const downloadInformationSheet = async (e, formData, insuredInfo, t) => {
  e.preventDefault();

  const apiService = new ApiService();

  const agencyID = formData.agencyID;
  const insuredID = formData.insuredID;
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;
  const apd = formData.apdDetails || {};
  const mtc = formData.mtcDetails || {};
  const losses = formData.lossHistory || {};

  let currentTruckList = [];
  let currentTrailerList = [];
  const truckSnapshot = await getDocs(
    collection(db, "agencies", agencyID, "insureds", insuredID, "power-units")
  );
  truckSnapshot.forEach((doc) => {
    const policyID = formData.id;
    const policiesDetails = doc.data().policiesDetails;
    const isActiveOnThisPolicy = policiesDetails.filter(
      (policiesDetails) =>
        policiesDetails.id === policyID && policiesDetails.status === "Active"
    );
    const foundPolicy = isActiveOnThisPolicy[0];
    isActiveOnThisPolicy.length > 0 &&
      currentTruckList.push({ ...doc.data(), ...foundPolicy, id: doc.id });
  });
  const trailerSnapshot = await getDocs(
    collection(db, "agencies", agencyID, "insureds", insuredID, "trailers")
  );
  trailerSnapshot.forEach((doc) => {
    const policyID = formData.id;
    const policiesDetails = doc.data().policiesDetails;
    const isActiveOnThisPolicy = policiesDetails.filter(
      (policiesDetails) =>
        policiesDetails.id === policyID && policiesDetails.status === "Active"
    );
    const foundPolicy = isActiveOnThisPolicy[0];
    isActiveOnThisPolicy.length > 0 &&
      currentTrailerList.push({ ...doc.data(), ...foundPolicy, id: doc.id });
  });

  let tiLimit = "N/A";

  if (apd?.trailerInterchangeTarget || mtc?.trailerInterchangeTarget) {
    tiLimit = apd?.trailerInterchangeTarget
      ? apd.trailerInterchangeLimit
      : mtc?.trailerInterchangeLimit || "N/A";
  }

  let tiExposure = "N/A";
  if (
    formData.trailerInterchangeTarget &&
    (apd?.tiExposure || mtc?.tiExposure)
  ) {
    tiExposure = apd?.tiExposure || mtc?.tiExposure || "N/A";
  }

  let coverage = "";
  if (hasAPD || hasMTC) {
    if (hasAPD && hasMTC) {
      coverage = "Auto Physical Damage and Motor Truck Cargo (APD & MTC)";
    } else if (hasAPD) {
      coverage = "Auto Physical Damage (APD)";
    } else if (hasMTC) {
      coverage = "Motor Truck Cargo (MTC)";
    }
  }

  // Prepare commodities data
  const myCommodityData = commodityData(t);
  const commoditiesWithoutValues = Object.keys(
    formData.standardCommodityPercentages
  ).map((key) => ({
    commodityDescription: camelCaseToTitleCase(key),
    commodityLoad: Number(formData.standardCommodityPercentages[key]),
  }));
  const commodities = commoditiesWithoutValues.map((commodity) => {
    const commodityData = myCommodityData.find(
      (data) => data.commodityDescription === commodity.property
    );
    return {
      ...commodity,
      commodityMaxValue: commodityData?.value || 0,
      commodityAvgValue: "",
    };
  });

  const getPowerUnitCount = async () => {
    const truckList = currentTruckList;
    let powerUnitCountAPD;
    let powerUnitCountMTC;
    const powerUnitCount = truckList.filter((truck) => {
      const policiesDetails = truck.policiesDetails;
      const matchingPolicies =
        policiesDetails.filter(
          (policiesDetails) =>
            policiesDetails.id === formData.id &&
            policiesDetails.status === "Active"
        ).length > 0;
      if (matchingPolicies) {
        if (truck.hasAPDCoverage) {
          powerUnitCountAPD++;
        } else if (truck.hasMTCCoverage) {
          powerUnitCountMTC++;
        }
      }
      return matchingPolicies;
    }).length;
    return [powerUnitCount, powerUnitCountAPD, powerUnitCountMTC];
  };

  const getTrailerCount = async () => {
    const trailerList = currentTrailerList;
    trailerSnapshot.forEach((doc) => {
      trailerList.push({ ...doc.data(), id: doc.id });
    });
    const powerUnitCount = trailerList.filter((trailer) => {
      const policiesDetails = trailer.policiesDetails;
      const matchingPolicies =
        policiesDetails.filter(
          (policiesDetails) =>
            policiesDetails.id === formData.id &&
            policiesDetails.status === "Active"
        ).length > 0;
      return matchingPolicies;
    }).length;
    return powerUnitCount;
  };

  const trailerCount = await getTrailerCount();
  const powerUnitArray = await getPowerUnitCount();
  const powerUnitCount = powerUnitArray[0];
  const powerUnitCountWithAPD = powerUnitArray[1];
  const powerUnitCountWithMTC = powerUnitArray[2];

  const data = {
    namedInsured: insuredInfo.company,
    submissionDate: new Date().toLocaleDateString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }),
    dba: formData.dba,
    mailingAddress: insuredInfo.mailingAddress,
    physicalAddress: insuredInfo.physicalAddress,
    dot: insuredInfo.mcNumber + " / " + insuredInfo.dot,
    effectiveDate: formData?.effectiveDate
      ?.toDate()
      .toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      }),
    tiv: calculateGrandTotalOfActualCashValues(
      currentTruckList,
      currentTrailerList
    ),
    anyOneLoss: calculateGrandTotalOfActualCashValues(
      currentTruckList,
      currentTrailerList
    ),
    anyOneUnitLimit: findHighestActualCashValue(currentTruckList),
    numberOfUnits: powerUnitCount,
    numberOfTrailers: trailerCount,
    maxUnitValue: findHighestActualCashValue(currentTruckList),
    maxTrailerValue: findHighestActualCashValue(currentTrailerList),
    commodities,
    towingLimit: !apd?.towingStorageAndCleanUp
      ? "N/A"
      : apd?.towingAndStorageLimit || "N/A",
    nonOwnedAutoLimit: apd?.nonOwnedHiredAutoLimit || "",
    tiLimit,
    tiExposure,
    coverage,
    year1APDDeductible: losses?.year1APDLossData?.deductible,
    year1APDLosses: losses?.year1APDLossData?.losses,
    year1APDClaims: losses?.year1APDLossData?.howManyClaims,
    year1MTCDeductible: losses?.year1MTCLossData?.deductible,
    year1MTCLosses: losses?.year1MTCLossData?.losses,
    year1MTCClaims: losses?.year1MTCLossData?.howManyClaims,
    year1tiv: losses?.year1MTCLossData?.tiv || losses?.year1APDLossData?.tiv,
    year1units:
      losses?.year1MTCLossData?.numberOfUnits ||
      losses?.year1APDLossData?.numberOfUnits,
    year1mileage:
      losses?.year1MTCLossData?.mileage || losses?.year1APDLossData?.mileage,
    year1apdCarrier: losses?.year1APDLossData?.carrier,
    year1mtcCarrier: losses?.year1MTCLossData?.carrier,
    year2APDDeductible: losses?.year2APDLossData?.deductible,
    year2APDLosses: losses?.year2APDLossData?.losses,
    year2APDClaims: losses?.year2APDLossData?.howManyClaims,
    year2MTCDeductible: losses?.year2MTCLossData?.deductible,
    year2MTCLosses: losses?.year2MTCLossData?.losses,
    year2MTCClaims: losses?.year2MTCLossData?.howManyClaims,
    year2tiv: losses?.year2MTCLossData?.tiv || losses?.year2APDLossData?.tiv,
    year2units:
      losses?.year2MTCLossData?.numberOfUnits ||
      losses?.year2APDLossData?.numberOfUnits,
    year2mileage:
      losses?.year2MTCLossData?.mileage || losses?.year2APDLossData?.mileage,
    year2apdCarrier: losses?.year2APDLossData?.carrier,
    year2mtcCarrier: losses?.year2MTCLossData?.carrier,
    year3APDDeductible: losses?.year3APDLossData?.deductible,
    year3APDLosses: losses?.year3APDLossData?.losses,
    year3APDClaims: losses?.year3APDLossData?.howManyClaims,
    year3MTCDeductible: losses?.year3MTCLossData?.deductible,
    year3MTCLosses: losses?.year3MTCLossData?.losses,
    year3MTCClaims: losses?.year3MTCLossData?.howManyClaims,
    year3tiv: losses?.year3MTCLossData?.tiv || losses?.year3APDLossData?.tiv,
    year3units:
      losses?.year3MTCLossData?.numberOfUnits ||
      losses?.year3APDLossData?.numberOfUnits,
    year3mileage:
      losses?.year3MTCLossData?.mileage || losses?.year3APDLossData?.mileage,
    year3apdCarrier: losses?.year3APDLossData?.carrier,
    year3mtcCarrier: losses?.year3MTCLossData?.carrier,
    year4APDDeductible: losses?.year4APDLossData?.deductible,
    year4APDLosses: losses?.year4APDLossData?.losses,
    year4APDClaims: losses?.year4APDLossData?.howManyClaims,
    year4MTCDeductible: losses?.year4MTCLossData?.deductible,
    year4MTCLosses: losses?.year4MTCLossData?.losses,
    year4MTCClaims: losses?.year4MTCLossData?.howManyClaims,
    year4tiv: losses?.year4MTCLossData?.tiv || losses?.year4APDLossData?.tiv,
    year4units:
      losses?.year4MTCLossData?.numberOfUnits ||
      losses?.year4APDLossData?.numberOfUnits,
    year4mileage:
      losses?.year4MTCLossData?.mileage || losses?.year4APDLossData?.mileage,
    year4apdCarrier: losses?.year4APDLossData?.carrier,
    year4mtcCarrier: losses?.year4MTCLossData?.carrier,
    year5APDDeductible: losses?.year5APDLossData?.deductible,
    year5APDLosses: losses?.year5APDLossData?.losses,
    year5APDClaims: losses?.year5APDLossData?.howManyClaims,
    year5MTCDeductible: losses?.year5MTCLossData?.deductible,
    year5MTCLosses: losses?.year5MTCLossData?.losses,
    year5MTCClaims: losses?.year5MTCLossData?.howManyClaims,
    year5tiv: losses?.year5MTCLossData?.tiv || losses?.year5APDLossData?.tiv,
    year5units:
      losses?.year5MTCLossData?.numberOfUnits ||
      losses?.year5APDLossData?.numberOfUnits,
    year5mileage:
      losses?.year5MTCLossData?.mileage || losses?.year5APDLossData?.mileage,
    year5apdCarrier: losses?.year5APDLossData?.carrier,
    year5mtcCarrier: losses?.year5MTCLossData?.carrier,
    apdDeductibleA: numberWithCommas(apd?.targetDeductible),
    mtcDeductibleA: numberWithCommas(mtc?.targetDeductible),
  };

  try {
    const response = await apiService.createKPBrokersSheet(data);
    window.open(response, "_blank");
  } catch (error) {
    console.error(error);
  }
};

export default downloadInformationSheet;
