import React, { useState } from "react";
import LosscontrolSidebar from "../../Components/LossControl/LossControlSidebar";
import DriverScoring from "../../Components/LossControl/DriverScoring";
import TruckMasterList from "../../Components/LossControl/TruckMasterList";
import Inspections from "../../Components/LossControl/Inspections";
import Reports from "../../Components/Reports";

function Home() {
  return <div>Welcome to the Home Page!</div>;
}

function Factfinders() {
  return <div>Factfinders Content Here!</div>;
}

function LCReports() {
  return <div>Loss Control Reports Content Here!</div>;
}

export default function LossControl() {
  const [activeComponent, setActiveComponent] = useState("Driver Scoring");

  const renderComponent = () => {
    switch (activeComponent) {
      case "Home":
        return <Home />;
      case "Driver Scoring":
        return <DriverScoring />;
      case "Factfinders":
        return <Factfinders />;
      case "LC Reports":
        return <LCReports />;
      case "VIN Master List":
        return <TruckMasterList />;
      case "View Inspections":
        return <Inspections />;
      case "Reports":
        return <Reports />;
      default:
        return <Home />;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      {/* Sidebar */}
      <LosscontrolSidebar setActiveComponent={setActiveComponent} />

      {/* Main Content */}
      <main style={{ padding: "20px", flex: 1, backgroundColor: "#f5f5f5" }}>
        {renderComponent()}
      </main>
    </div>
  );
}
