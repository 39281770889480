import React, { useEffect, useState } from "react";
import DynamicTable from "../assets/lists/DynamicTable";
import { collection, collectionGroup, doc, getDoc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";

const ProofOfFilingsList = () => {
  const [skeletonTableLoader, setSkeletonTableLoader] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [proofOfFilingsData, setProofOfFilingsData] = useState([]);

  const getAgencyAndInsured = async (agencyID, insuredDOT) => {
    const names = {};
    try {
      const agencyRef = doc(db, "agencies", agencyID);
      const agencySnap = await getDoc(agencyRef);
      if (agencySnap.exists()) {
        names["agencyName"] = agencySnap.data().name;
      }
      const insuredRef = query(
        collectionGroup(db, "insureds"),
        where("dot", "==", insuredDOT)
      );
      const querySnapshot = await getDocs(insuredRef);
      querySnapshot.forEach((doc) => {
        if (doc.data().company) {
          names["insuredName"] = doc.data().company
        }
      });
      return names;
    } catch (error) {
      console.error(error);
      return {
        agencyName: "",
        insuredName: "",
      };
    }
  };

  const formingTableData = async () => {
    const tableData = proofOfFilingsData?.map(async (data, dataIndex) => {
      const {
        creationDate,
        agencyID,
        insuredDOT,
        submittingUserName,
        filingType,
      } = data || {};
      const iconLeft = [];
      const iconRight = [
        {
          icon: "delete",
          action: true,
          toField: "action",
        },
      ];

      const actions = ["view"];

      // iconLeft.push({
      //   name: "redFlag-filings",
      //   toField: "creationDate",
      //   className: "text-[#8B0000]",
      //   onClick: null,
      // });

      // actions.push("redFlag-filings");

      const { agencyName, insuredName } = await getAgencyAndInsured(
        agencyID,
        insuredDOT
      );

      const parsedCreationDate = creationDate
        ? creationDate.toDate().toLocaleDateString("en-US", {
            month: "short",
            day: "2-digit",
            year: "numeric",
          })
        : null;

      const tableObject = {
        ...data,
        tableInfo: {
          creationDate: {
            text: parsedCreationDate,
          },
          agencyName: {
            text: agencyName,
          },
          namedInsured: {
            text: insuredName,
          },
          submittingUserName: {
            text: submittingUserName,
          },
          filingType: {
            text: filingType,
          },
        },
        dataIndex,
        iconLeft,
        iconRight,
      };
      tableObject["tableInfo"]["actions"] = {
        actions,
      };
      return tableObject;
    });
    const tableDataSolved = await Promise.all(tableData);
    return tableDataSolved;
  };

  useEffect(() => {
    setSkeletonTableLoader(true);
    const proofOfFilingsRef = collection(db, "proof-of-filings");
    const q = query(proofOfFilingsRef);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const proofOfFilingsResult = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProofOfFilingsData(proofOfFilingsResult);
      setSkeletonTableLoader(false);
    });
    return () => unsubscribe();
  }, []);

  const filterKeysForFilings = [
    "creationDate",
    "agencyName",
    "namedInsured",
    "submittingUserName",
    "filingType",
    "url",
  ];

  const columnsForFilings = [
    {
      name: "creationDate",
      label: "Creation Date",
    },
    {
      name: "agencyName",
      label: "Company",
    },
    {
      name: "namedInsured",
      label: "Named Insured",
    },
    {
      name: "submittingUserName",
      label: "Submitting User",
    },
    {
      name: "filingType",
      label: "Type",
    },
    {
      name: "action",
      label: "See Proof",
    },
  ];

  const handleOpenEditMode = (e, body) => {
    e.preventDefault();
    window.open(body.url, "_blank");
  };

  useEffect(() => {
    if (proofOfFilingsData.length > 0) {
      formingTableData().then((tableData) => {
        setTableData(tableData);
      });
    }
  }, [proofOfFilingsData]);

  return (
    <div>
      <DynamicTable
        skeletonLoader={skeletonTableLoader}
        columns={columnsForFilings}
        tableData={tableData}
        handleOpenEditMode={handleOpenEditMode}
        filterKeys={filterKeysForFilings}
        // name="filings"
      />
    </div>
  );
};

export default ProofOfFilingsList;
