import React, { useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { db } from "../../firebase";
import {
  collection,
  collectionGroup,
  doc,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import AddTrailers from "../../Components/underwriter/endorsements/AddTrailers";
// import AddTrailer from "../../Components/underwriter/endorsements/AddTrailer";
import AddPowerUnits from "../../Components/underwriter/endorsements/AddPowerUnits";
import AddDrivers from "../../Components/underwriter/endorsements/AddDrivers";
import AddDriver from "../../Components/underwriter/endorsements/AddDriver";
import {
  POLICY_ROUTE,
  UNDERWRITING_ENDORSEMENT_ROUTE,
} from "../../utils/routes";
import BigAlert from "../../Components/generals/Toast/BigAlert";
import RemoveOne from "../../Components/underwriter/endorsements/RemoveOne";
import {
  filterDriverKeysToSearch,
  multipleDriverTableColumn,
  removeMultipleDriversTable,
  removeDriverEndorsementDetails,
  removePowerUnitEndorsementDetails,
  removeTrailerEndorsementDetails,
  removeMultiplePowerUnitsTable,
  multiplePowerUnitsTableColumn,
  filterPowerUnitsKeysToSearch,
  removeMultipleTrailersTable,
  multipleTrailersTableColumn,
  filterTrailerKeysToSearch,
  addWaiverOfSubrogationEndorsementDetails,
} from "../../utils/endorsementDetails";
import RemoveMultiple from "../../Components/underwriter/endorsements/RemoveMultiple";
import { endsWithLowerCaseS } from "../../utils/helpers";
import AddOne from "../../Components/underwriter/endorsements/AddOne";
import SwapPowerUnits from "../../Components/underwriter/endorsements/SwapPowerUnits";
import AdditionalInsured from "../../Components/underwriter/endorsements/AdditionalInsured";
import GoBack from "../../Components/generals/GoBack";
import RequestExceptions from "../../Components/generals/Toast/RequestExceptions";
import { UserAuth } from "../../Context/AuthContent";
import useGeneralState from "../../Context/actions/useGeneralState";
import { endorsementPdfPayloads } from "../../utils/endorsementsPdfPayloads";
import DownloadButton from "../../Components/generals/DesignElements/DownloadIcon";
import SentLetterButton from "../../Components/generals/DesignElements/SentLetterIcon";
import Cancellation from "../../Components/underwriter/endorsements/Cancellation";
import ApiService from "../../services/southern-api/apiService";
import FormPopup from "../../Components/generals/Popup/FormPopup";
import PremiumsTable from "../../Components/underwriter/endorsements/AddPowerUnits/PremiumsTable";
import LoadingScreen from "../../Components/assets/LoadingScreen";
import useUsersState from "../../Context/actions/useUsersState";
import ModifyCommodities from "../../Components/underwriter/endorsements/ModifyCommodities";
import RedButton from "../../Components/assets/forms/RedButton";
import { ModeEdit as PencilIcon } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit"; // Importing the pencil icon
import { Button } from "@mui/material";
import ReturnToAgentMessage from "../../Components/PolicyLifecycleManager/ReturnToAgentModal/ReturnToAgentMessage";
import ReasonsModal from "../../Components/PolicyLifecycleManager/ReturnToAgentModal";

export const Endorsement = () => {
  const navigate = useNavigate();
  const {
    paramRefID: paramRefIDFromRoute,
    paramPolicyID: paramPolicyIDFromRoute,
  } = useParams();
  const [endorsement, setEndorsement] = useState({});
  const [gotEndo, setGotEndo] = useState(false);
  const [agencyName, setAgencyName] = useState("");
  const [agencyInfo, setAgencyInfo] = useState("");
  const [gotAgencyName, setGotAgencyName] = useState(false);
  const { user: auth } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [sendLetterLoading, setSendLetterLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { controlToast } = useGeneralState();

  const paramPolicyID = endorsement?.policyID || "";
  const paramRefID = endorsement?.id || "";

  const { user } = useUsersState();

  const role = user?.role || "";

  const getAgencyName = async (agencyID) => {
    if (!agencyID) return "";
    try {
      const agencyDocRef = doc(db, "agencies", agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      if (agencyDocSnap.exists()) {
        setAgencyInfo(agencyDocSnap?.data());
        setAgencyName(agencyDocSnap?.data().name);
        setGotAgencyName(true);
      } else {
        console.log("No such agency document with ID:", agencyID);
        setAgencyName("Not Found");
        setGotAgencyName(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [statusText, setStatusText] = useState("Underwrite");
  const [statusColorClass, setStatusColorClass] = useState("text-yellow-900");
  const [success, setSuccess] = useState(false);

  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    setLoadingData(true);
    let endorsementRef;
    if (paramRefIDFromRoute && paramPolicyIDFromRoute) {
      endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyIDFromRoute,
        "endorsements",
        paramRefIDFromRoute
      );
    } else {
      endorsementRef = query(
        collectionGroup(db, "endorsements"),
        where("endorsementNumber", "==", Number(paramRefIDFromRoute))
      );
    }

    gotEndo && !gotAgencyName && getAgencyName(endorsement?.agencyID);

    const fetchEndorsement = (ref, isRetry = false) => {
      return onSnapshot(ref, (doc) => {
        const isCollection = Boolean(
          paramRefIDFromRoute &&
            paramPolicyIDFromRoute &&
            doc?.exists &&
            doc?.exists()
        );

        const updatedEndorsementData = isCollection
          ? { ...doc.data(), id: doc.id }
          : { ...doc.docs?.[0]?.data(), id: doc.docs?.[0]?.id };

        if (updatedEndorsementData?.id) {
          setEndorsement(updatedEndorsementData);
          setGotEndo(true);
          setLoadingData(false);

          if (updatedEndorsementData.documentType === "Exception Request") {
            setStatusText("Exception Request");
            setStatusColorClass("text-orange-500");
          } else if (updatedEndorsementData.status === "Submitted") {
            setStatusText("Submitted");
            setStatusColorClass("text-yellow-500");
          } else if (updatedEndorsementData.status === "Returned") {
            setStatusText("Returned");
            setStatusColorClass("text-blue-500");
          } else if (
            updatedEndorsementData.status === "Approved" ||
            updatedEndorsementData.status === "Issued"
          ) {
            setStatusText("Approved");
            setStatusColorClass("text-green-900");
          } else if (updatedEndorsementData.status === "Declined") {
            setStatusText("Declined");
            setStatusColorClass("text-[#8B0000]");
          }
        } else if (!isRetry) {
          const newRef = query(
            collectionGroup(db, "endorsements"),
            where("endorsementNumber", "==", String(paramRefIDFromRoute))
          );
          unsubscribe();
          unsubscribe = fetchEndorsement(newRef, true);
        } else {
          setLoadingData(false);
          setEndorsement(null);
        }
      });
    };

    let unsubscribe = fetchEndorsement(endorsementRef);

    return () => unsubscribe();
  }, [gotEndo, endorsement?.status, gotAgencyName, endorsement?.agencyID]);

  const requestExceptionQ = collection(db, "request-exception-types");

  const [requestExceptionInputs, setRequestExceptionInputs] = useState([]);

  useEffect(() => {
    if (endorsement?.status !== "Declined") {
      return () => {};
    }
    const q = query(requestExceptionQ);
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setRequestExceptionInputs(values);
    });
    return () => {
      unsubscribe();
    };
  }, [
    endorsement?.status,
    endorsement?.agencyID,
    endorsement?.documentType,
    endorsement?.wasARequestException,
  ]);

  const [openReturnToAgentModal, setOpenReturnToAgentModal] = useState(false);

  const handleCloseReturnToAgentModal = () => {
    setOpenReturnToAgentModal(false);
  }

  const handleReturnEndorsement = async (e, reasons) => {
    e.preventDefault();
    const newStatusPayload =
      endorsement?.status === "Returned" ? "Submitted" : "Returned";
    const returnedReasons = newStatusPayload === "Returned" ? reasons : [];
    try {
      const endoRef = doc(db, endoDocRefAsString);
      await updateDoc(endoRef, {
        status: newStatusPayload,
        returnedReasons
      });
      setOpenReturnToAgentModal(false);
    } catch (error) {
      console.error(error);
    }
  }

  const handleGoBack = () => {
    navigate(`${POLICY_ROUTE}/${paramPolicyID}`);
  };

  const handleEndorsementCoverageTypeText = () => {
    if (
      endorsement?.type === "Add Waiver Of Subrogation" &&
      Boolean(endorsement?.data?.type)
    ) {
      if (endorsement?.data?.type === "AL") {
        return "Auto Liability";
      }
      if (endorsement?.data?.type === "GL") {
        return "General Liability";
      }
    }
    return "";
  };

  const [insuredInfo, setInsured] = useState({});

  const [openSendLetterModal, setOpenSendLetterModal] = useState(false);

  useEffect(() => {
    const handleGetInsured = async () => {
      const { agencyID, insuredID } = endorsement;
      const insuredDocRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID
      );
      const insuredDocSnap = await getDoc(insuredDocRef);
      if (insuredDocSnap.exists()) {
        const insured = {
          ...insuredDocSnap.data(),
          id: insuredDocSnap.id,
        };
        setInsured(insured);
      }
    };
    if (endorsement && endorsement?.agencyID && endorsement?.insuredID) {
      handleGetInsured();
    }
  }, [endorsement]);

  const [formData, setFormData] = useState({});
  const [
    endoEffectiveDateEditionModalLoading,
    setEndoEffectiveDateEditionModalLoading,
  ] = useState(false);
  const [endoEffectiveDateEditionModal, setEndoEffectiveDateEditionModal] =
    useState(false);

  useEffect(() => {
    const handleGetFormData = async () => {
      const { policyID } = endorsement;
      const policyDocRef = doc(db, "policy-applications", policyID);
      const policyDocSnap = await getDoc(policyDocRef);
      if (policyDocSnap.exists()) {
        const result = {
          ...policyDocSnap.data(),
          id: policyDocSnap.id,
        };
        setFormData(result);
      }
    };
    if (endorsement && endorsement?.policyID) {
      handleGetFormData();
    }
  }, [endorsement]);

  const handleDownload = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { agencyID, insuredID } = endorsement;
      const insuredDocRef = doc(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID
      );
      const insuredDocSnap = await getDoc(insuredDocRef);
      const agencyDocRef = doc(db, "agencies", agencyID);
      const agencyDocSnap = await getDoc(agencyDocRef);
      let agencyInfo = null;
      if (agencyDocSnap?.exists()) {
        agencyInfo = agencyDocSnap?.data();
      }
      if (insuredDocSnap.exists()) {
        const insuredInfo = insuredDocSnap.data();
        await endorsementPdfPayloads(
          {
            ...endorsement,
            id: paramRefID,
          },
          auth,
          insuredInfo,
          agencyInfo
        );
        setLoading(false);
        controlToast(true, "Endorsement printing was succesful", "success");
        return;
      }
      setLoading(false);
      controlToast(
        true,
        "There was an error finding insured info to print the endorsement",
        "error"
      );
      return;
    } catch (error) {
      setLoading(false);
      controlToast(
        true,
        "There was an error printing the endorsement",
        "error"
      );
    }
  };

  if (endorsement === null) {
    return <Navigate to="*" />;
  }

  if (!gotEndo) return null;

  const humanDate = (dateObj) => {
    if (!dateObj) return "";
    if (dateObj?.toDate) {
      return dateObj.toDate().toLocaleString();
    }
    if (dateObj?.toLocaleDateString) {
      return dateObj.toLocaleString();
    }
    const date = new Date(
      dateObj.seconds * 1000 + Math.floor(dateObj.nanoseconds / 1000000)
    );
    const humanDate = date.toLocaleString();
    return humanDate;
  };

  const inputs = [
    {
      label: "Cancellation Date",
      type: "date",
      name: "dateOfCancellation",
      minDate: new Date(),
      required: true,
    },
    {
      label: "NOC Proof",
      type: "file",
      storageFile: `files/filings-proof`,
      name: "pdfFile",
      required: true,
      multiple: true,
      dissapearWhenImgExists: true,
      showPastingOption: true,
    },
  ];

  const handleSendingLetter = async (e, values) => {
    e.preventDefault();
    try {
      setSendLetterLoading(true);
      const {
        agencyID,
        insuredID,
        policyID,
        authorID,
        author,
        underwriterEmail,
      } = endorsement;
      const apiService = new ApiService();
      const abdielEmail = "abdiel@southernstarmga.com";
      const coltEmail = "colt@southernstarmga.com";
      const email =
        underwriterEmail !== abdielEmail && underwriterEmail !== coltEmail
          ? underwriterEmail
          : "";
      const emails = email
        ? [abdielEmail, coltEmail, email]
        : [abdielEmail, coltEmail];
      function convertToFirestoreTimestamp(newDate) {
        const seconds = Math.floor(newDate.getTime() / 1000);
        return { seconds, nanoseconds: 0 };
      }
      const payload = {
        dateOfCancellation: convertToFirestoreTimestamp(
          values?.dateOfCancellation
        ),
        authorID,
        authorName: author,
        pdfFile: values?.pdfFile?.[0],
        policyID,
        agencyID,
        insuredID,
        emails,
      };
      await apiService.createCancelEventAndNoc(payload);
      setSendLetterLoading(false);
      controlToast(true, "Letter was sent successfully", "success");
      return;
    } catch (error) {
      setSendLetterLoading(false);
      controlToast(
        true,
        "There was an error printing the endorsement",
        "error"
      );
    }
  };

  const formSettingsSendLetter = {
    onSubmit: handleSendingLetter,
    inputs,
    buttonLabel: "Send Letter",
  };

  function isAtLeast14Days(timestamp) {
    if (endorsement.type !== "Exclude Drivers") return false;
    if (!timestamp || typeof timestamp.toDate !== "function") return false;
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const fourteenDaysAgo = new Date();
    fourteenDaysAgo.setDate(today.getDate() - 14);
    const sentDate = timestamp.toDate();
    return sentDate <= fourteenDaysAgo;
  }

  const isCollection = Boolean(paramRefID && paramPolicyIDFromRoute);
  const endoDocRefAsString = `policy-applications/${paramPolicyID}/endorsements/${paramRefID}`;

  const handleEffectiveDateEdition = async (e, values) => {
    e.preventDefault();
    try {
      setEndoEffectiveDateEditionModalLoading(true);
      const endoRef = doc(db, endoDocRefAsString);
      await updateDoc(endoRef, {
        endoEffectiveDate: values?.endoEffectiveDate,
      });
      setEndoEffectiveDateEditionModalLoading(false);
      setEndoEffectiveDateEditionModal(false);
    } catch (error) {
      setEndoEffectiveDateEditionModalLoading(false);
      console.error(error);
    }
  };

  const effectiveDateInput = [
    {
      label: "New Effective Date",
      type: "date",
      name: "endoEffectiveDate",
      minDate: new Date(),
      required: true,
    },
  ];

  const endoModalSettings = {
    onSubmit: handleEffectiveDateEdition,
    inputs: effectiveDateInput,
    buttonLabel: "Update",
  };

  const isAtLeast14DaysFromSentDate = isAtLeast14Days(
    endorsement?.signedProposalData?.dateSent
  );

  const isPremiumBearingEndorsement = Boolean(
    endorsement.alPremiums || endorsement.glPremiums
  )

  return (
    <>
      {loadingData ? (
        <LoadingScreen text={"Loading endorsement details..."} />
      ) : !endorsement?.id && !loadingData ? (
        <div className="bg-white shadow-md rounded-lg p-6 m-6 border border-gray-200">
          <h1 className="text-center text-lg font-semibold text-red-500">
            Endorsement {paramRefID} not found
          </h1>
        </div>
      ) : (
        <div
          className={`
          ${
            String(endorsement?.type || "").includes("Remove") ||
            String(endorsement?.type || "").includes("Exclude")
              ? ""
              : !Boolean(endsWithLowerCaseS(String(endorsement?.type)))
              ? "max-w-xl"
              : "max-w-xl"
          }
          mx-auto p-6 bg-white shadow-lg rounded-lg mt-3 position-relative`}
        >
          <FormPopup
            isOpen={openSendLetterModal}
            onRequestClose={() => setOpenSendLetterModal(false)}
            title="Send Letter"
            formSettings={formSettingsSendLetter}
            loading={sendLetterLoading}
          />

          <FormPopup
            isOpen={endoEffectiveDateEditionModal}
            onRequestClose={() => setEndoEffectiveDateEditionModal(false)}
            title="Edit Your Endorsement Effective Date"
            formSettings={endoModalSettings}
            loading={endoEffectiveDateEditionModalLoading}
          />

          <ReasonsModal
            title="Return Endorsement"
            text="Please enter the reason(s) why the application is being returned to the agent."
            addButtonText="Add Another Reason"
            onSubmit={handleReturnEndorsement}
            open={openReturnToAgentModal}
            onClose={handleCloseReturnToAgentModal}
          />

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <GoBack
              defaultRoute={!isCollection ? UNDERWRITING_ENDORSEMENT_ROUTE : ""}
              throughHistory={true}
            />
            {endorsement.status === "Returned" && (
              <Button
                variant="outlined"
                color="primary"
                startIcon={<EditIcon />}
                onClick={(e) => setEditMode(!editMode)}
              >
                {editMode ? "Save" : "Edit"}
              </Button>
            )}
          </div>

          {endorsement?.status === "Approved" ||
          endorsement?.status === "Issued" ? (
            <DownloadButton
              disabled={loading}
              downloadFunction={handleDownload}
              className="download-button"
            />
          ) : null}
          {endorsement.type === "Exclude Drivers" &&
          endorsement?.signedProposalData?.status === "sent" &&
          isAtLeast14DaysFromSentDate ? (
            <SentLetterButton
              disabled={sendLetterLoading}
              fn={() => setOpenSendLetterModal(true)}
              thereIsDownloadButton={
                endorsement?.status === "Approved" ||
                endorsement?.status === "Issued"
              }
            />
          ) : null}
          <h1 className={`text-3xl font-semibold mb-2 ${statusColorClass}`}>
            {statusText} Endorsement
            {/* Copy button to copy endo doc ref */}
            {role === "Admin" && (
              <button
                onClick={() => {
                  navigator.clipboard.writeText(endoDocRefAsString);
                  controlToast(true, "Endorsement reference copied", "success");
                }}
                className="text-xs ml-2 text-gray-500"
              >
                Copy Ref
              </button>
            )}
          </h1>
          <div>
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Named Insured:</span>{" "}
              {endorsement.namedInsured}
            </p>
            {endorsement?.alPolicyNo ? (
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Policy No:</span>{" "}
                {endorsement.alPolicyNo}
              </p>
            ) : null}
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Endorsement Type:</span>{" "}
              {endorsement.type}
            </p>
            {handleEndorsementCoverageTypeText(endorsement) ? (
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Coverage Type:</span>{" "}
                {handleEndorsementCoverageTypeText(endorsement)}
              </p>
            ) : null}
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Endorsement Number:</span>{" "}
              {endorsement.endorsementNumber}
            </p>
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Status:</span>{" "}
              {endorsement.status}
            </p>
            <div className="flex">
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Endo Effective Date:</span>{" "}
                {humanDate(endorsement?.endoEffectiveDate)}
              </p>
              {editMode && (
                <PencilIcon
                  className="cursor-pointer ml-2"
                  sx={{ color: "#8B0000" }}
                  onClick={() => setEndoEffectiveDateEditionModal(true)}
                />
              )}
            </div>
            {endorsement?.deletionReason ? (
              <p className="text-gray-700 mb-2">
                <span className="font-semibold">Deletion Reason:</span>{" "}
                {endorsement?.deletionReason}
              </p>
            ) : null}
          </div>
          <div className="mb-4">
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Author:</span>{" "}
              {endorsement.author}
            </p>
            <p className="text-gray-700 mb-2">
              <span className="font-semibold">Agency:</span> {agencyName}
            </p>
            <p className="text-gray-700">
              <span className="font-semibold">Submitted on:</span>{" "}
              {humanDate(endorsement?.dateCreated)}
            </p>
            {endorsement?.rejectionReasons &&
            Boolean(endorsement?.rejectionReasons.length) ? (
              <p className="text-red-700 mt-2">
                <span className="font-semibold">Rejection Reasons:</span>{" "}
                {endorsement?.rejectionReasons?.join(", ")}
              </p>
            ) : null}
            {endorsement?.returnedReasons &&
            Boolean(endorsement?.returnedReasons.length) ? (
              <p className="text-blue-700 mt-2">
                <span className="font-semibold">Returned Reasons:</span>{" "}
                {endorsement?.returnedReasons?.join(", ")}
              </p>
            ) : null}
          </div>
          <RequestExceptions endorsement={endorsement} mustBeApproved={true} />
          <div className="mt-6">
            {endorsement.type !== "Blanket Additional Insured" ? (
              <p className="text-lg font-semibold mb-2">Details</p>
            ) : null}
            {endorsement.type === "Swap Power Units" && (
              <SwapPowerUnits
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                userInfo={user}
              />
            )}
            {endorsement.type === "Add Power Units" && (
              <AddPowerUnits
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                agencyID={endorsement?.agencyID}
                formData={formData}
              />
            )}
            {endorsement.type === "Add Power Unit" ||
            endorsement.type === "Adjust Radius" ? (
              <AddPowerUnits
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                agencyID={endorsement?.agencyID}
                formData={formData}
              />
            ) : null}
            {endorsement.type === "Add Trailers" ? (
              <AddTrailers
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                agencyID={endorsement?.agencyID}
                formData={formData}
              />
            ) : null}
            {endorsement.type === "Modify Commodities" && endorsement?.data ? (
              <ModifyCommodities
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            ) : null}
            {/* {endorsement.type === "Add Trailer" && (
              <AddTrailer
                editMode={editMode} setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                userInfo={user}
              />
            )} */}
            {endorsement.type === "Add Drivers" && (
              <AddDrivers
                agencyID={endorsement?.agencyID}
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                formData={formData}
              />
            )}
            {endorsement.type === "Add Driver" && (
              <AddDriver
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                userInfo={user}
              />
            )}
            {endorsement.type === "Add Waiver Of Subrogation" && (
              <AddOne
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="waivers-of-subrogation"
                details={addWaiverOfSubrogationEndorsementDetails(endorsement)}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            )}
            {endorsement.type === "Remove Driver" ||
            endorsement.type === "Exclude Driver" ? (
              <RemoveOne
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="drivers"
                details={removeDriverEndorsementDetails(endorsement)}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            ) : null}
            {endorsement.type === "Remove Power Unit" && (
              <RemoveOne
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="power-units"
                details={removePowerUnitEndorsementDetails(endorsement)}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            )}
            {endorsement.type === "Remove Trailer" && (
              <RemoveOne
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="trailers"
                details={removeTrailerEndorsementDetails(endorsement)}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            )}
            {endorsement.type === "Remove Drivers" ||
            endorsement.type === "Exclude Drivers" ? (
              <RemoveMultiple
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="drivers"
                subcollectionName="Drivers"
                identifier="licenseNumber"
                tableDataRequest={() =>
                  removeMultipleDriversTable(
                    endorsement,
                    "drivers",
                    paramPolicyID,
                    editMode
                  )
                }
                columns={multipleDriverTableColumn(editMode)}
                filterKeysToSearch={filterDriverKeysToSearch}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                formData={formData}
              />
            ) : null}
            {endorsement.type === "Remove Power Units" && (
              <RemoveMultiple
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="power-units"
                subcollectionName="Power Units"
                identifier="truckVIN"
                tableDataRequest={() =>
                  removeMultiplePowerUnitsTable(
                    endorsement,
                    "power-units",
                    paramPolicyID,
                    editMode
                  )
                }
                columns={multiplePowerUnitsTableColumn(editMode)}
                filterKeysToSearch={filterPowerUnitsKeysToSearch}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                formData={formData}
              />
            )}
            {endorsement.type === "Remove Trailers" && (
              <RemoveMultiple
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                subcollection="trailers"
                subcollectionName="Trailers"
                identifier="trailerVIN"
                tableDataRequest={() =>
                  removeMultipleTrailersTable(
                    endorsement,
                    "trailers",
                    paramPolicyID,
                    editMode
                  )
                }
                columns={multipleTrailersTableColumn(editMode)}
                filterKeysToSearch={filterTrailerKeysToSearch}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
                formData={formData}
              />
            )}
            {endorsement.type === "Add Additional Insureds" && (
              <AdditionalInsured
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            )}
            {endorsement.type === "Cancellation" && (
              <Cancellation
                editMode={editMode}
                setSuccess={setSuccess}
                endorsement={endorsement}
                paramRefID={paramRefID}
                paramPolicyID={paramPolicyID}
                requestExceptionInputs={requestExceptionInputs}
                insuredInfo={insuredInfo}
                userInfo={user}
              />
            )}
          </div>

          {/* Endorsements Total Premiums Table */}
          {endorsement.status === "Approved" && isPremiumBearingEndorsement && (
            <>
              <PremiumsTable data={endorsement.alPremiums} />
            </>
          )}

          {statusText === "Returned" ||
          statusText === "Submitted" ? (
            <div>
              <RedButton
                text={
                  endorsement?.status !== "Returned"
                    ? "RETURN TO AGENT"
                    : "SUBMIT"
                }
                onClickFunction={(e) => {
                  e.preventDefault();
                  if (endorsement?.status !== "Returned") {
                    setOpenReturnToAgentModal(true);
                  } else {
                    handleReturnEndorsement(e, []);
                  }
                }}
                hasContentToTop={true}
                hasContentToBottom={true}
                className={`w-full mb-3`}
                icon={"no-icon"}
              />
            </div>
          ) : null}

          {success && (
            <>
              {endorsement.status === "Approved" ||
              endorsement.status === "Issued" ? (
                <BigAlert
                  title="This endorsement has been approved."
                  subtitle="Click below to view other pending endorsements."
                  okButton="OK"
                  className="mt-2"
                  status="successful"
                  fn={handleGoBack}
                />
              ) : null}
              {endorsement.status === "Declined" &&
              endorsement.documentType !== "Exception Request" ? (
                <BigAlert
                  title="This endorsement has been declined."
                  subtitle="Click below to view other pending endorsements."
                  okButton="OK"
                  className="mt-2"
                  status="error"
                  list={endorsement.rejectionReasons}
                  fn={handleGoBack}
                />
              ) : null}
            </>
          )}
        </div>
      )}
    </>
  );
};
