import { Col, Container, Row } from "react-bootstrap";
import React from "react";
import PowerUnitDetailFlags from "../../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";
import priceToHandle from "../utils/addPowerUnit/priceToHandle";
import {
  Delete as DeleteIcon,
  ModeEdit as PencilIcon,
} from "@mui/icons-material";
import { deleteItemFromEndorsement } from "../../../PolicyLifecycleManager/Drivers/multipleToEndorsements";

export default function PowerUnitsMap({
  paramPolicyID,
  endorsementData,
  handleEdition,
  endorsement,
  editMode
}) {
  return (
    <div>
      {endorsementData &&
        endorsementData.map((truck, truckIndex) => {
          const policyDetailToShow = truck?.policiesDetails.find(
            (detail) => detail.id === paramPolicyID
          );
          return (
            <div
              key={truckIndex}
              className="mx-auto p-6 bg-white shadow-md rounded-lg mb-2"
            >
              <Container className="p-0 position-relative">
                <Row>
                  <Col>
                    <PowerUnitDetailFlags
                      powerUnit={truck}
                      policyID={paramPolicyID}
                    />
                  </Col>
                  {endorsement?.type !== "Adjust Radius" && editMode ? (
                    <Col className="flex justify-end">
                      <DeleteIcon
                        className="cursor-pointer"
                        sx={{ color: '#8B0000', marginRight: '10px' }}
                        onClick={(e) => deleteItemFromEndorsement(
                          e,
                          truck,
                          endorsementData,
                          'power-units',
                          paramPolicyID,
                          endorsement
                        )}
                      />
                      <PencilIcon
                        className="cursor-pointer"
                        sx={{ color: '#8B0000' }}
                        onClick={(e) => handleEdition(e, truck)}
                      />
                    </Col>
                  ) : null}
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">VIN:</span> {truck.truckVIN}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Weight:</span>{" "}
                    {truck.truckWeight}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Year:</span>{" "}
                    {truck.truckYear}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Make:</span>{" "}
                    {policyDetailToShow?.truckMake}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Model:</span>{" "}
                    {policyDetailToShow?.truckModel}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Class:</span>{" "}
                    {policyDetailToShow?.truckClass}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Rating:</span>{" "}
                    {priceToHandle(policyDetailToShow?.adjustedRate)}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">SL Tax:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.surplusLinesTax)}
                  </Col>
                </Row>
                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Stamp Fee:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.stampFee)}
                  </Col>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Processing Fee:</span>{" "}
                    {priceToHandle(policyDetailToShow?.taxes?.processingFee)}
                  </Col>
                </Row>

                {truck?.oldRadius ? (
                  <Row>
                    {truck?.oldRadius ? (
                      <Col sm={12} md={6}>
                        <span className="font-semibold">Old Radius:</span>{" "}
                        {truck?.oldRadius}
                      </Col>
                    ) : null}
                    {policyDetailToShow?.truckRadius ? (
                      <Col sm={12} md={6}>
                        <span className="font-semibold">New Radius:</span>{" "}
                        {policyDetailToShow?.truckRadius}
                      </Col>
                    ) : null}
                  </Row>
                ) : null}

                <Row>
                  <Col sm={12} md={6}>
                    <span className="font-semibold">Total:</span>{" "}
                    {priceToHandle(
                      policyDetailToShow?.taxes?.processingFee +
                        policyDetailToShow?.taxes?.stampFee +
                        policyDetailToShow?.taxes?.surplusLinesTax +
                        policyDetailToShow?.adjustedRate
                    )}
                  </Col>
                </Row>
              </Container>
            </div>
          );
        })}
    </div>
  );
}
