import React, { useMemo, useState } from "react";
import {
  addDoc,
  collection,
  doc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { Col, Container, Row } from "../../generals/GridSystem";
import { removeLastS } from "../../../utils/helpers";
import PDFPreviewer from "../../generals/PDFPreviewer";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";

export default function AddOne({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  subcollection,
  details,
  requestExceptionInputs,
  userInfo
}) {
  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const approveAUEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const { agencyID, insuredID } = endorsement;
    const updateEndorsementStatusAndCreateDriver = async (
      endorsementId,
      status,
      payload
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );

      const subCollectionRef = collection(
        db,
        "agencies",
        agencyID,
        "insureds",
        insuredID,
        subcollection
      );

      const { data } = payload;

      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = new Date()
          const payload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            payload["wasARequestException"] = true;
            payload["requestException"] = requestExceptionData;
          }
          await transaction.update(endorsementRef, payload);
          await addDoc(subCollectionRef, data);
        });
      } catch (error) {
        console.error(
          `Error updating endorsement status
          and creating in ${subcollection} collection`,
          error
        );
      }
    };

    updateEndorsementStatusAndCreateDriver(paramRefID, "Approved", endorsement);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAUEndorsement = async (e, _, reasons) => {
    if (e) {
      e.preventDefault();
    }
    const driverRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (isRequestException) {
      payload["wasARequestException"] = true;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(driverRef, payload);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const requestExceptionModeSetting = async (e, data) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
      requestException: data,
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setRequestingExceptionMode(false);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  return (
    <div>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) => declineAUEndorsement(e, null, reasons)}
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />

      <div className="max-w-xl mx-auto p-6 bg-white shadow-lg rounded-lg">
        <Container className="!p-0">
          {details.map((detail, detailIndex) => (
            <Row key={detailIndex} className="mb-1">
              <Col sm={12} md={12}>
                <p className="font-semibold">{detail.label}:</p>
                {detail.data ? (
                  <p>{detail.data}</p>
                ) : (
                  <PDFPreviewer file={detail.file} />
                )}
              </Col>
            </Row>
          ))}
        </Container>
      </div>

      {requestingExceptionMode &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {(endorsement.status === "Submitted" || isRequestException) &&
      user.role === "Underwriter" ? (
        <>
          <article className="mt-3">
            <p
              className={
                isRequestException
                  ? "text-red-700 text-center !max-w-[345px] mx-auto"
                  : ""
              }
            >
              {isRequestException
                ? `This endorsement has already been declined. 
                You are accepting/decling an exception request`
                : `Approving this endorsement will add the above ${removeLastS(
                    subcollection
                  )} to
                the policy and notify the insured of the change.`}
            </p>
          </article>
          {isRequestException ? (
            <RequestExceptions
              endorsement={endorsement}
              color="red"
              center={true}
            />
          ) : null}
          {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
            <ApprovalButtons
              onApprove={approveAUEndorsement}
              onDecline={() => setRejectionModal(true)}
            />
          )}
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException &&
        user.role === "Agent" &&
        !requestingExceptionMode ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={() => setRequestingExceptionMode(true)}
          />
        </>
      ) : null}
    </div>
  );
}
