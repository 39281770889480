import React, { useEffect, useMemo, useState } from "react";
import DynamicTable from "../../assets/lists/DynamicTable";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import Form from "../../generals/Forms/Form";
import useUsersState from "../../../Context/actions/useUsersState";
import RequestExceptions from "../../generals/Toast/RequestExceptions";
import ApiService from "../../../services/southern-api/apiService";
import requestExceptionModeSetting from "./utils/requestExceptionModeSetting";
import approveAUEndorsement from "./utils/removePowerUnit/approveAUEndorsement";
import Modals from "./AddPowerUnits/Modals";
import ButtonInstructions from "./RemovePowerUnits/ButtonInstructions";
import RatePowerUnitEndorsement from "./RatePowerUnitEndorsement";
import PremiumsTable from "./AddPowerUnits/PremiumsTable";
import BlueButton from "../../assets/forms/BlueButton";
import AddDriver from "../../PolicyLifecycleManager/Drivers/AddDriver";
import AddTruck from "../../PolicyLifecycleManager/Units/AddTruck";
import AddTrailer from "../../PolicyLifecycleManager/Trailers/AddTrailer";
import { deleteItemFromEndorsement, getDocumentPaths } from "../../PolicyLifecycleManager/Drivers/multipleToEndorsements";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import { buildShowingInfoJustActiveOnes, setPolicyDetailUpdate, setPolicyInfo } from "../../PolicyLifecycleManager/Drivers/setPolicyInfo";
import GoBack from "../../generals/GoBack";
import { generateSearchWords } from "../../../utils/helpers";

export default function RemoveMultiple({
  endorsement,
  paramPolicyID,
  paramRefID,
  setSuccess,
  subcollection,
  tableDataRequest,
  columns,
  filterKeysToSearch,
  requestExceptionInputs,
  insuredInfo,
  subcollectionName,
  identifier,
  userInfo,
  formData,
  editMode
}) {
  const apiService = new ApiService();

  const { user } = useUsersState();
  const [requestingExceptionMode, setRequestingExceptionMode] = useState(false);

  const determineDeletionStatus = () => {
    return endorsement?.type?.includes("Exclude") ? "Excluded" : "Deleted";
  };

  const deletionStatus = determineDeletionStatus();

  const [vinArray, setVinArray] = useState([]);
  const [details, setDetails] = useState([]);
  const [data, setData] = useState([]);
  const policyID = paramPolicyID;
  const endoID = paramRefID;

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  useEffect(() => {
    tableDataRequest()
      .then((res) => {
        const { tableData, matchedSubcollections } = res;
        setDetails(tableData);
        setData(matchedSubcollections);
        if (tableData) {
          // Generate VIN array
          const values = tableData.map((unit) => unit.truckVIN);
          setVinArray(values);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, [tableDataRequest]);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const [openInCancellationModal, setOpenInCancellationModal] = useState(false);
  const [unitsRated, setUnitsRated] = useState(false);
  const [rejectionModal, setRejectionModal] = useState(false);

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: requestExceptionModeSetting,
      onDecline: () => setRequestingExceptionMode(false),
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState("");

  const handleOpenDetailsModal = (id) => {
    setSelectedDriver(id);
    setOpenDetailsModal(!openDetailsModal);
  };

  const [addUI, setAddUI] = useState(false);
  const [editUI, setEditUI] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeItemsInPolicy, setActiveItemsInPolicy] = useState([]);
  const [selectedActiveItemToAdd, setSelectedActiveItemToAdd] = useState(null);

  const endorsementData = details.map((item) => {
    const newItem = { ...item }
    delete newItem['tableInfo']
    delete newItem['iconLeft']
    delete newItem['iconRight']
    delete newItem['bindData']
    delete newItem['tableObject']
    delete newItem['inspasData']
    return newItem
  })

  const handleAddition = (e) => {
    e.preventDefault();
    setAddUI(!addUI);
  }

  // TESTEA ESTA FUNCION
  // DESPUES TESTEA EL FLUJO DE BORRAR Y EDITAR EN DRIVER
  // DESPUES EN POWER UNITS Y TRAILERS TODOS LOS FLUJOS
  const submitAddition = async () => {
    try {
      const item = activeItemsInPolicy.find(item => item.id === selectedActiveItemToAdd)
      const policiesDetails = item?.policiesDetails.map((detail) => {
        if (detail.id === paramPolicyID) {
          return {
            ...detail,
            status: 'Pending Deletion'
          };
        }
        return detail;
      })
      item["policiesDetails"] = policiesDetails;
      const elemRef = doc(db, `agencies/${endorsement.agencyID}/insureds/${endorsement.insuredID}/${subcollection}/${item.id}`);
      await updateDoc(elemRef, { policiesDetails });
      const newList = [...endorsementData];
      newList.push(item);
      const updatedData = getDocumentPaths(newList, endorsement?.type, formData);
      const updatedSearchData = [];
      newList.forEach(item => { 
        if (item?.driverFirst || item?.driverLast) {
          const searchName = `${item?.driverFirst || ""} ${item?.driverLast || ""}`.trim();
          const words = generateSearchWords(searchName);
          updatedSearchData.push(...words, searchName);
        }
        if (item?.licenseNumber) updatedSearchData.push(String(item?.licenseNumber));
        if (item?.truckVIN) updatedSearchData.push(String(item?.truckVIN));
        if (item?.trailerVIN) updatedSearchData.push(String(item?.trailerVIN));
      });
      const payload = {
        data: updatedData,
        searchData: updatedSearchData,
        modifiedDate: new Date(),
        modifiedBy: auth.currentUser.displayName
      }
      const endoRef = doc(db, `policy-applications/${policyID}/endorsements/${endorsement.id}`);
      await updateDoc(endoRef, payload);
      setAddUI(false);
    } catch (error) {
      console.error("Error updating document: ", error);
    }
  }

  const handleEdition = (e, item) => {
    e.preventDefault();
    setEditUI(!editUI);
    setSelectedItem(item);
  }

  const necessaryValuesInsideEndorsement = {
    addUI: addUI,
    editUI: editUI,
    endorsement,
    selectedItem,
    completeList: endorsementData,
  }

  useEffect(() => {
    if (!endorsement?.agencyID || !endorsement?.insuredID) return;
    const itemsRef = collection(
      db,
      "agencies",
      endorsement.agencyID,
      "insureds",
      endorsement.insuredID,
      subcollection
    );
    const unsubscribe = onSnapshot(itemsRef, (snapshot) => {
      const itemsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const activeItemsList = buildShowingInfoJustActiveOnes(itemsList, paramPolicyID);
      setActiveItemsInPolicy(activeItemsList);
    });
    return () => unsubscribe();
  }, [endorsement?.agencyID, endorsement?.insuredID]);

  const handleChange = (item) => {
    setSelectedActiveItemToAdd(item)
  }

  return (
    <div>

      {(endorsement.status === "Returned" &&
      !addUI &&
      !editUI
      ) && (
        <BlueButton
          text={
            subcollection === "power-units"
              ? "Add Power Unit"
              : subcollection === "drivers"
              ? "Add Driver"
              : subcollection === "trailers"
              ? "Add Trailer"
              : "Add Item"
          }
          onClickFunction={handleAddition}
          hasContentToTop={true}
          hasContentToBottom={true}
          className={`w-full mb-3`}
        />
      )}

      <Modals
        openInCancellationModal={openInCancellationModal}
        setOpenInCancellationModal={setOpenInCancellationModal}
        rejectionModal={rejectionModal}
        setRejectionModal={setRejectionModal}
        endorsement={endorsement}
        isRequestException={isRequestException}
        paramPolicyID={paramPolicyID}
        paramRefID={paramRefID}
        data={data}
        subcollection={subcollection}
        subcollectionName={subcollectionName}
        identifier={identifier}
        deletionStatus={deletionStatus}
        insuredInfo={insuredInfo}
        setSuccess={setSuccess}
        apiService={apiService}
        user={user}
        selectedDriver={selectedDriver}
        openDetailsModal={openDetailsModal}
        setOpenDetailsModal={setOpenDetailsModal}
        endoID={endoID}
        policyID={policyID}
        endorsementData={endorsementData}
        setPolicyInfo={setPolicyInfo}
        setPolicyDetailUpdate={setPolicyDetailUpdate}
      />

      {(addUI) &&
      (endorsement.status === "Submitted" ||
      endorsement.status === "Unsubmitted") ? (
        <div className="flex flex-col">
          <div>
            <GoBack
              handleFn={() => {
                setAddUI(false);
                setEditUI(false);
              }}
            />
          </div>
          <label className="text-sm font-semibold mb-1">
            Select an active item for add it in this endorsement:
          </label>
          <select
            name="contactCountryCode"
            onChange={(e) => {
              handleChange(e.target.value)
            }}
            className="mt-1 p-1 block w-full rounded-md border border-gray-300 shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={selectedActiveItemToAdd}
          >
            <option value="">Select an option</option>
            {activeItemsInPolicy.map((item) => {
              return (
                <option key={item.id} value={item.id}>
                  {subcollection === "power-units" ?
                  item?.truckVIN :
                  subcollection === "drivers" ?
                  item?.licenseNumber :
                  subcollection === "trailers" ?
                  item?.trailerVIN : ''}
                </option>
              )
            })}
          </select>
          <div>
            <BlueButton
              text={"SUBMIT"}
              onClickFunction={submitAddition}
              hasContentToTop={true}
              hasContentToBottom={true}
              className={`w-full mb-3`}
          />
          </div>
        </div>
      ) : null}

      {(editUI) &&
      endorsement.status === "Returned" ?
        <>
          {subcollection === "power-units" ? (
            <AddTruck
              insuredInfo={insuredInfo}
              truckList={endorsementData}
              userID={userInfo.id}
              userInfo={userInfo}
              paramRefID={paramRefID}
              policyID={paramPolicyID}
              formData={formData}
              setShowAddTruck={() => {
                setAddUI(false);
                setEditUI(false);
              }}
              setSelectedItem={setSelectedItem}
              necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
              isEndoManager={false}
              rerateRequired={false}
              setRerateRequired={() => {}}
              getEndorsements={() => {}}
              getTruckList={() => {}}
              setTruckList={() => {}}
              setShowTable={() => {}}
              setFormData={() => {}}
            />
          ) :
          subcollection === "drivers" ? (
            <AddDriver
              isEndoManager={false}
              getEndorsements={() => {}}
              driverList={endorsementData}
              formData={formData}
              insuredInfo={insuredInfo}
              setShowAddDriver={() => {
                setAddUI(false);
                setEditUI(false);
              }}
              userID={userInfo.id}
              userInfo={userInfo}
              policyID={paramPolicyID}
              setShowTable={() => {}}
              saveForm={() => {}}
              formSaved={false}
              setFormSaved={() => {}}
              setSelectedItem={setSelectedItem}
              necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
            />
          ) :
          subcollection === "trailers" ? (
            <AddTrailer
              userInfo={userInfo}
              trailerList={endorsementData}
              insuredInfo={insuredInfo}
              formData={formData}
              policyID={paramPolicyID}
              setShowAddTrailer={() => {
                setAddUI(false);
                setEditUI(false);
              }}
              isEndoManager={false}
              getEndorsements={() => {}}
              rerateRequired={() => {}}
              setRerateRequired={() => {}}
              setShowTable={() => {}}
              setSelectedItem={setSelectedItem}
              necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
            />
          ) : null
          }
        </> :
        !addUI ?
        <>
          <DynamicTable
            columns={columns}
            tableData={details}
            onClickItem={(e, id) => {
              e.preventDefault();
              if (subcollection === "drivers") {
                handleOpenDetailsModal(id);
              }
            }}
            filterKeys={filterKeysToSearch}
            handleOpenEditMode={handleEdition}
            deleteThisItem={(e, item) => deleteItemFromEndorsement(
              e,
              item,
              endorsementData,
              subcollection,
              paramPolicyID,
              endorsement
            )}
          />
          {requestingExceptionMode &&
          typeof correspondingRequestExceptionInputs !== "undefined" ? (
            <Form
              formSettings={formSettingsRequestException}
              isSmallVersion={true}
            />
          ) : null}
          {(endorsement.status === "Submitted" || isRequestException) &&
          (user.role === "Underwriter" || user.role === "Admin") ? (
            <>
              {subcollection === "power-units" && !unitsRated ? (
                <RatePowerUnitEndorsement
                  truckList={data}
                  policyID={paramPolicyID}
                  endoID={paramRefID}
                  setSuccess={setUnitsRated}
                  endoType={endorsement.type}
                />
              ) : (
                <>
                  {subcollection === "power-units" ? (
                    <PremiumsTable data={endorsement.alPremiums} />
                  ) : null}
                  <ButtonInstructions
                    isRequestException={isRequestException}
                    subcollection={subcollection}
                  />
                  {isRequestException && (
                    <RequestExceptions
                      endorsement={endorsement}
                      color="red"
                      center={true}
                    />
                  )}
                  {(userInfo.canApproveEndos || userInfo.role === "Admin") && (
                    <ApprovalButtons
                      onApprove={(e) =>
                        approveAUEndorsement(
                          e,
                          null,
                          endorsement,
                          paramPolicyID,
                          paramRefID,
                          details,
                          subcollection,
                          subcollectionName,
                          identifier,
                          deletionStatus,
                          insuredInfo,
                          setSuccess,
                          apiService,
                          data,
                          policyID,
                          endoID,
                          setOpenInCancellationModal,
                          vinArray
                        )
                      }
                      onDecline={() => setRejectionModal(true)}
                    />
                  )}
                </>
              )}
            </>
          ) : endorsement.status === "Declined" &&
            !isRequestException &&
            !wasARequestException &&
            user.role === "Agent" &&
            !requestingExceptionMode ? (
            <>
              <ApprovalButtons
                dissapearAccept={true}
                titleDecline="REQUEST EXCEPTION"
                onDecline={() => setRequestingExceptionMode(true)}
              />
            </>
          ) : null}
        </> : null
      }
    </div>
  );
}
