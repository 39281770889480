import { formatPrice } from "../Components/Finances/Tables/utils";
import { formatDateToDDMMYYYY } from "./helpers";

export const convertTimestampToDateGTM = (timestamp) => {
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    throw new Error("Invalid timestamp format");
  }
  const milliseconds = timestamp.seconds * 1000;
  const additionalMilliseconds = Math.floor(timestamp.nanoseconds / 1e6);
  const totalMilliseconds = milliseconds + additionalMilliseconds;
  const date = new Date(totalMilliseconds);
  const options = { year: "numeric", month: "2-digit", day: "2-digit", timeZone: "UTC" };
  return date.toLocaleDateString("en-US", options);
}

export function convertTimestampToDate(timestamp) {
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    throw new Error("Invalid timestamp format");
  }
  const milliseconds = timestamp.seconds * 1000;
  const additionalMilliseconds = Math.floor(timestamp.nanoseconds / 1e6);
  const totalMilliseconds = milliseconds + additionalMilliseconds;
  const date = new Date(totalMilliseconds);
  const options = { year: "numeric", month: "short", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
}

export function convertTimestampToDateWithSlash(timestamp) {
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    throw new Error("Invalid timestamp format");
  }
  const milliseconds = timestamp.seconds * 1000;
  const additionalMilliseconds = Math.floor(timestamp.nanoseconds / 1e6);
  const totalMilliseconds = milliseconds + additionalMilliseconds;
  const date = new Date(totalMilliseconds);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  return date.toLocaleDateString("en-US", options);
}

export function convertTimestampToDateParts(timestamp) {
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    throw new Error("Invalid timestamp format");
  }

  const milliseconds = timestamp.seconds * 1000;
  const additionalMilliseconds = Math.floor(timestamp.nanoseconds / 1e6);
  const totalMilliseconds = milliseconds + additionalMilliseconds;
  const date = new Date(totalMilliseconds);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "long" });
  const year = date.getFullYear();

  return { day, month, year };
}

export const driverListToDocxToPdfFormat = (driversList) => {
  if (Array.isArray(driversList)) {
    const parsedDriversList = driversList?.map((driver) => {
      const {
        driverFirst,
        driverLast,
        dob,
        licenseNumber,
        licenseExpirationDate,
        licenseExpiration,
        inspasData,
      } = driver;
      return {
        name: `${driverFirst} ${driverLast}`,
        dob: dob && dob?.seconds ? convertTimestampToDateGTM(dob) : "",
        licenseNumber,
        expirationDate:
          licenseExpirationDate && licenseExpirationDate?.seconds
            ? convertTimestampToDateGTM(licenseExpirationDate)
            : licenseExpiration && licenseExpiration?.seconds
            ? convertTimestampToDateGTM(licenseExpiration)
            : "",
        licenseType: inspasData?.LicClass,
      };
    });
    const sortedDriversList = parsedDriversList.sort((a, b) => a.name.localeCompare(b.name));
    return sortedDriversList.map((driver, index) => ({
      ...driver,
      no: String(index + 1),
    }));
  } else {
    return [];
  }
};

export const scheduleOfPowerUnitsListToDocxToPdfFormat = (powerUnitsList, formData) => {
  if (Array.isArray(powerUnitsList)) {
    const filteredPowerUnitsList = powerUnitsList.filter((unit) =>
      unit.policiesDetails.some((policy) => policy.id === formData?.id && policy.status === "Active")
    );
    const parsedPowerUnitsList = filteredPowerUnitsList?.map((unit) => {
      const policyDetail = unit.policiesDetails.find((policy) => policy.id === formData?.id);
      return {
        vin: unit.truckVIN,
        make: policyDetail.truckMake,
        model: unit.truckModel,
        year: unit.truckYear,
        grossWeight: unit.truckWeight,
        radius: policyDetail.truckRadius,
        puNet: policyDetail?.adjustedRate ? formatPrice(policyDetail?.adjustedRate) : "",
      };
    });
    return parsedPowerUnitsList.map((unit, index) => ({
      ...unit,
      no: String(index + 1),
    }));
  } else {
    return [];
  }
};

export const scheduleOfDriversListToDocxToPdfFormat = (driversList, formData) => {
  if (Array.isArray(driversList)) {
    const filteredDriversList = driversList.filter((driver) =>
      driver.policiesDetails.some((policy) => policy.id === formData?.id && policy.status === "Active")
    );
    const parsedDriversList = filteredDriversList?.map((driver) => {
      const {
        driverFirst,
        driverLast,
        dob,
        licenseNumber,
        yoe
      } = driver;
      return {
        name: `${driverFirst} ${driverLast}`,
        dob: dob && dob?.seconds ? convertTimestampToDateGTM(dob) : "",
        license: licenseNumber,
        yoe,
        age: dob && dob?.seconds ? new Date().getFullYear() - new Date(dob.seconds * 1000).getFullYear() : "",
      };
    });
    const sortedDriversList = parsedDriversList.sort((a, b) => a.name.localeCompare(b.name));
    return sortedDriversList.map((driver, index) => ({
      ...driver,
      no: String(index + 1),
    }));
  } else {
    return [];
  }
};

export const naftaAlPolicyPayload = (
  formData,
  insuredInfo,
  driverList,
  truckList,
  trailerList,
  agencyInfo,
  option,
  quoteSelected
) => {
  const drivers = driverListToDocxToPdfFormat(driverList);
  const scheduleOfPowerUnits = scheduleOfPowerUnitsListToDocxToPdfFormat(truckList, formData)
  const scheduleOfDrivers = scheduleOfDriversListToDocxToPdfFormat(driverList, formData)

  const { company, mailingAddress: addressFromInsured, phyiscalAddress, businessStructure } = insuredInfo;

  const individual = Boolean(businessStructure === "Sole Proprietorship")
  const partnership = Boolean(businessStructure === "Partnership")
  const limitedLiability = Boolean(businessStructure === "LLC")
  const organization = Boolean(businessStructure === "Corporation")

  const {
    alPolicyNo,
    effectiveDate,
    expirationDate,
    alPremiums,
    coverageSingleLimit,
    bindData,
  } = formData;

  const personalInjuryProtectionLimit = formData?.bindData?.pipLimit || ""
  const umLiabilityPTL = formData?.bindData?.uimLimit || ""
  const biLiabilityDeductible = formData?.bindData?.biLiabilityDeductible || ""

  const processingFeeAL = alPremiums?.processingFeeAmount || 0;
  const otherFeesAL = alPremiums?.otherFees || 0;
  const surplusLinesTaxAL = alPremiums?.surplusLinesTaxAmount || 0;
  const stampTaxAL = alPremiums?.stampTaxAmount || 0;
  const totalPremiumAL = alPremiums?.totalPremium || 0;

  const processingFee = processingFeeAL
  const otherFees = otherFeesAL
  const surplusLinesTax = surplusLinesTaxAL
  const stampTax = stampTaxAL
  const totalPremium = totalPremiumAL

  const isMCS09 = option?.includes('MCS09')
  const isScheduled = option?.includes('Scheduled')

  const partsOfDate = effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateParts(effectiveDate)
    : {
        day: "",
        month: "",
        year: "",
    }

  const mcs90Payload = {
    usDot: insuredInfo.dot,
    date: effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateWithSlash(effectiveDate)
    : "",
    issuedTo: company,
    of: formData.stateOfEntry || "",
    time: "12:01 AM",
    day: partsOfDate.day,
    month: partsOfDate.month,
    year: partsOfDate.year,
    policyNo: alPolicyNo,
    effectiveDate: effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateWithSlash(effectiveDate)
    : "",
    insuranceCompany: "Accredited Specialty Insurance Company Inc",
    counterSignedBy: "",
    firstLimitAccept: "X",
    firstLimit: coverageSingleLimit,
    secondLimitAccept: "",
    secondLimit: "",
    thirdLimit: "",
    phone: "(210) 948-0402",
  }

  const scheduledPowerUnits = {
    powerUnits: scheduleOfPowerUnits,
    policyNumber: alPolicyNo,
  }
  const scheduledDrivers = {
    drivers: scheduleOfDrivers,
    policyNumber: alPolicyNo,
  }

  let payload = [
    {
      policyNo: alPolicyNo,
      company,
      namedInsured: company,
      mga: "Southern Star MGA",
      dba: insuredInfo?.dba || "",
      surpluslineAgent: agencyInfo?.name || "",
      issuedDate:
        bindData?.bindDate && bindData?.bindDate?.seconds
          ? convertTimestampToDate(bindData?.bindDate)
          : "",
      address: phyiscalAddress,
      startDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      endDate:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      summaryCALLimit: coverageSingleLimit
        ? `${coverageSingleLimit}.00`
        : "NOT COVERED",
      summaryCIMLimit: "NOT COVERED",
      summaryCGLLimit: "NOT COVERED",
      summaryAPDLimit: "NOT COVERED",
      summaryTILimit: "NOT COVERED",
      summaryTRIA2022Limit: "NOT COVERED",
      summarySLTLimit: "NOT COVERED",
      summarySFLimit: "NOT COVERED",
      summaryPFLimit: "NOT COVERED",
      summaryOFLimit: "NOT COVERED",
      summaryTPDLimit: "NOT COVERED",
      summaryDPDLimit: "NOT COVERED",
      summaryCALDeductible: formatPrice(biLiabilityDeductible),
      summaryCALPremium:
        typeof alPremiums?.GWP === "number"
          ? formatPrice(alPremiums?.GWP)
          : "NOT COVERED",
      summaryCIMPremium: "NOT COVERED",
      summaryCGLPremium: "NOT COVERED",
      summaryAPDPremium: "NOT COVERED",
      summaryTIPremium: "NOT COVERED",
      summaryTRIA2022Premium: "NOT COVERED",
      summarySLTPremium:
        typeof surplusLinesTax === "number"
          ? formatPrice(surplusLinesTax)
          : "NOT COVERED",
      summarySFPremium:
        typeof stampTax === "number" ? formatPrice(stampTax) : "NOT COVERED",
      summaryPFPremium:
        typeof processingFee === "number"
          ? formatPrice(processingFee)
          : "NOT COVERED",
      summaryOFPremium:
        typeof otherFees === "number" ? formatPrice(otherFees) : "NOT COVERED",
      summaryTPDPremium:
        typeof totalPremium === "number"
          ? formatPrice(totalPremium)
          : "NOT COVERED",
      summaryDPDPremium: "NOT COVERED",
    }, // 1
    {
      policyNo: alPolicyNo,
    }, // 2
    {
      namedInsured: company,
      mailAddress: addressFromInsured,
      policyPeriodFrom:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      policyPeriodTo:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      corporation: organization,
      partnership: partnership,
      liability: limitedLiability,
      other: false,
      individual: individual,
      totalPremium: formatPrice(alPremiums?.GWP, true),
      surplusLineTax: formatPrice(alPremiums?.surplusLinesTaxAmount, true),
      stampingFee: formatPrice(alPremiums?.stampTaxAmount, true),
      managementFee: formatPrice(alPremiums?.processingFeeAmount, true),
      otherFees: formatPrice(alPremiums?.otherFees, true),
      totalCost: formatPrice(alPremiums?.totalPremium, true),
      coverageLiabilityLimit: `${coverageSingleLimit} CSL`,
      coverageLiabilityPremium: formatPrice(alPremiums?.GWP),
      coverageProtectionLimit: personalInjuryProtectionLimit ? `${formatPrice(personalInjuryProtectionLimit)} Each Accident` : "$2,500.00 Each Accident",
      coverageProtectionPremium: "Included",
      coverageMotoristLimit: umLiabilityPTL ? `${formatPrice(umLiabilityPTL)} Each Occurance` : "$85,000.00 Each Occurance",
      coverageMotoristPremium: "Included",
      representativeDate:
        bindData?.bindDate && bindData?.bindDate?.seconds
        ? convertTimestampToDate(bindData?.bindDate)
        : "",
    }, // 3
    {
      maximumBiLimit: `${coverageSingleLimit}`,
    }, // 4.html
    {}, // 4
    {}, // 5 - 7
    {
      accept: "DDS",
      endorsementLimit: `${formatPrice(umLiabilityPTL).replaceAll("$", "")}`,
      namedInsured: company,
    }, // 8
    {}, // 9
    {}, // 10 - 22
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      // nameOfPersonOrOrganization: company,
      // addressOfPersonOrOrganization: phyiscalAddress,
    }, // 23
    {
      signatureEffectiveDate: formatDateToDDMMYYYY(new Date()),
      signatureName: company,
    }, // 24
    {}, // 25 - 26
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      insureds: [
        {
          limitOfInsurance: `${formatPrice(personalInjuryProtectionLimit)} CSL`.replaceAll("$", ""),
          premium: "Included",
        },
      ],
      description1: true,
      description2: true,
      description3: true,
      description4: true,
    }, // 27
    {
      policyEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      company: "Accredited Specialty Insurance Company Inc",
      producer: "LICONA INSURANCE GROUP, LLC",
      namedInsured: company,
      initials: "",
      limits: formatPrice(personalInjuryProtectionLimit),
      reject: true,
      date: formatDateToDDMMYYYY(new Date()),
    }, // 28
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      // nameOfPersonOrOrganization: company,
      // addressOfPersonOrOrganization: phyiscalAddress,
    }, // 29
    {}, // 30 - 31
    { drivers }, // 32
    mcs90Payload, // MCS-09
    {
      namedInsured: company,
    }, // 124
    scheduledPowerUnits, // Schedule of power units
    scheduledDrivers, // Schedule of drivers
    {} // Privacy Notice
  ];
  if (isMCS09) {
    payload = [mcs90Payload]
  }

  if (isScheduled) {
    payload = [scheduledPowerUnits, scheduledDrivers]
  }

  return payload
};

export const domesticAlPolicyPayload = (
  formData,
  insuredInfo,
  driverList,
  truckList,
  trailerList,
  agencyInfo,
  option,
  quoteSelected
) => {
  const drivers = driverListToDocxToPdfFormat(driverList);
  const scheduleOfPowerUnits = scheduleOfPowerUnitsListToDocxToPdfFormat(truckList, formData)
  const scheduleOfDrivers = scheduleOfDriversListToDocxToPdfFormat(driverList, formData)

  const { company, mailingAddress: addressFromInsured, phyiscalAddress, businessStructure } = insuredInfo;

  const individual = Boolean(businessStructure === "Sole Proprietorship")
  const partnership = Boolean(businessStructure === "Partnership")
  const limitedLiability = Boolean(businessStructure === "LLC")
  const organization = Boolean(businessStructure === "Corporation")

  const {
    alPolicyNo,
    effectiveDate,
    expirationDate,
    alPremiums,
    coverageSingleLimit,
    bindData,
  } = formData;

  const personalInjuryProtectionLimit = formData?.bindData?.pipLimit || ""
  const umLiabilityPTL = formData?.bindData?.uimLimit || ""
  const biLiabilityDeductible = formData?.bindData?.biLiabilityDeductible || ""

  const processingFeeAL = alPremiums?.processingFeeAmount || 0;
  const otherFeesAL = alPremiums?.otherFees || 0;
  const surplusLinesTaxAL = alPremiums?.surplusLinesTaxAmount || 0;
  const stampTaxAL = alPremiums?.stampTaxAmount || 0;
  const totalPremiumAL = alPremiums?.totalPremium || 0;
  const processingFee = processingFeeAL;
  const otherFees = otherFeesAL;
  const surplusLinesTax = surplusLinesTaxAL;
  const stampTax = stampTaxAL;
  const totalPremium = totalPremiumAL;

  const isMCS09 = option?.includes('MCS09')
  const isScheduled = option?.includes('Scheduled')

  const partsOfDate = effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateParts(effectiveDate)
    : {
        day: "",
        month: "",
        year: "",
    }

  const mcs90Payload = {
    usDot: insuredInfo.dot,
    date: effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateWithSlash(effectiveDate)
    : "",
    issuedTo: company,
    of: formData.stateOfEntry || "",
    time: "12:01 AM",
    day: partsOfDate.day,
    month: partsOfDate.month,
    year: partsOfDate.year,
    policyNo: alPolicyNo,
    effectiveDate: effectiveDate && effectiveDate?.seconds
    ? convertTimestampToDateWithSlash(effectiveDate)
    : "",
    insuranceCompany: "Accredited Specialty Insurance Company Inc",
    counterSignedBy: "",
    firstLimitAccept: "X",
    firstLimit: coverageSingleLimit,
    secondLimitAccept: "",
    secondLimit: "",
    thirdLimit: "",
    phone: "(210) 948-0402",
  }

  const scheduledPowerUnits = {
    powerUnits: scheduleOfPowerUnits,
    policyNumber: alPolicyNo,
  }
  const scheduledDrivers = {
    drivers: scheduleOfDrivers,
    policyNumber: alPolicyNo,
  }

  let payload = [
    {
      policyNo: alPolicyNo,
      company,
      namedInsured: company,
      mga: "Southern Star MGA",
      dba: insuredInfo?.dba || "",
      surpluslineAgent: agencyInfo?.name || "",
      issuedDate:
        bindData?.bindDate && bindData?.bindDate?.seconds
          ? convertTimestampToDate(bindData?.bindDate)
          : "",
      address: phyiscalAddress,
      startDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      endDate:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      summaryCALLimit: coverageSingleLimit
        ? `${coverageSingleLimit}.00`
        : "NOT COVERED",
      summaryCIMLimit: "NOT COVERED",
      summaryCGLLimit: "NOT COVERED",
      summaryAPDLimit: "NOT COVERED",
      summaryTILimit: "NOT COVERED",
      summaryTRIA2022Limit: "NOT COVERED",
      summarySLTLimit: "NOT COVERED",
      summarySFLimit: "NOT COVERED",
      summaryPFLimit: "NOT COVERED",
      summaryOFLimit: "NOT COVERED",
      summaryTPDLimit: "NOT COVERED",
      summaryDPDLimit: "NOT COVERED",
      summaryCALDeductible: formatPrice(biLiabilityDeductible),
      summaryCALPremium:
        typeof alPremiums?.GWP === "number"
          ? formatPrice(alPremiums?.GWP)
          : "NOT COVERED",
      summaryCIMPremium: "NOT COVERED",
      summaryCGLPremium: "NOT COVERED",
      summaryAPDPremium: "NOT COVERED",
      summaryTIPremium: "NOT COVERED",
      summaryTRIA2022Premium: "NOT COVERED",
      summarySLTPremium:
        typeof surplusLinesTax === "number"
          ? formatPrice(surplusLinesTax)
          : "NOT COVERED",
      summarySFPremium:
        typeof stampTax === "number" ? formatPrice(stampTax) : "NOT COVERED",
      summaryPFPremium:
        typeof processingFee === "number"
          ? formatPrice(processingFee)
          : "NOT COVERED",
      summaryOFPremium:
        typeof otherFees === "number" ? formatPrice(otherFees) : "NOT COVERED",
      summaryTPDPremium:
        typeof totalPremium === "number"
          ? formatPrice(totalPremium)
          : "NOT COVERED",
      summaryDPDPremium: "NOT COVERED",
    }, // 1
    {
      policyNo: alPolicyNo,
    }, // 2
    {
      namedInsured: company,
      mailAddress: addressFromInsured,
      policyPeriodFrom:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      policyPeriodTo:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      corporation: organization,
      partnership: partnership,
      liability: limitedLiability,
      other: false,
      individual: individual,
      totalPremium: formatPrice(alPremiums?.GWP, true),
      surplusLineTax: formatPrice(alPremiums?.surplusLinesTaxAmount, true),
      stampingFee: formatPrice(alPremiums?.stampTaxAmount, true),
      managementFee: formatPrice(alPremiums?.processingFeeAmount, true),
      otherFees: formatPrice(alPremiums?.otherFees, true),
      totalCost: formatPrice(alPremiums?.totalPremium, true),
      coverageLiabilityLimit: `${coverageSingleLimit} CSL`,
      coverageLiabilityPremium: formatPrice(alPremiums?.GWP),
      coverageProtectionLimit: personalInjuryProtectionLimit ? `${formatPrice(personalInjuryProtectionLimit)} Each Accident` : "$2,500.00 Each Accident",
      coverageProtectionPremium: "Included",
      coverageMotoristLimit: umLiabilityPTL ? `${formatPrice(umLiabilityPTL)} Each Occurance` : "$85,000.00 Each Occurance",
      coverageMotoristPremium: "Included",
      representativeDate:
        effectiveDate && effectiveDate?.seconds
        ? convertTimestampToDate(effectiveDate)
        : "",
    }, // 3
    {
      maximumBiLimit: `${coverageSingleLimit}`,
    }, // 4.html
    {}, // 4
    {}, // 5 - 7
    {
      accept: "DDS",
      endorsementLimit: `${formatPrice(umLiabilityPTL).replaceAll("$", "")}`,
      namedInsured: company,
    }, // 8
    {}, // 9
    {}, // 10 - 22
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      // nameOfPersonOrOrganization: company,
      // addressOfPersonOrOrganization: phyiscalAddress,
    }, // 23
    {
      signatureEffectiveDate: formatDateToDDMMYYYY(new Date()),
      signatureName: company,
    }, // 24
    {}, // 25 - 26
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      insureds: [
        {
          limitOfInsurance: `${formatPrice(personalInjuryProtectionLimit)} CSL`.replaceAll("$", ""),
          premium: "Included",
        },
      ],
      description1: true,
      description2: true,
      description3: true,
      description4: true,
    }, // 27
    {
      policyEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      company: "Accredited Specialty Insurance Company Inc",
      producer: "LICONA INSURANCE GROUP, LLC",
      namedInsured: company,
      initials: "",
      limits: formatPrice(personalInjuryProtectionLimit),
      reject: true,
      date: formatDateToDDMMYYYY(new Date()),
    }, // 28
    {
      namedInsured: company,
      endorsementEffectiveDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      // nameOfPersonOrOrganization: company,
      // addressOfPersonOrOrganization: phyiscalAddress,
    }, // 29
    {}, // 30 - 31
    { drivers }, // 32
    mcs90Payload, // MCS-09
    {
      namedInsured: company,
    }, // 124
    scheduledPowerUnits, // Schedule of power units
    scheduledDrivers, // Schedule of drivers
    {} // Privacy Notice
  ];
  if (isMCS09) {
    payload = [mcs90Payload]
  }
  if (isScheduled) {
    payload = [scheduledPowerUnits, scheduledDrivers]
  }
  return payload
};

export const naftaGlPolicyPayload = (
  formData,
  insuredInfo,
  _, __, ___, ____, _____, formDataGL
) => {

  const {
    company,
    mailingAddress: addressFromInsured,
    businessStructure
  } = insuredInfo;
  const {
    bindData,
  } = formData;

  const {
    effectiveDate,
    expirationDate,
    glPolicyNo
  } = formDataGL

  const individual = Boolean(businessStructure === "Sole Proprietorship") ? ' X ' : '___'
  const partnership = Boolean(businessStructure === "Partnership") ? ' X ' : '___'
  const jointVenture = '___'
  const trust = '___'
  const limitedLiability = Boolean(businessStructure === "LLC") ? ' X ' : '___'
  const organization = Boolean(businessStructure === "Corporation") ? ' X ' : '___'

  const payload = [
    {
      dba: insuredInfo?.dba || "",
      namedInsured: company,
      address: addressFromInsured,
      glPolicyNo: glPolicyNo || "",
      issuedDate:
        bindData?.bindDate && bindData?.bindDate?.seconds
          ? convertTimestampToDate(bindData?.bindDate)
          : "",
      startDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      endDate:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      eachOccuranceLimit: "1,000,000",
      rentedLimit: "Not Included",
      medicalLimit: "Not Included",
      injuryLimit: "Not Included",
      generalAggregateLimit: "2,000,000.00",
      operationsAggregateLimit: "Not Included",
      retroactiveDate: "NONE",
      individual,
      partnership,
      jointVenture,
      trust,
      limitedLiability,
      organization,
      businessDescription: "Transportation",
    }, // 50
    {},
    {},
    {
      namedInsured: company,
    }, // 53
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      description: "",
    }, // 62
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      namedInsured: company,
    }, // 201
  ];
  return payload
};

export const domesticGlPolicyPayload = (
  formData,
  insuredInfo,
  _, __, ___, ____, _____, formDataGL
) => {
  
  const {
    company,
    mailingAddress: addressFromInsured,
    businessStructure
  } = insuredInfo;
  const {
    bindData,
  } = formData;

  const {
    effectiveDate,
    expirationDate,
    glPolicyNo
  } = formDataGL

  const individual = Boolean(businessStructure === "Sole Proprietorship") ? ' X ' : '___'
  const partnership = Boolean(businessStructure === "Partnership") ? ' X ' : '___'
  const jointVenture = '___'
  const trust = '___'
  const limitedLiability = Boolean(businessStructure === "LLC") ? ' X ' : '___'
  const organization = Boolean(businessStructure === "Corporation") ? ' X ' : '___'

  const payload = [
    {
      dba: insuredInfo?.dba || "",
      namedInsured: company,
      address: addressFromInsured,
      glPolicyNo: glPolicyNo || "",
      issuedDate:
        bindData?.bindDate && bindData?.bindDate?.seconds
          ? convertTimestampToDate(bindData?.bindDate)
          : "",
      startDate:
        effectiveDate && effectiveDate?.seconds
          ? convertTimestampToDate(effectiveDate)
          : "",
      endDate:
        expirationDate && expirationDate?.seconds
          ? convertTimestampToDate(expirationDate)
          : "",
      eachOccuranceLimit: "1,000,000",
      rentedLimit: "Not Included",
      medicalLimit: "Not Included",
      injuryLimit: "Not Included",
      generalAggregateLimit: "2,000,000.00",
      operationsAggregateLimit: "Not Included",
      retroactiveDate: "NONE",
      individual,
      partnership,
      jointVenture,
      trust,
      limitedLiability,
      organization,
      businessDescription: "Transportation",
    }, // 50
    {},
    {},
    {
      namedInsured: company,
    }, // 53
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      description: "",
    }, // 62
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {
      namedInsured: company,
    }, // 201
  ];
  return payload
};