import React, { useEffect, useMemo, useState } from "react";
import BlueButton from "../../assets/forms/BlueButton";
import RedButton from "../../assets/forms/RedButton";
import { Col, Container, Row } from "react-bootstrap";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  runTransaction,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebase";
import { numberWithCommas } from "../../../utils/helpers";
import endoStatusChangeNotification from "../../utils/notifications/endoStatusChangeNotification";
import Form from "../../generals/Forms/Form";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import ReasonsModal from "../../PolicyLifecycleManager/ReturnToAgentModal";
import { formatDateToYYYYMMDD } from "../../Finances/Tables/utils";
import { getLastId } from "../../../utils/endorsementDetails";
import PowerUnitDetailFlags from "../../PolicyLifecycleManager/RedFlags/PowerUnitDetailFlags";

export default function SwapPowerUnits(props) {
  const endorsement = props.endorsement;
  const paramPolicyID = props.paramPolicyID;
  const paramRefID = props.paramRefID;
  const setSuccess = props.setSuccess;
  const requestExceptionInputs = props.requestExceptionInputs;

  const isRequestException = endorsement?.documentType === "Exception Request";
  const wasARequestException = endorsement?.wasARequestException === true;

  const [oldVehicles, setOldVehicles] = useState([]);
  const [newVehicles, setNewVehicles] = useState([]);

  const handleGetPowerUnitsById = async () => {
    let oldUnits = [];
    for (const powerUnitId of endorsement?.data?.oldVehicles) {
      const docRef = doc(
        db,
        "agencies",
        endorsement.agencyID,
        "insureds",
        endorsement.insuredID,
        "power-units",
        getLastId(powerUnitId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        oldUnits.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    setOldVehicles(oldUnits);
    let newUnits = [];
    for (const powerUnitId of endorsement?.data?.newVehicles) {
      const docRef = doc(
        db,
        "agencies",
        endorsement.agencyID,
        "insureds",
        endorsement.insuredID,
        "power-units",
        getLastId(powerUnitId.path)
      );
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        newUnits.push({
          id: docSnap.id,
          ...docSnap.data(),
        });
      } else {
        console.log("No such document!");
        return null;
      }
    }
    setNewVehicles(newUnits);
  };

  useEffect(() => {
    handleGetPowerUnitsById();
  }, [endorsement]);

  const [correspondingRequestExceptionInputs] = requestExceptionInputs
    .filter((info) => {
      return info.id === endorsement?.type;
    })
    .map((info) => info.inputs);

  const approveAddPowerUnitsEndorsement = async (e, requestExceptionData) => {
    e.preventDefault();
    const updateEndorsementStatusAndCreatePowerUnit = async (
      endorsementId,
      status,
      powerUnitData
    ) => {
      const endorsementRef = doc(
        db,
        "policy-applications",
        paramPolicyID,
        "endorsements",
        endorsementId
      );
      const powerUnitsCollectionRef = collection(
        db,
        "policy-applications",
        paramPolicyID,
        "power-units"
      );
      try {
        await runTransaction(db, async (transaction) => {
          await transaction.get(endorsementRef);
          const issuedDate = new Date()
          const endoPayload = {
            status,
            wasARequestException: false,
            issuedDate,
          };
          if (requestExceptionData) {
            endoPayload["wasARequestException"] = true;
            endoPayload["requestException"] = requestExceptionData;
          }
          transaction.update(endorsementRef, endoPayload);
          const promises = powerUnitData.map(async (powerUnit) => {
            const newPowerUnitRef = await addDoc(powerUnitsCollectionRef, {
              ...powerUnit,
              effectiveDate: endorsement.endoEffectiveDate,
            });
            return newPowerUnitRef.id;
          });
          Promise.all(promises)
            .then((createdPowerUnitIds) => {
              setSuccess(true);
            })
            .catch((error) => {
              console.error("Error creating powerUnits:", error);
            });
        });
      } catch (error) {
        console.error(
          "Error updating endorsement status and creating power unit:",
          error
        );
      }
    };
    updateEndorsementStatusAndCreatePowerUnit(
      paramRefID,
      "Approved",
      endorsement.data
    );
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Approved",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const [rejectionModal, setRejectionModal] = useState(false);

  const declineAddPowerUnitsEndorsement = async (
    e,
    requestExceptionData,
    reasons
  ) => {
    if (e) {
      e.preventDefault();
    }
    const powerUnitRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );

    const payload = {
      status: "Declined",
      wasARequestException: false,
      declinedDate: new Date(),
    };
    if (requestExceptionData) {
      payload["wasARequestException"] = true;
      payload["requestException"] = requestExceptionData;
    }
    if (reasons) {
      payload["rejectionReasons"] = reasons;
    }

    await updateDoc(powerUnitRef, payload);
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
    setSuccess(true);
  };

  const formSettingsRequestException = useMemo(() => {
    const settings = {
      title: "Reason for exception",
      onSubmit: approveAddPowerUnitsEndorsement,
      onDecline: declineAddPowerUnitsEndorsement,
      inputs: correspondingRequestExceptionInputs || [],
      buttonLabel: "Submit",
      buttonLabelDecline: "Cancel",
      otherInput: { label: "Other exception request" },
    };
    return settings;
  }, [correspondingRequestExceptionInputs]);

  const requestExceptionModeSetting = async (e) => {
    e.preventDefault();
    const endoRef = doc(
      db,
      "policy-applications",
      paramPolicyID,
      "endorsements",
      paramRefID
    );
    await updateDoc(endoRef, {
      documentType: "Exception Request",
    });
    endoStatusChangeNotification(
      endorsement.type,
      endorsement.endorsementNumber,
      "Declined",
      paramRefID,
      paramPolicyID,
      endorsement.agencyID
    );
  };

  const priceToHandle = (price) => {
    if (price) {
      const amount = numberWithCommas(price.toFixed(2));
      if (amount === NaN || !amount || amount === "NaN") {
        return "Not calculated yet";
      }
      return `$${amount}`;
    } else {
      return "Not calculated yet";
    }
  };

  const PowerUnitContent = ({ truck, policyDetailToShow }) => {
    return (
      <Container className="p-0">
        <Row>
          <Col sm={12} md={6}>
            <PowerUnitDetailFlags powerUnit={truck} policyID={paramPolicyID} />
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <span className="font-semibold">VIN:</span> {truck.truckVIN}
          </Col>
          <Col sm={12} md={6}>
            <span className="font-semibold">Weight:</span> {truck.truckWeight}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <span className="font-semibold">Year:</span> {truck.truckYear}
          </Col>
          <Col sm={12} md={6}>
            <span className="font-semibold">Make:</span>{" "}
            {policyDetailToShow?.truckMake}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <span className="font-semibold">Model:</span>{" "}
            {policyDetailToShow?.truckModel}
          </Col>
          <Col sm={12} md={6}>
            <span className="font-semibold">Class:</span>{" "}
            {policyDetailToShow?.truckClass}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={6}>
            <span className="font-semibold">Rating:</span>{" "}
            {priceToHandle(policyDetailToShow?.adjustedRate)}
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <>
      <ReasonsModal
        title="Rejection Reasons"
        text="Please enter the reason(s) why the endorsement is being rejected."
        addButtonText="Add A Reason"
        onSubmit={(e, reasons) =>
          declineAddPowerUnitsEndorsement(e, null, reasons)
        }
        open={rejectionModal}
        onClose={() => setRejectionModal(false)}
      />
      <h2 className="font-bold mb-2">New Vehicle(s)</h2>
      <div>
        {newVehicles.map((truck) => {
          const policyDetailToShow = truck?.policiesDetails.find(
            (detail) => detail.id === paramPolicyID
          );
          return (
            <div className="mx-auto p-6 bg-white shadow-lg rounded-lg mb-2">
              <PowerUnitContent
                truck={truck}
                policyDetailToShow={policyDetailToShow}
              />
            </div>
          );
        })}
      </div>
      <h2 className="font-bold mt-4 mb-2">Old Vehicle(s)</h2>
      <div>
        {oldVehicles.map((truck) => {
          const policyDetailToShow = truck?.policiesDetails.find(
            (detail) => detail.id === paramPolicyID
          );
          return (
            <div className="mx-auto p-6 bg-white shadow-lg rounded-lg mb-2">
              <PowerUnitContent
                truck={truck}
                policyDetailToShow={policyDetailToShow}
              />
            </div>
          );
        })}
      </div>

      {isRequestException &&
      typeof correspondingRequestExceptionInputs !== "undefined" ? (
        <Form
          formSettings={formSettingsRequestException}
          isSmallVersion={true}
        />
      ) : null}

      {endorsement.status === "Submitted" ? (
        <>
          <article className="mt-3">
            <p>
              Approving this endorsement will add the above powerUnit to the
              policy and notify the insured of the change.
            </p>
          </article>
          <div className="mt-6">
            <BlueButton
              onClickFunction={(e) => approveAddPowerUnitsEndorsement(e)}
              text="Approve"
            />
            <RedButton
              onClickFunction={() => setRejectionModal(true)}
              text="Decline"
            />
          </div>
        </>
      ) : endorsement.status === "Declined" &&
        !isRequestException &&
        !wasARequestException ? (
        <>
          <ApprovalButtons
            dissapearAccept={true}
            titleDecline="REQUEST EXCEPTION"
            onDecline={requestExceptionModeSetting}
          />
        </>
      ) : null}
    </>
  );
}
