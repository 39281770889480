// TODO: Currently you cannot change the commodities when the doucment type = "Policy". We need to change this and build an endorsement workflow for the adjusting of commodities.
import React, { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import updatePolicyField, {
  updatePolicyInsuredField,
} from "../../utils/updatePolicyField";
import Breadcrumbs from "../../assets/Breadcrumbs";
import StandardCommodity from "./StandardCommodity";
import CustomCommodity from "./CustomCommodity";
import commodityData, { commodityImportData } from "./utils/commodityData";
import handleAddCommodity from "./utils/handleAddCommodity";
import DragDropImportList from "../../assets/forms/DragDropFileUpload/DragDropImportList";
import useGeneralState from "../../../Context/actions/useGeneralState";
import Popup from "../../generals/Popup";
import BlueButton from "../../assets/forms/BlueButton";
import { Tooltip, useMediaQuery } from "@mui/material";
import { IconButton, MenuItem, Select } from "@mui/material";
import {
  ViewComfy as ViewComfyIcon,
  IosShare as IosShareIcon,
  ModeEdit as PencilIcon,
  Cancel as CancelIcon,
  Visibility as ViewIcon,
} from "@mui/icons-material";
import "../../styling/styles.css";
import ApiService from "../../../services/southern-api/apiService";
import ShareModal from "../../ShareModal";
import { useLocation } from "react-router-dom";
import useTranslations from "../../../Context/actions/useTranslations";
import RedButton from "../../assets/forms/RedButton";
import ApprovalButtons from "../../assets/forms/ApprovalButtons";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../firebase";
import CommoditiesLogs from "./utils/CommodityLogs";
import {
  commoditiesSubmission,
  CommodityLogger,
} from "./utils/handleSubmission";

export default function Commodities({
  formData: generalFormData,
  policyID,
  userInfo,
  setDidSuccess,
  emailValue,
  insuredInfo,
  setInsuredInfo,
}) {
  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const getModuleValueFromCurrentUrl = () => {
    const url = window.location.href;
    const urlObject = new URL(url);
    return urlObject.searchParams.get("module");
  };

  const { t } = useTranslations();
  const moduleValue = getModuleValueFromCurrentUrl();

  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [columns, setColumns] = useState(!isMobileScreen ? 3 : 1);
  const [formData, setFormData] = useState(generalFormData);
  const [viewLogs, setViewLogs] = useState(false);

  const data = Boolean(moduleValue) ? formData : generalFormData;

  const handleColumnChange = (event) => {
    setColumns(event.target.value);
  };

  const [didFormData, setDidFormData] = useState(false);

  useEffect(() => {
    if (generalFormData && !didFormData) {
      setFormData(generalFormData);
      setDidFormData(true);
    }
  }, [data]);

  useEffect(() => {
    if (!data?.commodityFields || data?.commodityFields?.length === 0) {
      updatePolicyInsuredField(
        policyID,
        "commodityFields",
        [
          {
            id: 1,
            commodityDescription: "",
            commodityLoad: "0",
          },
        ],
        data,
        insuredInfo,
        setInsuredInfo
      );
    }
  }, [data?.commodityFields]);

  const breadcrumbs = [
    { label: t(`plm.status.${data?.documentType}`) },
    { label: t("plm.commodities.title") },
  ];

  const { controlToast } = useGeneralState();
  const [openShareModal, setOpenShareModal] = useState(false);
  const location = useLocation();
  const isTemporalUrl = location?.pathname?.includes("temporarily");
  const isTemporalUrlClient =
    location?.pathname?.includes("temporarily-client");
  const apiService = new ApiService();
  const handleFinishOperation = async () => {
    try {
      await apiService.renewalProcessModuleUpdateConfirmation({
        policyData: data,
        module: "commodities",
        emails: [emailValue],
      });
      setDidSuccess(true);
    } catch (error) {
      console.error(error);
    }
  };

  const documentType = data?.documentType;
  const isNeverEditable =
    documentType === "Quote Request" || documentType === "Bind Order";
  const [editMode, setEditMode] = useState(false);
  const initialState = {
    commodityFields: insuredInfo?.commodityFields || [],
    commodityTotal: insuredInfo?.commodityTotal || 0,
    standardCommodityPercentages:
      insuredInfo?.standardCommodityPercentages || {},
    standardCommodityValues: insuredInfo?.standardCommodityValues || {},
  };
  const [newInsuredInfo, setNewInsuredInfo] = useState(initialState);

  function sumCommodityPercentages(percentages) {
    return Object.entries(percentages).reduce((sum, [key, value]) => {
      if (!key.includes("Explanation")) {
        const numericValue = Number(value) || 0;
        return sum + numericValue;
      }
      return sum;
    }, 0);
  }

  function sumCommodityLoad(commodities) {
    if (!Array.isArray(commodities) || commodities.length === 0) {
      return 0;
    }
    return commodities.reduce((sum, item) => {
      const loadValue = Number(item.commodityLoad) || 0;
      return sum + loadValue;
    }, 0);
  }

  useEffect(() => {
    const preparedInsuredInfo = { ...newInsuredInfo };
    const percentages = sumCommodityPercentages(
      preparedInsuredInfo.standardCommodityPercentages
    );
    const percentagesCustom = sumCommodityLoad(
      preparedInsuredInfo.commodityFields
    );
    preparedInsuredInfo["commodityTotal"] = percentages + percentagesCustom;
    setNewInsuredInfo(preparedInsuredInfo);
  }, [
    newInsuredInfo?.standardCommodityPercentages,
    newInsuredInfo?.commodityFields,
  ]);

  const handleSubmitInsuredCommodityChanges = async () => {
    try {
      setLoading(true);

      const cleanedInsuredInfo = await commoditiesSubmission({
        insuredInfo,
        newInsuredInfo,
        formData,
        documentType,
      });

      if (documentType === "Application") {
        setInsuredInfo({
          ...insuredInfo,
          cleanedInsuredInfo,
        });
      }

      setEditMode(false);
      setLoading(false);
      if (documentType === "Application") {
        controlToast(true, "Commodities updated successfully", "success");
      } else {
        controlToast(
          true,
          "Modify Commodities endorsement successfully created",
          "success"
        );
      }
    } catch (error) {
      console.log("Error updating commodities", error);
      setLoading(false);
      controlToast(true, "Error updating commodities", "error");
    }
  };

  const handleCancelInsuredCommodityChanges = async () => {
    setEditMode(false);
    setNewInsuredInfo(insuredInfo);
  };

  return (
    <div>
      <CommoditiesLogs
        open={viewLogs}
        setOpen={setViewLogs}
        formData={formData}
        modal={true}
      />
      <ShareModal
        insuredInfo={newInsuredInfo}
        formData={data}
        openShareModal={openShareModal}
        setOpenShareModal={setOpenShareModal}
        module={"commodities"}
      />
      <Popup
        isOpen={open}
        onRequestClose={() => {
          if (!loading) {
            setOpen(false);
          }
        }}
        maxWidth="100%"
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={loading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={`Printing... Don't leave the screen`}
          >
            <p className="text-2xl mt-2 font-semibold mb-3">
              {t("plm.commodities.import-title")}
            </p>
            <DragDropImportList
              file={file}
              setFile={setFile}
              handleUpload={async (fileObj) => {
                await commodityImportData(
                  fileObj,
                  policyID,
                  controlToast,
                  setLoading,
                  newInsuredInfo,
                  formData,
                  setNewInsuredInfo
                );
                setOpen(false);
              }}
              fileTypes={["XLSX"]}
            />
          </LoadingScreen>
        </div>
      </Popup>
      <Breadcrumbs items={breadcrumbs} />
      <div className="flex items-center mb-4">
        <h1 className="text-3xl mt-2 mr-3 font-semibold">
          {t("plm.commodities.title")}
        </h1>
        <h1 className="text-3xl font-semibold">
          {typeof newInsuredInfo.commodityTotal !== "undefined" &&
          newInsuredInfo.commodityTotal !== 100 ? (
            <Tooltip
              title={
                t("plm.commodities.custom.final-text") +
                " " +
                newInsuredInfo.commodityTotal +
                "%"
              }
            >
              <button className="text-[#8B0000]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-flag-fill d-inline mr-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M14.778.085A.5.5 0 0 1 15 .5V8a.5.5 0 0 1-.314.464L14.5 8l.186.464-.003.001-.006.003-.023.009a12.435 12.435 0 0 1-.397.15c-.264.095-.631.223-1.047.35-.816.252-1.879.523-2.71.523-.847 0-1.548-.28-2.158-.525l-.028-.01C7.68 8.71 7.14 8.5 6.5 8.5c-.7 0-1.638.23-2.437.477A19.626 19.626 0 0 0 3 9.342V15.5a.5.5 0 0 1-1 0V.5a.5.5 0 0 1 1 0v.282c.226-.079.496-.17.79-.26C4.606.272 5.67 0 6.5 0c.84 0 1.524.277 2.121.519l.043.018C9.286.788 9.828 1 10.5 1c.7 0 1.638-.23 2.437-.477a19.587 19.587 0 0 0 1.349-.476l.019-.007.004-.002h.001" />
                </svg>
              </button>
            </Tooltip>
          ) : null}
        </h1>
      </div>

      <div>
        {/* Commodities List */}

        <div className="container mx-auto mt-1 p-8 bg-white rounded shadow-lg">
          <fieldset className="mb-8">
            <p className="mb-2">
              {t(
                "Please indicate what percentage of hauled loads consist of the following commodities."
              )}
            </p>
            {isTemporalUrl && (
              <p className="mb-2">{t("plm.commodities.submit-instructions")}</p>
            )}
            <div className="mb-3">
              <div className="mt-3 mb-1 flex items-center flex-wrap">
                {isTemporalUrl && !isTemporalUrlClient && (
                  <BlueButton
                    text={t("plm.commodities.submit-changes")}
                    onClickFunction={handleFinishOperation}
                    hasContentToBottom={true}
                    hasContentToRight={!isMobileScreen}
                    className={isMobileScreen ? "w-[100%]" : ""}
                    disabled={newInsuredInfo?.commodityTotal !== 100}
                    icon="no-icon"
                  />
                )}
                {!isTemporalUrl && (
                  <>
                    {userInfo?.policyPermissions && (
                      <BlueButton
                        className={isMobileScreen ? "w-[100%]" : ""}
                        text={t("plm.commodities.import")}
                        onClickFunction={() => setOpen(true)}
                        hasContentToRight={!isMobileScreen}
                        hasContentToBottom={true}
                        hasContentToTop={true}
                      />
                    )}
                    {!isNeverEditable &&
                    !editMode &&
                    userInfo?.policyPermissions ? (
                      <BlueButton
                        className={isMobileScreen ? "w-[100%]" : ""}
                        text={t("plm.commodities.edit.enable-edit")}
                        onClickFunction={() => setEditMode(!editMode)}
                        hasContentToBottom={true}
                        hasContentToTop={true}
                        hasContentToRight={!isMobileScreen}
                        icon={<PencilIcon />}
                      />
                    ) : null}
                    {userInfo?.role === "Agent" ||
                    userInfo?.role === "Admin" ||
                    userInfo?.role === "Underwriter" ? (
                      <BlueButton
                        text={t("plm.share-module.share")}
                        hasContentToBottom={true}
                        hasContentToTop={true}
                        hasContentToRight={!isMobileScreen}
                        onClickFunction={() => {
                          console.log("click in share modal");
                          setOpenShareModal(true);
                        }}
                        icon={<IosShareIcon />}
                        className={isMobileScreen ? "w-[100%]" : ""}
                      />
                    ) : null}
                    <BlueButton
                      className={isMobileScreen ? "w-[100%]" : ""}
                      text={t("plm.commodities.edit.view-logs")}
                      onClickFunction={() => setViewLogs(true)}
                      hasContentToBottom={true}
                      hasContentToTop={true}
                      icon={<ViewIcon />}
                    />
                  </>
                )}

                {!isMobileScreen && (
                  <div className="flex items-center space-x-2 ml-auto">
                    <span className="text-md">
                      {t("plm.commodities.manage-columns")}:
                    </span>
                    <IconButton>
                      <ViewComfyIcon fontSize="large" />
                    </IconButton>
                    <Select
                      value={columns}
                      onChange={handleColumnChange}
                      displayEmpty
                      variant="outlined"
                      size="small"
                      className="w-20"
                    >
                      <MenuItem value={1}>
                        {t("plm.commodities.column1")}
                      </MenuItem>
                      <MenuItem value={2}>
                        {t("plm.commodities.column2")}
                      </MenuItem>
                      <MenuItem value={3}>
                        {t("plm.commodities.column3")}
                      </MenuItem>
                    </Select>
                  </div>
                )}
              </div>
              {data?.documentType === "Policy" && (
                <>{t("plm.commodities.description2")}</>
              )}
              {data?.documentType !== "Application" &&
                data?.documentType !== "Policy" && (
                  <>{t("plm.commodities.description3")}</>
                )}
            </div>

            {newInsuredInfo?.commodityTotal !== 100 && (
              <p className="text-[#8B0000] font-bold">
                {t("plm.commodities.custom.final-text")}{" "}
                {newInsuredInfo?.commodityTotal || 0}%
              </p>
            )}

            <div className="mb-8">
              {editMode && (
                <div className="w-full">
                  <ApprovalButtons
                    onApprove={async (e) => {
                      e.preventDefault();
                      await handleSubmitInsuredCommodityChanges();
                    }}
                    onDecline={handleCancelInsuredCommodityChanges}
                    disabledAccept={newInsuredInfo?.commodityTotal !== 100}
                    titleAccept={"SAVE COMMODITIES"}
                    titleDecline={"CANCEL"}
                    forceLoadingAccept={loading}
                  />
                </div>
              )}
              <div
                className={`grid grid-cols-1 sm:grid-cols-${columns} lg:grid-cols-${columns} gap-4`}
              >
                {commodityData(t).map((commodity, index) => (
                  <>
                    {data?.documentType === "Application" ||
                    newInsuredInfo?.standardCommodityPercentages?.[
                      commodity.property
                    ] > 0 ||
                    editMode ? (
                      <div
                        key={"standard-commodity" + index}
                        className="p-4 border rounded-lg shadow-md bg-white"
                      >
                        <StandardCommodity
                          editMode={editMode}
                          label={commodity.label}
                          property={commodity.property}
                          formData={data}
                          defaultValue={commodity.defaultValue}
                          marks={commodity.marks}
                          max={commodity.max}
                          min={commodity.min}
                          value={commodity.value}
                          hasDetails={commodity.hasDetails}
                          disabledSlider={commodity.disabledSlider}
                          policyID={policyID}
                          setFormData={setFormData}
                          insuredInfo={newInsuredInfo}
                          setInsuredInfo={setNewInsuredInfo}
                        />
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
            </div>

            <h1 className="text-2xl font-bold mb-2">
              {t("plm.commodities.custom.title")}
            </h1>

            <p className="mb-2 text-gray-600">
              {t("plm.commodities.description4")}
            </p>

            {newInsuredInfo?.commodityFields.map((field, index) => (
              <CustomCommodity
                editMode={editMode}
                formData={data}
                setFormData={setFormData}
                field={field}
                index={index}
                insuredInfo={newInsuredInfo}
                setInsuredInfo={setNewInsuredInfo}
              />
            ))}

            {(data?.documentType === "Application" ||
              data?.documentType === "Policy") &&
            editMode ? (
              <button
                className="text-blue-500 hover:text-blue-700"
                onClick={(e) => {
                  e.preventDefault();
                  handleAddCommodity(newInsuredInfo, setNewInsuredInfo);
                }}
                disabled={!editMode}
              >
                {t("plm.commodities.custom.add-commodity")}
              </button>
            ) : null}
          </fieldset>

          {newInsuredInfo?.commodityTotal !== 100 && (
            <p className="text-[#8B0000] font-bold">
              {t("plm.commodities.custom.final-text")}{" "}
              {newInsuredInfo?.commodityTotal || 0}%
            </p>
          )}

          {editMode && (
            <div className="w-full">
              <ApprovalButtons
                onApprove={async (e) => {
                  e.preventDefault();
                  await handleSubmitInsuredCommodityChanges();
                }}
                onDecline={handleCancelInsuredCommodityChanges}
                disabledAccept={newInsuredInfo?.commodityTotal !== 100}
                titleAccept={"SAVE COMMODITIES"}
                titleDecline={"CANCEL"}
                forceLoadingAccept={loading}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
