import React, { useState } from "react";
import ExpandCollapseArrow from "../../generals/DesignElements/ExpandCollapseArrow";

export default function RequestBindMessage({
  className,
  reasons,
  requestDate,
}) {
  const [show, setShow] = useState(true);
  const color = "green";
  return (
    <main
      className={`card mt-3 bg-${color}-100 text-${color}-700 ${
        className || ""
      }`}
    >
      <div className="card-header" onClick={(e) => setShow(!show)}>
        <h1 className={`text-lg font-bold text-${color}-700`}>
          <button>Bind Requested</button>
          <ExpandCollapseArrow show={show} setShow={setShow} />
        </h1>
      </div>
      {show && (
        <div className="card-body">
          <p className="mb-2">
            A bind order was submitted on{" "}
            {requestDate?.toDate().toLocaleDateString("en-US", {
              month: "short",
              day: "2-digit",
              year: "numeric",
            })}{" "}
            with the following comments:
          </p>
          <ul className="list-disc ml-6">
            {reasons.map((reason, i) => {
              return (
                <li key={i} className="mb-2">
                  {reason}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </main>
  );
}
