import { collection, getDocs, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { auth, db } from "../../../firebase";
import Modal from "react-modal";
import Breadcrumbs from "../../assets/Breadcrumbs";
import PremiumsTable from "./ALandGL";
import {
  Backdrop,
  CircularProgress,
  Typography,
  useMediaQuery,
} from "@mui/material";
import countPowerUnits from "./utils/countPowerUnits";
import applyAdjustments from "./utils/applyAdjustments";
import useTranslations from "../../../Context/actions/useTranslations";
import QuotesTable from "./QuotesTable";
import GoBack from "../../generals/GoBack";
import Header from "./Header";
import KPBrokersInformationSheetDownloadButton from "./KPBrokersInformationSheetDownloadButton";
import NewQuoteDetails from "./NewQuoteDetails";
import PreviousYearsAdjustmentsModal from "./PreviousYearsAdjustmentsModal";
import createNewQuote from "./utils/createNewQuote";
import ReferralMessage from "../Referral/ReferralMessage";
import LoadingScreen from "../../assets/LoadingScreen";

Modal.setAppElement("#root");
export default function Quotes({
  insuredInfo,
  policyID,
  trailerList,
  taxSchedule,
  formData,
  setFormData,
  apdAndMtc,
  userInfo,
  formDataGL,
  formDataAPD,
  formDataMTC,
}) {
  const { t } = useTranslations();

  const agencyID = formData.agencyID;
  const insuredID = formData.insuredID;

  const [powerUnitCount, setPowerUnitCount] = useState(0);
  const [quoteHistory, setQuoteHistory] = useState([]);
  const [gotQuoteHistory, setGotQuoteHistory] = useState(false);
  const [loading, setLoading] = useState(false);

  const [truckListData, setTruckListData] = useState([]);
  const [showQuoteSuccess, setShowQuoteSuccess] = useState(false);

  const getTruckListData = async () => {
    const agencyID = formData.agencyID;
    const insuredID = formData.insuredID;
    let truckList = [];
    let totalRating = 0;
    const truckSnapshot = await getDocs(
      collection(db, "agencies", agencyID, "insureds", insuredID, "power-units")
    );
    truckSnapshot.forEach((doc) => {
      truckList.push({ ...doc.data(), id: doc.id });
      totalRating += doc.data().rating;
    });
    setTruckListData([totalRating, truckList]);
  };

  useEffect(() => {
    getTruckListData();
  }, [loading]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, "policy-applications", policyID, "quotes"),
      (querySnapshot) => {
        let values = [];
        querySnapshot.forEach((doc) => {
          values.push({ ...doc.data(), id: doc.id });
        });
        setQuoteHistory(values);
        setGotQuoteHistory(true);
      }
    );

    countPowerUnits(agencyID, insuredID, setPowerUnitCount);

    // Cleanup the listener when the component unmounts
    return () => unsubscribe();
  }, [policyID]);

  const [isNewQuote, setIsNewQuote] = useState(false);

  const hasALOrGL = formData.hasALCoverage || formData.hasGLCoverage;

  const isMobileScreen = useMediaQuery("(max-width: 600px)");

  const breadcrumbsNotNew = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t(`plm.quotes.titles.Quote Request`) },
  ];

  const breadcrumbsNew = [
    { label: t(`plm.status.${formData.documentType}`) },
    { label: t(`plm.quotes.titles.Quote Request`) },
  ];

  const staging = formData?.stagingArea?.adjustmentFormData;

  const [adjustmentFormData, setAdjustmentFormData] = useState({
    debitOrCredit: staging?.debitOrCredit || "Debit",
    description: "",
    lossRatioAdjustmentDebitOrCredit:
      staging?.lossRatioAdjustmentDebitOrCredit || "Debit",
    lossRatioAdjustmentPercentage: staging?.lossRatioAdjustmentPercentage || 0,
    percentage: staging?.percentage || 0,
    renewalCredit: staging?.renewalCredit || 0,
    safetyAdjustmentDebitOrCredit:
      staging?.safetyAdjustmentDebitOrCredit || "Debit",
    safetyAdjustmentPercentage: staging?.safetyAdjustmentPercentage || 0,
  });

  const breadcrumbs = isNewQuote ? breadcrumbsNew : breadcrumbsNotNew;

  return (
    <>
      {loading ? (
        <LoadingScreen text={"Please wait..."} />
      ) : (
        <main>
          <Breadcrumbs items={breadcrumbs} />

          <Header
            userInfo={userInfo}
            formData={formData}
            isNewQuote={isNewQuote}
          />
          <ReferralMessage
            formData={formData}
            className="mt-2"
            policyID={policyID}
            userRole={userInfo?.role}
          />
          <fieldset disabled={!userInfo?.policyPermissions}>
            {isNewQuote && (
              <div className="mt-2">
                <GoBack handleFn={() => setIsNewQuote(false)} />
                <NewQuoteDetails
                  formData={formData}
                  loading={loading}
                  setLoading={setLoading}
                  insuredInfo={insuredInfo}
                  setFormData={setFormData}
                  userInfo={userInfo}
                  powerUnitCount={powerUnitCount}
                  isNewQuote={isNewQuote}
                  setIsNewQuote={setIsNewQuote}
                  truckListData={truckListData}
                  setTruckListData={setTruckListData}
                  setShowQuoteSuccess={setShowQuoteSuccess}
                  showQuoteSuccess={showQuoteSuccess}
                  formDataGL={formDataGL}
                  formDataAPD={formDataAPD}
                  formDataMTC={formDataMTC}
                  adjustmentFormData={adjustmentFormData}
                  setAdjustmentFormData={setAdjustmentFormData}
                />
              </div>
            )}

            {/* Page introduction paragraph */}
            {!isNewQuote && formData.documentType !== "Policy" && (
              <>
                <KPBrokersInformationSheetDownloadButton
                  formData={formData}
                  userInfo={userInfo}
                  insuredInfo={insuredInfo}
                />

                {formData?.signedProposalData?.status !== "complete" && (
                  <PreviousYearsAdjustmentsModal formData={formData} />
                )}

                <QuotesTable
                  loading={loading}
                  setLoading={setLoading}
                  hasALOrGL={hasALOrGL}
                  applyAdjustments={(e, quoteData) =>
                    applyAdjustments(
                      e,
                      quoteData,
                      policyID,
                      formData,
                      taxSchedule,
                      formDataGL
                    )
                  }
                  userInfo={userInfo}
                  quoteHistory={quoteHistory}
                  formData={formData}
                  setFormData={setFormData}
                  gotQuoteHistory={gotQuoteHistory}
                  policyID={policyID}
                  setIsNewQuote={setIsNewQuote}
                  isNewQuote={isNewQuote}
                  setQuoteHistory={setQuoteHistory}
                  setGotQuoteHistory={setGotQuoteHistory}
                  createNewQuote={(
                    e,
                    isReprint,
                    quoteAdjustmentFormData,
                    quoteData
                  ) => {
                    createNewQuote(
                      e,
                      isReprint,
                      setLoading,
                      quoteAdjustmentFormData,
                      setAdjustmentFormData,
                      truckListData,
                      setShowQuoteSuccess,
                      formData,
                      insuredInfo,
                      auth,
                      formDataGL,
                      setIsNewQuote,
                      isNewQuote,
                      quoteData,
                      formDataAPD,
                      formDataMTC
                    );
                  }}
                />
              </>
            )}
            {!isNewQuote && formData.documentType !== "Application" && (
              <PremiumsTable
                policyID={policyID}
                formData={formData}
                formDataGL={formDataGL}
                formDataAPD={formDataAPD}
                formDataMTC={formDataMTC}
              />
            )}
          </fieldset>
        </main>
      )}
    </>
  );
}
