import { TableBody, TableCell, TableRow } from "@mui/material";
import { Timestamp } from "firebase/firestore";
import React from "react";

export default function CustomTableBody({
  results,
  sortField,
  sortDirection,
  page,
  rowsPerPage,
}) {
  const sortedResults = results.slice().sort((a, b) => {
    const valueA = a[sortField];
    const valueB = b[sortField];

    if (valueA < valueB) {
      return sortDirection === "asc" ? -1 : 1;
    }
    if (valueA > valueB) {
      return sortDirection === "asc" ? 1 : -1;
    }
    return 0;
  });

  return (
    <>
      <TableBody>
        {sortedResults
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map((result) => (
            <TableRow
              key={result.id}
              sx={{ cursor: "pointer" }}
              onClick={() =>
                window.open(
                  `/underwriting/endorsements/${result.id}/${result.policyID}`,
                  "_blank"
                )
              }
            >
              <TableCell>{result.status}</TableCell>
              <TableCell>{result.endorsementNumber}</TableCell>
              <TableCell>{result.namedInsured}</TableCell>
              <TableCell>{result.type}</TableCell>
              <TableCell>
                {result.endoEffectiveDate && result.endoEffectiveDate.seconds
                  ? new Timestamp(
                      result.endoEffectiveDate.seconds,
                      result.endoEffectiveDate.nanoseconds || 0
                    )
                      .toDate()
                      .toString()
                      .slice(0, 15)
                  : ""}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </>
  );
}
