import { doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import calculateTaxesAPD from "./premiumCalculation/calculateTaxesAPD";
import calculateTaxesMTC from "./premiumCalculation/calculateTaxesMTC";

const calculateAPDMTCPremiums = async (
  policyID,
  formData,
  apdDetails,
  mtcDetails,
  updatePolicy,
  powerUnitCountWithAPD,
  powerUnitCountWithMTC
) => {
  const hasAPD = formData.hasAPDCoverage;
  const hasMTC = formData.hasMTCCoverage;
  const hasXS = formData.hasXSCoverage;

  const apdPremium = hasAPD ? (apdDetails.premiumPerPowerUnit * powerUnitCountWithAPD) : null;

  const mtcPremium = hasMTC ? (mtcDetails.premiumPerPowerUnit * powerUnitCountWithMTC) : null;

  const apdTaxes = await calculateTaxesAPD(
    policyID,
    formData,
    apdPremium,
    powerUnitCountWithAPD,
    updatePolicy
  );

  const mtcTaxes = await calculateTaxesMTC(
    policyID,
    formData,
    mtcPremium,
    powerUnitCountWithMTC,
    updatePolicy,
    hasXS
  );

  const apdReturn = {
    ...apdTaxes,
    ...apdDetails,
    totalPremiumBeforeTaxes: apdPremium,
  };

  const mtcReturn = {
    ...mtcTaxes,
    ...mtcDetails,
    totalPremiumBeforeTaxes: mtcPremium,
  };

  if (updatePolicy) {
    const mtcPolicyID = formData?.mtcPolicyID;
    const apdPolicyID = formData?.apdPolicyID;
    const mtcPolicyRef = !hasMTC ? null : doc(db, "mtc-policies", mtcPolicyID);
    const apdPolicyRef = !hasAPD ? null : doc(db, "apd-policies", apdPolicyID);
    hasMTC &&
      (await setDoc(
        mtcPolicyRef,
        {
          mtcPremiums: mtcReturn,
          GWP: mtcPremium,
        },
        { merge: true }
      ));
    hasAPD &&
      (await setDoc(
        apdPolicyRef,
        {
          apdPremiums: apdReturn,
          GWP: apdPremium,
        },
        { merge: true }
      ));
  }

  return [apdReturn, mtcReturn];
};

export default calculateAPDMTCPremiums;
