import React, { useState, useEffect } from "react";
import { Table, Tbody, Td, Thead, Tr } from "react-super-responsive-table";
import LoadingScreen from "react-loading-screen";
import classNames from "classnames";
import { collection, getDoc, onSnapshot, query, where, doc, setDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import useTranslations from "../../../Context/actions/useTranslations";
import { APPLICATION_ROUTE } from "../../../utils/routes";
import Pagination from "../../assets/lists/DynamicTable/Pagination";
import TableSkeleton from "../../generals/TableSkeleton";
import { activeFilters, handleFilterStatus, manageApplicationOptions } from "../../../utils/searchFilters";
import ListFilters from "../policies/ListFilters";
import { handleDocumentTypeStatus } from "../../../utils/constants";
import {
  Delete as DeleteIcon,
} from "@mui/icons-material";
import Popup from "../../generals/Popup";
import RedButton from "../../assets/forms/RedButton";
import useGeneralState from "../../../Context/actions/useGeneralState";
import ArchiveIcon from "@mui/icons-material/Archive";
import { Tooltip } from "@mui/material";

const applicationsPerPage = 10;

function ApplicationsList(props) {

  const { t, language, timezone } = useTranslations()
  const { controlToast } = useGeneralState()

  const initialSortConfig = {
    key: "",
    direction: "asc",
  };

  const [sortConfig, setSortConfig] = useState(initialSortConfig);
  const [searchTerm, setSearchTerm] = useState("");

  const {
    userInfo,
    applicationList,
    setApplicationList,
    loading,
    setLoading
  } = props

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const [currentFilters, setCurrentFilters] = useState({
    unsubmitted: true,
    submitted: true,
    returned: true,
    // fulfilled: true,
    inRenewal: true,
  });

  // TODO: Implement Lazy Loading
  useEffect(() => {
    if (userInfo.agencyID) {
      const filters = activeFilters(manageApplicationOptions, currentFilters)
      setLoading(true)
      const policiesCollection = collection(db, "policy-applications");
      const buildQuery = () => {
        let q = query(
          policiesCollection,
          where("documentType", "in", ["Application", "Quote Request", "Bind Order"]),
          where("status", "!=", "Not Underwritten")
        );
        if (
          userInfo.role !== 'Admin' &&
          userInfo.role !== 'Underwriter'
        ) {
          q = query(q, where("agencyID", "==", props.userInfo.agencyID));
        }
        if (filters.length > 0) {
          q = query(q, where("status", "in", filters));
        }
        return q;
      };
      const policiesQuery = buildQuery();
      const unsubscribe = onSnapshot(policiesQuery, (snapshot) => {
        const updatedapplicationListPromises = snapshot.docs.map(async (applicationDoc) => {
          const applicationData = applicationDoc.data();
          const { namedInsured, agencyID, ...rest } = applicationData;
          if (agencyID) {
            const insuredDocRef = doc(db, "agencies", userInfo.agencyID, "insureds", applicationData.insuredID);
            const agencyDocRef = doc(db, "agencies", userInfo.agencyID);
            const insuredDocSnapshot = await getDoc(insuredDocRef);
            const agencyDocSnapshot = await getDoc(agencyDocRef);
            const agencyData = agencyDocSnapshot.data()
            if (insuredDocSnapshot.exists()) {
              const companyName = insuredDocSnapshot.data().company;
              return {
                id: applicationDoc.id,
                namedInsured: companyName,
                insuredInfo: insuredDocSnapshot.data(),
                agencyInfo: agencyData,
                ...rest,
              };
            }
          }
          return null;
        });
        Promise.all(updatedapplicationListPromises).then((updatedapplicationList) => {
          const filteredApplications = updatedapplicationList.filter(Boolean)
          const notDeletedApplications = filteredApplications
            .filter(application => application.status !== "Deleted")
          setApplicationList(notDeletedApplications);
          setLoading(false)
        });
      });
      return () => unsubscribe();
    }
  }, [userInfo.agencyID, currentFilters]);

  const filteredData = applicationList.filter((application) =>
    `${application.status} ${application.documentType} ${application.namedInsured} ${application.effectiveDate} ${application.totalWrittenPremium} ${application.insuredInfo.dot}`
      .toLowerCase()
      .includes(searchTerm.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    if (sortConfig.direction === "asc") {
      return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
    } else {
      return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
    }
  });

  const [currentPage, setCurrentPage] = useState(0);
  const offset = currentPage * applicationsPerPage;
  const currentItems = sortedData.slice(offset, offset + applicationsPerPage);

  const pageCount = Math.ceil(sortedData.length / applicationsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [hoveredRow, setHoveredRow] = useState(null);

  const handleMouseEnter = (index) => {
    setHoveredRow(index);
  };

  const handleMouseLeave = () => {
    setHoveredRow(null);
  };
  const navigate = useNavigate();
  const handleRowClick = (application) => {
    navigate(`${APPLICATION_ROUTE}/${application.id}`);
  };

  const [applicationData, setApplicationData] = useState(null)
  const [openDeletionModal, setOpenDeletionModal] = useState(false)
  const [deletionLoading, setDeletionLoading] = useState(false)

  const handleOpenDeletionModal = (event, application) => {
    event.stopPropagation();
    setApplicationData(application)
    setOpenDeletionModal(true)
  }

  const handleCloseDeletionModal = () => {
    setOpenDeletionModal(false)
    setApplicationData(null)
  }

  const handleSubmitDeletion = async () => {
    try {
      setDeletionLoading(true);
      const policyRef = doc(db, "policy-applications", applicationData.id);
      await setDoc(policyRef, {
        status: "Deleted",
        deletedDate: new Date(),
      }, { merge: true });
      controlToast(true, t('manage-applications.lists.alerts.deleted-successfully'), "success");
      setDeletionLoading(false);
      handleCloseDeletionModal();
    } catch (error) {
      console.error('Delete the application', error)
      controlToast(true, t('manage-applications.lists.alerts.deletion-error'), "success");
      setDeletionLoading(false);
    }
  }

  const handleArchiveApplication = async (e, applicationId) => {
    e.preventDefault();
    try {
      const policyRef = doc(db, "policy-applications", applicationId);
      await setDoc(policyRef, {
        status: "Archived",
        archivedDate: new Date(),
      }, { merge: true });
      controlToast(true, t('Application archived.'), "success");
    } catch (error) {
      console.error('Archive the application', error)
      controlToast(true, t('manage-applications.lists.alerts.archived-error'), "success");
    }
  }

  return (
    <>
      <Popup
        isOpen={openDeletionModal}
        onRequestClose={() => {
          if (!deletionLoading) {
            handleCloseDeletionModal()
          }
        }}
        maxWidth='100%'
      >
        <div className="h-[300px] flex justify-center items-center">
          <LoadingScreen
            loading={deletionLoading}
            bgColor="#f1f1f1"
            spinnerColor="#9ee5f8"
            textColor="#676767"
            padding="0"
            logoSrc=""
            text={t('manage-applications.lists.alerts.printing')}
          >
            <p className="text-2xl mt-2 font-semibold mb-10">
              {t('manage-applications.lists.alerts.sure')}
            </p>
            <RedButton
              text={t('plm.drivers.delete')}
              onClickFunction={handleSubmitDeletion}
              hasContentToBottom={true}
              className={"w-[100%]"}
            />
          </LoadingScreen>
        </div>
      </Popup>
      <div>
        <ListFilters
          options={manageApplicationOptions}
          currentFilters={currentFilters}
          setCurrentFilters={setCurrentFilters}
        />
      </div>
      {loading ? <TableSkeleton rows={5} columns={5} /> :
      applicationList.length === 0 ? (
        <p>{t('manage-applications.no-applications')}</p>
      ) : (
        <div>
          <div className="mb-2">
            <h2 className="text-2xl font-semibold mb-1">{t('manage-applications.application-list')}</h2>
          </div>
          <input
            type="text"
            placeholder={t('manage-applications.search')}
            value={searchTerm}
            onChange={handleSearch}
            className="mb-2 p-2 border rounded w-full"
          />
          <Table>
            <Thead>
              <Tr>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white rounded-tl-md cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "status",
                      "bg-blue-500": sortConfig.key === "status",
                    }
                  )}
                  onClick={() => handleSort("status")}
                >
                  {t('manage-applications.status')}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "documentType",
                      "bg-blue-500": sortConfig.key === "documentType",
                    }
                  )}
                  onClick={() => handleSort("documentType")}
                >
                  {t('manage-applications.doc-type')}
                </Td>
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "namedInsured",
                      "bg-blue-500": sortConfig.key === "namedInsured",
                    }
                  )}
                  onClick={() => handleSort("namedInsured")}
                >
                  {t('manage-applications.named-insured')}
                </Td>
                {(
                  userInfo.role === "Admin" ||
                  userInfo.role === "Underwriter"
                ) ? (
                  <Td
                    className={classNames(
                      "bg-[#072a48] text-white cursor-pointer",
                      {
                        "hover:bg-[#072a48]": sortConfig.key !== "agencyName",
                        "bg-blue-500": sortConfig.key === "agencyName",
                      }
                    )}
                    onClick={() => handleSort("agencyName")}
                  >
                    {t('manage-applications.lists.agency-name')}
                  </Td>
                ) : null}
                <Td
                  className={classNames(
                    "bg-[#072a48] text-white cursor-pointer",
                    {
                      "hover:bg-[#072a48]": sortConfig.key !== "effectiveDate",
                      "bg-blue-500": sortConfig.key === "effectiveDate",
                    }
                  )}
                  onClick={() => handleSort("effectiveDate")}
                >
                  {t('manage-applications.effective-date')}
                </Td>
                <Td
                  className={classNames(
                    `bg-[#072a48] text-white cursor-pointer ${userInfo.role !== "Admin" ? "rounded-tr-md" : ""}`,
                    {
                      "hover:bg-[#072a48]":
                        sortConfig.key !== "dot",
                      "bg-blue-500": sortConfig.key === "dot",
                    }
                  )}
                  onClick={() => handleSort("dot")}
                >
                  {t('manage-applications.lists.dot')}
                </Td>
                {userInfo.role === "Admin" ?
                  <Td className={classNames("bg-[#072a48] text-white rounded-tr-md cursor-pointer")}>
                    {t('manage-applications.lists.actions')}
                  </Td> : null
                }
              </Tr>
            </Thead>

            <Tbody>
              {currentItems.map((application, index) => (
                <Tr
                  key={application.id}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleRowClick(application)}
                  style={{
                    cursor: "pointer",
                    backgroundColor:
                      hoveredRow === index ? "#e6f7ff" : "inherit",
                  }}
                >
                  <Td>{handleFilterStatus(application?.status, manageApplicationOptions, language)}</Td>
                  <Td>{handleDocumentTypeStatus(application?.documentType, language)}</Td>
                  <Td>{application?.namedInsured}</Td>
                  {(
                    userInfo.role === "Admin" ||
                    userInfo.role === "Underwriter"
                  ) ?
                    <Td title={application.agencyID}>{application?.agencyInfo?.name} </Td> : null
                  }
                  <Td>
                    {application?.effectiveDate && (
                      <>
                        {application?.effectiveDate
                          ?.toDate()
                          .toLocaleDateString(timezone, { month: 'short', day: '2-digit', year: 'numeric', })}
                      </>
                    )}{" "}
                  </Td>
                  <Td>{application?.insuredInfo?.dot}</Td>
                  {userInfo.role === "Admin" ?
                    <Td>
                      <Tooltip title="Delete Application">
                      <DeleteIcon
                        sx={{ color: '#8B0000' }}
                        onClick={(e) => handleOpenDeletionModal(e, application)}
                      />
                      </Tooltip>
                      <Tooltip title="Archive Application">
                      <ArchiveIcon
                        sx={{ color: '#8B0000' }}
                        onClick={(e) => handleArchiveApplication(e, application)}
                      />
                      </Tooltip>
                    </Td> : null
                  }
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Pagination
            pageCount={pageCount}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        </div>
      )}
    </>
  );
}

export default ApplicationsList;
