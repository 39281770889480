const buildDetailIndividualObject = (
  detail,
  policyInfo,
  typeOfProcess,
  data,
  renewal,
  endoEffectiveDate
) => {
  let detailPayload = { ...detail }
  if (!detail) {
    detailPayload = {
      id: policyInfo?.policyID,
    }
  } else {
    detailPayload = { ...detail }
  }

  if (data?.truckVIN) {
    let payload = data
    if (renewal) {
      payload = payload?.policiesDetails?.find((detail) => {
        return detail?.id === policyInfo?.id
      })
    }
    if (payload?.rating) detailPayload["rating"] = payload?.rating
    if (payload?.truckACV) detailPayload["truckACV"] = payload?.truckACV
    if (payload?.truckClass) detailPayload["truckClass"] = payload?.truckClass
    if (payload?.truckLookupStatus) detailPayload["truckLookupStatus"] = payload?.truckLookupStatus
    if (payload?.truckMake) detailPayload["truckMake"] = payload?.truckMake
    if (payload?.truckModel) detailPayload["truckModel"] = payload?.truckModel  
    if (payload?.truckRadius) detailPayload["truckRadius"] = payload?.truckRadius
    if (payload?.fleetCredit) detailPayload["fleetCredit"] = payload?.fleetCredit
    if (payload?.adjustedRate) detailPayload["adjustedRate"] = payload?.adjustedRate
    if (payload?.documentSource) detailPayload["documentSource"] = payload?.documentSource
    if (payload?.nonOwnedAdjustment) detailPayload["nonOwnedAdjustment"] = payload?.nonOwnedAdjustment
    if (payload?.oldRadius) detailPayload["oldRadius"] = payload?.oldRadius
  }

  if (policyInfo?.status)
    detailPayload["status"] = policyInfo?.status;
  if (policyInfo?.alPremiums)
    detailPayload["alPremiums"] = policyInfo?.alPremiums
  if (policyInfo?.glPremiums)
    detailPayload["glPremiums"] = policyInfo?.glPremiums
  if (policyInfo?.documentSource)
    detailPayload["documentSource"] = policyInfo?.documentSource
  if (policyInfo?.coverageSingleLimit)
    detailPayload["coverageSingleLimit"] = policyInfo?.coverageSingleLimit
  if (policyInfo?.documentType)
    detailPayload["documentType"] = policyInfo?.documentType
  if (policyInfo?.agencyID)
    detailPayload["agencyID"] = policyInfo?.agencyID
  if (policyInfo?.insuredID)
    detailPayload["insuredID"] = policyInfo?.insuredID
  // if (policyInfo?.bindData)
  //   detailPayload["bindData"] = policyInfo?.bindData
  if (typeOfProcess === 'direct-addition') {
    detailPayload["addedDate"] = new Date();
    detailPayload["removedDate"] = null;
  } else if (typeOfProcess === 'endo-addition') {
    detailPayload["addedDate"] =
      endoEffectiveDate ? endoEffectiveDate :
      detail?.addedDate ? detail?.addedDate :
      new Date();
    detailPayload["removedDate"] = null;
  } if (typeOfProcess === 'direct-deletion') {
    detailPayload["addedDate"] = detail?.addedDate ? detail?.addedDate : new Date();
    detailPayload["removedDate"] = new Date();
  } else if (typeOfProcess === 'endo-deletion') {
    detailPayload["addedDate"] = detail?.addedDate ? detail?.addedDate : new Date();
    detailPayload["removedDate"] =
      endoEffectiveDate ? endoEffectiveDate :
      new Date()
  }
  return detailPayload
}

export const setPolicyInfo = (
  data,
  policyInfo,
  typeOfProcess,
  renewal = false,
  endoEffectiveDate
) => {
  const payload = { ...data };

  if (
    payload?.policies?.length &&
    payload?.policies?.length > 0 &&
    (
      policyInfo?.status === "Active"
    )
  ) {
    payload["policies"] = [
      ...payload?.policies,
      policyInfo?.policyID,
    ];
  } else if (policyInfo?.status === "Active") {
    payload["policies"] = [policyInfo?.policyID];
  } else if (payload?.policies && policyInfo?.status !== "Active") {
    payload["policies"] = payload?.policies.filter(policy => policy !== policyInfo?.policyID)
  }

  let thereWasAMatchInDetails = false

  const newPoliciesDetails = payload?.policiesDetails?.map((detail) => {
    if (
      detail?.id === policyInfo?.policyID ||
      detail?.policyID === policyInfo?.policyID
    ) {
      thereWasAMatchInDetails = true
      const newDetailPayload = buildDetailIndividualObject(
        detail,
        policyInfo,
        typeOfProcess,
        payload,
        renewal,
        endoEffectiveDate
      )
      return newDetailPayload
    } else {
      return detail
    }
  }) || []

  if (!thereWasAMatchInDetails) {
    const newDetailPayload = buildDetailIndividualObject(
      null,
      policyInfo,
      typeOfProcess,
      payload,
      renewal,
      endoEffectiveDate
    )
    newPoliciesDetails.push(newDetailPayload)
  }

  payload["policiesDetails"] = newPoliciesDetails;
  if (policyInfo?.policyEffectiveDate) {
    payload["effectiveDate"] = policyInfo?.policyEffectiveDate;
  }
  if (policyInfo?.bindData) {
    payload["bindData"] = policyInfo?.bindData;
  }
  if (policyInfo?.powerUnitCountAtBind) {
    payload["powerUnitCountAtBind"] = policyInfo?.powerUnitCountAtBind;
  }

  if (data?.truckVIN) {
    if (data?.truckVIN) payload["truckVIN"] = data?.truckVIN
    if (data?.truckWeight) payload["truckWeight"] = data?.truckWeight
    if (data?.truckYear) payload["truckYear"] = data?.truckYear
    if (data?.rating) delete payload.rating
    if (data?.truckACV) delete payload.truckACV
    if (data?.truckClass) delete payload.truckClass
    if (data?.truckLookupStatus) delete payload.truckLookupStatus
    if (data?.truckMake) delete payload.truckMake
    if (data?.truckModel) delete payload.truckModel
    if (data?.truckRadius) delete payload.truckRadius
    if (data?.fleetCredit) delete payload.fleetCredit
    if (data?.adjustedRate) delete payload.adjustedRate
    if (data?.documentSource) delete payload.documentSource
    if (data?.nonOwnedAdjustment) delete payload.nonOwnedAdjustment
  }

  return payload;
};

export const setPolicyInfoForEndorsement = (payload, policyID) => {
  const data = JSON.parse(JSON.stringify(payload));
  if (payload?.['policiesDetails']) {
    const policiesDetails = payload?.['policiesDetails']?.filter((detail) => {
      return detail.id === policyID
    })
    data["policiesDetails"] = policiesDetails;
  }
  return data;
}

export const setPolicyDetailUpdate = (payload, payloadWithPolicyDetails, policyDetailToUpdate) => {
  const data = JSON.parse(JSON.stringify(payload));
  if (policyDetailToUpdate) {
    data['policiesDetails'] = payloadWithPolicyDetails?.['policiesDetails']?.map((detail) => {
      if (detail?.id === policyDetailToUpdate?.id) {
        return policyDetailToUpdate
      } else {
        return detail
      }
    })
  }
  delete data?.driverDOB
  delete data?.dob
  return data;
}

export const buildShowingInfo = (values, policyID, renew = false) => {
  const filteredValues = []
  values.forEach((value) => {
    let thisValueWillBeAdded = false
    value?.policiesDetails?.forEach((detail) => {
      const validPolicyDetail = (
        (detail?.policyID === policyID || detail?.id === policyID) &&
        (
          renew ?
          detail?.status === "Active" :
          (
            detail?.status === "Active" ||
            detail?.status === "Pending" ||
            detail?.status === "Pending Deletion" ||
            detail?.status === "Pending Exclusion"
          )
        )
      );
      if (validPolicyDetail) {
        thisValueWillBeAdded = true;
      }
    });
    if (thisValueWillBeAdded) {
      filteredValues.push(value)
    }
  })
  return filteredValues
}

export const buildShowingInfoJustActiveOnes = (values, policyID) => {
  const filteredValues = []
  values.forEach((value) => {
    let thisValueWillBeAdded = false
    value?.policiesDetails?.forEach((detail) => {
      const validPolicyDetail = (
        (detail?.policyID === policyID || detail?.id === policyID) &&
        detail?.status === "Active"
      );
      if (validPolicyDetail) {
        thisValueWillBeAdded = true;
      }
    });
    if (thisValueWillBeAdded) {
      filteredValues.push(value)
    }
  })
  return filteredValues
}

export const filterRightPoliciesDetails = (policiesDetails, policyID) => {
  const chosenPolicyDetail = policiesDetails.find((detail) => {
    const validPolicyDetail = (
      (detail?.policyID === policyID || detail?.id === policyID) &&
      (
        detail?.status === "Active" ||
        detail?.status === "Pending" ||
        detail?.status === "Pending Deletion" ||
        detail?.status === "Pending Exclusion"
      )
    );
    return validPolicyDetail
  });
  return chosenPolicyDetail
}
