import React, { useEffect, useState } from "react";
import BigAlert from "../../generals/Toast/BigAlert";
import "react-datepicker/dist/react-datepicker.css";
import { setPolicyInfo } from "./setPolicyInfo";
import FormPopup from "../../generals/Popup/FormPopup";
import Step1 from "./addDriverSteps/Step1";
import Step2 from "./addDriverSteps/Step2";
import Step3 from "./addDriverSteps/Step3";
import Breadcrumbs from "../../assets/Breadcrumbs";
import useLossControlListener from "../../utils/useLossControlListener";
import useTranslations from "../../../Context/actions/useTranslations";
import Step0 from "./addDriverSteps/Step0";
import ApiService from "../../../services/southern-api/apiService";
import { parseFullName } from "../../../pages/Converter/readOldInspasData";

export default function AddDriver(props) {

  const {
    setShowAddDriver,
    setShowTable,
    isEndoManager,
    formData,
    driverList,
    policyID,
    insuredInfo,
    necessaryValuesInsideEndorsement,
    setSelectedItem
  } = props

  const [success, setSuccess] = useState(false);
  const [endoEffectiveDate, setEndoEffectiveDate] = useState(new Date());
  const [dobDate, setDobDate] = useState(new Date());
  const [
    medicalLongFormExpirationDate,
    setMedicalLongFormExpirationDate
  ] = useState(new Date());
  const [doaDate, setDoaDate] = useState(new Date());
  const [licenseExpirationDate, setLicenseExpirationDate] = useState(
    new Date()
  );
  const [driverData, setDriverData] = useState({
    licenseExpiration: new Date(),
  });
  const [progresspercent, setProgresspercent] = useState(0);

  const goBack = (e) => {
    e.preventDefault();
    setDriverData({});
    setShowAddDriver(false);
    setShowTable(true);
    if (setSelectedItem) {
      setSelectedItem(null);
    }
  };

  const { t } = useTranslations();

  const [showStep0, setShowStep0] = useState(true);
  const [showStep1, setShowStep1] = useState(false);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [fileObjectsLicenseFront, setFileObjectsLicenseFront] = useState([]);
  const [fileObjectsLicenseBack, setFileObjectsLicenseBack] = useState([]);
  const [fileObjectsMvr, setFileObjectsMvr] = useState([]);
  const [fileObjectsCaDriverAbstract, setFileObjectsCaDriverAbstract] =
    useState([]);
  const [fileObjectsMedicalForm, setFileObjectsMedicalForm] = useState([]);
  const [fileObjectsAdditionalForm, setFileObjectsAdditionalForm] = useState([]);
  const [fileObjectsOtherDocuments, setFileObjectsOtherDocuments] = useState([]);
  const [openModalAddDriverException, setOpenModalAddDriverException] =
    useState(false);
  const handleCloseAddDriverModal = () => {
    setOpenModalAddDriverException(false);
  };

  const handleAddValue = (e, value) => {
    e.preventDefault();
    setDriverData({
      ...driverData,
      exceptionReason: value["exception-reason"]
    });
    goToSecondStep();
    handleCloseAddDriverModal();
  };

  const addDriverModal = [
    {
      label: "Add a reason for exception",
      type: "text",
      name: "exception-reason",
      required: true,
    }
  ];

  const formSettingsAddDriver = {
    onSubmit: handleAddValue,
    inputs: addDriverModal,
    buttonLabel: "Add driver exception",
  };

  const goToFirstStep = () => {
    setShowStep0(false);
    setShowStep1(true);
  };

  const goToSecondStep = () => {
    setShowStep1(false);
    setShowStep2(true);
  };

  const breadcrumbs = [
    { label: formData.documentType },
    { label: "Drivers" },
    { label: "Add Driver" },
  ];

  const [fmcsaDriverFitness, setFmcsaDriverFitness] = useState([]);

  useLossControlListener(
    "driverData",
    "fmcsaDriverFitness",
    setFmcsaDriverFitness
  );

  const activeDriversWithAtLeas5YearsOfExperience = driverList.filter((driver) => {
    const validPolicyDetail = driver.policiesDetails.find((policyDetail) => {
      return policyDetail.id === policyID && policyDetail.status === "Active"
    })
    const isAtLeast5Years = driver.yoe >= 5

    const driverFitnessMatch = fmcsaDriverFitness.find((fitness) => {
      const fitnessLicenseNumber =
        fitness.nacionality === "Mexicano" ?
        fitness.drvLicMx : fitness.drvLicUs
      return fitnessLicenseNumber === driver.licenseNumber
    })

    const isFitnessDriverInNotTraining =
      driverFitnessMatch ?
      driverFitnessMatch.inTraining === 'NO ONGOING TRAINING' : true

    return Boolean(
      validPolicyDetail &&
      isAtLeast5Years &&
      isFitnessDriverInNotTraining
    )
  })

  const selectInsureds = [
    'ARIZPE TRANSPORT AND LOGISTICS LLC',
    'FELHER TRANSPORTATION INC',
    'ROLESA TRANSPORT LLC',
    'DETA LOGIS PLUS LLC',
    'DETA LOGISTICS LLC',
    'TRADEX FREIGHT INC',
    'TRADEX LOGISTICS INC',
  ]
  const isSelectInsured = selectInsureds.includes(insuredInfo?.company || '')
  
  const appearATutorInput = Boolean(
    activeDriversWithAtLeas5YearsOfExperience.length > 0 &&
    isSelectInsured &&
    driverData.yoe < 2
  )

  useEffect(() => {
    if (!appearATutorInput) {
      setDriverData({
        ...driverData,
        tutor: ''
      })
    }
  }, [driverData.yoe])


  const apiService = new ApiService()

  function getExpirationDate(dates) {
    try {
      if (!Array.isArray(dates) || dates.length !== 2) {
        return null;
      }
      const parsedDates = dates.map(date => {
        const [day, month, year] = date.split('-').map(num => parseInt(num, 10));
        const parsedDate = new Date(year, month - 1, day);
        if (isNaN(parsedDate)) {
          return null;
        }
        return parsedDate;
      });
      if (parsedDates.includes(null)) {
        return null;
      }
      const expirationDate = new Date(Math.max(...parsedDates));
      return expirationDate;
    } catch (error) {
      return null;
    }
  }

  const getNationality = (text) => {
    const upperText = text.toUpperCase();
    if (upperText.includes("ESTADOUNIDENSE") || upperText.includes("ESTADOS UNIDOS DE AMÉRICA")) {
      return "USA";
    }
    if (upperText.includes("CANADIENSE") || upperText.includes("CANADÁ")) {
      return "CA";
    }
    if (upperText.includes("MEXICANO") || upperText.includes("MÉXICO")) {
      return "MX";
    }
    return "MX"
  }

  function getFirstType(text) {
    const match = text.match(/([A-Z])\)/);
    if (match) {
      return match[1];
    }
    return '';
  }

  function parseAntiquityDate(dateString) {
    try {
      const [day, month, year] = dateString.split('-').map(num => parseInt(num, 10));
      const parsedDate = new Date(year, month - 1, day);
      if (isNaN(parsedDate)) {
        return null;
      }
      return parsedDate;
    } catch (error) {
      return null;
    }
  }

  function getBirthDateFromCURP(curp) {
    if (!curp || curp.length !== 18) return null;
    const yearPrefix = curp.substring(4, 6);
    const month = curp.substring(6, 8);
    const day = curp.substring(8, 10);
    let year = parseInt(yearPrefix, 10) >= 40 ? `19${yearPrefix}` : `20${yearPrefix}`;
    return new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10));
  }
  
  const handleMexicanDriverParsing = async () => {
    try {
      const pdfUrl = fileObjectsLicenseFront[0]
      const result = await apiService.parseMexicanDriver({ pdfUrl })
      const data = result.data
      const licenseExpiration = getExpirationDate(data.licenseValidity);
      const licenseClass = getFirstType(data.type);
      const doa = parseAntiquityDate(data.antiquity);
      const licenseCountry = getNationality(data.nationality);
      const mxNMP = data.medicalFileNumber;
      const {
        firstName,
        lastName,
      } = parseFullName(data.fullName)
      const dob = getBirthDateFromCURP(data.curp)
      setDoaDate(doa)
      setDobDate(dob)
      setLicenseExpirationDate(licenseExpiration)
      setDriverData({
        ...driverData,
        licenseNumber: data.licenseNumber,
        driverFirst: firstName,
        driverLast: lastName,
        curp: data.curp,
        category: data.category,
        doa,
        dob,
        licenseClass,
        licenseExpiration,
        mxNMP,
        licenseCountry,
      })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (fileObjectsLicenseFront.length > 0) {
      handleMexicanDriverParsing()
    }
  }, [fileObjectsLicenseFront])

  useEffect(() => {
    if (
      necessaryValuesInsideEndorsement?.editUI &&
      necessaryValuesInsideEndorsement?.selectedItem
    ) {
      const initialData = {...necessaryValuesInsideEndorsement?.selectedItem}
      const initial = {...initialData}
      if (initialData.dob) {
        initial.dob = initialData.dob.toDate()
        setDobDate(initialData.dob?.toDate() || new Date())
      }
      if (initialData.licenseExpiration) {
        initial.licenseExpiration = initialData.licenseExpiration.toDate()
        setLicenseExpirationDate(initialData.licenseExpiration?.toDate() || new Date())
      }
      if (initialData.doa) {
        initial.doa = initialData.doa.toDate()
        setDoaDate(initialData.doa?.toDate() || new Date())
      }
      if (initialData.medicalLongFormExpiration) {
        initial.medicalLongFormExpiration = initialData.medicalLongFormExpiration.toDate()
        setMedicalLongFormExpirationDate(initialData.medicalLongFormExpiration?.toDate() || new Date)
      }
      setDriverData(initial)
    }
  }, [
    necessaryValuesInsideEndorsement?.editUI,
    necessaryValuesInsideEndorsement?.selectedItem
  ])

  return (
    <>
      <FormPopup
        isOpen={openModalAddDriverException}
        formSettings={formSettingsAddDriver}
        onRequestClose={handleCloseAddDriverModal}
        title="Driver has less than 2 years of experience, provide a reason to add it."
      />
      {success ? (
        <BigAlert
          title="Your endorsement has been successfully submitted."
          subtitle="An underwriter will review your endorsement, and you will receive a notification when the status is updated."
          okButton="OK"
          className="mt-2"
          status="successful"
          fn={goBack}
        />
      ) : (
        <main>
          <header>
            {!isEndoManager && !necessaryValuesInsideEndorsement ? <Breadcrumbs items={breadcrumbs} /> : null}
            <>
              {necessaryValuesInsideEndorsement?.editUI ?
                <h1
                  className={`${
                    isEndoManager
                      ? "text-2xl mt-2.5 font-bold md:mb-0"
                      : "text-3xl mt-2 font-semibold md:mb-0"
                  }`}
                >
                  Edit Driver
                </h1> :
                <h1
                  className={`${
                    isEndoManager
                      ? "text-2xl mt-2.5 font-bold md:mb-0"
                      : "text-3xl mt-2 font-semibold md:mb-0"
                  }`}
                >
                  {!isEndoManager ? `${t("Add Driver")}` : `${t("Add Driver")}`}
                </h1>
              }
            </>
            <p className="mt-2 mb-3">
              {(formData.documentType === "Application" ||
                formData.documentType === "Policy") && (
                <>
                  {necessaryValuesInsideEndorsement?.editUI ?
                    "Edit the driver information." :
                    t('plm.drivers.details.add-driver')
                  }
                </>
              )}
              {formData.documentType !== "Application" &&
                formData.documentType !== "Policy" && (
                  <>{t('plm.drivers.details.quoting-phase')}</>
                )}
            </p>
          </header>
          <section className="card mx-auto max-w-[750px] mb-4 shadow-lg">
            <div className="card-body">
              {showStep0 && (
                <Step0
                  parentProps={props}
                  driverData={driverData}
                  setDriverData={setDriverData}
                  goToFirstStep={goToFirstStep}
                  goBack={goBack}
                  fileObjectsLicenseFront={fileObjectsLicenseFront}
                  setFileObjectsLicenseFront={setFileObjectsLicenseFront}
                />
              )}
              {showStep1 && (
                <Step1
                  parentProps={props}
                  driverData={driverData}
                  setDriverData={setDriverData}
                  setEndoEffectiveDate={setEndoEffectiveDate}
                  endoEffectiveDate={endoEffectiveDate}
                  dobDate={dobDate}
                  setDobDate={setDobDate}
                  setOpenModalAddDriverException={setOpenModalAddDriverException}
                  goToSecondStep={goToSecondStep}
                  progresspercent={progresspercent}
                  goBack={goBack}
                  setShowStep0={setShowStep0}
                  setShowStep1={setShowStep1}
                  setShowStep2={setShowStep2}
                  setShowStep3={setShowStep3}
                  goToFirstStep={goToFirstStep}
                  appearATutorInput={appearATutorInput}
                  activeDriversWithAtLeas5YearsOfExperience={
                    activeDriversWithAtLeas5YearsOfExperience
                  }
                  medicalLongFormExpirationDate={medicalLongFormExpirationDate}
                  setMedicalLongFormExpirationDate={setMedicalLongFormExpirationDate}
                  necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
                />
              )}
              {showStep2 && (
                <Step2
                  driverData={driverData}
                  setDriverData={setDriverData}
                  licenseExpirationDate={licenseExpirationDate}
                  setLicenseExpirationDate={setLicenseExpirationDate}
                  progresspercent={progresspercent}
                  goBack={goBack}
                  setShowStep2={setShowStep2}
                  setShowStep3={setShowStep3}
                  setShowStep1={setShowStep1}
                  driverList={driverList}
                  doa={doaDate}
                  setDoa={setDoaDate}
                  necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
                />
              )}
              {showStep3 && (
                <Step3
                  parentProps={props}
                  driverData={driverData}
                  setDriverData={setDriverData}
                  fileObjectsMedicalForm={fileObjectsMedicalForm}
                  fileObjectsLicenseBack={fileObjectsLicenseBack}
                  fileObjectsLicenseFront={fileObjectsLicenseFront}
                  fileObjectsMvr={fileObjectsMvr}
                  fileObjectsCaDriverAbstract={fileObjectsCaDriverAbstract}
                  fileObjectsOtherDocuments={fileObjectsOtherDocuments}
                  setFileObjectsMedicalForm={setFileObjectsMedicalForm}
                  setFileObjectsLicenseBack={setFileObjectsLicenseBack}
                  setFileObjectsLicenseFront={setFileObjectsLicenseFront}
                  setFileObjectsMvr={setFileObjectsMvr}
                  setFileObjectsCaDriverAbstract={setFileObjectsCaDriverAbstract}
                  fileObjectsAdditionalForm={fileObjectsAdditionalForm}
                  setFileObjectsAdditionalForm={setFileObjectsAdditionalForm}
                  setFileObjectsOtherDocuments={setFileObjectsOtherDocuments}
                  endoEffectiveDate={endoEffectiveDate}
                  setSuccess={setSuccess}
                  setPolicyInfo={setPolicyInfo}
                  goBack={goBack}
                  setShowStep0={setShowStep0}
                  setShowStep1={setShowStep1}
                  setShowStep2={setShowStep2}
                  setShowStep3={setShowStep3}
                  setEndoEffectiveDate={setEndoEffectiveDate}
                  setProgresspercent={setProgresspercent}
                  setLicenseExpirationDate={setLicenseExpirationDate}
                  setDobDate={setDobDate}
                  appearATutorInput={appearATutorInput}
                  formData={formData}
                  necessaryValuesInsideEndorsement={necessaryValuesInsideEndorsement}
                />
              )}
            </div>
          </section>
        </main>
      )}
    </>
  );
}
